import { IOperation, Refashion } from "types/accounting.types";
import styles from "./refashionSelect.module.scss";
import REFASHION_LOGO from "assets/bonus-reparation-300x182.png";
import { REFASHION_OPERATIONS_PRICE } from "utils/operation.init";
import { UnfoldMore } from "@mui/icons-material";
import { useState } from "react";
import useElementDimensions from "hooks/useElementDimensions";
import clsx from "clsx";
import Button from "components/Button/Button";
import DropDownPortal from "components/DropDownPortal/DropDownPortal";
import BeautifulDiv from "components/beautifulDiv/BeautifulDiv";

interface RefashionSectionProps {
  className?: string;
  operation: IOperation;
  onRefashionChange?: (refashionOperation: Refashion) => void;
}

export default function RefashionSection({
  className,
  operation,
  onRefashionChange,
}: RefashionSectionProps) {
  const [expand, setExpand] = useState<boolean>(false);
  const [refDrop, widthDrop] = useElementDimensions<HTMLUListElement>([expand]);
  const [ref, width] = useElementDimensions<HTMLDivElement>([expand]);

  if (!onRefashionChange) {
    return <></>;
  }

  return (
    <div className={clsx(styles["select-state"], className)} ref={ref}>
      <div className={styles["select-state-container"]}>
        <Button
          className={styles["state-button"]}
          onClick={handleToggleDropDown}
        >
          <img className={styles["discount-logo"]} src={REFASHION_LOGO}></img>
          <p>{operation.refashionOperation || "N/A"}</p>
          <UnfoldMore className={styles["icon"]} />
        </Button>
      </div>
      <DropDownPortal<HTMLDivElement>
        buttonRef={ref}
        className={styles["state-select-dropdown"]}
        expand={expand}
        onClickOutside={handleCloseDropDown}
        offset={{ top: 4, left: (widthDrop || 0) - (width || 0) }}
      >
        <ul className={styles["dropdown"]} ref={refDrop}>
          {REFASHION_OPERATIONS_PRICE.map((op, index) => (
            <div
              className={clsx(
                styles["dropdown-item"],
                op.refashionOperation === operation.refashionOperation &&
                  styles["selected"]
              )}
              key={index}
              onClick={() => handleSelectRefashion(op as Refashion)}
            >
              {op.refashionProductCategory} - {op.refashionDiscount / 100} €
            </div>
          ))}
        </ul>
      </DropDownPortal>
    </div>
  );

  function handleToggleDropDown() {
    setExpand(!expand);
  }

  function handleCloseDropDown() {
    setExpand(false);
  }

  function handleSelectRefashion(refashionOperation: Refashion) {
    onRefashionChange && onRefashionChange(refashionOperation);
    setExpand(false);
  }
}
