import { Add, Delete, Remove } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import ColorComponent from "components/colorComponent/colorComponent";
import Image from "components/image/Image";
import Input from "components/inputs/Input/Input";
import { getCrossSellsList } from "requests/crossSelling";
import { ICrossSellProduct } from "types/accounting.types";
import styles from "./crossSellEntry.module.scss";

interface CrossSellEntryProps {
  crossSell: ICrossSellProduct;
  onChange: (crossSell: ICrossSellProduct) => void;
  onDelete: () => void;
}

export default function CrossSellEntry({
  crossSell,
  onChange,
  onDelete,
}: CrossSellEntryProps) {
  const { data } = useQuery({
    queryKey: ["cross-sell"],
    queryFn: () => getCrossSellsList(),
  });

  if (!data) {
    return null;
  }

  const { crossSells } = data;

  return (
    <div className={styles["cross-sell-entry"]}>
      <Image
        src={crossSell.image}
        className={styles["image"]}
        showTools={false}
      />
      <div>
        <div className={styles["inputs"]}>
          <Input
            placeholder={""}
            label="Description"
            onChange={handleOnChange}
            name={"description"}
            value={crossSell.description}
            autoComplete="off"
            list="cross-sell-description"
            className={styles["cross-sell-input"]}
          />
          <datalist id={"cross-sell-description"}>
            {crossSells.map((cs: ICrossSellProduct, key: number) => {
              return (
                <option key={key} className={styles["datalist-item"]}>
                  {cs.slug || ""}
                </option>
              );
            })}
          </datalist>
          <Input
            className={styles["price"]}
            type="number"
            name="price"
            label="Prix"
            onChange={handleOnChange}
            value={crossSell.price}
          />
        </div>
        <div className={styles["cross-sell-entry-bottom"]}>
          <ColorComponent className={styles["color"]} color={crossSell.color} />
          <div>
            <label>Quantité</label>
            <div className={styles["change-quantity-section"]}>
              <div
                className={styles["quantity-button"]}
                onClick={handleRemoveCartQuantity}
              >
                <Remove className={styles["icon"]} />
              </div>
              <p className={styles["cart-quantity"]}>
                {crossSell.cartQuantity}
              </p>
              <div
                className={styles["quantity-button"]}
                onClick={handleAddCartQuantity}
              >
                <Add className={styles["icon"]} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles["delete-icon"]} onClick={onDelete}>
        <Delete />
      </div>
    </div>
  );

  function handleOnChange({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    const { name, value } = target;
    let cs = undefined;
    if (name == "description") {
      cs = crossSells.find((cs) => cs.slug == value);
    }
    if (cs) {
      onChange(cs);
    } else {
      if (name == "price") {
        onChange({
          ...crossSell,
          [name]: parseFloat(value) * 100,
        });
        return;
      }
      onChange({ ...crossSell, [name]: value });
    }
  }

  function handleAddCartQuantity() {
    if (!crossSell.cartQuantity) {
      return;
    }
    onChange({ ...crossSell, cartQuantity: crossSell.cartQuantity! + 1 });
  }

  function handleRemoveCartQuantity() {
    if (!crossSell.cartQuantity) {
      return;
    }
    if (crossSell.cartQuantity == 1) {
      onDelete();
      return;
    }
    onChange({ ...crossSell, cartQuantity: crossSell.cartQuantity - 1 });
  }
}
