import { IProduct } from "types/logistic.types";
import { PRODUCT_STATE_CHECK_IN } from "utils/logistic.init";

export function productIsValid(product: IProduct) {
  if (!product.color) {
    return false;
  }
  if (!product.brand) {
    return false;
  }
  if (!product.category) {
    return false;
  }
  if (!product.size) {
    return false;
  }
  if (
    !product.incomingPicturesList ||
    product.incomingPicturesList?.length == 0
  ) {
    return false;
  }
  if (!product.workshopId) {
    return false;
  }
  if (!product.status || !PRODUCT_STATE_CHECK_IN.includes(product.status)) {
    return false;
  }
  return true;
}
