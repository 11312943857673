
export interface ChronotraceRequest {
  from: Date;
  to: Date;
  state: string;
  account: string;
  direction: string;
  filter2days: boolean;
}

export interface ChronotraceResponse {
  infosPODList: InfosPOD[];
}

export interface InfosPOD {
  contactEmail: string;
  contactName: string;
  contactPhone: string;
  dateDeposit: string;
  shipperRef: string;
  skybillNumber: string;
  trackId: string;
  significantEvent: SignificantEvent;
  direction: string;
}

export interface SignificantEvent {
  code: string;
  eventDate: string;
  eventLabel: string;
  highPriority: boolean;
  officeLabel: string;
  zipCode: string;
}

export async function searchChronotrace(
  queryKey: ChronotraceRequest
): Promise<ChronotraceResponse> {
  const { from, to, state, account, direction, filter2days = false } = queryKey;

  const options: RequestInit = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    method: "GET",
  };

  try {
    const query = new URLSearchParams({
      from: from.toISOString(),
      to: to.toISOString(),
      state,
      account,
      direction,
      filter2days: filter2days.toString(),
    }).toString();

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/chronotrace/search?${query}`,
      options
    );
    const data = await response.json();
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error) {
    throw error;
  }
}

export async function findPendingDeliveries(): Promise<ChronotraceResponse> {
  const options: RequestInit = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    method: "GET",
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/chronotrace/find-pending-deliveries`,
      options
    );
    const data = await response.json();
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error) {
    throw error;
  }
}

//find-pending-deliveries
