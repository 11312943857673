import { useMutation } from "@tanstack/react-query";
import clsx from "clsx";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";
import { useEffect, useState } from "react";
import { useNavigate, useRevalidator } from "react-router-dom";
import { toast } from "react-toastify";
import { OPERATION_GROUP, PRODUCT_GROUP } from "utils/logistic.init";
import translate from "utils/translation";
import Button from "../../../components/Button/Button";
import AddressForm from "../../../components/forms/addressForm/addressForm";
import ContactForm from "../../../components/forms/ContactForm/ContactForm";
import SectionFormTemplate from "../../../components/forms/sectionFormTemplate/SectionFormTemplate";
import {
  addShop,
  createLicense,
  removeShop,
  updateLicense,
  updateShop,
} from "../../../requests/licenses";
import { ILicense } from "../../../types/license.types";
import { IContact } from "../../../types/logistic.types";
import { IShop } from "../../../types/shop.types";
import { INITIAL_CONTACT } from "../../../utils/shipping.init";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../utils/toast.options";
import styles from "./create.module.scss";
import ShopMiniature from "./ShopMiniature/ShopMiniature";
import StepByStepModal from "./StepByStepModal/StepByStepModal";

export default function Create({ data }: { data?: ILicense }) {
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const [openShopModal, setOpenShopModal] = useState<IShop | null | undefined>(
    null
  );
  const [contact, setContact] = useState<IContact | undefined>(
    data?.contact || undefined
  );
  const [productGroup, setProductGroup] = useState<string[]>(
    data?.productGroup || []
  );
  const [operationGroup, setOperationGroup] = useState<string[]>(
    data?.operationGroup || []
  );
  const [shopsList, setShopsList] = useState<IShop[]>(
    (data?.shopsList || []).map((s) => s.shopData as IShop)
  );
  useEffect(() => {
    if (data) {
      setContact(data.contact);
      setShopsList((data.shopsList || []).map((s) => s.shopData as IShop));
    }
  }, [data]);

  const saveLicenseMutation = useMutation({
    mutationFn: data ? updateLicense : createLicense,
    onSuccess: (mutationData) => {
      if (mutationData.license && !data) {
        navigate(`/licenses/edit/${mutationData.license.id}`);
      } else if (mutationData.license) {
        navigate(`/licenses`);
      }
    },
    onError: (error) => {},
  });

  const addShopMutation = useMutation({
    mutationFn: addShop,
    onSuccess: (mutationData) => {
      toast.success("Boutique enregistrée", TOAST_SUCCESS_OPTIONS);
      revalidator.revalidate();
    },
    onError: (error) => {
      toast.success(
        "Erreur lors de l'enregistrement de la boutique",
        TOAST_ERROR_OPTIONS
      );
      revalidator.revalidate();
    },
  });

  const updateShopMutation = useMutation({
    mutationFn: updateShop,
    onSuccess: (mutationData) => {
      toast.success("Boutique modifiée", TOAST_SUCCESS_OPTIONS);
      revalidator.revalidate();
    },
    onError: (error) => {
      toast.success(
        "Erreur lors de la modification de la boutique",
        TOAST_ERROR_OPTIONS
      );
      revalidator.revalidate();
    },
  });

  const removeShopMutation = useMutation({
    mutationFn: removeShop,
    onSuccess: (mutationData) => {
      toast.success("Boutique supprimée", TOAST_SUCCESS_OPTIONS);
      revalidator.revalidate();
    },
    onError: (error) => {
      toast.success(
        "Erreur lors de la suppression de la boutique",
        TOAST_ERROR_OPTIONS
      );
      revalidator.revalidate();
    },
  });

  function handleSelectProductGroup(pg: string) {
    if (productGroup.includes(pg)) {
      setProductGroup((prev) => prev.filter((p) => p !== pg));
      return;
    }
    setProductGroup((prev) => [...prev, pg]);
  }

  function handleSelectOperationGroup(pg: string) {
    if (operationGroup.includes(pg)) {
      setOperationGroup((prev) => prev.filter((p) => p !== pg));
      return;
    }
    setOperationGroup((prev) => [...prev, pg]);
  }

  return (
    <>
      <div className={styles["create"]}>
        <div className={styles["title"]}>License professionels</div>
        <form className={styles["form"]} onSubmit={handleSubmit}>
          <SectionFormTemplate title="Contact">
            <ContactForm
              autoComplete={false}
              contact={contact || INITIAL_CONTACT}
              setContact={handleContactChange}
            />
          </SectionFormTemplate>
          <SectionFormTemplate title="Adresse">
            <AddressForm
              className={styles["contact-form"]}
              contact={contact}
              defaultAddress={contact}
              isShippingAddress={false}
              onChange={handleContactChange}
              zIndex={1999}
            />
          </SectionFormTemplate>
          <SectionFormTemplate title="Informations articles">
            <div className={styles["product-information-container"]}>
              <p className={styles["subtitle"]}>Groupe d'articles</p>
              <div className={styles["select-product-group"]}>
                {PRODUCT_GROUP.map((pg) => (
                  <p
                    className={clsx(
                      styles["product-group"],
                      productGroup.includes(pg) && styles["is-selected"]
                    )}
                    onClick={() => handleSelectProductGroup(pg)}
                  >
                    {translate(pg)}
                  </p>
                ))}
              </div>
              <p className={styles["subtitle"]}>Groupe d'opération</p>
              <div className={styles["select-operation-group"]}>
                {OPERATION_GROUP.map((opg) => (
                  <p
                    className={clsx(
                      styles["operation-group"],
                      operationGroup.includes(opg) && styles["is-selected"]
                    )}
                    onClick={() => handleSelectOperationGroup(opg)}
                  >
                    {translate(opg)}
                  </p>
                ))}
              </div>
            </div>
          </SectionFormTemplate>
          {data && (
            <>
              <hr className={styles["separator"]} />
              <div>Boutiques</div>
              <div className={styles["shop-list"]}>
                {(shopsList || []).map((shop, index) => (
                  <ShopMiniature
                    handleAddShop={handleAddShop}
                    handleDeleteShop={() => handleDeleteShop(shop)}
                    key={`${shop.id}-${index}`}
                    shop={shop}
                  />
                ))}
              </div>
              <Button
                className={styles["add-shop-button"]}
                type={"button"}
                onClick={(e) => {
                  e.preventDefault();
                  handleAddShop();
                }}
              >
                Ajouter boutique
              </Button>
            </>
          )}
          <div className={styles["footer"]}>
            <Button type={"submit"}>Enregistrer</Button>
          </div>
        </form>
      </div>
      <ModalContainer
        isOpen={openShopModal !== null}
        onCancel={handleCloseShopModal}
      >
        <StepByStepModal
          shopInit={openShopModal!}
          onValidate={(shop) => handleOnValidate(shop, openShopModal)}
        />
      </ModalContainer>
    </>
  );
  function handleCloseShopModal() {
    setOpenShopModal(null);
  }

  function handleAddShop(initialShop?: IShop) {
    setOpenShopModal(initialShop);
  }

  function handleOnValidate(shop: IShop, initialShop?: IShop | null) {
    setShopsList(shopsList ? [...shopsList, shop] : [shop]);
    setOpenShopModal(null);
    initialShop
      ? updateShopMutation.mutate({
          licenseId: data?.id as string,
          shopId: shop.id as string,
          shop,
        })
      : addShopMutation.mutate({ licenseId: data?.id as string, shop });
  }

  function handleContactChange(contact: IContact) {
    setContact((prev) => {
      return { ...prev, ...contact };
    });
  }

  function handleDeleteShop(shop: IShop) {
    if (!data) {
      return;
    }
    removeShopMutation.mutate({
      licenseId: data.id as string,
      shopId: shop.id as string,
    });
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (contact) {
      saveLicenseMutation.mutate({
        id: data?.id || "",
        license: { contact, productGroup, operationGroup },
      });
    }
  }
}
