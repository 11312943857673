import style from "./quoteModal.module.scss";
import { ICrossSellQuote, IQuote } from "../../../types/accounting.types";
import translate from "../../../utils/translation";
import ProductInformations from "../../ProductInformations/ProductInformations";
import Price from "../../Price/Price";
import DiscountValue from "../../DiscountValue/DiscountValue";

interface QuoteReadSectionProps {
  data: IQuote;
}
export default function QuoteReadSection({ data }: QuoteReadSectionProps) {
  return (
    <div className={style["body"]}>
      <div className={style["product-information-container"]}>
        <ProductInformations
          productsList={data.productsList}
          operationsTotal={data.operationsTotal}
        />
      </div>
      <div className={style["other-information-container"]}>
        <div className={style["other-information-top"]}>
          {data.discount && (
            <div className={style["other-information-section"]}>
              <span className={style["other-information-section-title"]}>
                Code promo
              </span>
              <div className={style["discount-line"]}>
                <div className={style["discount-description"]}>
                  {data.discount.code}
                </div>
                <hr className={style["discount-dots"]} />
                <DiscountValue
                  className={style["discount-price"]}
                  discount={data.discount}
                />
              </div>
            </div>
          )}
          {data.shipping && (
            <div className={style["other-information-section"]}>
              <span className={style["other-information-section-title"]}>
                Livraison
              </span>
              <div className={style["discount-line"]}>
                <div className={style["discount-description"]}>
                  {translate(data.shipping.shippingService)}
                </div>
                <hr className={style["discount-dots"]} />
                <Price
                  className={style["discount-price"]}
                  price={data.shipping.price}
                />
              </div>
            </div>
          )}
          {data.crossSellItems && data.crossSellItems.length > 0 && (
            <div className={style["other-information-section"]}>
              <span className={style["other-information-section-title"]}>
                Articles Cross-Selling
              </span>
              <div>
                {data.crossSellItems.map(
                  (crossSell: ICrossSellQuote, index: number) => {
                    return (
                      <div key={index}>
                        <ul>
                          <li key={index} className={style["cross-sell-line"]}>
                            <div className={style["cross-sell-description"]}>
                              {crossSell.description} {" - "}
                              <span>{`quantité: ${crossSell.cartQuantity}`}</span>
                            </div>
                            <hr className={style["cross-sell-dots"]} />
                            <Price
                              className={style["cross-sell-price"]}
                              price={crossSell.price}
                            />
                          </li>
                        </ul>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          )}
        </div>
        <div className={style["other-information-bottom"]}>
          <div className={style["total-section"]}>
            <span>Total</span>
            <Price price={data.total} />
          </div>
        </div>
      </div>
    </div>
  );
}
