import { keepPreviousData, useQuery } from "@tanstack/react-query";
import GPCalendar from "components/gpCalendar";
import moment from "moment";
import { useState } from "react";
import { fetchShippingList } from "../../requests/shipping";
import style from "./index.module.scss";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";
import ShippingModalSelect from "./calendar/shippingModal/shippingModalSelect";

interface CalendarItemData {
  title: string;
  subtitle: string;
  start: Date;
  end: Date;
  data: any;
  rowStartValue: any;
  rowEndValue: any;
}
export interface IOpenModal {
  id: string;
  date: Date;
  isExternal: boolean;
  isAllDay: boolean;
}

const rows = [
  {
    name: "Chronopost",
    value: -1,
    matchingFn: (date: Date, shippingService?: string) =>
      shippingService && shippingService.includes("CHRONO"),
  },
  {
    name: "",
    value: -99,
    matchingFn: (date: Date, shippingService?: string) => false,
  },
  {
    name: "08:00",
    value: 1,
    matchingFn: (date: Date, shippingService?: string) =>
      (!shippingService || !shippingService.includes("CHRONO")) &&
      moment(moment(date).format("HH:mm"), "HH:mm").isAfter(
        moment("07:59", "HH:mm")
      ) &&
      moment(moment(date).format("HH:mm"), "HH:mm").isBefore(
        moment("10:00", "HH:mm")
      ),
  },
  {
    name: "10:00",
    value: 2,
    matchingFn: (date: Date, shippingService?: string) =>
      (!shippingService || !shippingService.includes("CHRONO")) &&
      moment(moment(date).format("HH:mm"), "HH:mm").isAfter(
        moment("09:59", "HH:mm")
      ) &&
      moment(moment(date).format("HH:mm"), "HH:mm").isBefore(
        moment("12:00", "HH:mm")
      ),
  },
  {
    name: "12:00",
    value: 3,
    matchingFn: (date: Date, shippingService?: string) =>
      (!shippingService || !shippingService.includes("CHRONO")) &&
      moment(moment(date).format("HH:mm"), "HH:mm").isAfter(
        moment("11:59", "HH:mm")
      ) &&
      moment(moment(date).format("HH:mm"), "HH:mm").isBefore(
        moment("14:00", "HH:mm")
      ),
  },
  {
    name: "14:00",
    value: 4,
    matchingFn: (date: Date, shippingService?: string) =>
      (!shippingService || !shippingService.includes("CHRONO")) &&
      moment(moment(date).format("HH:mm"), "HH:mm").isAfter(
        moment("13:59", "HH:mm")
      ) &&
      moment(moment(date).format("HH:mm"), "HH:mm").isBefore(
        moment("16:00", "HH:mm")
      ),
  },
  {
    name: "16:00",
    value: 5,
    matchingFn: (date: Date, shippingService?: string) =>
      (!shippingService || !shippingService.includes("CHRONO")) &&
      moment(moment(date).format("HH:mm"), "HH:mm").isAfter(
        moment("15:59", "HH:mm")
      ) &&
      moment(moment(date).format("HH:mm"), "HH:mm").isBefore(
        moment("18:00", "HH:mm")
      ),
  },
  {
    name: "18:00",
    value: 6,
    matchingFn: (date: Date, shippingService?: string) =>
      (!shippingService || !shippingService.includes("CHRONO")) &&
      moment(moment(date).format("HH:mm"), "HH:mm").isAfter(
        moment("17:59", "HH:mm")
      ) &&
      moment(moment(date).format("HH:mm"), "HH:mm").isBefore(
        moment("20:00", "HH:mm")
      ),
  },
];

export default function Shippings() {
  const [dateRange, setDateRange] = useState([new Date()]);

  const { data, isLoading } = useQuery({
    queryKey: ["shippingCalendarData", dateRange],
    queryFn: () =>
      fetchShippingList(
        `?period.start=${(
          dateRange.at(0) || new Date()
        ).toISOString()}&period.start=${(
          dateRange.at(-1) || new Date()
        ).toISOString()}`
      ),
    refetchOnWindowFocus: true,
    placeholderData: keepPreviousData,
    select: (data): CalendarItemData[] => {
      let dataset = (data?.shippingsList || []).map(
        (shipping): CalendarItemData => {
          let startDate = new Date(shipping.period?.start || new Date());
          let endDate = new Date(shipping.period?.end || new Date());
          let d = {
            title: shipping.contact?.displayname || "Inconnu",
            subtitle: (shipping.shippingMethod?.shippingService || "").includes(
              "CHRONO"
            )
              ? ""
              : moment(startDate).format("HH:mm") +
                " - " +
                moment(endDate).format("HH:mm"),
            start: startDate,
            end: endDate,
            data: {
              id: shipping.id,
              state: shipping.state || "UNKNOWN",
              direction: shipping.direction || "UNKNOWN",
              shippingService:
                shipping.shippingMethod?.shippingService || "UNKNOWN",
            },
            rowStartValue:
              rows.find((r) =>
                r.matchingFn(
                  startDate,
                  shipping.shippingMethod?.shippingService
                )
              )?.value || 0,
            rowEndValue:
              rows.find((r) =>
                r.matchingFn(endDate, shipping.shippingMethod?.shippingService)
              )?.value || 0,
          };
          return d;
        }
      );

      return dataset.filter(
        (p) =>
          !p.data.shippingService.includes("CHRONO") ||
          (p.data.shippingService.includes("CHRONO") &&
            p.data.direction === "OUTGOING") ||
          (p.data.shippingService.includes("CHRONO") &&
            p.data.direction === "INCOMING" &&
            p.data.state === "TRANSITING")
      );
    },
  });

  // const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<{ date: Date; id: string } | null>(
    null
  );

  return (
    <div className={style["shipping-calendar"]}>
      <GPCalendar
        initialDate={dateRange.at(0) || new Date()}
        initialDisplay="daily"
        data={data || []}
        onDaysChange={handleDaysChange}
        onItemClick={handleItemClick}
        displayAxis={true}
        // calendarItemComponent={({ key, ...props }: any) => (
        //   <SeriesCalendarItem {...props} />
        // )}
        // additionalButtons={[
        //   () => (
        //     <GPCalendarHeaderButton
        //       onClick={() => {
        //         setAddSeriesModalData({ date: new Date() });
        //       }}
        //       icon={(props) => <AddCircleOutline {...props} />}
        //     />
        //   ),
        //   () => (
        //     <GPCalendarHeaderButton
        //       onClick={() => {
        //         navigate && navigate("/quick-access/workshop/cobbler/series");
        //       }}
        //       icon={(props) => <FormatListBulletedOutlined {...props} />}
        //     />
        //   ),
        // ]}
        calendarItemClassName={style["calendar-item"]}
        calendarItemTitleClassName={style["gp-calendar-series-item-title"]}
        calendarItemSubtitleClassName={
          style["gp-calendar-series-item-subtitle"]
        }
        calendarItemStyle={(item: any) => {
          let backgroundColor = "#0066cc";
          let grayScale = 100;

          if (item.data.direction === "INCOMING") {
            backgroundColor = "#4cb263";
            if (item.data.shippingService.includes("DROP")) {
              backgroundColor = "#43694c";
            }
          }
          if (item.data.direction === "OUTGOING") {
            backgroundColor = "#0066cc";
            if (item.data.shippingService.includes("DROP")) {
              backgroundColor = "#3b4f63";
            }
          }

          if (item.data.state === "DELIVERED") {
            grayScale = 50;
          }

          return {
            backgroundColor: backgroundColor,
            color: "white",
            filter: `brightness(${grayScale}%)`,
          };
        }}
        rows={rows}
      />
      <ModalContainer isOpen={!!modalData} onCancel={handleCloseModal}>
        {modalData && (
          <ShippingModalSelect
            openModal={{
              isExternal: false,
              isAllDay: false,
              date: modalData?.date,
              id: modalData.id,
            }}
          />
        )}
      </ModalContainer>
    </div>
  );

  function handleCloseModal() {
    setModalData(null);
  }

  function handleItemClick(data: any): void {
    setModalData({
      date: data.startDate,
      id: data.data.id,
    });
  }

  function handleDaysChange(days: Date[]) {
    console.debug("HANDLE DAYS CHANGE", days);
    setDateRange(days);
  }
}
