import { isDesktop } from "react-device-detect";
import style from "./image.module.scss";
import { Delete, ZoomOutMap } from "@mui/icons-material";
import { useState } from "react";
import { handleImageError } from "../../utils/utils";
import PictureLoadingSpinner from "../loadingSpinner/pictureloadingSpinner";
import ImageViewer from "components/ImageViewer/ImageViewer";
import ModalPortal from "components/modals/ModalPortal";
import RemoveBodyScroll from "components/modals/RemoveBodyScroll";
import clsx from "clsx";

interface ImageProps {
  handleDelete?: () => void;
  index?: number;
  isBlob?: boolean;
  pictureList?: string[];
  showTools?: boolean;
  clickable?: boolean;
  imageClassName?: string;
}

export default function Image({
  handleDelete,
  index = 0,
  isBlob = false,
  showTools = true,
  clickable = true,
  pictureList,
  imageClassName,
  ...props
}: ImageProps & React.ImgHTMLAttributes<HTMLImageElement>) {
  const [onHover, setOnHover] = useState<boolean>(false);
  const [openImageViewer, setOpenImageViewer] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const STYLE = { display: !isLoading ? "block" : "none" };

  return (
    <>
      <div
        className={clsx(style["image-container"], props.className)}
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
      >
        <div
          className={style["loader-container"]}
          style={{ display: isLoading ? "flex" : "none" }}
        >
          <PictureLoadingSpinner color="gold" />
        </div>
        <img
          {...props}
          className={clsx(
            style["image"],
            isDesktop ? style["hover"] : style["active"]
          )}
          style={Object.assign(STYLE, props.style)}
          src={
            isBlob
              ? props.src
              : `${process.env.REACT_APP_API_URL}/file/${props.src}`
          }
          onClick={handleOnClickOtherDevice}
          onError={handleImageError}
          onLoad={() => setIsLoading(false)}
        />
        {showTools && onHover && (
          <div className={style["tools-container"]}>
            <div className={style["delete-wrapper"]} onClick={handleOnClick}>
              <ZoomOutMap className={style["zoom-icon"]} />
            </div>
            {handleDelete && (
              <div className={style["delete-wrapper"]} onClick={handleDelete}>
                <Delete className={style["delete-icon"]} />
              </div>
            )}
          </div>
        )}
      </div>
      <ModalPortal isOpen={openImageViewer}>
        <ImageViewer
          index={index}
          isBlob={isBlob}
          pictureList={pictureList}
          onClose={handleCloseImageViewerModal}
        />
      </ModalPortal>
    </>
  );

  function handleOpenImageViewer() {
    setOpenImageViewer(true);
  }

  function handleCloseImageViewerModal() {
    setOpenImageViewer(false);
  }

  function handleOnClick(e: React.MouseEvent<HTMLImageElement, MouseEvent>) {
    props.onClick && props.onClick(e);
    if (pictureList) {
      handleOpenImageViewer();
    }
  }

  function handleOnClickOtherDevice(
    e: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) {
    if (clickable) {
      if (pictureList) {
        handleOpenImageViewer();
      }
      return;
    }
    if (!showTools) {
      props.onClick && props.onClick(e);
    } else {
      isDesktop ? props.onClick && props.onClick(e) : setOnHover(!onHover);
    }
  }
}
