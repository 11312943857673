import { PowerOffOutlined, PowerOutlined } from "@mui/icons-material";
import { ConnectionType, usePrinter } from "../../../context/printer.context";
import styles from "./connectPrinterBluetooth.module.scss";
import clsx from "clsx";
import PictureLoadingSpinner from "../../../components/loadingSpinner/pictureloadingSpinner";
import { useEffect } from "react";

interface ConnectPrinterBluetoothProps {
  className?: string;
}

export default function ConnectPrinterBluetooth({
  className,
}: ConnectPrinterBluetoothProps) {
  const {
    initPrinter,
    onConnectClicked,
    onDisconnectClicked,
    connectionState,
    connectionType,
  } = usePrinter();

  useEffect(() => {
    if (connectionType !== "bluetooth") {
      initPrinter("bluetooth");
    }
  }, []);

  switch (connectionState) {
    case "disconnected":
      return (
        <div
          className={clsx(
            styles["connect-printer-button"],
            styles["connected"]
          )}
          onClick={onConnectClicked}
        >
          <PowerOutlined className={styles["icon"]} />
        </div>
      );
    case "connected":
      return (
        <div
          className={clsx(
            styles["connect-printer-button"],
            styles["disconnected"]
          )}
          onClick={onDisconnectClicked}
        >
          <PowerOffOutlined className={styles["icon"]} />
        </div>
      );
    case "connecting":
      return (
        <div className={styles["connect-printer-button"]}>
          <PictureLoadingSpinner className={styles["spinner"]} />
        </div>
      );
  }
}
