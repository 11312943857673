import style from "./imageViewer.module.scss";
import { Close } from "@mui/icons-material";
import { useState } from "react";
import ImageCarrousel from "./ImageCarrousel/ImageCarrousel";
import RemoveBodyScroll from "components/modals/RemoveBodyScroll";
import ImageSlider from "./ImageSlider/ImageSlider";

interface ImageViewerProps {
  index?: number;
  isBlob?: boolean;
  pictureList?: string[];
  onClose: () => void;
}

export default function ImageViewer({
  index = 0,
  isBlob = false,
  pictureList = [],
  onClose,
}: ImageViewerProps) {
  const [pictureIndex, setPictureIndex] = useState(index);
  const [zoomIn, setZoomIn] = useState<boolean>(false);

  return (
    <RemoveBodyScroll>
      <div className={style["image-viewer"]}>
        <div className={style["close-button-container"]} onClick={onClose}>
          <Close className={style["close-icon"]} />
        </div>
        <ImageSlider
          isBlob={isBlob}
          pictureIndex={pictureIndex}
          pictureList={pictureList}
          onPictureSelect={handleSelectPicture}
          zoomIn={zoomIn}
          onPictureClick={handleZoomIn}
          onClickOutside={handleClickOutsidePicture}
        />
        <ImageCarrousel
          isBlob={isBlob}
          pictureIndex={pictureIndex}
          pictureList={pictureList}
          onPictureSelect={handleSelectPicture}
          hide={zoomIn}
        />
      </div>
    </RemoveBodyScroll>
  );

  function handleSelectPicture(index: number) {
    setPictureIndex(index);
  }

  function handleZoomIn(e: React.MouseEvent<HTMLImageElement, MouseEvent>) {
    e.stopPropagation();
    setZoomIn(!zoomIn);
  }

  function handleClickOutsidePicture() {
    if (zoomIn) {
      return setZoomIn(false);
    }
    onClose();
  }
}
