import { Link } from "react-router-dom";
import styles from "./index.module.scss";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import PagingComponent from "../../components/pagingComponent/pagingComponent";
import Item from "./item/item";
import { fetchCustomersList } from "../../requests/customer";
import SearchByKeyComponent from "../../components/searchByKeyComponent/searchByKeyComponent";
import { queryObject } from "../../types/query.types";
import useSaveQueryStorage from "../../hooks/useSaveQueryStorage";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import Button from "../../components/Button/Button";
import ListPageHeader from "../../components/ListPageHeader/ListPageHeader";
import FiltersContainer from "../../components/Filters/FiltersContainer";
import ListHeader from "./ListHeader/ListHeader";

export const INITIAL_QUERY: queryObject = {
  field: "",
  value: "",
  service: "",
  direction: "",
  status: "Tous",
  limit: 10,
  offset: 0,
  sort: "",
  dateFrom: "",
  dateTo: "",
  state: "Tous",
};

const CUSTOMER_SEARCH_OPTIONS = [
  "phone",
  "familyname",
  "givenname",
  "organization",
  "email",
];

let controller = new AbortController();

export default function Customers() {
  const [query, setQuery] = useSaveQueryStorage(INITIAL_QUERY, "customer");
  const { data, isLoading } = useQuery({
    queryKey: ["customers", query],
    queryFn: () => fetchCustomersList(formatedQuery()),
    placeholderData: keepPreviousData,
  });

  if (isLoading) {
    return <LoadingSpinner color="gold" />;
  }

  if (!data || !data.customers) {
    return <>Error data is missing !</>;
  }

  return (
    <div className={styles["list-page"]}>
      <ListPageHeader
        title={"Comptes clients"}
        actionSection={
          <Link onClick={() => window.scrollTo(0, 0)} to={"./create"}>
            <Button>+ Ajouter nouveau</Button>
          </Link>
        }
      />
      <FiltersContainer>
        <SearchByKeyComponent
          query={query}
          setQuery={setQuery}
          optionsList={CUSTOMER_SEARCH_OPTIONS}
        />
      </FiltersContainer>
      <div className={styles["list-container"]}>
        <div className={styles["paging-component-top"]}>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={data?.totalCount}
          />
        </div>
        <div className={styles["list"]}>
          <table className={styles["table"]}>
            <ListHeader />
            <tbody>
              {data.customers.map((value: any, key: number) => {
                return <Item key={key} data={value} />;
              })}
            </tbody>
          </table>
        </div>
        <div className={styles["paging-component"]}>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={data?.totalCount}
          />
        </div>
      </div>
    </div>
  );

  function formatedQuery() {
    let formatedQuery: string = "";

    if (query.limit) {
      formatedQuery += `?limit=${query.limit}`;
    }

    formatedQuery += `&offset=${query.offset * query.limit}`;

    if (query.field && query.value) {
      formatedQuery += `&${query.field}=${query.value}`;
    }
    if (query.sort) {
      formatedQuery += `${query.sort}`;
    }

    if (query.state && query.state !== "Tous") {
      formatedQuery += `&state=${query.state}`;
    }

    return formatedQuery;
  }
}
