import { AddAPhoto } from "@mui/icons-material";
import React, { useRef, useState } from "react";
import Image from "../../image/Image";
import style from "./imagesForm.module.scss";
import SectionTitle from "../../SectionTitle/SectionTitle";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";
import ImageContainer from "./ImageContainer/ImageContainer";

export interface ImagesFormProps {
  title?: string;
  imageList: string[];
  fileList?: File[];
  setIsLoading?: (value: boolean) => void;
  setFileList?: (fileList: File[], isWebCam: boolean) => void;
  deleteImage?: (imageURI: string, index: number) => void;
  className?: string;
  onAddFile?: (file: File) => void;
  onDeleteFile?: (file: File) => void;
}

export default function ImagesForm({
  title,
  imageList,
  fileList,
  setIsLoading,
  setFileList,
  deleteImage,
  className,
  onAddFile,
  onDeleteFile,
}: ImagesFormProps) {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  return (
    <>
      <div className={`${style["image-form"]} ${className}`}>
        {imageList.length > 0 && <SectionTitle title="Images" />}
        {imageList.length > 0 && (
          <div className={`${style["preview-list-container"]}`}>
            <div className={style["preview-list"]}>
              {imageList.map((uri: string, index: number) => {
                return (
                  <ImageContainer
                    uri={uri}
                    key={index}
                    index={index}
                    deleteImage={() =>
                      deleteImage ? deleteImage(uri, index) : undefined
                    }
                    pictureList={imageList}
                  />
                );
              })}
            </div>
          </div>
        )}
        <div className={`${style["file-list-container"]}`}>
          {imageList.length > 0 && <SectionTitle title="Nouvelles images" />}
          <div className={`${style["preview-list"]}`}>
            <div className={style["add-container"]} onClick={handleAddClick}>
              <button type="button" className={style["add-new-picture-button"]}>
                <AddAPhoto className={style["camera-icon"]} />
              </button>
              <input
                ref={hiddenFileInput}
                className={style["file-input"]}
                type="file"
                onChange={handleFileChange}
                onLoad={() => setIsLoading && setIsLoading(false)}
                multiple
              />
            </div>
            {fileList &&
              fileList.map((file: File, index: number) => {
                if (file) {
                  return (
                    <Image
                      alt={file.name}
                      className={style["image-preview-miniature"]}
                      handleDelete={() => handleDeleteFile(index)}
                      index={index}
                      isBlob
                      key={index}
                      pictureList={fileList
                        .filter((file) => file)
                        .map((file) => URL.createObjectURL(file))}
                      src={URL.createObjectURL(file)}
                    />
                  );
                }
              })}
          </div>
        </div>
      </div>
    </>
  );

  function handleAddClick() {
    hiddenFileInput?.current?.click();
  }

  function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    setIsLoading && setIsLoading(true);
    if (e.target.files && setFileList) {
      setFileList([...(fileList || []), e.target.files[0]], false);
    }
    setIsLoading && setIsLoading(false);

    if (e.target.files && e.target.files[0] && onAddFile) {
      onAddFile(e.target.files[0]);
    }
  }

  function handleDeleteFile(index: number) {
    if (fileList && setFileList) {
      let file = fileList.at(index);
      setFileList(
        fileList.filter((_, i) => i !== index),
        false
      );
      if (file && onDeleteFile) {
        onDeleteFile(file);
      }
    }
  }
}
