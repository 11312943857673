import Input from "components/inputs/Input/Input";
import styles from "./selectFromListModal.module.scss";
import clsx from "clsx";
import Button from "components/Button/Button";
import SelectFromList from "./SelectFromList/SelectFromList";

interface SelectFromListModalProps {
  input: string | null;
  onInputChange: (input: string) => void;
  onItemSelect: (item: string) => void;
  searchList: string[];
}

export default function SelectFromListModal({
  input,
  onInputChange,
  onItemSelect,
  searchList,
}: SelectFromListModalProps) {
  return (
    <div className={styles["select-from-list-modal"]}>
      <form
        className={styles["search-input"]}
        onSubmit={(e) => e.preventDefault()}
      >
        <Input
          className={styles["input"]}
          label="Recherche"
          name="brand"
          value={input || ""}
          onChange={handleInputChange}
          autoFocus
        />
        <Button
          className={styles["validate-button"]}
          onClick={() => onItemSelect(input || "")}
        >
          Valider
        </Button>
      </form>
      <SelectFromList
        input={input}
        onItemSelect={onItemSelect}
        searchList={searchList}
      />
    </div>
  );

  function handleInputChange({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    onInputChange(target.value);
  }
}
