import { ITracker } from "types/tracker.types";
import styles from "../overviewActionDispatch.module.scss";
import QuoteModal from "components/modals/quoteModal/quoteModal";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import Button from "components/Button/Button";

interface QuoteActionsButtonProps {
  tracker: ITracker;
}

export default function QuoteActionsButton({
  tracker,
}: QuoteActionsButtonProps) {
  const queryClient = useQueryClient();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  if (tracker.invoice || tracker.quote.state !== "QUOTE_VALIDATED") {
    return null;
  }

  return (
    <>
      {!tracker?.invoice && tracker.quote.state === "QUOTE_VALIDATED" && (
        <>
          <Button
            className={styles["action-button"]}
            onClick={handleOpenQuoteModal}
          >
            Générer facture
          </Button>
          <Button
            className={styles["action-button"]}
            onClick={handleOpenQuoteModal}
          >
            Envoi ReFashion
          </Button>
        </>
      )}
      <ModalContainer isOpen={modalIsOpen} onCancel={handleCloseQuoteModal}>
        <QuoteModal
          id={tracker.quote.documentId}
          invoice={tracker.invoice}
          requiredActions={true}
          onClose={handleCloseQuoteModal}
        />
      </ModalContainer>
    </>
  );

  function handleOpenQuoteModal() {
    setModalIsOpen(true);
  }

  function handleCloseQuoteModal() {
    setModalIsOpen(false);
    queryClient.refetchQueries({
      queryKey: ["quote-overview", tracker.quote.documentId],
    });
    queryClient.refetchQueries({ queryKey: ["tracker-overview", tracker.id] });
  }
}
