import { useAuth } from "context/auth.context";
import { ReactNode, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { logoutRequest } from "requests/user";

interface PrivateRouteProps {
  children: ReactNode;
}

export default function PrivateRoute({ children }: PrivateRouteProps) {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);
  const { unregisterUser } = useAuth();
  const location = useLocation();

  useEffect(() => {
    checkSession();
  }, [location.pathname]);

  return isAuthenticated ? <>{children}</> : <Navigate to="/login" replace />;

  async function checkSession() {
    try {
      const connected = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/logged`,
        {
          credentials: "include",
        }
      );
      if (connected.status === 401) {
        logoutRequest();
        unregisterUser();
        return setIsAuthenticated(false);
      }
      setIsAuthenticated(true);
      return null;
    } catch (error: any) {
      throw new Response(JSON.stringify(error.body), {
        statusText: error.statusText,
        status: error.status,
      });
    }
  }
}
