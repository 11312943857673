import { keepPreviousData, useQuery } from "@tanstack/react-query";
import PagingComponent from "components/pagingComponent/pagingComponent";
import SearchByWorkshopIdV2 from "components/searchByWorkshopIdV2/searchByWorkshopIdV2";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getSeriesList } from "requests/series";
import { Series as ISeries } from "types/logistic.types";
import style from "./series.module.scss";
import SeriesCard from "./seriesCard/SeriesCard";

export default function Series() {
  const [query, setQuery] = useState<any>({
    limit: 10,
    offset: 0,
    sort_field: "dueAt",
    sort_order: "asc",
  });
  const { data } = useQuery({
    queryKey: [`series`, query],
    queryFn: () => getSeriesList(formatedQuery()),
    placeholderData: keepPreviousData,
  });

  return (
    <div className={style["series"]}>
      <SeriesNav />
      <div className={style["series-content"]}>
        <SearchByWorkshopIdV2
          workshopId={query.workshopId || ""}
          setWorkshopId={(workshopId) =>
            setQuery((prev: any) => ({ ...prev, workshopId }))
          }
        />
        <PagingComponent
          query={query}
          setQuery={setQuery}
          totalCount={data?.totalCount || 0}
        />
        <div className={style["series-list"]}>
          {data &&
            data.seriesList.map((s: ISeries, index: number) => {
              return <SeriesCard key={index} series={s} />;
            })}
        </div>
      </div>
    </div>
  );

  function formatedQuery() {
    let formatedQuery: string = `?limit=${query.limit}&offset=${
      query.offset * query.limit
    }`;
    if (query.workshopId) {
      formatedQuery += `&productsList.workshopId=${query.workshopId}`;
    }
    return formatedQuery;
  }
}

export function SeriesNav() {
  return (
    <nav className={style["nav-container"]}>
      <Link
        className={style["nav-item"]}
        to="/quick-access/workshop/cobbler/series"
        title="Séries"
      >
        Séries
      </Link>
      <Link
        className={style["nav-item"]}
        to="/quick-access/workshop/cobbler/series/unassigned"
        title="Non assignés"
      >
        Non assignés
      </Link>
      <Link
        className={style["nav-item"]}
        to="/quick-access/workshop/cobbler/series/calendar"
        title="Calendrier"
      >
        Calendrier
      </Link>
    </nav>
  );
}
