import SectionFormTemplate from "components/forms/sectionFormTemplate/SectionFormTemplate";
import styles from "./commentSection.module.scss";

interface CommentSectionProps {
  comments: string;
  onCommentsChange: (comments: string) => void;
}

export default function CommentSection({
  comments,
  onCommentsChange,
}: CommentSectionProps) {
  return (
    <SectionFormTemplate title={"Commentaire"}>
      <textarea
        className={styles["comment"]}
        value={comments}
        onChange={handleOnCommentsChange}
      />
    </SectionFormTemplate>
  );

  function handleOnCommentsChange({
    target,
  }: {
    target: EventTarget & HTMLTextAreaElement;
  }) {
    const comments = target.value;
    onCommentsChange(comments);
  }
}
