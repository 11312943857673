import { NoteAdd } from "@mui/icons-material";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../components/Button/Button";
import ListPageHeader from "../../components/ListPageHeader/ListPageHeader";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import PagingComponent from "../../components/pagingComponent/pagingComponent";
import useSaveQueryStorage from "../../hooks/useSaveQueryStorage";
import {
  deleteOperation,
  exportOperations,
  fetchOperationsList,
} from "../../requests/operation";
import { IOperation, OperationForm } from "../../types/accounting.types";
import { queryObject } from "../../types/query.types";
import { STD_ERROR_MESSAGE } from "../../utils/constants";
import { TOAST_ERROR_OPTIONS } from "../../utils/toast.options";
import Filters from "./filters/Filters";
import styles from "./index.module.scss";
import Item from "./item/item";
import ListHeader from "./ListHeader/ListHeader";

let controller = new AbortController();

export const OPERATION_INITIAL_QUERY: queryObject = {
  limit: 10,
  offset: 0,
  operationType: "Tous",
  productGroup: "Tous",
  operationGroup: "Tous",
};

export default function Operations() {
  const [query, setQuery] = useSaveQueryStorage(
    OPERATION_INITIAL_QUERY,
    "operation"
  );
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["operation", query],
    queryFn: () => fetchOperationsList(formatedQuery(), false),
    placeholderData: keepPreviousData,
  });
  const [openImportModal, setOpenImportModal] = useState<boolean>(false);

  if (isLoading) {
    return <LoadingSpinner color="gold" />;
  }

  if (!data || !data.operations) {
    return <>Error data is missing !</>;
  }

  const { operations, totalCount } = data;

  return (
    <div className={styles["list-page"]}>
      <ListPageHeader
        title={"Opérations"}
        actionSection={
          <Link
            onClick={() => window.scrollTo(0, 0)}
            to={"./create"}
            title={"ajouter"}
          >
            <Button className={styles["add-new"]}>
              <NoteAdd />
            </Button>
          </Link>
        }
      />
      <Filters
        query={query}
        onFilterChange={handleFilterChange}
        reset={resetFilters}
      />
      <div className={styles["list-container"]}>
        <div className={styles["paging-component-top"]}>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={totalCount}
          />
        </div>
        <div className={styles["list"]}>
          <table className={styles["table"]}>
            <ListHeader />
            <tbody>
              {operations.map((value: IOperation, key: number) => {
                return (
                  <Item
                    key={key}
                    data={value as OperationForm}
                    onDelete={handleDelete}
                    refetch={refetch}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
        <div className={styles["paging-component"]}>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={totalCount}
          />
        </div>
      </div>
    </div>
  );

  function handleToogleExportModal() {
    setOpenImportModal(!openImportModal);
  }

  async function handleExportOperations() {
    try {
      // setIsLoading(true);
      const response = await exportOperations(formatedExportQuery());
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "data-export.xlsx";
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error: any) {
      toast.error(STD_ERROR_MESSAGE, TOAST_ERROR_OPTIONS);
    } finally {
      // setIsLoading(false);
    }
  }

  function formatedQuery() {
    let formatedQuery: string = "";

    if (query.limit) {
      formatedQuery = formatedQuery + `limit=${query.limit}`;
    }

    formatedQuery = formatedQuery + `&offset=${query.offset * query.limit}`;

    if (query.sort) {
      formatedQuery = formatedQuery + query.sort;
    }

    if (query.operationGroup && query.operationGroup !== "Tous") {
      formatedQuery += `&operationGroup=${query.operationGroup}`;
    }

    if (query.operationType && query.operationType !== "Tous") {
      formatedQuery += `&operationType=${query.operationType}`;
    }

    if (query.productGroup && query.productGroup !== "Tous") {
      formatedQuery += `&productGroup=${query.productGroup}`;
    }

    if (query.field && query.value) {
      formatedQuery = formatedQuery + `&${query.field}=${query.value}`;
    }

    return formatedQuery;
  }

  function formatedExportQuery() {
    let formatedQuery: string = "";

    if (query.operationGroup && query.operationGroup !== "Tous") {
      formatedQuery += `&operationGroup=${query.operationGroup}`;
    }

    if (query.operationType && query.operationType !== "Tous") {
      formatedQuery += `&operationType=${query.operationType}`;
    }

    if (query.productGroup && query.productGroup !== "Tous") {
      formatedQuery += `&productGroup=${query.productGroup}`;
    }

    if (query.field && query.value) {
      formatedQuery = formatedQuery + `&${query.field}=${query.value}`;
    }

    return formatedQuery;
  }

  async function handleDelete(slug: string) {
    controller.abort();
    try {
      controller = new AbortController();
      await deleteOperation({ slug: slug, signal: controller.signal });
      refetch();
    } catch (error) {
      console.error(error);
    }
  }

  function handleFilterChange(name: string, value: string) {
    setQuery((prev: any) => ({ ...prev, [name]: value }));
  }

  function resetFilters() {
    setQuery(OPERATION_INITIAL_QUERY);
  }
}
