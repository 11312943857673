import DataError from "../../../../../components/errors/DataError/DataError";
import PictureLoadingSpinner from "../../../../../components/loadingSpinner/pictureloadingSpinner";
import { fetchQuote } from "../../../../../requests/quote";
import Create from "../../../../quotes/create/create";
import OverviewSection from "../OverviewSection/OverviewSection";
import styles from "./quoteTab.module.scss";
import { useQuery } from "@tanstack/react-query";

interface QuoteTabProps {
  quoteId: string;
}

export default function QuoteTab({ quoteId }: QuoteTabProps) {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["quote", quoteId],
    queryFn: () => fetchQuote(quoteId),
    refetchOnWindowFocus: false,
    enabled: Boolean(quoteId),
  });

  if (!quoteId) {
    return <></>;
  }

  if (isLoading) {
    return (
      <div className={styles["loading-spinner-container"]}>
        <PictureLoadingSpinner />
      </div>
    );
  }

  if (!data) {
    return <DataError error="quote" />;
  }

  return (
    <OverviewSection className={styles["quote-section"]}>
      <Create defaultQuote={data} refetch={refetch} />
    </OverviewSection>
  );
}
