import { useParams } from "react-router-dom";
import Create from "../create/create";
import { fetchLogistic } from "../../../requests/logistic";
import { useQuery } from "@tanstack/react-query";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";
import DataError from "components/errors/DataError/DataError";
import styles from "./edit.module.scss";

export default function Edit() {
  const { id } = useParams();
  const { data: logistic, isFetching } = useQuery({
    queryKey: ["logistic-edit", id],
    queryFn: () => fetchLogistic({ params: { id: id } }),
    refetchOnWindowFocus: false,
  });

  if (isFetching) {
    return (
      <div className={styles["loading-spinner-container"]}>
        <LoadingSpinner color="gold" />
      </div>
    );
  }

  if (!logistic) {
    return <DataError error="logistic" />;
  }

  return <Create defaultLogistic={logistic} />;
}
