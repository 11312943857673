import { useState } from "react";
import { IShop } from "../../../types/shop.types";
import { IShippingPro } from "../../../types/shipping.types";
import { GP_SHIPPING_ADDRESS } from "../../../utils/shipping.init";
import style from "./addShippingProModal.module.scss";
import { toast } from "react-toastify";
import ValidateButton from "../../validateButton/validateButton";
import { TOAST_ERROR_OPTIONS } from "../../../utils/toast.options";
import { useNavigate } from "react-router-dom";
import { createShippingAndLinkToShop } from "../../../requests/shippingPro";
import SelectAndSearchShop from "../../SelectAndSearchShop/SelectAndSearchShop";

interface AddShippingProModalProps {
  direction: string;
  onValidate: () => void;
}

export default function AddShippingProModal({
  direction,
  onValidate,
}: AddShippingProModalProps) {
  const [shop, setShop] = useState<IShop | null>(null);
  const [date, setDate] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <div className={style["add-shipping-pro-modal"]}>
      <div className={style["add-shipping-pro-modal-container"]}>
        <div>
          <div className={style["title"]}>{`Ajouter une nouvelle ${
            direction === "OUTGOING" ? "livraison" : "collecte"
          }`}</div>
          <span>
            {`Sélectionner une boutique et une date pour créer la ${
              direction === "OUTGOING" ? "livraison" : "collecte"
            }`}
          </span>
          <div className={style["query-section"]}>
            <div>
              <SelectAndSearchShop shop={shop} setShop={handleSetShop} />
              {!shop && (
                <span className={style["error-message"]}>
                  * veuillez choisir une boutique
                </span>
              )}
            </div>
            <div className={style["date-picker"]}>
              <input
                className={style["date-picker-input"]}
                onChange={handleDateChange}
                type="date"
                value={date?.substring(0, 10)}
              />
              {!date && (
                <span className={style["error-message"]}>
                  * veuillez choisir une date
                </span>
              )}
            </div>
          </div>
        </div>
        <div className={style["button-container"]}>
          <ValidateButton
            title={"Valider"}
            handleOnClick={handleValidateShipping}
            isLoading={isLoading}
            loadingSpinnerStyle={style["validate-button"]}
          />
        </div>
      </div>
    </div>
  );

  async function handleValidateShipping() {
    if (!shop || !date) {
      return;
    }
    const shippingPro = generateShippingFromShop(shop);
    if (!shippingPro) {
      return;
    }
    setIsLoading(true);
    try {
      const shippingresponse = await createShippingAndLinkToShop(shippingPro);
      let directionPath = "pro-outgoing-shipping";
      if (direction === "INCOMING") {
        directionPath = "pro-incoming-shipping";
      }
      navigate(
        `/quick-access/${directionPath}/${shop.id}?date=${date}&shopName=${shop.name}&shipping-id=${shippingresponse.id}`
      );
      onValidate();
    } catch (error) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    } finally {
      setIsLoading(false);
    }
  }

  function handleDateChange({ target }: { target: HTMLInputElement }) {
    setDate(new Date(target.value).toISOString().substring(0, 10));
  }

  function generateShippingFromShop(shop: IShop): IShippingPro {
    function getShippingPeriod() {
      if (!date) {
        return { start: null, end: null };
      }
      return {
        start: new Date(
          date + "T" + shop.shippingMethod.businessHours[0].start
        ),
        end: new Date(date + "T" + shop.shippingMethod.businessHours[0].end),
      };
    }

    const shippingPro: IShippingPro = {
      state: "PENDING",
      trackId: [],
      shopId: shop.id,
      sender: direction === "OUTGOING" ? GP_SHIPPING_ADDRESS : shop.contact,
      recipient: direction === "OUTGOING" ? shop.contact : GP_SHIPPING_ADDRESS,
      direction: direction,
      shippingDate: date,
      period: getShippingPeriod(),
      shippingMethod: shop.shippingMethod,
      externalShipping: {
        reservationNumber: "",
        externalId: "",
        pickUpId: "",
      },
    };

    return shippingPro;
  }

  function handleSetShop(selectedShop: IShop | null) {
    setShop(selectedShop);
    if (direction === "OUTGOING") {
      if (!selectedShop || !selectedShop.nextOutgoingShipping) {
        setDate("");
        return;
      }
      setDate(selectedShop.nextOutgoingShipping.date);
    } else {
      if (!selectedShop || !selectedShop.nextIncomingShipping) {
        setDate("");
        return;
      }

      setDate(selectedShop.nextIncomingShipping.date);
    }
  }
}
