import styles from "./item.module.scss";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";
import { Delete, Edit, Launch } from "@mui/icons-material";
import { ICart } from "../../../types/booking.type";
import WorkshopId from "../../../components/workshopId/workshopId";
import ReadCartModal from "../../../components/modals/readCartModal/ReadCartModal";
import MoreTools, {
  IMoreToolsOptions,
} from "../../../components/MoreTools/MoreTools";
import clsx from "clsx";
import CenterCell from "../../../components/CenterCell/CenterCell";
import State from "../../../components/State/State";
import { useState } from "react";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";

export default function Item({
  data,
  onDelete,
}: {
  data: ICart;
  onDelete: (id?: string) => void;
}) {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      icon: <Launch />,
      name: "Ouvrir",
      onClick: handleOpenCartModal,
    },
    {
      icon: <Edit />,
      name: "Modifier",
      to: `/professionalCarts/edit/${data.id}`,
    },
    {
      icon: <Delete />,
      name: "Supprimer",
      askConfirmation: true,
      onClick: handleDelete,
    },
  ];

  return (
    <>
      <tr className={styles["item"]}>
        <td className={clsx(styles["item-cell"], styles["item-coord"])}>
          <div className={styles["coordinates"]}>
            <span>{`${
              data.contact?.givenname
            } ${data.contact?.familyname?.toLocaleUpperCase("FR")}`}</span>
            <span>{data.contact?.phone}</span>
          </div>
        </td>
        <td className={styles["item-cell"]}>
          {data.createdAt && new Date(data.createdAt).toLocaleDateString()}
          {data.updatedAt && new Date(data.updatedAt).toLocaleDateString()}
        </td>
        <td
          className={clsx(styles["item-cell"], styles["product-cell"])}
          onClick={handleOpenCartModal}
        >
          {data.productsList[0] && (
            <WorkshopId
              size={"xx-small"}
              workshopId={data.productsList[0].workshopId}
            />
          )}
          {data.productsList.length > 1 && (
            <div className={styles["more-circle"]}>
              + {data.productsList.length - 1}
            </div>
          )}
        </td>
        <td className={clsx(styles["item-cell"], styles["status-box"])}>
          <CenterCell>
            <State state={data.state} />
          </CenterCell>
        </td>
        <td className={clsx(styles["item-cell"], styles["tools"])}>
          <ProtectedComponent roles={["ROLE_ADMIN"]}>
            <CenterCell>
              <MoreTools options={MORE_TOOLS_OPTIONS} />
            </CenterCell>
          </ProtectedComponent>
        </td>
      </tr>
      <ModalContainer isOpen={modalIsOpen} onCancel={handleCloseCartModal}>
        <ReadCartModal cart={data} />
      </ModalContainer>
    </>
  );

  function handleOpenCartModal() {
    setModalIsOpen(true);
  }

  function handleCloseCartModal() {
    setModalIsOpen(false);
  }

  function handleDelete() {
    onDelete(data.id);
  }
}
