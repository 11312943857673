import { useQuery } from "@tanstack/react-query";
import DataError from "components/errors/DataError/DataError";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";
import { fetchShipping } from "../../../requests/shipping";
import Create from "../create/Create";
import style from "./edit.module.scss";

export default function Edit() {
  const { id } = useParams();
  const { data, isFetching, refetch } = useQuery({
    queryKey: ["shippingDocument", id],
    queryFn: () => fetchShipping({ id: id }),
    refetchOnWindowFocus: false,
  });

  if (isFetching) {
    return <LoadingSpinner color="gold" />;
  }

  if (!data) {
    return <DataError error="shipping" />;
  }

  return (
    <>
      {!isFetching ? (
        <>{data && <Create refetch={refetch} shipping={data} />}</>
      ) : (
        <div className={style["loading-spinner-container"]}>
          <LoadingSpinner color="gold" />
        </div>
      )}
    </>
  );
}
