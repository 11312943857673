import Widget from "components/widget/Widget";
import styles from "./leather.module.scss";
import LeatherIcon from "components/icons/LeatherIcon";

export default function LeatherWidget() {
  return (
    <Widget to={"leather"}>
      <div className={styles["icon-container"]}>
        <LeatherIcon className={styles["icon"]} />
      </div>
      <div>Maroquinerie</div>
    </Widget>
  );
}
