import { Launch } from "@mui/icons-material";
import { useMutation } from "@tanstack/react-query";
import Button from "components/Button/Button";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { sendEstimateFromDropOff } from "requests/dropOff";
import { DropOffForm } from "types/logistic.types";
import { STD_ERROR_MESSAGE } from "utils/constants";
import { INITIAL_DROPOFF } from "utils/logistic.init";
import { isEmailValid, isPhoneValid } from "utils/phoneValidation";
import { TOAST_ERROR_OPTIONS } from "utils/toast.options";
import { hasDuplicates } from "utils/utils";
import ValidateDropOffModal from "../../modals/ValidateDropOffModal/ValidateDropOffModal";
import ValidateFormModal from "../../modals/ValidateFormModal/ValidateFormModal";
import styles from "./validateFormSection.module.scss";

interface ValidateFormSectionProps {
  className?: string;
  dropOff: DropOffForm;
  onDropOffChange: (dropOff: DropOffForm) => void;
}

export default function ValidateFormSection({
  className,
  dropOff,
  onDropOffChange,
}: ValidateFormSectionProps) {
  const navigate = useNavigate();
  const mutation = useMutation({
    mutationFn: sendEstimateFromDropOff,
    onSuccess: ({ trackId }) => {
      navigate(`/overview/${trackId}`);
    },
    onError: () => {
      toast.error(STD_ERROR_MESSAGE, TOAST_ERROR_OPTIONS);
    },
  });
  const [openValidateDropOffModal, setOpenValidateDropOffModal] =
    useState<boolean>(false);
  const [openValidateFormModal, setOpenValidateFormModal] =
    useState<boolean>(false);

  return (
    <>
      <div className={className}>
        {!dropOff.trackId ? (
          <Button
            isLoading={mutation.isPending}
            className={styles["action-button"]}
            onClick={handleSendEstimate}
          >
            Envoyer une estimation
          </Button>
        ) : (
          <Link
            className={styles["overview-link"]}
            to={`/overview/${dropOff.trackId}`}
          >
            Vue d'ensemble
            <Launch className={styles["icon"]} />
          </Link>
        )}
        <Button
          className={styles["action-button"]}
          onClick={handleOpenValidateDropOffModal}
        >
          Valider les articles
        </Button>
      </div>
      <ModalContainer
        isOpen={openValidateDropOffModal}
        onCancel={handleCloseValidateDropOffModal}
      >
        <ValidateDropOffModal
          dropOff={dropOff}
          onDropOffChange={onDropOffChange}
          onClose={handleCloseValidateDropOffModal}
        />
      </ModalContainer>
      <ModalContainer
        isOpen={openValidateFormModal}
        onCancel={handleCloseValidateFormModal}
        width="narrow"
        height="fit"
      >
        <ValidateFormModal dropOff={dropOff} />
      </ModalContainer>
    </>
  );

  function handleCloseValidateFormModal() {
    setOpenValidateFormModal(false);
  }

  function handleOpenValidateDropOffModal() {
    if (!validateDropOffForm()) {
      return setOpenValidateFormModal(true);
    }
    setOpenValidateDropOffModal(true);
  }

  function handleSendEstimate() {
    if (!validateEstimateDropOffForm()) {
      return setOpenValidateFormModal(true);
    }
    mutation.mutate({ dropOff });
    onDropOffChange(INITIAL_DROPOFF);
  }

  function handleCloseValidateDropOffModal() {
    setOpenValidateDropOffModal(false);
  }

  function validateEstimateDropOffForm() {
    if (!dropOff.contact) {
      return false;
    }
    if (!dropOff.contact.phone || !isPhoneValid(dropOff.contact.phone)) {
      return false;
    }
    if (!dropOff.contact.email || !isEmailValid(dropOff.contact.email)) {
      return false;
    }
    if (!dropOff.productList.length) {
      return false;
    }
    if (
      dropOff.productList.some(
        (p) => !p.productGroup || !p.operationGroup || p.operations.length < 1
      )
    ) {
      return false;
    }
    return true;
  }

  function validateDropOffForm() {
    if (!dropOff.contact) {
      return false;
    }
    if (!dropOff.contact.givenname || !dropOff.contact.familyname) {
      return false;
    }
    if (!dropOff.contact.phone || !isPhoneValid(dropOff.contact.phone)) {
      return false;
    }
    if (!dropOff.contact.email || !isEmailValid(dropOff.contact.email)) {
      return false;
    }
    if (!dropOff.repareDays) {
      return false;
    }
    if (!dropOff.timeSlot) {
      return false;
    }
    if (
      dropOff.productList.some(
        (p) =>
          !p.productGroup ||
          !p.operationGroup ||
          !p.workshopId ||
          p.operations.length < 1
      )
    ) {
      return false;
    }
    if (hasDuplicates(dropOff.productList.map((p) => p.workshopId))) {
      return false;
    }
    if (!dropOff.productList.length && !dropOff.crossSells.length) {
      return false;
    }
    return true;
  }
}
