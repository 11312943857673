import { ILogistic } from "types/logistic.types";
import { PRODUCT_STATE_CHECK_IN } from "utils/logistic.init";
import styles from "./missingInformationModal.module.scss";
import { Fragment } from "react";

interface MissingInformationModalProps {
  logistic: ILogistic;
}

export default function MissingInformationModal({
  logistic,
}: MissingInformationModalProps) {
  return (
    <div className={styles["missing-information-modal"]}>
      <h3>Informations manquantes</h3>
      <ul className={styles["validate-form-list"]}>
        {logistic.productsList.map((p, index) => (
          <Fragment key={index}>
            {!p.color && (
              <li>
                <b>Couleur</b>
                {` manquante pour l'article `}
                <b>{index + 1}</b>.
              </li>
            )}
            {!p.brand && (
              <li>
                <b>Marque</b>
                {` manquante pour l'article `}
                <b>{index + 1}</b>.
              </li>
            )}
            {!p.category && (
              <li>
                <b>Catégorie</b>
                {` manquante pour l'article `}
                <b>{index + 1}</b>.
              </li>
            )}
            {!p.size && (
              <li>
                <b>Taille</b>
                {` manquante pour l'article `}
                <b>{index + 1}</b>.
              </li>
            )}
            {!p.workshopId && (
              <li>
                <b>Ticket atelier</b>
                {` manquant pour l'article `}
                <b>{index + 1}</b>.
              </li>
            )}
            {!p.status ||
              (!PRODUCT_STATE_CHECK_IN.includes(p.status) && (
                <li>
                  {`L'article `}
                  <b>{index + 1}</b>
                  {` n'a pas été `}
                  <b>déplacé</b>.
                </li>
              ))}
            {(!p.incomingPicturesList || p.incomingPicturesList.length < 1) && (
              <li>{`Ajouter au moins une image pour l'article ${
                index + 1
              }.`}</li>
            )}
          </Fragment>
        ))}
      </ul>
    </div>
  );
}
