import { KeyboardEvent, useEffect, useRef, useState } from "react";
import { COLOR_LIST, ColorType } from "../../utils/translation/color";
import useClickOutside from "../../hooks/useClickOutSide";
import styles from "./colorPicker.module.scss";
import { isDesktop } from "react-device-detect";
import { Close, Colorize, SearchOutlined } from "@mui/icons-material";
import { createPortal } from "react-dom";
import ColorComponent from "../colorComponent/colorComponent";
import COLOR_PICKER_LOGO from "../../assets/color-wheel.png";

interface ColorPickerProps {
  className?: string;
  color?: string;
  onColorChange: (value: string) => void;
}

export default function ColorPicker({
  className,
  color,
  onColorChange,
}: ColorPickerProps) {
  const [colorHovered, setColorHovered] = useState<ColorType>(
    getInitialColor()
  );
  const [expand, setExpand] = useState<boolean>(false);
  const [dropdownPosition, setDropdownPosition] = useState({
    top: -1000,
    left: -1000,
  });
  const dropdownRef = useClickOutside<HTMLDivElement>(handleCloseDropDown);
  const colorPickerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (colorPickerRef.current && dropdownRef.current) {
      const buttonRect = colorPickerRef.current.getBoundingClientRect();
      const dropdownHeight = dropdownRef.current.offsetHeight;
      const marginRight = 10;
      const marginBottom = 10;
      setDropdownPosition({
        top:
          buttonRect.bottom +
          window.scrollY -
          dropdownHeight / 2 -
          buttonRect.height / 2 -
          marginBottom,
        left: buttonRect.left + window.scrollX + buttonRect.width + marginRight,
      });
    }
  }, [expand]);

  return (
    <div className={`${styles["color-picker"]} ${className ? className : ""}`}>
      <div className={styles["color-picker-input"]} onClick={handleExpand}>
        <div className={styles["color-picker-icon"]} ref={colorPickerRef}>
          <img
            draggable={false}
            className={styles["icon"]}
            src={COLOR_PICKER_LOGO}
          />
        </div>
        {!!color && (
          <div
            className={styles["button-color-preview"]}
            onClick={(e) => {
              e.stopPropagation();
              resetColor();
            }}
          >
            <ColorComponent color={color} /> <Close />
          </div>
        )}
      </div>

      {expand &&
        createPortal(
          <div
            className={styles["color-picker-drop"]}
            ref={dropdownRef}
            style={{
              position: "absolute",
              top: dropdownPosition.top,
              left: dropdownPosition.left,
              zIndex: 2001,
            }}
          >
            <div
              className={styles["color-preview"]}
              style={{ backgroundColor: colorHovered.color }}
            >
              <span className={styles["color-preview-name"]}>
                {colorHovered.name}
              </span>
            </div>
            <div className={styles["color-picker-drop-input-container"]}>
              <input
                className={styles["color-picker-drop-input"]}
                type="text"
                value={color}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
              />
              <div className={styles["search-icon-container"]}>
                <div className={styles["search-icon"]}>
                  <SearchOutlined />
                </div>
              </div>
            </div>
            <div className={styles["color-list-container"]}>
              <div className={styles["color-list"]}>
                {COLOR_LIST.filter(({ name }) =>
                  name.match(new RegExp(`${color || ""}`, "i"))
                ).map((value: ColorType, index: number) => {
                  return (
                    <div
                      className={`${styles["color-box"]} ${
                        isDesktop
                          ? styles["hover"]
                          : value === colorHovered
                          ? styles["mobile-tablet-hovered"]
                          : ""
                      }`}
                      key={index}
                      style={{ backgroundColor: value.color }}
                      onMouseEnter={() => setColorHovered(value)}
                      onClick={() => handleClickColor(value)}
                    ></div>
                  );
                })}
              </div>
            </div>
          </div>,
          document.body
        )}
    </div>
  );

  function handleExpand() {
    setExpand(!expand);
  }

  function handleClickColor(value: ColorType) {
    if (isDesktop) {
      onColorChange(value.name);
      setExpand(false);
    } else {
      if (value === colorHovered) {
        onColorChange(value.name);
        setExpand(false);
      } else {
        setColorHovered(value);
      }
    }
  }

  function handleInputChange({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    onColorChange(target.value);
  }

  function resetColor() {
    onColorChange("");
  }

  function handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      setExpand(false);
    }
  }

  function getInitialColor(): ColorType {
    const found = COLOR_LIST.find((c: ColorType) => c.name === color);
    if (found) {
      return found;
    }
    return {
      code: 2,
      name: "Incolore",
      color: "#f1ebd1",
    };
  }

  function handleCloseDropDown() {
    setExpand(false);
  }
}
