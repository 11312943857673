import WorkshopId from "components/workshopId/workshopId";
import styles from "./productListSection.module.scss";
import { AddCircleOutline, Delete } from "@mui/icons-material";
import { DropOffForm, DropOffProductForm } from "types/logistic.types";
import clsx from "clsx";
import OperationGroupLogo from "components/OperationGroupLogo/OperationGroupLogo";
import { generateRandomString } from "utils/utils";
import { INITIAL_DROPOFF_PRODUCT } from "utils/logistic.init";

interface ProductListSectionProps {
  dropOff: DropOffForm;
  onDropOffChange: (value: DropOffForm) => void;
}

export default function ProductListSection({
  dropOff,
  onDropOffChange,
}: ProductListSectionProps) {
  return (
    <div className={styles["product-list"]}>
      <div className={styles["add-new-product"]} onClick={handleAddProduct}>
        <AddCircleOutline className={styles["add-new-product-icon"]} />
      </div>
      <ul className={styles["product-list-container"]}>
        {dropOff.productList.map((product, index) => (
          <li
            key={index}
            className={clsx(
              styles["product"],
              product.selected && styles["selected"]
            )}
            onClick={() => handleSelectProduct(product)}
          >
            <WorkshopId
              workshopId={product.workshopId || undefined}
              size="xxx-small"
            />
            <OperationGroupLogo
              className={styles["product-group-icon"]}
              operationGroup={product.operationGroup}
            />
            {product.selected && (
              <div
                className={styles["delete"]}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteProduct(product);
                }}
              >
                <Delete />
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );

  function handleAddProduct() {
    function generateUniqueProductId(productList: DropOffProductForm[]) {
      let id = generateRandomString(24);
      while (productList.some((p) => p.id == id)) {
        id = generateRandomString(24);
      }
      return id;
    }
    const productId = generateUniqueProductId(dropOff.productList);
    const unSelectAll = dropOff.productList.map((p) => ({
      ...p,
      selected: false,
    }));
    onDropOffChange({
      ...dropOff,
      productList: [
        ...unSelectAll,
        { ...INITIAL_DROPOFF_PRODUCT, id: productId, selected: true },
      ],
    });
  }

  function handleDeleteProduct(product: DropOffProductForm) {
    onDropOffChange({
      ...dropOff,
      productList: dropOff.productList.filter((p) => p.id !== product.id),
    });
  }

  function handleSelectProduct(targetProduct: DropOffProductForm) {
    const unSelectAll = dropOff.productList.map((p) => ({
      ...p,
      selected: false,
    }));
    onDropOffChange({
      ...dropOff,
      productList: unSelectAll.map((p) =>
        p.id === targetProduct.id ? { ...targetProduct, selected: true } : p
      ),
    });
  }
}
