import { getCrossSellItem } from "../../../requests/crossSelling";
import { useParams } from "react-router-dom";
import Create from "../create/create";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";
import { useQuery } from "@tanstack/react-query";
import DataError from "components/errors/DataError/DataError";

export default function Edit() {
  const { slug } = useParams();
  const { data, isFetching } = useQuery({
    queryKey: ["cross-sell", slug],
    queryFn: () => getCrossSellItem(slug),
    refetchOnWindowFocus: false,
    enabled: Boolean(slug),
  });

  if (isFetching) {
    return <LoadingSpinner color="gold" />;
  }

  if (!data) {
    return <DataError error="cross-sell" />;
  }

  return <Create data={data} />;
}
