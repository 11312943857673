import { useMutation, useQuery } from "@tanstack/react-query";
import style from "./estimateModal.module.scss";
import { fetchEstimate } from "../../../requests/estimate";
import LoadingSpinner from "../../loadingSpinner/loadingSpinner";
import { Edit, Launch, Mail, Person, Phone } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { setTocall } from "../../../requests/hubspot";
import { toast } from "react-toastify";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../utils/toast.options";
import Image from "../../image/Image";
import ProductInformations from "../../ProductInformations/ProductInformations";
import { fetchEstimateFromTrackerId } from "../../../requests/tracker";
import MoreTools, { IMoreToolsOptions } from "../../MoreTools/MoreTools";
import State from "../../State/State";

interface EstimateModalProps {
  id?: string;
  trackId?: string;
  onToolSelect?: () => void;
}

export default function EstimateModal({
  id,
  trackId,
  onToolSelect,
}: EstimateModalProps) {
  const { data, isFetching, error } = useQuery({
    queryKey: ["estimate-modal"],
    queryFn: () =>
      trackId
        ? fetchEstimateFromTrackerId(trackId)
        : fetchEstimate({ params: { id: id } }),
    retry: false,
  });

  const setToCallMutation = useMutation({
    mutationFn: setTocall,
    onSuccess: () => {
      toast.success("Rappel Hubspot programmé", TOAST_SUCCESS_OPTIONS);
    },
    onError: () => {
      toast.error("Une erreur est survenue", TOAST_ERROR_OPTIONS);
    },
  });

  if (error) {
    return (
      <div className={style["estimate-modal"]}>
        <div className={style["centered-error"]}>
          Aucune estimation pour cette commande
        </div>
      </div>
    );
  }

  if (isFetching) {
    return <LoadingSpinner color="gold" />;
  }

  if (!data) {
    return <>Une erreur est survenue</>;
  }

  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      icon: <Person />,
      name: `${data?.contact.displayname || data?.contact.email}`,
    },
    {
      icon: <Launch />,
      name: "Vue d'ensemble",
      onClick: handleOnToolClick,
      to: `/overview/${data?.trackId}`,
    },
    {
      icon: <Edit />,
      name: "Modifier",
      onClick: handleOnToolClick,
      to: `/estimate/edit/${data?.id}`,
    },
  ];

  return (
    <div className={style["estimate-modal"]}>
      <div className={style["read-document"]}>
        <div className={style["header"]}>
          <div className={style["header-left"]}>Demande d'estimation</div>
          <div className={style["header-right"]}>
            <MoreTools
              options={MORE_TOOLS_OPTIONS}
              className={style["more-tools"]}
            />
            <State state={data.state} className={style["state-container"]} />
          </div>
        </div>
        <div className={style["body"]}>
          <div className={style["contact-info"]}>
            {data.contact.displayname && (
              <div className={style["contact-info-element"]}>
                {data.contact.displayname}
              </div>
            )}
            {data.contact.email && (
              <Link
                className={style["contact-info-element"]}
                to={`mailto:${data.contact.email}`}
              >
                <Mail />
                {data.contact.email}
              </Link>
            )}
            {data.contact.phone && (
              <Link
                className={style["contact-info-element"]}
                to={`tel:${data.contact.phone}`}
              >
                <Phone />
                {data.contact.phone}
              </Link>
            )}
            <div className={style["contact-info-hubspot"]}>
              <button
                onClick={() => {
                  setToCallMutation.mutate({
                    trackId: data.trackId as string,
                  });
                }}
                className={style["contact-info-hubspot-button"]}
              >
                Rappel Hubspot
              </button>
            </div>
          </div>
          {data.contactPreference && (
            <div className={style["contact-preference"]}>
              {"Le client souhaite être contacté par"}
              <b>
                {data.contactPreference === "email"
                  ? " e-mail."
                  : " téléphone."}
              </b>
            </div>
          )}
          <div className={style["comment"]}>{`“${data.comments}”`}</div>
          {data.imagesList.length > 0 && (
            <label className={style["image-label"]}>Images : </label>
          )}
          <div className={style["images-list-container"]}>
            <div className={style["images-list"]}>
              {data.imagesList.map((imageUri: string, index: number) => {
                return (
                  <Image
                    className={style["image-preview"]}
                    src={imageUri}
                    key={index}
                    index={index}
                    pictureList={data.imagesList}
                  />
                );
              })}
            </div>
          </div>
          <div className={style["product-information-container"]}>
            <ProductInformations productsList={data.productsList} />
          </div>
        </div>
      </div>
    </div>
  );

  function handleOnToolClick() {
    onToolSelect && onToolSelect();
  }
}
