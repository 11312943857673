import { Launch } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { fetchCustomer } from "../../requests/customer";
import PictureLoadingSpinner from "../loadingSpinner/pictureloadingSpinner";
import styles from "./customerLink.module.scss";

interface CustomerLinkProps {
  id: string;
}

export default function CustomerLink({ id }: CustomerLinkProps) {
  const { data: customer, isLoading } = useQuery({
    queryKey: ["customer", id],
    queryFn: () => fetchCustomer(id),
    refetchOnWindowFocus: false,
  });

  if (isLoading) {
    return <PictureLoadingSpinner />;
  }

  if (!customer) {
    return <>customer is missing !</>;
  }

  return (
    <Link to={`/customers/edit/${id}`} className={styles["customer-link"]}>
      <p>{[customer.givenname, customer.familyname].join(" ")}</p>
      <Launch className={styles["icon"]} />
    </Link>
  );
}
