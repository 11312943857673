import ImageSection from "pages/quickAccess/inStoreDropOff/ProductFormSection/ImageSection/ImageSection";
import OperationsListSection from "../OperationListSection/OperationListSection";
import ProductStateSection from "../ProductStateSection/ProductStateSection";
import styles from "./productCheckInFormSection.module.scss";
import { DropOffProductForm, ILogistic, IProduct } from "types/logistic.types";
import ProductDescriptionSection from "pages/quickAccess/inStoreDropOff/ProductFormSection/ProductDescriptionSection/ProductDescriptionSection";
import { useMemo, useState } from "react";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";
import PrintProductLabelModal from "components/modals/printProductLabelModal/PrintProductLabelModal";
import Button from "components/Button/Button";
import {
  getOperationsListAcronym,
  parseNonConventionalOperations,
  parseOperationsAcronym,
} from "utils/parseLabelOperation.function";
import FrontLabel from "pages/dev_tools/ble_printer/FrontLabel";
import RearLabelList from "pages/dev_tools/ble_printer/RearLabelList";
import ConnectPrinterBluetooth from "pages/dev_tools/ble_printer/ConnectPrinterBluetooth";

interface ProductCheckInFormSectionProps {
  logistic: ILogistic;
  onLogisticChange: (logistic: ILogistic) => void;
  selectedProductId: string | null;
}

export default function ProductCheckInFormSection({
  logistic,
  onLogisticChange,
  selectedProductId,
}: ProductCheckInFormSectionProps) {
  const [printModalIsOpen, setPrintModalIsOpen] = useState<boolean>(false);
  const selectedProduct = useMemo(() => {
    return (
      logistic.productsList.find((p) => p.id === selectedProductId) || null
    );
  }, [selectedProductId, logistic]);

  const operationsAcronym = parseOperationsAcronym(
    selectedProduct?.operationsList || [],
    getOperationsListAcronym(selectedProduct?.operationsList || [])
  );

  const nonConventionalOperations = parseNonConventionalOperations(
    selectedProduct?.operationsList || []
  );

  if (!selectedProduct) {
    return null;
  }

  return (
    <div className={styles["product-check-in-form-section"]}>
      <p className={styles["title"]}>
        Déplacer l'article et remplir les données nécessaires
      </p>
      <ProductStateSection
        product={selectedProduct}
        onProductStateChange={handleProductStateChange}
      />
      <div style={{ width: "100%", display: "flex" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <OperationsListSection
            operationsList={selectedProduct?.operationsList}
          />
          <ImageSection
            isOptional={false}
            product={selectedProduct}
            onProductChange={handleProductChange}
            imagePrefix={`-${logistic.trackId}-checkin`}
          />
          <ProductDescriptionSection
            isOptional={false}
            product={selectedProduct}
            onProductChange={handleProductChange}
          />
        </div>
        <div className={styles["label-section"]}>
          <div className={styles["label-section-header"]}>
            <p>Tickets</p>
            <ConnectPrinterBluetooth />
          </div>
          <FrontLabel
            className={styles["front-label"]}
            operations={operationsAcronym}
            workshopId={selectedProduct?.workshopId || ""}
            comments={
              selectedProduct?.dueDate
                ? "à faire avant le: " +
                  new Date(selectedProduct.dueDate).toLocaleDateString("fr-FR")
                : "-"
            }
            isPreview
          />
          <RearLabelList
            operations={nonConventionalOperations}
            workshopId={selectedProduct.workshopId || ""}
            dueDate={selectedProduct?.dueDate || null}
            isPreview
          />
          <div style={{ height: "0.5rem" }}></div>
          <Button style={{ width: "100%" }} onClick={handleOpenPrintLabelModal}>
            Modifier les tickets
          </Button>
        </div>
        {/* )} */}
      </div>
      <ModalContainer
        isOpen={printModalIsOpen}
        onCancel={handleClosePrintLabelModal}
      >
        <PrintProductLabelModal
          product={selectedProduct}
          comments={
            selectedProduct.dueDate
              ? "à faire avant le: " +
                new Date(selectedProduct.dueDate).toLocaleDateString("fr-FR")
              : ""
          }
        />
      </ModalContainer>
    </div>
  );

  function handleOpenPrintLabelModal() {
    setPrintModalIsOpen(true);
  }

  function handleClosePrintLabelModal() {
    setPrintModalIsOpen(false);
  }

  function handleProductStateChange(state: string) {
    if (!selectedProduct) {
      return;
    }
    onLogisticChange({
      ...logistic,
      productsList: logistic.productsList.map((p, i) =>
        selectedProduct.id === p.id ? { ...p, status: state } : p
      ),
    });
  }

  function handleProductChange(product: DropOffProductForm | IProduct) {
    if (!selectedProduct) {
      return;
    }
    onLogisticChange({
      ...logistic,
      productsList: logistic.productsList.map((p, i) =>
        selectedProduct.id === p.id
          ? {
              ...p,
              color: product.color || "",
              brand: product.brand || "",
              category: product.category || "",
              size: product.size || "",
              incomingPicturesList: product.incomingPicturesList,
            }
          : p
      ),
    });
  }
}
