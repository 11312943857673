import style from "./cartHeader.module.scss";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import LinkIcon from "@mui/icons-material/Link";
import { Link } from "react-router-dom";
import { ICart } from "../../../../types/booking.type";
import State from "../../../State/State";

interface CartModalProps {
  cart: ICart;
}

export default function CartHeader({ cart }: CartModalProps) {
  return (
    <div className={style["header"]}>
      <div className={style["left"]}>
        <>
          <div className={style["contact-name"]}>
            {cart.contact.displayname === " " ? "NR" : cart.contact.displayname}
          </div>
          {cart.customRef && (
            <div className={style["contact-info-item"]}>
              <LinkIcon className={style["icon"]} />
              <div className={style["contact-info-item-text"]}>
                {cart.customRef}
              </div>
            </div>
          )}

          <Link
            to={`tel:${cart.contact.phone}`}
            className={style["contact-info-item"]}
          >
            <LocalPhoneIcon className={style["icon"]} />
            <div className={style["contact-info-item-text"]}>
              {cart.contact.phone || "NR"}
            </div>
          </Link>
          <Link
            to={`mailto:${cart.contact.email}`}
            className={style["contact-info-item"]}
          >
            <EmailIcon className={style["icon"]} />
            <div className={style["contact-info-item-text"]}>
              {cart.contact.email || "NR"}
            </div>
          </Link>
          {cart.comments && (
            <div className={style["contact-comment"]}>"{cart.comments}"</div>
          )}
        </>
      </div>
      <div className={style["right-container"]}>
        <div>
          <State state={cart.state} className={style["state-widget"]} />
        </div>
        <div className={style["create-date"]}>
          {"crée le "}
          {cart.createdAt &&
            new Date(cart.createdAt).toLocaleString("fr-FR").substring(0, 10)}
        </div>
      </div>
    </div>
  );
}
