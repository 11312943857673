import { isEmailValid, isPhoneValid } from "utils/phoneValidation";
import styles from "./validateFormModal.module.scss";
import { DropOffForm } from "types/logistic.types";
import { hasDuplicates } from "utils/utils";

interface ValidateFormModalProps {
  dropOff: DropOffForm;
}

export default function ValidateFormModal({ dropOff }: ValidateFormModalProps) {
  const emailError =
    !dropOff.contact ||
    !dropOff.contact.email ||
    !isEmailValid(dropOff.contact.email);
  const phoneError =
    !dropOff.contact ||
    !dropOff.contact.phone ||
    !isPhoneValid(dropOff.contact.phone);
  const workshopIdDuplicate = hasDuplicates(
    dropOff.productList.map((p) => p.workshopId)
  );

  return (
    <div className={styles["validate-form-modal"]}>
      <h3>Informations manquantes</h3>
      <ul className={styles["validate-form-list"]}>
        {!dropOff.contact && <li>Informations contact</li>}
        {emailError && <li>Adresse e-mail incorrecte</li>}
        {phoneError && <li>Numéro de téléphone incorrect</li>}
        {!dropOff.contact?.givenname && <li>Prénom manquant</li>}
        {!dropOff.contact?.familyname && <li>Nom de famille manquant</li>}
        {!dropOff.repareDays && <li>Délai de réparation</li>}
        {!dropOff.timeSlot && <li>Créneau manquant</li>}
        {workshopIdDuplicate && <li>Tickets en doublons</li>}
        {dropOff.productList.map((p, index) => (
          <div key={index}>
            {!p.productGroup && (
              <li>{`Groupe d'articles pour l'article ${index + 1}`}</li>
            )}
            {!p.operationGroup && (
              <li>{`Groupe d'opérations pour l'article ${index + 1}`}</li>
            )}
            {!p.workshopId && (
              <li>{`Ticket atelier manquant pour l'article ${index + 1}`}</li>
            )}
            {p.operations.length < 1 && (
              <li>{`Ajouter au moins une opération pour l'article ${
                index + 1
              }`}</li>
            )}
          </div>
        ))}
        {!dropOff.productList.length && !dropOff.crossSells.length && (
          <li>Aucun article ni soins/entretien.</li>
        )}
      </ul>
    </div>
  );
}
