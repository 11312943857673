import clsx from "clsx";
import styles from "./date.module.scss";

interface DateProps {
  className?: string;
  date?: Date | null;
  type?: "short" | "long";
  time?: boolean;
}

export default function Date({
  className,
  date,
  type = "short",
  time = false,
}: DateProps) {
  if (!date) {
    return (
      <p className={clsx(styles["unavailable-date"], className)}>
        indisponible
      </p>
    );
  }

  return (
    <p className={clsx(styles["date"], className)}>
      {date.toLocaleDateString("fr-FR", {
        day: "2-digit",
        weekday: type,
        month: type,
        hour: time ? "2-digit" : undefined,
        minute: time ? "2-digit" : undefined,
        second: time ? "2-digit" : undefined,
      })}
    </p>
  );
}
