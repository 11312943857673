import CheckInIcon from "components/icons/CheckInIcon";
import Widget from "../Widget";
import style from "./incomingShippingWidget.module.scss";

export default function IncomingShippingWidget() {
  return (
    <Widget to={"incoming-shipping"}>
      <div className={style["icon-container"]}>
        <CheckInIcon className={style["icon"]} />
      </div>
      <div>Réceptionner colis</div>
    </Widget>
  );
}
