import { useRef, useState } from "react";
import style from "./moreTools.module.scss";
import clsx from "clsx";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";
import DropDownPortal from "components/DropDownPortal/DropDownPortal";
import ConfirmationModalDispatch from "components/modals/ConfirmationModalDispatch/ConfirmationModalDispatch";
import OptionsList from "./OptionList/OptionList";
import MoreButton from "./MoreButton/MoreButton";

export interface IMoreToolsOptions {
  icon?: JSX.Element;
  name: string;
  onClick?: () => void;
  to?: string;
  hidden?: boolean;
  disabled?: boolean;
  askConfirmation?: boolean;
}

interface MoreToolsProps {
  className?: string;
  buttonClassName?: string;
  options: IMoreToolsOptions[];
}

export default function MoreTools({
  options,
  className,
  buttonClassName,
}: MoreToolsProps) {
  const [expand, setExpand] = useState<boolean>(false);
  const moreButtonRef = useRef<HTMLDivElement | null>(null);
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<IMoreToolsOptions | null>(null);

  return (
    <>
      <div className={clsx(style["more-tools"], className)}>
        <MoreButton
          className={buttonClassName}
          onClick={handleExpand}
          buttonRef={moreButtonRef}
        />
        <DropDownPortal
          className={style["more-tools-dropdown"]}
          onClickOutside={handleCloseDropDown}
          expand={expand}
          buttonRef={moreButtonRef}
        >
          <OptionsList options={options} onOptionClick={handleOnOptionClick} />
          <div className={style["beautiful-div"]}></div>
        </DropDownPortal>
      </div>
      <ModalContainer
        isOpen={!!openConfirmationModal}
        onCancel={handleCloseModal}
        width="fit"
        height="fit"
      >
        <ConfirmationModalDispatch
          option={openConfirmationModal}
          onCancel={handleCloseModal}
          onConfirm={handleCloseModal} //c'est normal :P
        />
      </ModalContainer>
    </>
  );

  function handleExpand() {
    setExpand(!expand);
  }

  function handleCloseDropDown() {
    setExpand(false);
  }

  function handleOnOptionClick(option: IMoreToolsOptions) {
    if (option.askConfirmation) {
      setOpenConfirmationModal(option);
    } else {
      option.onClick && option.onClick();
    }
    setExpand(false);
  }

  function handleCloseModal() {
    setOpenConfirmationModal(null);
  }
}
