import { useParams } from "react-router-dom";
import styles from "./edit.module.scss";
import { fetchOperation } from "../../../requests/operation";
import { OperationForm } from "../../../types/accounting.types";
import Create from "../create/create";
import DataError from "components/errors/DataError/DataError";
import { useQuery } from "@tanstack/react-query";
import PictureLoadingSpinner from "components/loadingSpinner/pictureloadingSpinner";

export default function Edit() {
  const { operationId } = useParams();
  const { data, isFetching } = useQuery({
    queryKey: ["operation", operationId],
    queryFn: () => fetchOperation({ operationId }),
    refetchOnWindowFocus: false,
    enabled: Boolean(operationId),
  });

  if (isFetching) {
    return (
      <div className={styles["loading-container"]}>
        <PictureLoadingSpinner />
      </div>
    );
  }

  if (!data) {
    return <DataError error="operation" />;
  }

  return <Create operation={data as OperationForm} />;
}
