import style from "./addCrossSellLogistic.module.scss";
import { ICrossSellQuote } from "../../../types/accounting.types";
import CrossSellItem from "./CrossSellItem/CrossSellItem";
import AddCrossSellModal from "../../modals/addCrossSellModal/addCrossSellModal";
import { ICrossSellItem, LogisticForm } from "../../../types/logistic.types";
import Input from "../../inputs/Input/Input";
import { useState } from "react";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";
import SectionFormTemplate from "../sectionFormTemplate/SectionFormTemplate";

interface AddCrossSellLogisticProps {
  formValues: LogisticForm;
  onFormValuesChange: (formValues: LogisticForm) => void;
}

export default function AddCrossSellLogistic({
  formValues,
  onFormValuesChange,
}: AddCrossSellLogisticProps) {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { crossSell } = formValues;

  if (
    !crossSell ||
    !crossSell.crossSellItems ||
    crossSell.crossSellItems.length == 0
  ) {
    return (
      <SectionFormTemplate title="Articles Cross-selling">
        <div className={style["add-crosssell"]} onClick={handleOpenModal}>
          ajouter des articles Cross-selling
        </div>
      </SectionFormTemplate>
    );
  }

  return (
    <>
      <SectionFormTemplate title="Articles Cross-selling">
        <div className={style["cross-sell-form-container"]}>
          <div className={style["cross-selling-products-container"]}>
            <Input
              label="Numéro de ticket"
              name="workshopId"
              inputMode="numeric"
              value={crossSell.workshopId}
              onChange={handleWorkShopIdChange}
            />
            <ul className={style["cross-selling-products-list"]}>
              {crossSell.crossSellItems.map(
                (cs: ICrossSellQuote, index: number) => {
                  return (
                    <CrossSellItem
                      crossSell={cs}
                      handleDelete={() => handleDeleteCrossSellItem(index)}
                      handleSetCrossSellItemState={
                        handleSetCrossSellItemState
                          ? () => handleSetCrossSellItemState(index)
                          : undefined
                      }
                      key={index}
                    />
                  );
                }
              )}
            </ul>
          </div>
          <div className={style["add-crosssell"]} onClick={handleOpenModal}>
            ajouter des articles Cross-selling
          </div>
        </div>
      </SectionFormTemplate>
      <ModalContainer isOpen={openModal} onCancel={handleCloseModal}>
        <AddCrossSellModal
          handleCrossSellChange={handleCrossSellItemChange}
          crossSellItems={crossSell?.crossSellItems || []}
        />
      </ModalContainer>
    </>
  );

  function handleOpenModal() {
    setOpenModal(true);
  }

  function handleCloseModal() {
    setOpenModal(false);
  }

  function handleCrossSellItemChange(crossSellItems: ICrossSellQuote[]) {
    onFormValuesChange({
      ...formValues,
      crossSell: {
        ...formValues.crossSell,
        crossSellItems: crossSellItems as ICrossSellItem[],
      },
    });
    handleCloseModal();
  }

  function handleWorkShopIdChange({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    onFormValuesChange({
      ...formValues,
      crossSell: {
        ...formValues.crossSell,
        workshopId: target.value,
      },
    });
  }

  function handleDeleteCrossSellItem(index: number) {
    onFormValuesChange({
      ...formValues,
      crossSell: {
        ...formValues.crossSell,
        crossSellItems: formValues.crossSell?.crossSellItems?.filter(
          (_, i) => i !== index
        ),
      },
    });
  }

  function handleSetCrossSellItemState(index: number) {
    onFormValuesChange({
      ...formValues,
      crossSell: {
        ...formValues.crossSell,
        crossSellItems: (
          formValues.crossSell?.crossSellItems as ICrossSellItem[]
        ).map((cs: ICrossSellItem, i) => {
          return i === index ? { ...cs, isItemReady: !cs.isItemReady } : cs;
        }),
      },
    });
  }
}
