import { IProduct } from "../../../types/logistic.types";
import styles from "./printProductLabelModal.module.scss";
import {
  getOperationsListAcronym,
  parseNonConventionalOperations,
  parseOperationsAcronym,
} from "../../../utils/parseLabelOperation.function";
import FrontLabel from "../../../pages/dev_tools/ble_printer/FrontLabel";
import RearLabelList from "../../../pages/dev_tools/ble_printer/RearLabelList";
import PrinterConnectHeader from "../../../pages/dev_tools/ble_printer/PrinterConnectHeader";
import { useState } from "react";
import PrintOperationsChecklist from "../../PrintOperationsCheckList/PrintOperationsChecklist";
import ClimbingFrontLabel from "../../../pages/dev_tools/ble_printer/ClimbingFrontLabel";
import ConnectPrinterBluetooth from "pages/dev_tools/ble_printer/ConnectPrinterBluetooth";

interface PrintProductLabelModalProps {
  product: IProduct;
  comments?: string;
}

export default function PrintProductLabelModal({
  product,
  comments = "",
}: PrintProductLabelModalProps) {
  const [operationFlatAcronym, setOperationFlatAcronym] = useState<string[]>(
    getOperationsListAcronym(product.operationsList || [])
  );
  const operationsAcronym = parseOperationsAcronym(
    product.operationsList || [],
    operationFlatAcronym
  );
  const nonConventionalOperations = parseNonConventionalOperations(
    product.operationsList || []
  );

  return (
    <div className={styles["print-product-label-modal"]}>
      <h3>Impression ticket</h3>
      {/* <PrinterConnectHeader /> */}
      <div className={styles["header"]}>
        <ConnectPrinterBluetooth />
      </div>
      <div className={styles["body"]}>
        <PrintOperationsChecklist
          operations={operationFlatAcronym}
          toggleAcronymInList={toggleAcronymInList}
        />
        <div className={styles["label-preview-container"]}>
          {product.operationGroup === "SHOES_CLIMBING" ? (
            <ClimbingFrontLabel
              operations={operationsAcronym}
              workshopId={product.workshopId || ""}
              comments={comments}
            />
          ) : (
            <FrontLabel
              operations={operationsAcronym}
              workshopId={product.workshopId || ""}
              comments={comments}
              // isPreview
            />
          )}
          <RearLabelList
            operations={nonConventionalOperations}
            workshopId={product.workshopId || ""}
            dueDate={product.dueDate || null}
            // isPreview
          />
        </div>
      </div>
    </div>
  );

  function toggleAcronymInList(operationAcronym: string) {
    if (operationFlatAcronym.includes(operationAcronym)) {
      setOperationFlatAcronym((prev) =>
        prev.filter((op) => op !== operationAcronym)
      );
    } else {
      setOperationFlatAcronym((prev) => [...prev, operationAcronym]);
    }
  }
}
