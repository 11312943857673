import { IValidationError } from "../types";
import { ILogistic } from "../types/logistic.types";
import { PRODUCT_CATEGORIES } from "../utils/operation.init";
import brandDataTest from "./brands.json";

export async function fetchLogistic({
  params,
}: {
  params: any;
}): Promise<ILogistic> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/logistic/${params.id}`,
      {
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getLogistic(id: string): Promise<ILogistic> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/logistic/${id}`,
      {
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchLogisticList(
  query?: string
): Promise<{ logisticsList: ILogistic[]; totalCount: number } | null> {
  try {
    let queryUrl = query ? query : "";
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/logistic` + queryUrl,
      {
        credentials: "include",
      }
    );
    const data = await response.json();

    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function addLogistic(
  logistic: ILogistic,
  updateQuote: boolean = false,
  setShippingToDelivered: boolean = false
): Promise<string | IValidationError[] | null> {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/logistic`, {
      headers: new Headers({ "Content-Type": "application/json" }),
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ logistic, updateQuote, setShippingToDelivered }),
    });
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function addLogisticMutation({
  logistic,
  updateQuote = false,
  setShippingToDelivered = false,
}: {
  logistic: ILogistic;
  updateQuote?: boolean;
  setShippingToDelivered?: boolean;
}): Promise<any> {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/logistic`, {
      headers: new Headers({ "Content-Type": "application/json" }),
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ logistic, updateQuote, setShippingToDelivered }),
    });
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function updateLogisticMutation({
  logistic,
  updateQuote = false,
  setShippingToDelivered = false,
}: {
  logistic: ILogistic;
  updateQuote?: boolean;
  setShippingToDelivered?: boolean;
}): Promise<string | IValidationError[] | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/logistic/${logistic.id}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "PUT",
        credentials: "include",
        body: JSON.stringify({ logistic, updateQuote, setShippingToDelivered }),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function updateLogistic(
  logistic: ILogistic,
  updateQuote: boolean = false,
  setShippingToDelivered: boolean = false
): Promise<string | IValidationError[] | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/logistic/${logistic.id}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "PUT",
        credentials: "include",
        body: JSON.stringify({ logistic, updateQuote, setShippingToDelivered }),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function deleteLogistic(
  logisticId: string,
  signal?: AbortSignal
): Promise<void | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/logistic/${logisticId}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "DELETE",
        credentials: "include",
        signal: signal,
      }
    );
    if (response.status >= 400) {
      throw new Response("Deletion failed", {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function deleteFileFromLogistic(
  fileName: string
): Promise<{ deleted: boolean; fileName: string }> {
  try {
    const response = await fetch(
      `${
        process.env.REACT_APP_API_URL
      }/logistic/delete-file-from-product/${encodeURI(fileName)}`,
      {
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function checkIfWorkshopIdExists(
  workshopId: string
): Promise<boolean> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/logistic/check-if-workshop-id-exists/${workshopId}`,
      {
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchBrands({
  request,
}: {
  request?: Request;
}): Promise<string[]> {
  try {
    // const response = await fetch(`${API_URL_LOGISTIC}operation-groups`, {
    //   signal: request?.signal,
    // });

    // const data = await response.json();

    // if (response.status !== 200) {
    //   throw new Response(data, { status: response.status });
    // }
    const data = await timeOut(brandDataTest);
    return data;
    // return data.operationGroups;
  } catch (error: any) {
    throw new Response(error);
  }
}

async function timeOut(data: string[]): Promise<string[]> {
  return new Promise((resolve, reject) =>
    setTimeout(() => {
      resolve(data);
    }, 1500)
  );
}

export async function fetchCategories({
  request,
}: {
  request?: Request;
}): Promise<string[]> {
  try {
    // const response = await fetch(`${API_URL_LOGISTIC}operation-groups`, {
    //   signal: request?.signal,
    // });

    // const data = await response.json();

    // if (response.status !== 200) {
    //   throw new Response(data, { status: response.status });
    // }
    const data = await timeOut(PRODUCT_CATEGORIES.map((c) => c.description));
    return data;
    // return data.operationGroups;
  } catch (error: any) {
    throw new Response(error);
  }
}
