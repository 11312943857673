import { useMutation } from "@tanstack/react-query";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  checkIfWorkshopIdExists,
  updateLogisticMutation,
} from "../../../../requests/logistic";
import {
  ICrossSellLogistic,
  ILogistic,
} from "../../../../types/logistic.types";
import { TOAST_ERROR_OPTIONS } from "../../../../utils/toast.options";
import Button from "../../../Button/Button";
import PrepareCrossSellProducts from "./PrepareCrossSellProducts/PrepareCrossSellProducts";
import MissingInformationModal from "./ProductsCheckIn/MissingInformationModal/MissingInformationModal";
import ProductsCheckIn from "./ProductsCheckIn/ProductsCheckIn";
import { productIsValid } from "./ProductsCheckIn/index.function";
import style from "./logisticActionSection.module.scss";

interface LogisticActionSectionProps {
  data: ILogistic;
  dueDate?: Date;
  onActionValidated?: () => void;
}

export default function LogisticActionSection({
  data,
  dueDate,
  onActionValidated,
}: LogisticActionSectionProps) {
  const MOVE_PRODUCT_STEP = 0;
  const CROSS_SELL_STEP = 1;
  const [step, setStep] = useState<number>(MOVE_PRODUCT_STEP);
  const [logistic, setLogistic] = useState<ILogistic>({ ...data });
  const [openModal, setOpenModal] = useState<boolean>(false);

  const logisticMutation = useMutation({
    mutationFn: updateLogisticMutation,
    onSuccess: () => {
      onActionValidated && onActionValidated();
    },
    onError: () => {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    },
  });

  return (
    <div className={style["logistic-action-section"]}>
      {step === MOVE_PRODUCT_STEP && (
        // <MoveProducts
        //   productsList={logistic.productsList}
        //   setProductsList={(value) => {
        //     setLogistic((prev) => ({ ...prev, productsList: [...value] }));
        //   }}
        //   dueDate={dueDate}
        // />
        <ProductsCheckIn
          logistic={logistic}
          onLogisticChange={handleChangeLogistic}
        />
      )}
      {step === CROSS_SELL_STEP &&
        logistic.crossSell &&
        logistic.crossSell.crossSellItems &&
        logistic.crossSell.crossSellItems.length > 0 && (
          <PrepareCrossSellProducts
            logistic={logistic}
            setCrossSell={handleSetCrossSell}
          />
        )}
      <div className={style["logistic-action-container-bottom"]}>
        {step > 0 && (
          <Button className={style["previous-button"]} onClick={previousStep}>
            Retour
          </Button>
        )}
        <Button
          isLoading={logisticMutation.isPending}
          onClick={handleValidateAction}
        >
          Valider
        </Button>
      </div>

      <ModalContainer
        width="narrow"
        height="fit"
        isOpen={openModal}
        onCancel={handleCloseModal}
      >
        <MissingInformationModal logistic={logistic} />
      </ModalContainer>
    </div>
  );

  function handleChangeLogistic(logistic: ILogistic) {
    setLogistic(logistic);
  }

  function handleCloseModal() {
    setOpenModal(false);
  }

  async function handleValidateAction() {
    if (step === MOVE_PRODUCT_STEP) {
      return handleMoveProductStep();
    }
    if (step === CROSS_SELL_STEP) {
      await handleCrossSellingStep();
    }
  }

  function handleMoveProductStep() {
    if (!allProductsAreValid()) {
      return setOpenModal(true);
    }
    if (
      // logistic.crossSell &&
      logistic.crossSell?.crossSellItems &&
      logistic.crossSell?.crossSellItems.length > 0
    ) {
      return setStep(CROSS_SELL_STEP);
    }
    logisticMutation.mutate({
      logistic: {
        ...logistic,
        status: "LOGISTIC_WORKSHOP",
        productsList: logistic.productsList.map((p) => ({ ...p, dueDate })),
      },
      updateQuote: false,
      setShippingToDelivered: false,
    });
  }

  async function handleCrossSellingStep() {
    if (
      !logistic.crossSell?.workshopId ||
      logistic.crossSell?.workshopId.length < 7 ||
      (await checkIfWorkshopIdExists(logistic.crossSell?.workshopId))
    ) {
      toast.error(
        "Veuillez rentrer un numéro de ticket valide.",
        TOAST_ERROR_OPTIONS
      );
      return;
    }
    logisticMutation.mutate({
      logistic: {
        ...logistic,
        status: "LOGISTIC_WORKSHOP",
        productsList: logistic.productsList.map((p) => ({ ...p, dueDate })),
      },
      updateQuote: false,
      setShippingToDelivered: false,
    });
  }

  function allProductsAreValid(): boolean {
    return logistic.productsList.every((product) => productIsValid(product));
  }

  function previousStep() {
    setStep((prev) => prev - 1);
  }

  function handleSetCrossSell(crossSell: ICrossSellLogistic) {
    setLogistic((prev) => ({ ...prev, crossSell }));
  }
}
