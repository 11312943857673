import { Outlet, RouteObject } from "react-router-dom";
import ErrorPage from "../errorPage/error-page";

import Overview from "./index";
import Read from "./read/read";

export const route: RouteObject = {
  path: "/overview",
  element: <Outlet />,
  errorElement: <ErrorPage />,

  children: [
    {
      index: true,
      element: <Overview />,
    },
    {
      path: ":id",
      element: <Read />,
    },
  ],
};
