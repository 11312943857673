import style from "./productMiniature.module.scss";
import {
  ILogisticFileList,
  IProduct,
  ProductForm,
} from "../../../types/logistic.types";
import WorkshopId from "../../workshopId/workshopId";
import translate from "../../../utils/translation";
import { Delete } from "@mui/icons-material";
import getStatusColor from "../../../utils/translation/statusColor";
import { useState } from "react";
import ConfirmModalComp from "components/confirmationModal/ConfirmModalComp";
import AddProductModal from "components/modals/addProductModal/AddProductModal";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";

interface ProductMiniatureProps {
  product: ProductForm;
  handleDelete: () => void;
  onProductChange: (product: ProductForm) => void;
}

export default function ProductMiniature({
  product,
  handleDelete,
  onProductChange,
}: ProductMiniatureProps) {
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <>
      <div className={style["product-miniature"]} onClick={handleOpenModal}>
        <div className={style["product-miniature-top"]}>
          <div
            className={style["state"]}
            style={{
              backgroundColor: `${getStatusColor(product.status)}`,
            }}
          >
            {translate(product.status)}
          </div>
          <div className={style["delete"]} onClick={handleConfirmDelete}>
            <Delete />
          </div>
        </div>
        <div className={style["product-miniature-bottom"]}>
          <WorkshopId workshopId={product.workshopId} size={"x-small"} />
          <div className={style["brand"]}>{product.brand}</div>
        </div>
      </div>
      <ModalContainer
        width="fit"
        height="fit"
        isOpen={openConfirmModal}
        onCancel={handleCloseConfirmModal}
      >
        <ConfirmModalComp
          confirmButtonClassName={style["delete-button"]}
          title={"Etes-vous sur de vouloir supprimer cette article ?"}
          confirmText="Supprimer"
          onConfirm={handleDelete}
          onCancel={handleCloseConfirmModal}
        />
      </ModalContainer>
      <ModalContainer
        isOpen={openModal}
        onCancel={handleCloseModal}
        askCancelConfirmation
      >
        <AddProductModal
          product={product}
          setProduct={onProductChange}
          onValidateForm={handleCloseModal}
        />
      </ModalContainer>
    </>
  );

  function handleValidateProduct(product: ProductForm) {
    onProductChange(product);
    handleCloseModal();
  }

  function handleCloseModal() {
    setOpenModal(false);
  }

  function handleOpenModal() {
    setOpenModal(true);
  }

  function handleConfirmDelete(
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) {
    e.stopPropagation();
    setOpenConfirmModal(true);
  }

  function handleCloseConfirmModal() {
    setOpenConfirmModal(false);
  }
}
