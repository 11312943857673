import Input from "components/inputs/Input/Input";
import { useState } from "react";

interface TimeSlotInputsProps {
  onChange: ({ start, end }: { start: string; end: string }) => void;
}

export default function TimeslotInputs({ onChange }: TimeSlotInputsProps) {
  const [start, setStart] = useState("00:00");
  const [end, setEnd] = useState("00:00");

  return (
    <div>
      <Input type="time" label="Début" value={start} onChange={(e) => {
        handleChange(e.target.value, "start")
      }} />
       <Input type="time" label="Fin" value={end} onChange={(e) => {
        handleChange(e.target.value, "end")
      }} />
      <button
        type="button"
        className="button-s outlined"
        onClick={handleValidate}
      >
        Ajouter
      </button>
    </div>
  );

  function handleChange(value: any, period: string) {
    if (period === "start") {
      setStart(value);
    }
    if (period === "end") {
      setEnd(value);
    }
  }

  function handleValidate(event: any) {
    onChange({ start, end });
  }
}
