import { keepPreviousData, useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import Button from "components/Button/Button";
import DataError from "components/errors/DataError/DataError";
import Input from "components/inputs/Input/Input";
import PictureLoadingSpinner from "components/loadingSpinner/pictureloadingSpinner";
import Price from "components/Price/Price";
import { useState } from "react";
import { fetchOperationsList } from "requests/operation";
import { IOperation } from "types/accounting.types";
import { DropOffProductForm } from "types/logistic.types";
import { OPERATION_DEFAULT } from "utils/operation.init";
import styles from "./addOperationModal.module.scss";

interface AddOperationModalProps {
  product: DropOffProductForm | null;
  onAddOperation: (operation: IOperation) => void;
  onCancel: () => void;
}

export default function AddOperationModal({
  product,
  onAddOperation,
  onCancel,
}: AddOperationModalProps) {
  const [name, setName] = useState<string>("");
  const { data, isLoading } = useQuery({
    queryKey: ["quick-access-operationListSelect", name],
    queryFn: () =>
      fetchOperationsList(
        `productGroup=${product?.productGroup}&operationGroup=${
          product?.operationGroup
        }${name ? `&name=${name}` : ""}`, //&name=${name}
        true
      ),
    enabled: Boolean(product),
    placeholderData: keepPreviousData,
  });

  if (!product) {
    onCancel();
    return null;
  }

  if (isLoading) {
    return (
      <div className={styles["add-operation-modal"]}>
        <PictureLoadingSpinner />
      </div>
    );
  }

  if (!data) {
    return (
      <div className={styles["add-operation-modal"]}>
        <DataError error="operation-list" />
      </div>
    );
  }

  const { operations } = data;

  return (
    <div className={styles["add-operation-modal"]}>
      <Input
        placeholder="Operation"
        className={styles["operation-input"]}
        value={name}
        onChange={({ target }) => setName(target.value)}
      />
      <div className={styles["add-new-container"]}>
        <Button onClick={handleAddDefaultOperation}>
          Ajouter une opération vide
        </Button>
      </div>
      <ul className={styles["operation-list"]}>
        {operations.map((op, index) => (
          <li
            className={clsx(
              styles["operation"],
              product.operations.find(
                (selectedOp) => op.slug === selectedOp.slug
              ) && styles["selected"]
            )}
            key={index}
            onClick={() => onAddOperation(op)}
          >
            <p>{op.description}</p>
            <Price price={op.price} />
          </li>
        ))}
      </ul>
    </div>
  );

  function handleAddDefaultOperation() {
    const defaultOperation = {
      ...OPERATION_DEFAULT,
      operationGroup: product?.operationGroup!,
      productGroup: product?.productGroup!,
      operationType:
        product?.productGroup! === "SHOES" ? "SHOES_AUTRE" : "LEATHER_AUTRE",
    };
    onAddOperation(defaultOperation);
    onCancel();
  }
}
