import ProductGroupLogo from "components/ProductGroupLogo/ProductGroupLogo";
import ShippingServiceLogo from "components/ShippingServiceLogo/ShippingServiceLogo";
import { IOperation } from "types/accounting.types";
import { IPeriod, IShipping } from "types/shipping.types";
import styles from "./incomingShippingCard.module.scss";
import { IProduct } from "types/logistic.types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import translate from "utils/translation";

interface IncomingShippingCardProps {
  shipping: IShipping;
}

export default function IncomingShippingCard({
  shipping,
}: IncomingShippingCardProps) {
  return (
    <Link
      className={styles["incoming-shipping-card"]}
      to={`/quick-access/incoming-shipping/${shipping.trackId}?type=non-drop-off`}
    >
      <div className={styles["incoming-shipping-card-left"]}>
        <div className={styles["shipping-period"]}>
          <p className={styles["period"]}>
            {timeSlotToString(shipping.period)}
          </p>
          <p className={styles["name"]}>
            {shipping.contact?.givenname + " " + shipping.contact?.familyname}
          </p>
        </div>
        <ShippingAddress shipping={shipping} />
        <div>
          <ShippingServiceLogo
            shippingService={shipping.shippingMethod?.shippingService}
          />
          <p>{shipping?.externalShipping?.externalId}</p>
        </div>
      </div>
      <ProductsList
        className={styles["incoming-shipping-card-right"]}
        productsList={shipping.productsList}
      />
    </Link>
  );

  function timeSlotToString(period: IPeriod | undefined) {
    if (!period) {
      return "-";
    }
    return `${new Date(period.start).toLocaleTimeString("fr-FR", {
      hour: "2-digit",
      minute: "2-digit",
    })} - ${new Date(period.end).toLocaleTimeString("fr-FR", {
      hour: "2-digit",
      minute: "2-digit",
    })}`;
  }
}

interface ShippingAddressProps {
  shipping: IShipping;
}

function ShippingAddress({ shipping }: ShippingAddressProps) {
  const address =
    shipping.direction === "INCOMING" ? shipping.sender : shipping.recipient;

  if (!address) {
    return null;
  }

  return (
    <div className={styles["address"]}>
      <p>{address.address1 + " " + (address.address2 || "")}</p>
      <p>{address.zipCode + " " + address.city}</p>
    </div>
  );
}

interface ProductsListProps {
  className?: string;
  productsList?: IProduct[];
}

function ProductsList({ className, productsList }: ProductsListProps) {
  if (!productsList) {
    return null;
  }

  return (
    <ul className={clsx(styles["products-list"], className)}>
      {productsList.map((product, index) => (
        <li className={styles["product-item"]} key={index}>
          <div className={styles["product-item-header"]}>
            <ProductGroupLogo productGroup={product.productGroup} />
            <p className={styles["brand"]}>
              {product.brand || translate(product.productGroup)}
            </p>
          </div>
          <OperationsList operationsList={product.operationsList} />
        </li>
      ))}
    </ul>
  );
}

interface OperationsListProps {
  operationsList: IOperation[];
}

function OperationsList({ operationsList }: OperationsListProps) {
  return (
    <ul className={styles["operations-list"]}>
      {operationsList.map((operation, index) => (
        <li className={styles["operation-item"]} key={index}>
          {operation.description}
        </li>
      ))}
    </ul>
  );
}
