import { Edit } from "@mui/icons-material";
import styles from "./addContactSection.module.scss";
import { DropOffForm } from "types/logistic.types";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";
import DropOffContactModal from "../../modals/DropOffContactModal/DropOffContactModal";
import Button from "components/Button/Button";
import { useState } from "react";

interface AddContactSectionProps {
  className?: string;
  dropOff: DropOffForm;
  onDropOffChange: (dropOff: DropOffForm) => void;
}

export default function AddContactSection({
  className,
  dropOff,
  onDropOffChange,
}: AddContactSectionProps) {
  const [openContactModal, setOpenContactModal] = useState<boolean>(false);

  if (dropOff.contact) {
    return (
      <div className={className}>
        <div
          className={styles["contact-information"]}
          onClick={handleOpenContactModal}
        >
          <p className={styles["contact-item"]}>
            {`${dropOff.contact.displayname} | ${dropOff.contact.phone} | ${dropOff.contact.email}`}
          </p>
          <div className={styles["edit-button"]}>
            <Edit className={styles["icon"]} />
          </div>
        </div>
        <ModalContainer
          isOpen={openContactModal}
          onCancel={handleCloseContactModal}
        >
          <DropOffContactModal
            dropOff={dropOff}
            onDropOffChange={onDropOffChange}
            onClose={handleCloseContactModal}
          />
        </ModalContainer>
      </div>
    );
  }

  return (
    <div className={className}>
      <Button
        className={styles["action-button"]}
        onClick={handleOpenContactModal}
      >
        Ajouter un contact
      </Button>
      <ModalContainer
        isOpen={openContactModal}
        onCancel={handleCloseContactModal}
      >
        <DropOffContactModal
          dropOff={dropOff}
          onDropOffChange={onDropOffChange}
          onClose={handleCloseContactModal}
        />
      </ModalContainer>
    </div>
  );

  function handleOpenContactModal() {
    setOpenContactModal(true);
  }

  function handleCloseContactModal() {
    setOpenContactModal(false);
  }
}
