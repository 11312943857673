import { DropOffForm, ShippingServiceForm } from "types/logistic.types";
import styles from "./shippingSection.module.scss";
import ShippingServiceSection from "./ShippingServiceSection/ShippingServiceSection";
import RepareTimeSection from "./RepareTimeSection/RepareTimeSection";
import { useEffect, useState } from "react";
import ShippingServiceLogo from "components/ShippingServiceLogo/ShippingServiceLogo";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { TimeSlot } from "types/shipping.types";
import { addBusinessDays } from "utils/utils";
import Date from "components/Date/Date";
import Timeslot from "components/Timeslot/Timeslot";
import NonDropOffShippingSection from "./NonDropOffShippingSection/NonDropOffShippingSection";

interface ShippingSectionProps {
  dropOff: DropOffForm;
  onDropOffChange: (dropOff: DropOffForm) => void;
}

export default function ShippingSection({
  dropOff,
  onDropOffChange,
}: ShippingSectionProps) {
  const [expand, setExpand] = useState<boolean>(false);
  const repareDays = dropOff.repareDays || defaultRepareDays();
  useEffect(() => {
    if (dropOff.repareDays == null) {
      handleRepareTimeChange(defaultRepareDays());
    }
  }, [dropOff.productList]);

  if (dropOff.nonDropOff || dropOff.productList.length === 0) {
    return (
      <NonDropOffShippingSection
        dropOff={dropOff}
        onDropOffChange={onDropOffChange}
      />
    );
  }

  if (!expand) {
    return (
      <div
        className={styles["in-store-drop-off-bottom-collapsed"]}
        onClick={handleToogleSection}
      >
        <div className={styles["in-store-drop-off-bottom-collapsed-left"]}>
          <ShippingServiceLogo
            shippingService={dropOff.shippingService.name}
            className={styles["shipping-service-logo"]}
          />
          <Date date={addBusinessDays(repareDays)} />
          <Timeslot timeSlot={dropOff.timeSlot} />
        </div>
        <div className={styles["expand-button"]} onClick={handleToogleSection}>
          <AddCircleOutline />
        </div>
      </div>
    );
  }

  return (
    <div className={styles["in-store-drop-off-bottom"]}>
      <ShippingServiceSection
        dropOff={dropOff}
        onShippingServiceChange={handleShippingServiceChange}
      />
      <RepareTimeSection
        dropOff={dropOff}
        onRepareTimeChange={handleRepareTimeChange}
        onTimeSlotChange={handleTimeSlotChange}
      />
      <div className={styles["collapse-button"]} onClick={handleToogleSection}>
        <RemoveCircleOutline />
      </div>
    </div>
  );

  function handleToogleSection() {
    setExpand(!expand);
  }

  function handleRepareTimeChange(repareDays: number) {
    onDropOffChange({ ...dropOff, repareDays });
  }

  function handleTimeSlotChange(timeSlot: TimeSlot) {
    onDropOffChange({ ...dropOff, timeSlot });
  }

  function handleShippingServiceChange(shippingService: ShippingServiceForm) {
    onDropOffChange({ ...dropOff, shippingService });
  }

  function defaultRepareDays() {
    if (dropOff.productList.some((p) => p.productGroup === "LEATHER")) {
      return 21;
    }
    return 10;
  }
}
