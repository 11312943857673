import Widget from "../Widget";
import styles from "./shopDrop.module.scss";
import Icon from "components/Icon/Icon";
import shopIcon from "assets/icons/shoemaker-shop.png";

export default function ShopDropWidget() {
  return (
    <Widget to={"/quick-access/in-store-drop-off"}>
      <div className={styles["icon-container"]}>
        <Icon logo={shopIcon} className={styles["icon"]} />
      </div>
      <p>Dépôt boutique</p>
    </Widget>
  );
}
