import { Outlet, RouteObject } from "react-router-dom";
import ErrorPage from "../errorPage/error-page";

import Home from "../home";

export const route: RouteObject = {
  path: "/dashboard",
  element: <Outlet />,

  errorElement: <ErrorPage />,
  children: [
    {
      index: true,
      element: <Home />,
    },
  ],
};
