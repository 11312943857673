import clsx from "clsx";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";
import PaymentModal from "components/modals/paymentModal/paymentModal";
import { ProtectedComponent } from "components/protectedComponent/protectedComponent";
import styles from "../overviewActionDispatch.module.scss";
import { ITracker } from "types/tracker.types";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import Button from "components/Button/Button";

interface ForcePaymentButtonProps {
  tracker: ITracker;
}

export default function ForcePaymentButton({
  tracker,
}: ForcePaymentButtonProps) {
  const queryClient = useQueryClient();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  if (tracker.payment) {
    return null;
  }

  return (
    <>
      <ProtectedComponent roles={["ROLE_ADMIN"]}>
        <Button
          className={clsx(styles["payment-button"], styles["action-button"])}
          onClick={handleOpenPaymentModal}
        >
          Forcer un paiement
        </Button>
      </ProtectedComponent>
      <ModalContainer isOpen={modalIsOpen} onCancel={handleClosePaymentModal}>
        <PaymentModal tracker={tracker} onSuccess={handleClosePaymentModal} />
      </ModalContainer>
    </>
  );

  function handleOpenPaymentModal() {
    setModalIsOpen(true);
  }

  function handleClosePaymentModal() {
    setModalIsOpen(false);
    queryClient.refetchQueries({ queryKey: ["tracker-overview", tracker.id] });
  }
}
