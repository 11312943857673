import styles from "./linkTrackerToDropOff.module.scss";
import getStatusColor from "utils/translation/statusColor";
import { translateTrackingState } from "utils/tracking.init";
import Button from "components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";
import CenterCell from "components/CenterCell/CenterCell";
import { ITracker } from "types/tracker.types";
import { COLLECTED, trackerStateMap } from "utils/tracking.utils";
import TrackerSummaryModal from "./TrackerSummaryModal/TrackerSummaryModal";

interface LinkTrackerToDropOffProps {
  tracker: ITracker;
  isCurrent?: boolean;
  onClose: () => void;
}

export default function LinkTrackerToDropOff({
  isCurrent = false,
  tracker,
  onClose,
}: LinkTrackerToDropOffProps) {
  const navigate = useNavigate();
  const [openQuoteSummaryModal, setOpenQuoteSummaryModal] =
    useState<boolean>(false);

  if (!tracker) {
    return <>tracker is missing !</>;
  }

  const trackerStateRank = trackerStateMap.get(tracker.state) || 0;

  return (
    <tr className={styles["link-tracker-to-drop-off"]}>
      <td className={styles["date"]}>
        {!!tracker.eventsList[0].date
          ? new Date(tracker.eventsList[0].date).toLocaleDateString("fr-FR")
          : "indisponible"}
      </td>
      <td>
        <CenterCell>
          <div
            className={styles["state"]}
            style={{ color: getStatusColor(tracker.state) }}
          >
            {translateTrackingState(tracker.state)}
          </div>
        </CenterCell>
      </td>
      <td>
        <CenterCell>
          <Button
            className={styles["more-details"]}
            onClick={handleOpenQuoteSummaryModal}
          >
            plus de details
          </Button>
        </CenterCell>
      </td>
      <td>
        <CenterCell>
          {isCurrent ? (
            <p className={styles["actual"]}>commande actuelle</p>
          ) : (
            <>
              {trackerStateRank < COLLECTED ? (
                <Button onClick={backToOrder}>Reprendre cette commande</Button>
              ) : null}
            </>
          )}
        </CenterCell>
      </td>
      <ModalContainer
        isOpen={openQuoteSummaryModal}
        onCancel={handleCancelQuoteSummaryModal}
        width="narrow"
        height="fit"
      >
        <TrackerSummaryModal tracker={tracker} />
      </ModalContainer>
    </tr>
  );

  function handleCancelQuoteSummaryModal() {
    setOpenQuoteSummaryModal(false);
  }

  function handleOpenQuoteSummaryModal() {
    setOpenQuoteSummaryModal(true);
  }

  function backToOrder() {
    if (!tracker) {
      return;
    }
    if (
      tracker.incomingShipping &&
      tracker.incomingShipping.shippingService !== "DROP-OR-COLLECT"
    ) {
      navigate(
        `/quick-access/incoming-shipping/${tracker.id}?type=non-drop-off&source=resume-old`
      );
    } else {
      navigate(
        `/quick-access/incoming-shipping/${tracker.id}?source=resume-old`
      );
    }
    onClose();
  }
}

interface TrackerActionCellProps {}

function TrackerActionCell({}: TrackerActionCellProps) {}
