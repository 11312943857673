import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import ReferralForm from "../../../components/forms/ReferralForm/ReferralForm";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";
import { getReferral } from "../../../requests/referrals";
import styles from "./edit.module.scss";

export default function Edit() {
  const { id } = useParams();
  const { data, isFetching } = useQuery({
    queryKey: ["referral-edit", id],
    queryFn: () => getReferral(id),
    refetchOnWindowFocus: false,
    enabled: Boolean(id),
  });

  if (isFetching) {
    return <LoadingSpinner />;
  }

  if (!data) {
    return <>data is missing !</>;
  }

  const { referral } = data;

  return (
    <div className={styles["container"]}>
      <div className={styles["title"]}>Modifier un parrainage</div>
      <ReferralForm referral={referral} />
    </div>
  );
}
