import { Link } from "react-router-dom";
import translate from "../../../../utils/translation";
import { IProduct } from "../../../../types/logistic.types";
import { IShipping } from "../../../../types/shipping.types";
import style from "./shippingSelection.module.scss";
import logo from "../../../../assets/logo-chronopost.png";
import logoGp from "../../../../assets/favicon-32x32.png";
import { IOperation } from "../../../../types/accounting.types";
import IncomingShippingCard from "../ShippingService/IncomingInternal/ShippingList/IncomingShippingCard/IncomingShippingCard";

interface ShippingSelectionProps {
  shippings?: IShipping[];
  link?: string;
}
export default function ShippingSelection({
  shippings,
  link,
}: ShippingSelectionProps) {
  if (!shippings) {
    return <></>;
  }

  return (
    <div className={style["shipping-selection-list"]}>
      {shippings.map((shipping: IShipping, index: number) => (
        <IncomingShippingCard key={index} shipping={shipping} />
      ))}
    </div>
  );
}

interface ShippingChildProps {
  shipping: IShipping;
  link?: string;
}

function ShippingChild({ link, shipping }: ShippingChildProps) {
  return (
    <Link
      to={`/quick-access/incoming-shipping/${shipping.trackId}?type=non-drop-off`}
      className={style["shipping-widget-container"]}
    >
      <div className={style["shipping-widget-left"]}>
        <div className={style["shipping-widget-left-top"]}>
          {shipping.period &&
            shipping.shippingMethod?.shippingService === "INTERNAL" && (
              <div className={style["shipping-period"]}>
                {new Date(shipping.period.start).toLocaleTimeString("fr-FR", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
                {" - "}
                {new Date(shipping.period.end).toLocaleTimeString("fr-FR", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </div>
            )}
          <div
            className={`${
              shipping.shippingMethod?.shippingService !== "INTERNAL"
                ? style["shipping-period"]
                : style["displayname"]
            }`}
          >
            {shipping.contact && shipping.contact.displayname}
          </div>
        </div>
        <div className={style["shipping-widget-left-bottom"]}>
          {shipping.productsList && (
            <div className={style["external-shipping"]}>
              Nombre d'articles : {shipping.productsList.length}
            </div>
          )}
          <div className={style["external-shipping"]}>
            {shipping.externalShipping?.externalId ??
              "id chronopost inexistant"}
          </div>
          <div>
            {shipping.shippingMethod?.shippingService === "CHRONOPOST-2SHOP" ||
            shipping.shippingMethod?.shippingService === "CHRONOPOST-EUR" ? (
              <img
                className={style["service-logo"]}
                src={logo}
                alt={"chronopost-logo"}
              />
            ) : (
              <img
                className={style["service-logo"]}
                src={logoGp}
                alt={"chronopost-logo"}
              />
            )}
          </div>
        </div>
      </div>
      <div className={style["shipping-widget-right"]}>
        {shipping.productsList &&
          shipping.productsList.map((product, index) => (
            <ProductChild key={`product-${index}`} product={product} />
          ))}
        <div>
          {shipping.externalShipping &&
            shipping.externalShipping.externalId &&
            shipping.externalShipping.externalId}
        </div>
      </div>
    </Link>
  );
}

interface ProductChildProps {
  product: IProduct;
}

function ProductChild({ product }: ProductChildProps) {
  return (
    <div>
      <div>
        {translate(product.productGroup)} - {translate(product.operationGroup)}
        <div>
          <ul>
            {product.operationsList.map((operation: IOperation) => {
              if (!operation.canceled)
                return (
                  <li key={`${operation.slug}-${operation.description}`}>
                    {operation.description}
                  </li>
                );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
