import { useQuery } from "@tanstack/react-query";
import PictureLoadingSpinner from "components/loadingSpinner/pictureloadingSpinner";
import { fetchOperationsList } from "requests/operation";
import { IOperation } from "types/accounting.types";
import { DropOffProductForm } from "types/logistic.types";
import OperationEntry from "./OperationEntry/OperationEntry";
import styles from "./operationsList.module.scss";

interface OperationsListProps {
  product: DropOffProductForm | null;
  onUpdateOperation: (operation: IOperation, targetIndex: number) => void;
  onDeleteOperation: (index: number) => void;
}

export default function OperationsList({
  product,
  onUpdateOperation,
  onDeleteOperation,
}: OperationsListProps) {
  const { data, isLoading } = useQuery({
    queryKey: ["quick-access-operationListSelect", product?.id],
    queryFn: () =>
      fetchOperationsList(
        `productGroup=${product?.productGroup}&operationGroup=${product?.operationGroup}`,
        true
      ),
    enabled: Boolean(product),
  });

  if (!product || !product.operationGroup || !product.productGroup) {
    return null;
  }

  if (isLoading) {
    return (
      <div className={styles["operations-list"]}>
        <PictureLoadingSpinner />
      </div>
    );
  }

  return (
    <div className={styles["operations-list"]}>
      {(product.operations || []).map(
        (operation: IOperation, index: number) => (
          <OperationEntry
            key={`product-${product.id || ""}-${index}-operation`}
            operationGroup={product.operationGroup!}
            datalist={data?.operations || []}
            operation={operation}
            onChange={(updatedOperation) => {
              onUpdateOperation(updatedOperation, index);
            }}
            onDelete={() => onDeleteOperation(index)}
          />
        )
      )}
    </div>
  );
}
