import { Close } from "@mui/icons-material";
import Image from "../../../image/Image";
import styles from "./imagePreview.module.scss";
import PictureLoadingSpinner from "../../../loadingSpinner/pictureloadingSpinner";
import clsx from "clsx";

interface ImagePreviewProps {
  isLoading?: boolean;
  className?: string;
  imagesList: string[];
  onDeleteImage?: (filename: string) => void;
}

export default function ImagePreview({
  isLoading,
  className,
  imagesList,
  onDeleteImage,
}: ImagePreviewProps) {
  if (isLoading) {
    return (
      <div className={`${styles["loading-container"]}`}>
        <PictureLoadingSpinner />
      </div>
    );
  }
  if (!imagesList || imagesList.length === 0) {
    return null;
  }

  return (
    <div className={clsx(styles["preview-list-container"], className)}>
      <div className={styles["preview-list"]}>
        {imagesList.map((uri: string, index: number) => {
          return (
            <div className={styles["image-preview"]} key={index}>
              <div className={styles["image-preview-left"]}>
                <Image
                  className={styles["image-preview-miniature"]}
                  src={uri}
                  index={index}
                  pictureList={imagesList}
                  showTools={false}
                />
                <p className={styles["filename"]}>{uri}</p>
              </div>
              <div
                className={styles["close-button"]}
                onClick={() => handleDeleteImage(uri)}
              >
                <Close />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );

  function handleDeleteImage(filename: string) {
    onDeleteImage && onDeleteImage(filename);
  }
}
