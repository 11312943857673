import { Outlet, RouteObject } from "react-router-dom";
import ErrorPage from "../errorPage/error-page";

import Payments from ".";

export const route: RouteObject = {
  path: "/payments",
  element: <Outlet />,

  errorElement: <ErrorPage />,
  children: [
    {
      index: true,
      element: <Payments />,
    },
  ],
};
