import { IProduct } from "types/logistic.types";
import styles from "./productStateSection.module.scss";
import { PRODUCT_STATE_CHECK_IN } from "utils/logistic.init";
import translate from "utils/translation";
import clsx from "clsx";

interface ProductStateSectionProps {
  product: IProduct | null;
  onProductStateChange: (state: string) => void;
}

export default function ProductStateSection({
  product,
  onProductStateChange,
}: ProductStateSectionProps) {
  if (!product) {
    return null;
  }

  return (
    <>
      <ul className={styles["product-state-list"]}>
        {PRODUCT_STATE_CHECK_IN.map((state, index) => (
          <li
            className={clsx(
              styles["product-state"],
              state === product.status && styles["state-selected"]
            )}
            key={index}
            onClick={() => handleSelectProductState(state)}
          >
            {stateName(state)}
          </li>
        ))}
      </ul>
    </>
  );

  function stateName(state: string) {
    if (state === "PRODUCT_CONTROLLED") {
      return "Non faisable";
    }
    return translate(state);
  }

  function handleSelectProductState(state: string) {
    onProductStateChange(state);
  }
}
