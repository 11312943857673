import Widget from "../Widget";
import styles from "./sendEstimate.module.scss";
import EstimateIcon from "components/icons/EstimateIcon";

export default function SendEstimateWidget() {
  return (
    <Widget to={"/estimate/create"}>
      <div className={styles["icon-container"]}>
        <EstimateIcon className={styles["icon"]} />
      </div>
      <p>Envoyer une estimation</p>
    </Widget>
  );
}
