import {
  ICrossSellProduct,
  IDiscount,
  IOperation,
} from "types/accounting.types";
import {
  DropOffForm,
  DropOffProductForm,
  IContact,
  ShippingServiceForm,
} from "types/logistic.types";
import { IShippingPoint, TimeSlot } from "types/shipping.types";

export function areDropOffFormsEqual(
  form1?: DropOffForm | null,
  form2?: DropOffForm | null
): boolean {
  // First check if either form is null/undefined
  if (!form1 || !form2) {
    return form1 === form2;
  }

  // Compare trackId
  if (form1.trackId && form2.trackId) {
    if (form1.trackId !== form2.trackId) {
      return false;
    } else {
      return true;
    }
  }

  if (form1.trackId !== form2.trackId) {
    return false;
  }

  // Compare nonDropOff
  if (form1.nonDropOff !== form2.nonDropOff) {
    return false;
  }

  // Compare repareDays
  // if (form1.repareDays !== form2.repareDays) {
  //   return false;
  // }

  // Compare contact objects
  if (!areContactsEqual(form1.contact, form2.contact)) {
    return false;
  }

  // Compare shipping address
  if (!areShippingPointsEqual(form1.shippingAddress, form2.shippingAddress)) {
    return false;
  }

  // Compare time slot
  if (!areTimeSlotsEqual(form1.timeSlot, form2.timeSlot)) {
    return false;
  }

  // Compare shipping service
  if (!areShippingServicesEqual(form1.shippingService, form2.shippingService)) {
    return false;
  }

  // Compare discount
  if (!areDiscountsEqual(form1.discount, form2.discount)) {
    return false;
  }

  // Compare product lists
  if (!areProductListsEqual(form1.productList, form2.productList)) {
    return false;
  }

  // Compare cross sells
  if (!areCrossSellsEqual(form1.crossSells, form2.crossSells)) {
    return false;
  }

  // If all checks pass, the forms are equal
  return true;
}

/**
 * Compares two IContact objects for equality
 */
function areContactsEqual(
  contact1?: IContact | null,
  contact2?: IContact | null
): boolean {
  // Check if both are null/undefined
  if (!contact1 || !contact2) {
    return contact1 === contact2;
  }

  // Compare all fields
  return (
    contact1.displayname === contact2.displayname &&
    contact1.familyname === contact2.familyname &&
    contact1.givenname === contact2.givenname &&
    contact1.phone === contact2.phone &&
    contact1.email === contact2.email &&
    contact1.organization === contact2.organization &&
    contact1.address1 === contact2.address1 &&
    contact1.address2 === contact2.address2 &&
    contact1.city === contact2.city &&
    contact1.zipCode === contact2.zipCode &&
    contact1.country === contact2.country &&
    contact1.countryCode === contact2.countryCode &&
    contact1.id === contact2.id
  );
}

/**
 * Compares two IShippingPoint objects for equality
 */
function areShippingPointsEqual(
  point1?: IShippingPoint | null,
  point2?: IShippingPoint | null
): boolean {
  // Check if both are null/undefined
  if (!point1 || !point2) {
    return point1 === point2;
  }

  // Compare all fields
  return (
    point1.displayname === point2.displayname &&
    point1.familyname === point2.familyname &&
    point1.givenname === point2.givenname &&
    point1.phone === point2.phone &&
    point1.email === point2.email &&
    point1.organization === point2.organization &&
    point1.address1 === point2.address1 &&
    point1.address2 === point2.address2 &&
    point1.city === point2.city &&
    point1.zipCode === point2.zipCode &&
    point1.country === point2.country &&
    point1.countryCode === point2.countryCode &&
    point1.comments === point2.comments
  );
}

/**
 * Compares two TimeSlot objects for equality
 */
function areTimeSlotsEqual(
  slot1?: TimeSlot | null,
  slot2?: TimeSlot | null
): boolean {
  // Check if both are null/undefined
  if (!slot1 || !slot2) {
    return slot1 === slot2;
  }

  // Compare start and end times
  return slot1.start === slot2.start && slot1.end === slot2.end;
}

/**
 * Compares two ShippingServiceForm objects for equality
 */
function areShippingServicesEqual(
  service1?: ShippingServiceForm,
  service2?: ShippingServiceForm
): boolean {
  // Check if both are null/undefined
  if (!service1 || !service2) {
    return service1 === service2;
  }

  // Compare name and price
  if (service1.name !== service2.name || service1.price !== service2.price) {
    return false;
  }

  // Compare business hours length
  if (service1.businessHours.length !== service2.businessHours.length) {
    return false;
  }

  // Compare each business hour slot
  for (let i = 0; i < service1.businessHours.length; i++) {
    const hour1 = service1.businessHours[i];
    const hour2 = service2.businessHours[i];
    if (hour1.start !== hour2.start || hour1.end !== hour2.end) {
      return false;
    }
  }

  return true;
}

/**
 * Compares two IDiscount objects for equality
 */
function areDiscountsEqual(
  discount1?: IDiscount | null,
  discount2?: IDiscount | null
): boolean {
  // Check if both are null/undefined
  if (!discount1 || !discount2) {
    return discount1 === discount2;
  }

  // Compare all fields
  return (
    discount1.code === discount2.code &&
    discount1.value === discount2.value &&
    discount1.isRate === discount2.isRate &&
    discount1.expireAt?.getTime() === discount2.expireAt?.getTime() &&
    discount1.baseQuantity === discount2.baseQuantity &&
    discount1.quantity === discount2.quantity
  );
}

/**
 * Compares two arrays of DropOffProductForm objects for equality
 */
function areProductListsEqual(
  list1: DropOffProductForm[],
  list2: DropOffProductForm[]
): boolean {
  // Check lengths
  if (list1.length !== list2.length) {
    return false;
  }

  // Sort both lists by id to ensure consistent comparison
  const sortedList1 = [...list1].sort((a, b) =>
    (a.id || "").localeCompare(b.id || "")
  );
  const sortedList2 = [...list2].sort((a, b) =>
    (a.id || "").localeCompare(b.id || "")
  );

  // Compare each product
  for (let i = 0; i < sortedList1.length; i++) {
    if (!areProductsEqual(sortedList1[i], sortedList2[i])) {
      return false;
    }
  }

  return true;
}

/**
 * Compares two DropOffProductForm objects for equality
 */
function areProductsEqual(
  product1: DropOffProductForm,
  product2: DropOffProductForm
): boolean {
  // Compare basic fields
  if (
    // product1.id !== product2.id ||
    // product1.selected !== product2.selected ||
    product1.workshopId !== product2.workshopId ||
    product1.operationGroup !== product2.operationGroup ||
    product1.productGroup !== product2.productGroup ||
    product1.color !== product2.color ||
    product1.brand !== product2.brand ||
    product1.size !== product2.size ||
    product1.category !== product2.category
  ) {
    return false;
  }

  // Compare incoming pictures list
  if (
    product1.incomingPicturesList.length !==
    product2.incomingPicturesList.length
  ) {
    return false;
  }

  for (let i = 0; i < product1.incomingPicturesList.length; i++) {
    if (product1.incomingPicturesList[i] !== product2.incomingPicturesList[i]) {
      return false;
    }
  }

  // Compare operations list
  if (product1.operations.length !== product2.operations.length) {
    return false;
  }

  // Sort operations by slug for consistent comparison
  const sortedOps1 = [...product1.operations].sort((a, b) =>
    a.slug.localeCompare(b.slug)
  );
  const sortedOps2 = [...product2.operations].sort((a, b) =>
    a.slug.localeCompare(b.slug)
  );

  for (let i = 0; i < sortedOps1.length; i++) {
    if (!areOperationsEqual(sortedOps1[i], sortedOps2[i])) {
      return false;
    }
  }

  return true;
}

/**
 * Compares two IOperation objects for equality
 */
function areOperationsEqual(op1: IOperation, op2: IOperation): boolean {
  return (
    op1.productGroup === op2.productGroup &&
    op1.operationGroup === op2.operationGroup &&
    op1.operationType === op2.operationType &&
    op1.slug === op2.slug &&
    op1.description === op2.description &&
    op1.comments === op2.comments &&
    op1.price === op2.price &&
    op1.priceNoTax === op2.priceNoTax &&
    op1.originalPrice === op2.originalPrice &&
    op1.canceled === op2.canceled &&
    op1.refashionProductCategory === op2.refashionProductCategory &&
    op1.refashionOperation === op2.refashionOperation &&
    op1.refashionDiscount === op2.refashionDiscount &&
    op1.professionalDiscount === op2.professionalDiscount &&
    op1.publicDisplay === op2.publicDisplay
  );
}

/**
 * Compares two arrays of ICrossSellProduct objects for equality
 */
function areCrossSellsEqual(
  list1: ICrossSellProduct[],
  list2: ICrossSellProduct[]
): boolean {
  // Check lengths
  if (list1.length !== list2.length) {
    return false;
  }

  // Sort both lists by slug to ensure consistent comparison
  const sortedList1 = [...list1].sort((a, b) =>
    (a.slug || "").localeCompare(b.slug || "")
  );
  const sortedList2 = [...list2].sort((a, b) =>
    (a.slug || "").localeCompare(b.slug || "")
  );

  // Compare each cross sell product
  for (let i = 0; i < sortedList1.length; i++) {
    if (!areCrossSellProductsEqual(sortedList1[i], sortedList2[i])) {
      return false;
    }
  }

  return true;
}

/**
 * Compares two ICrossSellProduct objects for equality
 */
function areCrossSellProductsEqual(
  prod1: ICrossSellProduct,
  prod2: ICrossSellProduct
): boolean {
  // Check if basic fields match
  if (
    prod1.slug !== prod2.slug ||
    prod1.description !== prod2.description ||
    prod1.category !== prod2.category ||
    prod1.price !== prod2.price ||
    prod1.priceNoTax !== prod2.priceNoTax ||
    prod1.quantity !== prod2.quantity ||
    prod1.cartQuantity !== prod2.cartQuantity ||
    prod1.image !== prod2.image ||
    prod1.color !== prod2.color
  ) {
    return false;
  }

  // Compare productGroup arrays
  if (!areArraysEqual(prod1.productGroup, prod2.productGroup)) {
    return false;
  }

  return true;
}

/**
 * Helper function to compare arrays of primitive values
 */
function areArraysEqual<T>(arr1?: T[] | null, arr2?: T[] | null): boolean {
  // If both are undefined/null, they're equal
  if (!arr1 && !arr2) return true;
  // If only one is undefined/null, they're not equal
  if (!arr1 || !arr2) return false;
  // If lengths differ, they're not equal
  if (arr1.length !== arr2.length) return false;

  // Sort both arrays and compare elements
  const sorted1 = [...arr1].sort();
  const sorted2 = [...arr2].sort();

  for (let i = 0; i < sorted1.length; i++) {
    if (sorted1[i] !== sorted2[i]) return false;
  }

  return true;
}
