import { Launch } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import DataError from "components/errors/DataError/DataError";
import PictureLoadingSpinner from "components/loadingSpinner/pictureloadingSpinner";
import EstimateSection from "pages/overview/read/_components/EstimateSection/EstimateSection";
import QuoteProduct from "pages/overview/read/_components/QuoteSection/QuoteProduct/QuoteProduct";
import QuoteSumUp from "pages/overview/read/_components/QuoteSection/QuoteSumUp/QuoteSumUp";
import { Link } from "react-router-dom";
import { fetchQuote } from "requests/quote";
import { ITracker } from "types/tracker.types";
import translate from "utils/translation";
import styles from "./trackerSummaryModal.module.scss";

interface TrackerSummaryModalProps {
  tracker: ITracker;
}

export default function TrackerSummaryModal({
  tracker,
}: TrackerSummaryModalProps) {
  const { data, isLoading } = useQuery({
    queryKey: ["quote", tracker?.quote?.documentId],
    queryFn: () => fetchQuote(tracker?.quote?.documentId),
    refetchOnWindowFocus: false,
    enabled: Boolean(tracker?.quote?.documentId),
  });

  if (!tracker?.quote?.documentId) {
    return <></>;
  }

  if (isLoading) {
    return (
      <div className={styles["quote-summary-modal"]}>
        <PictureLoadingSpinner />
      </div>
    );
  }

  if (!data) {
    <div className={styles["quote-summary-modal"]}>
      <DataError error={"quote"} />
    </div>;
  }

  return (
    <div className={styles["quote-summary-modal"]}>
      <div className={styles["quote-summary-modal-container"]}>
        <ul className={styles["product-list"]}>
          {data?.productsList?.map((product, i) => (
            <QuoteProduct product={product} key={i} />
          ))}
        </ul>
        <QuoteSumUp
          className={styles["sub-total"]}
          title={"Sous-total"}
          value={data?.operationsTotal || 0}
        />
        <QuoteSumUp
          className={styles["sub-total"]}
          title={`Livraison (${translate(data?.shipping?.shippingService)})`}
          value={data?.shipping?.price || 0}
        />
        {!!data?.discountTotal && (
          <QuoteSumUp
            title={"Code promo"}
            value={-data?.discountTotal}
            className={styles["sub-total"]}
          />
        )}
        <QuoteSumUp
          title={"Total"}
          value={data?.total || 0}
          className={styles["total"]}
        />
      </div>
      <EstimateSection estimateId={tracker?.estimate?.documentId} />
      <div className={styles["overview-link-container"]}>
        <Link
          className={styles["overview-link"]}
          to={`/overview/${tracker.id}`}
        >
          Vue d'ensemble
          <Launch className={styles["icon"]} />
        </Link>
      </div>
    </div>
  );
}
