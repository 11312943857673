import clsx from "clsx";
import styles from "./shippingService.module.scss";
import ShippingServiceLogo from "components/ShippingServiceLogo/ShippingServiceLogo";
import { ShippingServiceForm } from "types/logistic.types";

interface ShippingServiceProps {
  shippingService: ShippingServiceForm & { selected: boolean };
  onShippingSelect: () => void;
}

export default function ShippingService({
  shippingService,
  onShippingSelect,
}: ShippingServiceProps) {
  return (
    <div
      className={clsx(
        shippingService.selected && styles["is-selected"],
        styles["shipping-service"]
      )}
      onClick={onShippingSelect}
    >
      <ShippingServiceLogo
        shippingService={shippingService.name}
        className={styles["shipping-service-logo"]}
      />
    </div>
  );
}
