import RearLabel from "./RearLabel";
import { splitStringsToFitCanvas } from "./useDetailedLabel";
import { NiimbotBluetoothClient } from "@mmote/niimbluelib";
import styles from "./RearLabelList.module.scss";
import clsx from "clsx";

interface RearLabelListProps {
  operations: string[];
  workshopId: string;
  dueDate: Date | null;
  isPreview?: boolean;
}

export default function RearLabelList({
  operations,
  workshopId,
  dueDate,
  isPreview = false,
}: RearLabelListProps) {
  const chunks = splitStringsToFitCanvas(operations, {
    width: 8 * 50,
    height: 8 * 30,
  });

  return (
    <div className={styles["rear-label-list"]}>
      {chunks.map((strings, index) => (
        <RearLabel
          dueDate={dueDate}
          key={index}
          strings={strings}
          workshopId={workshopId}
          isPreview={isPreview}
        />
      ))}
    </div>
  );
}
