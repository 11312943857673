import { AddCircleOutline } from "@mui/icons-material";
import style from "./addProductSection.module.scss";
import ProductMiniature from "../../../../components/miniature/ProductMiniature/ProductMiniature";
import { ProductForm } from "../../../../types/logistic.types";
import { DEFAULT_PRODUCT_FORM } from "utils/logistic.init";

interface AddProductSectionProps {
  productsList: ProductForm[];
  onProductsListChange: (productsList: ProductForm[]) => void;
}

export default function AddProductSection({
  productsList,
  onProductsListChange,
}: AddProductSectionProps) {
  return (
    <>
      <div>{`Articles ${
        productsList.length > 0 ? `(${productsList.length})` : ""
      }`}</div>
      <div className={style["products-list-container"]}>
        <div className={style["products-list"]}>
          <div
            className={style["add-new-product-miniature"]}
            onClick={handleAddNewProduct}
          >
            <AddCircleOutline className={style["icon"]} />
          </div>
          {productsList.map((product, index: number) => {
            return (
              <ProductMiniature
                key={index}
                handleDelete={() => handleDeleteProduct(index)}
                onProductChange={(product) =>
                  handleProductChange(product, index)
                }
                product={product}
              />
            );
          })}
        </div>
      </div>
    </>
  );

  function handleProductChange(targetProduct: ProductForm, index: number) {
    onProductsListChange(
      productsList.map((p, i) => (i == index ? targetProduct : p))
    );
  }

  function handleAddNewProduct() {
    onProductsListChange([...productsList, { ...DEFAULT_PRODUCT_FORM }]);
  }

  function handleDeleteProduct(index: number) {
    onProductsListChange(productsList.filter((_, i) => i !== index));
  }
}
