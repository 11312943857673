import leatherIcon from "assets/icons/leather.png";
import Icon from "components/Icon/Icon";

interface LeatherIconProps {
  className?: string;
}

export default function LeatherIcon({ className }: LeatherIconProps) {
  return <Icon logo={leatherIcon} className={className} />;
}
