import { useMemo, useState } from "react";
import useClickOutside from "../../../../../../hooks/useClickOutSide";
import { useKeyDown } from "../../../../../../hooks/useKeyDown";
import { IOperation } from "../../../../../../types/accounting.types";
import BeautifulDiv from "../../../../../beautifulDiv/BeautifulDiv";
import Input from "../../../../../inputs/Input/Input";
import style from "./autoCompleteOperationInput.module.scss";

interface AutoCompleteOperationInputProps {
  autoCompleteOperationsList?: IOperation[];
  operationForm: IOperation;
  setOperationForm: (operationForm: IOperation | null) => void;
  setPriceIsDisabled: (value: boolean) => void;
}

export default function AutoCompleteOperationInput({
  autoCompleteOperationsList,
  operationForm,
  setOperationForm,
  setPriceIsDisabled,
}: AutoCompleteOperationInputProps) {
  const [openDropDown, setOpenDropDown] = useState<boolean>(false);
  const ref = useClickOutside<HTMLDivElement>(() => setOpenDropDown(false));
  const FILTERED_LIST = useMemo(() => {
    if (!autoCompleteOperationsList) {
      return [];
    }
    return autoCompleteOperationsList.filter((op) =>
      op.description
        .toLowerCase()
        .includes(operationForm.description.toLowerCase())
    );
  }, [operationForm.description, autoCompleteOperationsList]);

  useKeyDown("Escape", () => setOpenDropDown(false));

  return (
    <div className={style["auto-complete-operation-input"]} ref={ref}>
      <Input
        className={style["input-container"]}
        label="Opération"
        name="description"
        autoComplete="off"
        value={operationForm.description}
        onChange={handleOnChange}
        onClick={() => setOpenDropDown(!openDropDown)}
      />
      {openDropDown && (
        <div className={style["drop-down-container"]}>
          <ul className={style["drop-down"]}>
            {FILTERED_LIST.map((op, index) => {
              return (
                <li
                  className={style["drop-down-item"]}
                  key={index}
                  onClick={() => handleSelectOperation(op)}
                >
                  {op.description}
                </li>
              );
            })}
          </ul>
          <BeautifulDiv />
        </div>
      )}
    </div>
  );

  function handleSelectOperation(operation: IOperation) {
    setOperationForm(operation);
    setPriceIsDisabled(true);
    setOpenDropDown(false);
  }

  function handleOnChange({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    setOpenDropDown(true);
    setOperationForm({
      ...operationForm,
      description: target.value,
    });
  }
}
