import { Cancel, Check } from "@mui/icons-material";
import style from "./otherControl.module.scss";
import { useMemo, useState } from "react";
import Button from "../../../components/Button/Button";
import { IProduct } from "../../../types/logistic.types";
import { CheckListItem } from "../../../types";
import Checklist from "../../../components/Checklist/Checklist";
import { isValidJSONString } from "../../../utils/utils";

interface OtherControlProps {
  product: IProduct;
  state: boolean;
  validateControl: () => void;
  rejectControl: () => void;
}

export default function OtherControl({
  product,
  state,
  validateControl,
  rejectControl,
}: OtherControlProps) {
  const [otherCheckList, setOtherCheckList] = useState<CheckListItem[]>(
    parseComment()
  );
  const DISABLED = useMemo(
    () => otherCheckList.some((other) => !other.checked),
    [otherCheckList]
  );

  return (
    <div className={style["other-control"]}>
      <div className={style["title"]}>Articles confiés avec :</div>
      <Checklist checklist={otherCheckList} setChecklist={setOtherCheckList} />
      <div className={style["button-container"]}>
        <Button className={style["cancel"]} onClick={rejectControl}>
          <Cancel />
          Retour Atelier
        </Button>
        <Button
          disabled={DISABLED}
          onClick={validateControl}
          className={DISABLED ? style["disabled"] : style["validate"]}
        >
          <Check />
          Valider
        </Button>
      </div>
    </div>
  );

  function parseComment() {
    if (!isValidJSONString(product.comments)) {
      return [];
    }
    const comment = JSON.parse(product.comments);
    let otherCheckList: CheckListItem[] = [];
    for (let key of Object.keys(comment)) {
      otherCheckList.push({ description: comment[key], checked: state });
    }
    return otherCheckList;
  }
}
