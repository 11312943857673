import { ITracker } from "types/tracker.types";
import styles from "../overviewActionDispatch.module.scss";
import Button from "components/Button/Button";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";
import LogisticModal from "components/modals/logisticModal/logisticModal";

interface OpenLogisticButtonProps {
  title: string;
  tracker: ITracker;
}

export default function OpenLogisticButton({
  title,
  tracker,
}: OpenLogisticButtonProps) {
  const queryClient = useQueryClient();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  return (
    <>
      <Button
        className={styles["action-button"]}
        onClick={handleOpenLogisticModal}
      >
        {title}
      </Button>
      <ModalContainer isOpen={modalIsOpen} onCancel={handleCloseLogisticModal}>
        <LogisticModal
          id={tracker.logistic.documentId}
          requiredActions={false}
          dueDate={tracker?.outgoingShipping?.period?.start}
          onToolSelect={handleCloseLogisticModal}
        />
      </ModalContainer>
    </>
  );

  function handleOpenLogisticModal() {
    setModalIsOpen(true);
  }

  function handleCloseLogisticModal() {
    setModalIsOpen(false);
    queryClient.refetchQueries({
      queryKey: ["logistic-overview", tracker.logistic.documentId],
    });
  }
}
