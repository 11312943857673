import { IOperation, OperationForm } from "../types/accounting.types";

export async function fetchOperation({
  operationId,
  isLegacy,
}: {
  operationId?: string;
  isLegacy?: boolean;
}): Promise<IOperation> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/operation/${operationId}?${
        isLegacy ? `format=legacy` : ""
      }`,
      {
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchOperationsList(
  query?: string,
  legacy?: boolean
): Promise<{ operations: IOperation[]; totalCount: number }> {
  try {
    let queryUrl = query ? query : "";
    queryUrl = queryUrl + (legacy ? "&format=legacy" : "");

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/operation?` + queryUrl,
      {
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function exportOperations(query?: string) {
  try {
    let queryUrl = query ? query : "";
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/operation/export?` + queryUrl,
      {
        credentials: "include",
      }
    );
    if (response.status >= 400) {
      throw new Response(JSON.stringify(response), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return response;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function exportOperationsTemplate() {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/operation/export-template`,
      {
        credentials: "include",
      }
    );
    if (response.status >= 400) {
      throw new Response(JSON.stringify(response), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return response;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function postOperation(
  operation: OperationForm
): Promise<OperationForm> {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/operation`, {
      headers: new Headers({ "Content-Type": "application/json" }),
      credentials: "include",
      method: "POST",
      body: JSON.stringify(operation),
    });
    const data = await response.json();

    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function updateOperation(
  operation: OperationForm
): Promise<OperationForm> {
  try {
    if (!operation.slug) {
      throw new Response("Missing operation slug");
    }
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/operation/${operation.slug}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        credentials: "include",
        method: "PUT",
        body: JSON.stringify(operation),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function deleteOperation({
  slug,
  signal,
}: {
  slug: string;
  signal?: AbortSignal;
}) {
  try {
    if (!slug) {
      throw new Response("Missing operation slug");
    }
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/operation/${slug}`,
      {
        credentials: "include",
        signal: signal,
        method: "DELETE",
      }
    );
    if (response.status >= 400) {
      throw new Response("Deletion failes", {
        statusText: response.statusText,
        status: response.status,
      });
    }
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export interface UploadOperationsResponse {
  numberOfOperationInSheet: number;
  numberOfOperationUploaded: number;
  numberOfOperationUpdated: number;
  numberOfErrors: number;
}

export async function uploadOperations(
  file: File
): Promise<UploadOperationsResponse> {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/operation/upload`,
      {
        credentials: "include",
        method: "POST",
        body: formData,
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchProductGroups({
  request,
}: {
  request?: Request;
}): Promise<string[]> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/operation/product-groups`,
      {
        credentials: "include",
        signal: request?.signal,
      }
    );

    const data = await response.json();

    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.productGroups;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchOperationGroups({
  request,
}: {
  request?: Request;
}): Promise<string[]> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/operation/operation-groups`,
      {
        credentials: "include",
        signal: request?.signal,
      }
    );

    const data = await response.json();

    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.operationGroups;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchOperationTypes({
  request,
}: {
  request?: Request;
}): Promise<string[]> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/operation/operation-types`,
      {
        credentials: "include",
        signal: request?.signal,
      }
    );

    const data = await response.json();

    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.operationTypes;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function checkIfSlugExist(slug: string): Promise<boolean> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/operation/check-if-slug-exist/${slug}`,
      {
        credentials: "include",
      }
    );

    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.exist;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}
