import style from "./climbingItemCard.module.scss";
import { IOperation, IProduct } from "../../../../../types/proCart.types";
import WorkshopId from "../../../../../components/workshopId/workshopId";
import ConfirmModalComp from "../../../../../components/confirmationModal/ConfirmModalComp";
import Select from "../../../../../components/Select/Select";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";
import { useState } from "react";

interface ClimbingItemCardProps {
  operationsList: IOperation[];
  product: IProduct;
  updateCartOperation: (operation: IOperation) => void;
}

export default function ClimbingItemCard({
  operationsList,
  product,
  updateCartOperation,
}: ClimbingItemCardProps) {
  const [openModal, setOpenModal] = useState<IOperation | null>(null);

  return (
    <>
      <div className={style["climbing-card"]}>
        <div className={style["climbing-card-left"]}>
          <label>Type de gomme</label>
          <div className={style["rubber-type"]}>
            {product.operationsList[0].comments}
          </div>
        </div>
        <div className={style["climbing-card-right"]}>
          <WorkshopId workshopId={product.workshopId} size="xx-small" />
        </div>
        <div className={style["operation-selection"]}>
          <Select
            optionsList={operationsList.map((o) => o.description)}
            value={product.operationsList[0].description}
            setValue={handleOperationSelectChanged}
          />
        </div>
      </div>
      <ModalContainer
        width="fit"
        height="fit"
        isOpen={!!openModal}
        onCancel={handleCloseModal}
      >
        <ConfirmModalComp
          confirmButtonClassName={style["validate-button"]}
          title={`Changer l'opération en "${openModal?.description}" ?`}
          message={`${product.workshopId} - ${product.brand || "-"}`}
          confirmText="Valider"
          onConfirm={() =>
            updateCartOperation({
              ...openModal!,
              comments: product.operationsList[0].comments,
            })
          }
          onCancel={handleCloseModal}
        />
      </ModalContainer>
    </>
  );

  function handleCloseModal() {
    setOpenModal(null);
  }

  function handleOpenModal(operation: IOperation) {
    setOpenModal(operation);
  }

  function handleOperationSelectChanged(value: string) {
    let op = operationsList.find((op) => op.description === value);
    if (op) {
      handleOpenModal(op);
    }
  }
}
