import { useQuery } from "@tanstack/react-query";
import DataError from "components/errors/DataError/DataError";
import PictureLoadingSpinner from "components/loadingSpinner/pictureloadingSpinner";
import { useParams } from "react-router-dom";
import { fetchCart } from "../../../requests/professionalCarts";
import Create from "../create/create";

export default function Edit() {
  const { id } = useParams();
  const { data, isFetching, error, refetch } = useQuery({
    queryKey: ["cart"],
    queryFn: () => fetchCart(id),
    refetchOnWindowFocus: false,
  });

  if (isFetching) {
    return <PictureLoadingSpinner />;
  }

  if (!data) {
    return <DataError error="pro-cart" />;
  }

  return <Create data={data} />;
}
