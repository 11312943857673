import { useEffect, useMemo, useRef, useState } from "react";
import ContactForm from "../../../components/forms/ContactForm/ContactForm";
import InputContainer from "../../../components/forms/inputs/inputContainer/inputContainer";
import { fetchDiscountList } from "../../../requests/discount";
import { addQuote, updateQuote } from "../../../requests/quote";
import { IValidationError } from "../../../types";
import {
  ICrossSellQuote,
  IDiscount,
  IQuote,
} from "../../../types/accounting.types";
import { IContact, IProduct } from "../../../types/logistic.types";
import { INITIALE_QUOTE, QUOTE_STATES } from "../../../utils/accounting.init";
import { scrollToElement } from "../../../utils/utils";
import style from "./create.module.scss";

import { Close, Send } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import DataError from "components/errors/DataError/DataError";
import PictureLoadingSpinner from "components/loadingSpinner/pictureloadingSpinner";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";
import { toast } from "react-toastify";
import Button from "../../../components/Button/Button";
import CheckboxItem from "../../../components/checkboxItem/CheckboxItem";
import ConcurrentTasksTemplate from "../../../components/concurrentTasksTemplate/ConcurrentTasksTemplate";
import ConfirmModalComp from "../../../components/confirmationModal/ConfirmModalComp";
import DiscountValue from "../../../components/DiscountValue/DiscountValue";
import AddCrossSell from "../../../components/forms/crossSellForm/addCrossSell";
import ProductSection from "../../../components/forms/productForm/ProductSection";
import SectionFormTemplate from "../../../components/forms/sectionFormTemplate/SectionFormTemplate";
import Input from "../../../components/inputs/Input/Input";
import OverviewButton from "../../../components/OverviewButton/OverviewButton";
import Price from "../../../components/Price/Price";
import Select from "../../../components/Select/Select";
import { INITIAL_CONTACT } from "../../../utils/shipping.init";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../utils/toast.options";
import SelectState from "../../shipping/create/SelectState/SelectState";

interface CreateProps {
  defaultQuote?: IQuote;
  refetch?: () => void;
}

export default function Create({ defaultQuote, refetch }: CreateProps) {
  const { data, isLoading: discountIsLoading } = useQuery({
    queryKey: ["discount-list"],
    queryFn: () => fetchDiscountList(),
    refetchOnWindowFocus: false,
  });
  const [formValues, setFormValues] = useState<IQuote>(
    defaultQuote
      ? { ...INITIALE_QUOTE, ...defaultQuote }
      : { ...INITIALE_QUOTE }
  );
  const [scrollTo, setScrollTo] = useState<boolean>(true);
  const [validationError, setValidationError] = useState<IValidationError[]>(
    []
  );

  const [updateLogistic, setUpdateLogistic] = useState<boolean>(true);
  const [sendEmail, setSendEmail] = useState<boolean>(true);
  const ref = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const CAN_EDIT = useMemo(() => {
    if (!defaultQuote) {
      return true;
    }
    return (
      defaultQuote.state !== "QUOTE_SENT" &&
      defaultQuote.state !== "QUOTE_VALIDATED"
    );
  }, [defaultQuote]);

  useEffect(() => {
    if (defaultQuote) {
      setFormValues({ ...INITIALE_QUOTE, ...defaultQuote });
    }
  }, [defaultQuote]);

  useEffect(() => {
    handleScroll();
  }, [scrollTo]);

  useEffect(() => {
    calculateTotalPrice();
  }, [
    formValues.operationsTotal,
    formValues.salesTotal,
    formValues.discountTotal,
    formValues.shipping?.price,
  ]);

  useEffect(() => {
    if (!formValues.crossSellItems) {
      return;
    }
    if (formValues.crossSellItems.length === 0) {
      setFormValues((prev) => ({ ...prev, crossSellItems: undefined }));
    }
  }, [formValues.crossSellItems]);

  if (discountIsLoading) {
    return <PictureLoadingSpinner />;
  }

  if (!data) {
    return <DataError error="quote" />;
  }

  const { discounts } = data;

  return (
    <>
      <div className={style["quote"]}>
        <div ref={ref} className={style["title"]}>
          <span className={style["title-container"]}>
            {defaultQuote ? "Modifier devis" : "Nouveau devis"}
            <OverviewButton trackId={formValues.trackId} />
          </span>
          <div className={style["top-right"]}>
            <SelectState
              label="Status du devis"
              state={formValues.state ?? "QUOTE_PENDING"}
              stateOption={QUOTE_STATES}
              setState={(state) => {
                setFormValues({ ...formValues, state });
              }}
            />
            {formValues?.contact?.email && formValues.trackId && (
              <Button
                isLoading={isLoading}
                onClick={handleSendQuoteValidationPendingEmail}
              >
                Envoyer le devis <Send className={style["send-icon"]} />
              </Button>
            )}
          </div>
        </div>
        <div className={style["form"]}>
          <SectionFormTemplate title="Contact">
            <ContactForm
              contact={formValues.contact || INITIAL_CONTACT}
              setContact={handleContactChange}
              className={style["contact-form"]}
            />
          </SectionFormTemplate>
          <SectionFormTemplate title="Délai (jours)">
            <div className={style["input-line"]}>
              <Input
                label="Délai minimum"
                name={"minimumDurationDays"}
                type={"text"}
                inputMode="numeric"
                min={1}
                value={formValues?.minimumDurationDays}
                onChange={handleChange}
              />
              <Input
                label="Délai maximum"
                name={"maximumDurationDays"}
                type={"text"}
                inputMode="numeric"
                min={0}
                value={formValues?.maximumDurationDays}
                onChange={handleChange}
              />
            </div>
          </SectionFormTemplate>
          <SectionFormTemplate title="Prix de la livraison (€)">
            <Input
              name={"shipping.price"}
              type={"text"}
              inputMode="numeric"
              min={1}
              value={formValues?.shipping?.price}
              onChange={handleShippingPriceChange}
            />
          </SectionFormTemplate>
          <SectionFormTemplate title="Code promo">
            <Select
              className={style["discount-select"]}
              placeholder="Sélectionnez un code promo..."
              value={formValues?.discount?.code || ""}
              setValue={handleDiscountChange}
              optionsList={discounts.map((value: IDiscount) => value.code)}
            />
            {formValues.discount && formValues.discount.code !== "AUCUN" && (
              <div
                className={style["remove-discount"]}
                onClick={() => handleDiscountChange("AUCUN")}
              >
                <Close />
              </div>
            )}
          </SectionFormTemplate>
          {defaultQuote && (
            <SectionFormTemplate title="Dates d'envoi/réponse">
              <div className={style["input-line"]}>
                <InputContainer className={style["input-container"]}>
                  <label>Date d'envoi</label>
                  <input
                    name={"sentAt"}
                    type={"date"}
                    value={
                      formValues.sentAt
                        ? new Date(formValues.sentAt)
                            .toISOString()
                            .substring(0, 10)
                        : ""
                    }
                    onChange={handleDateChange}
                  />
                </InputContainer>
                <InputContainer className={style["input-container"]}>
                  <label>Date de réponse</label>
                  <input
                    name={"repliedAt"}
                    type={"date"}
                    value={
                      formValues.repliedAt
                        ? new Date(formValues.repliedAt)
                            .toISOString()
                            .substring(0, 10)
                        : ""
                    }
                    onChange={handleDateChange}
                  />
                </InputContainer>
              </div>
            </SectionFormTemplate>
          )}
          <SectionFormTemplate title="Commentaire">
            <textarea
              className={style["comment"]}
              value={formValues.comments}
              onChange={({ target }) =>
                setFormValues((prev) => ({ ...prev, comments: target.value }))
              }
            />
          </SectionFormTemplate>
          <hr className={style["separator"]} />
          <ProductSection
            productsList={formValues.productsList || []}
            setProductsList={handleProductChange}
            canEdit={CAN_EDIT}
          />
          <SectionFormTemplate title="Articles Cross-selling">
            <AddCrossSell
              handleCrossSellChange={handleCrossSellChange}
              crossSellItems={formValues.crossSellItems}
              handleDeleteCrossSellItem={handleDeleteCrossSellItem}
            />
          </SectionFormTemplate>
          <hr className={style["separator"]} />
          <div className={style["input-line"]}>
            <InputContainer className={style["input-container"]}>
              <label>Total code promo</label>
              <DiscountValue
                className={style["price-value"]}
                discount={formValues.discount}
              />
            </InputContainer>
            <InputContainer className={style["input-container"]}>
              <label>Total opérations</label>
              <Price
                className={style["price-value"]}
                price={formValues.operationsTotal}
              />
            </InputContainer>
            <InputContainer className={style["input-container"]}>
              <label>Total Cross-selling</label>
              <Price
                className={style["price-value"]}
                price={formValues.salesTotal}
              />
            </InputContainer>
            <InputContainer className={style["input-container"]}>
              <label>Total</label>
              <Price
                className={style["price-value"]}
                price={formValues.total}
              />
            </InputContainer>
          </div>
          <div>
            {validationError.find(
              (value: IValidationError) => value.field === "productsList"
            ) && (
              <div className={style["error-message"]}>
                * veuillez ajouter au moins un article.
              </div>
            )}
          </div>
          <ConcurrentTasksTemplate>
            <CheckboxItem
              checked={updateLogistic}
              onChecked={setUpdateLogistic}
              title="Mettre à jour le document logistique associé"
            />
            <CheckboxItem
              checked={sendEmail}
              onChecked={setSendEmail}
              title="Envoyer le devis"
            />
          </ConcurrentTasksTemplate>
          <div
            className={style["submit-button-container"]}
            onClick={handleOpenModal}
          >
            <Button type={"submit"}>
              {defaultQuote ? "Mettre à jour" : "Créer"}
            </Button>
          </div>
        </div>
      </div>
      <ModalContainer
        width="fit"
        height="fit"
        isOpen={openModal}
        onCancel={handleCloseModal}
      >
        <ConfirmModalComp
          confirmButtonClassName={style["validate-button"]}
          title={`Voulez-vous ${
            defaultQuote ? "mettre à jour" : "créer"
          } ce devis ?`}
          element={
            <>
              {(sendEmail || updateLogistic) && (
                <ul style={{ fontSize: "14px" }}>
                  Ceci entrainera les actions suivantes:
                  {sendEmail && <li>Envoi du devis par email</li>}
                  {updateLogistic && (
                    <li>Mise à jour du document logistique</li>
                  )}
                </ul>
              )}
            </>
          }
          confirmText="Oui"
          cancelText="Non"
          onConfirm={handleSubmitForm}
          onCancel={handleCloseModal}
        />
      </ModalContainer>
    </>
  );

  function handleCloseModal() {
    setOpenModal(false);
  }

  function handleOpenModal() {
    setOpenModal(true);
  }

  async function handleSendQuoteValidationPendingEmail() {
    if (!formValues?.contact?.email) {
      return;
    }
    try {
      setIsLoading(true);
      await updateQuote({ ...formValues, state: "QUOTE_SENT" }, false, true);
      setFormValues((prev) => ({ ...prev, state: "QUOTE_SENT" }));
      toast.success(`Le devis a été envoyé avec succès`, TOAST_SUCCESS_OPTIONS);
    } catch (error) {
      toast.error(
        "Un problème est survenu lors de l'envoi du devis.",
        TOAST_ERROR_OPTIONS
      );
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSubmitForm() {
    try {
      const response =
        defaultQuote && defaultQuote.id
          ? await updateQuote(formValues, updateLogistic, sendEmail)
          : await addQuote(formValues, sendEmail);
      if ((response as IValidationError[]).length > 0) {
        setValidationError(response as IValidationError[]);
        setScrollTo(!scrollTo);
        return;
      }
      toast.success(
        `Le devis a été ${defaultQuote ? "mis à jour" : "crée"}.`,
        TOAST_SUCCESS_OPTIONS
      );
      refetch && refetch();
    } catch (error) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    } finally {
      handleCloseModal();
    }
  }

  function handleProductChange(productsList: IProduct[]) {
    const newFormValues = { ...formValues };
    newFormValues["productsList"] = productsList;
    newFormValues.operationsTotal = productsList.reduce(
      (acc, product: IProduct) => acc + (product.totalPrice ?? 0),
      0
    );
    setFormValues(newFormValues);
  }

  function handleContactChange(contact: IContact) {
    setFormValues((prev) => ({ ...prev, contact: contact }));
  }

  function handleCrossSellChange(crossSell: ICrossSellQuote[]) {
    const salesTotal =
      crossSell.reduce(
        (acc, cs) => acc + (cs.price || 0) * cs.cartQuantity,
        0
      ) / 100;
    setFormValues((prev) => ({
      ...prev,
      crossSellItems: crossSell,
      salesTotal,
    }));
  }

  function handleDeleteCrossSellItem(index: number) {
    if (!formValues.crossSellItems) {
      return;
    }
    const salesTotal =
      formValues.crossSellItems
        ?.filter((_, i) => i !== index)
        .reduce((acc, cs) => acc + (cs.price || 0) * cs.cartQuantity, 0) / 100;
    setFormValues((prev) => ({
      ...prev,
      crossSellItems: formValues.crossSellItems?.filter((_, i) => i !== index),
      salesTotal,
    }));
  }

  function handleChange({
    target,
  }: {
    target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
  }) {
    setFormValues((prev) => ({ ...prev, [target.name]: target.value }));
  }

  function handleShippingPriceChange({
    target,
  }: {
    target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
  }) {
    let price = parseInt(target.value);
    if (isNaN(price)) {
      price = 0;
    }
    setFormValues((prev) => {
      let shipping = prev.shipping || {
        freeShippingMinimumAmount: price * 100,
        price: price * 100,
        shippingService: "INTERNAL",
      };
      return {
        ...prev,
        shipping: {
          ...shipping,
          price: price * 100,
          freeShippingMinimumAmount: price * 100,
        },
      };
    });
  }

  function handleDateChange({
    target,
  }: {
    target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
  }) {
    setFormValues((prev) => ({
      ...prev,
      [target.name]: new Date(target.value),
    }));
  }

  function handleDiscountChange(value: string) {
    const found = discounts.find(
      (discount: IDiscount) => discount.code === value
    );
    setFormValues((prev) => ({
      ...prev,
      discount: found || null,
      discountTotal: calculateDiscountTotal({
        ...prev,
        discount: found || null,
      }),
    }));
  }

  function calculateDiscountTotal(quote: IQuote) {
    if (!quote.discount || !quote.discount.value) return 0;
    if (!quote.discount.isRate) {
      return quote.discount.value;
    }
    return ((quote.operationsTotal || 0) * quote.discount.value) / 100;
  }

  function calculateTotalPrice() {
    const newFormValues = { ...formValues };
    const total =
      (formValues.operationsTotal ?? 0) +
      (formValues.salesTotal ?? 0) +
      (formValues.shipping?.price ?? 0) -
      (formValues.discountTotal ?? 0);

    newFormValues.total = total >= 0 ? total : 0;

    setFormValues(newFormValues);
  }

  function handleScroll() {
    if (
      validationError.some((e) =>
        [
          "contact.email",
          "contact.phone",
          "contact.familyname",
          "contact.displayname",
        ].some((str) => e.field.includes(str))
      )
    ) {
      scrollToElement(ref.current, 1000);
    }
  }
}
