import { cloneElement } from "react";
import styles from "./optionItem.module.scss";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { IMoreToolsOptions } from "components/MoreTools/MoreTools";

interface OptionItemProps {
  option: IMoreToolsOptions;
  onClickOnOption: () => void;
}

export default function OptionItem({
  option,
  onClickOnOption,
}: OptionItemProps) {
  const styledIcon: JSX.Element = cloneElement(option.icon || <span></span>, {
    className: styles["option-list-item-icon"],
  });

  if (option.hidden) {
    return null;
  }

  if (option.to) {
    return (
      <Link
        className={clsx(styles["option-list-item"], {
          [styles["disabled"]]: option.disabled,
        })}
        onClick={onClickOnOption}
        to={option.to}
      >
        {styledIcon}
        {option.name}
      </Link>
    );
  }

  return (
    <div
      className={clsx(styles["option-list-item"], {
        [styles["disabled"]]: option.disabled,
      })}
      onClick={onClickOnOption}
    >
      {styledIcon}
      {option.name}
    </div>
  );
}
