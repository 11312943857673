import { DropOffProductForm } from "types/logistic.types";
import styles from "./dropOffProductsSummary.module.scss";
import OperationGroupLogo from "components/OperationGroupLogo/OperationGroupLogo";
import translate from "utils/translation";
import QuoteOperation from "pages/overview/read/_components/QuoteSection/QuoteOperation/QuoteOperation";
import { IOperation } from "types/accounting.types";

interface DropOffProductsSummaryProps {
  product: DropOffProductForm;
  onProductChange: (product: DropOffProductForm) => void;
}

export default function DropOffProductsSummary({
  product,
  onProductChange,
}: DropOffProductsSummaryProps) {
  return (
    <li>
      <div className={styles["product-description"]}>
        <OperationGroupLogo
          className={styles["operation-logo"]}
          operationGroup={product.operationGroup}
        />
        <p className={styles["brand"]}>
          {product.brand || translate(product.operationGroup!)}
        </p>
      </div>
      <ul className={styles["operation-list"]}>
        {product.operations.map((op, index) => (
          <QuoteOperation
            operation={op}
            key={index}
            onOperationChange={(operation) =>
              handleOperationChange(operation, index)
            }
          />
        ))}
      </ul>
    </li>
  );

  function handleOperationChange(operation: IOperation, index: number) {
    onProductChange({
      ...product,
      operations: product.operations.map((op, i) =>
        i === index ? operation : op
      ),
    });
  }
}
