import { toast } from "react-toastify";
import Select from "../../../../components/Select/Select";
import translate from "../../../../utils/translation";
import { IShippingMethod } from "../../../../types/accounting.types";
import style from "./selectShippingMethod.module.scss";
import { TOAST_ERROR_OPTIONS } from "../../../../utils/toast.options";

interface SelectShippingMethodProps {
  shippingLabel: string | null;
  resetAddress: () => void;
  shippingMethod: IShippingMethod | null;
  setShippingMethod: (value: IShippingMethod | null) => void;
  shippingMethodList: IShippingMethod[];
}

export default function SelectShippingMethod({
  shippingLabel,
  resetAddress,
  shippingMethod,
  setShippingMethod,
  shippingMethodList,
}: SelectShippingMethodProps) {
  return (
    <Select
      className={style["select-shipping-method"]}
      doTranslate={true}
      optionsList={shippingMethodList.map((sp) =>
        translate(sp.shippingService)
      )}
      placeholder="Méthodes de livraison..."
      setValue={handleSetShippingMethod}
      value={shippingMethod?.shippingService || ""}
    />
  );

  function handleSetShippingMethod(value: string) {
    if (shippingLabel) {
      return toast.warning(
        'Vous ne pouvez pas faire de modifications. Vous devez "Annuler" le bon chronopost actuel avant de pouvoir faire des modifications.',
        TOAST_ERROR_OPTIONS
      );
    }
    setShippingMethod(
      shippingMethodList.find(
        (sp) => value === translate(sp?.shippingService)
      ) || null
    );
    if (translate(shippingMethod?.shippingService) !== value) {
      resetAddress();
    }
  }
}
