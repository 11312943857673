import clsx from "clsx";
import styles from "./timeslot.module.scss";
import { TimeSlot } from "types/shipping.types";

interface TimeSlotProps {
  className?: string;
  timeSlot: TimeSlot | null | undefined;
}

export default function Timeslot({ className, timeSlot }: TimeSlotProps) {
  if (!timeSlot) {
    return <p className={className}>-</p>;
  }

  return (
    <p className={clsx(styles["timeslot"], className)}>
      {`${timeSlot.start} - ${timeSlot.end}`}
    </p>
  );
}
