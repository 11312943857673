import { IOperation } from "types/accounting.types";
import styles from "./operationListSection.module.scss";
import clsx from "clsx";

interface OperationsListSectionProps {
  operationsList?: IOperation[];
}

export default function OperationsListSection({
  operationsList,
}: OperationsListSectionProps) {
  if (!operationsList) {
    return null;
  }

  return (
    <ul className={styles["operations-list"]}>
      {operationsList.map((op, index) => (
        <li className={clsx(op.canceled && styles["canceled"])} key={index}>
          {op.description}
        </li>
      ))}
    </ul>
  );
}
