import style from "./addCrossSell.module.scss";
import { ICrossSellQuote } from "../../../types/accounting.types";
import CrossSellItem from "./CrossSellItem/CrossSellItem";
import AddCrossSellModal from "../../modals/addCrossSellModal/addCrossSellModal";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";
import { useState } from "react";

interface AddCrossSellProps {
  handleCrossSellChange: (cs: ICrossSellQuote[]) => void;
  crossSellItems: ICrossSellQuote[] | undefined;
  handleDeleteCrossSellItem: (index: number) => void;
}

export default function AddCrossSell({
  handleCrossSellChange,
  crossSellItems,
  handleDeleteCrossSellItem,
}: AddCrossSellProps) {
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <>
      <div className={style["cross-sell-form-container"]}>
        {crossSellItems && (
          <div className={style["cross-selling-products-container"]}>
            <ul className={style["cross-selling-products-list"]}>
              {crossSellItems.map((cs: ICrossSellQuote, index: number) => {
                return (
                  <CrossSellItem
                    crossSell={cs}
                    handleDelete={() => handleDeleteCrossSellItem(index)}
                    key={index}
                  />
                );
              })}
            </ul>
          </div>
        )}
        <div className={style["add-crosssell"]} onClick={handleOpenModal}>
          ajouter des articles Cross-selling
        </div>
      </div>
      <ModalContainer isOpen={openModal} onCancel={handleCloseModal}>
        <AddCrossSellModal
          handleCrossSellChange={handleCrossSellItemChange}
          crossSellItems={crossSellItems}
        />
      </ModalContainer>
    </>
  );

  function handleOpenModal() {
    setOpenModal(true);
  }

  function handleCloseModal() {
    setOpenModal(false);
  }

  function handleCrossSellItemChange(crossSellItems: ICrossSellQuote[]) {
    handleCrossSellChange(crossSellItems);
    handleCloseModal();
  }
}
