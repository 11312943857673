import WorkshopId from "components/workshopId/workshopId";
import styles from "./productCheckInList.module.scss";
import OperationGroupLogo from "components/OperationGroupLogo/OperationGroupLogo";
import { IProduct } from "types/logistic.types";
import clsx from "clsx";
import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material";
import { productIsValid } from "../index.function";

interface ProductCheckInListProps {
  onProductSelect: (id: string | null) => void;
  productsList: IProduct[];
  selectedProductId: string | null;
}

export default function ProductCheckInList({
  onProductSelect,
  productsList,
  selectedProductId,
}: ProductCheckInListProps) {
  return (
    <>
      <ul className={styles["product-list"]}>
        {productsList.map((p, index) => (
          <li
            className={clsx(
              styles["product-item"],
              p.id === selectedProductId && styles["product-selected"]
            )}
            onClick={() => onProductSelect(p.id || null)}
            key={index}
          >
            <WorkshopId workshopId={p.workshopId} size="xxx-small" />
            <OperationGroupLogo
              className={styles["product-group-icon"]}
              operationGroup={p.operationGroup}
            />
            <div className={styles["checkin-state"]}>
              {productIsValid(p) ? (
                <CheckCircleOutline className={styles["checked-icon"]} />
              ) : (
                <ErrorOutline className={styles["missing-icon"]} />
              )}
            </div>
          </li>
        ))}
      </ul>
    </>
  );
}
