import { useQuery } from "@tanstack/react-query";
import DataError from "components/errors/DataError/DataError";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";
import { fetchQuote } from "../../../requests/quote";
import Create from "../create/create";

export default function Edit() {
  const { id } = useParams();
  const { data, isFetching, refetch } = useQuery({
    queryKey: ["quote", id],
    queryFn: () => fetchQuote(id),
    refetchOnWindowFocus: false,
  });

  if (isFetching) {
    return <LoadingSpinner color="gold" />;
  }

  if (!data) {
    return <DataError error="quote" />;
  }

  return <Create defaultQuote={data} refetch={refetch} />;
}
