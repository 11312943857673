import Button from "../../../../../../../components/Button/Button";
import { ITracker } from "../../../../../../../types/tracker.types";
import {
  ESTIMATE_PENDING,
  ESTIMATE_REACHED,
  ESTIMATE_SENT,
  CART_PENDING,
  INCOMING_PENDING,
  INCOMING_TRANSITING,
  INCOMING_DELIVERED,
  COLLECTED,
  QUOTE_SENT,
  PAYED,
  REFURBISHED,
  CONTROLLED,
  DELIVERED,
  CANCELED,
  NON_FEASIBLE,
  trackerStateMap,
} from "../../../../../../../utils/tracking.utils";
import styles from "./overviewActionDispatch.module.scss";
import { useNavigate } from "react-router-dom";
import { OverviewTabs } from "../../../../../../../types/overview.types";
import PutInRepairButton from "./ActionButtons/PutInRepairButton";
import ForcePaymentButton from "./ActionButtons/ForcePaymentButton";
import QuoteActionsButton from "./ActionButtons/QuoteActionsButton";
import OpenLogisticButton from "./ActionButtons/OpenLogisticButton";

interface OverviewActionDispatchProps {
  tracker: ITracker;
  onChangeTab: (tab: OverviewTabs) => void;
}

export default function OverviewActionDispatch({
  tracker,
  onChangeTab,
}: OverviewActionDispatchProps) {
  const trackerStep = trackerStateMap.get(tracker.state);
  const navigate = useNavigate();

  switch (trackerStep) {
    case ESTIMATE_PENDING:
      return (
        <>
          <Button className={styles["action-button"]} onClick={estimateAction}>
            Répondre à la demande
          </Button>
          <Button className={styles["action-button"]} onClick={checkInOrder}>
            Réceptionner la demande
          </Button>
        </>
      );
    case ESTIMATE_REACHED:
      return (
        <>
          <Button className={styles["action-button"]} onClick={estimateAction}>
            Répondre à la demande
          </Button>
          <Button className={styles["action-button"]} onClick={checkInOrder}>
            Réceptionner la demande
          </Button>
        </>
      );
    case ESTIMATE_SENT:
      return (
        <>
          <Button className={styles["action-button"]} onClick={estimateAction}>
            Modifier à la demande
          </Button>
          <Button className={styles["action-button"]} onClick={estimateAction}>
            Envoyer la demande
          </Button>
          <Button className={styles["action-button"]} onClick={checkInOrder}>
            Réceptionner la demande
          </Button>
        </>
      );
    case CART_PENDING:
      return <></>;
    case INCOMING_PENDING:
      return (
        <>
          <Button className={styles["action-button"]} onClick={checkInOrder}>
            Réceptionner la commande
          </Button>
          <Button className={styles["action-button"]} onClick={shippingAction}>
            Reprogrammer la collecte
          </Button>
          <Button className={styles["action-button"]}>*Envoyer RDV</Button>
        </>
      );
    case INCOMING_TRANSITING:
      return (
        <Button className={styles["action-button"]} onClick={checkInOrder}>
          Réceptionner la commande
        </Button>
      );
    case INCOMING_DELIVERED:
      return (
        <>
          <Button className={styles["action-button"]} onClick={checkInOrder}>
            Finaliser la récéption
          </Button>
          <Button className={styles["action-button"]}>
            *Envoyer un email personnalisé
          </Button>
        </>
      );
    case COLLECTED:
      return <></>;
    case QUOTE_SENT:
      return (
        <>
          <ForcePaymentButton tracker={tracker} />
          <Button className={styles["action-button"]} onClick={quoteAction}>
            Envoyer devis
          </Button>
          <Button className={styles["action-button"]} onClick={quoteAction}>
            Modifier devis
          </Button>
        </>
      );
    case PAYED:
      return (
        <>
          <QuoteActionsButton tracker={tracker} />
          <PutInRepairButton tracker={tracker} />
        </>
      );
    case REFURBISHED:
      return (
        <>
          <OpenLogisticButton
            title="Contrôler les articles"
            tracker={tracker}
          />
          <OpenLogisticButton title="Déplacer les articles" tracker={tracker} />
          <Button className={styles["action-button"]} onClick={shippingAction}>
            Reprogrammer la livraison
          </Button>
          <Button className={styles["action-button"]}>
            *Retard réparation
          </Button>
          <OpenLogisticButton
            title="Imprimer tickets articles"
            tracker={tracker}
          />
          <Button className={styles["action-button"]}>
            *Approvisionnement matière
          </Button>
        </>
      );
    case CONTROLLED:
      return (
        <>
          <Button className={styles["action-button"]} onClick={shippingAction}>
            Reprogrammer la livraison
          </Button>
          <Button className={styles["action-button"]}>*Commande livré</Button>
        </>
      );
    case DELIVERED:
      return (
        <Button className={styles["action-button"]} disabled>
          *Créer SAV
        </Button>
      );
    case CANCELED:
      return (
        <Button className={styles["action-button"]} onClick={shippingAction}>
          Plannifier la livraison
        </Button>
      );
    case NON_FEASIBLE:
      return (
        <Button className={styles["action-button"]} onClick={estimateAction}>
          Modifier la demande
        </Button>
      );
    default:
      return <></>;
  }

  function estimateAction() {
    onChangeTab("estimation");
  }

  function shippingAction() {
    onChangeTab("shipping");
  }

  function quoteAction() {
    onChangeTab("quote");
  }

  function checkInOrder() {
    if (
      tracker.incomingShipping &&
      tracker.incomingShipping.shippingService !== "DROP-OR-COLLECT"
    ) {
      navigate(
        `/quick-access/incoming-shipping/${tracker.id}?type=non-drop-off`
      );
    } else {
      navigate(`/quick-access/incoming-shipping/${tracker.id}`);
    }
  }
}
