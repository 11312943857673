import Icon from "components/Icon/Icon";
import checkInIcon from "assets/icons/unboxing.png";

interface CheckInIconProps {
  className?: string;
}

export default function CheckInIcon({ className }: CheckInIconProps) {
  return <Icon logo={checkInIcon} className={className} />;
}
