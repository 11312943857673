import Button from "components/Button/Button";
import styles from "./confirmationModalDispatch.module.scss";
import { IMoreToolsOptions } from "components/MoreTools/MoreTools";

interface ConfirmationModalDispatchProps {
  option: IMoreToolsOptions | null;
  onCancel: () => void;
  onConfirm: () => void;
}

export default function ConfirmationModalDispatch({
  option,
  onCancel,
  onConfirm,
}: ConfirmationModalDispatchProps) {
  if (!option) {
    return null;
  }
  switch (option.name) {
    case "Supprimer":
      return (
        <DeleteConfirmationModal
          onCancel={onCancel}
          onConfirm={handleOnConfirm}
        />
      );
    case "Envoyer devis":
      return (
        <SendQuoteConfirmationModal
          onCancel={onCancel}
          onConfirm={handleOnConfirm}
        />
      );
    default:
      return (
        <DefaultConfirmationModal
          onCancel={onCancel}
          onConfirm={handleOnConfirm}
        />
      );
  }

  function handleOnConfirm() {
    option?.onClick && option?.onClick();
    onConfirm();
  }
}

interface DefaultConfirmationModalProps {
  onCancel: () => void;
  onConfirm: () => void;
}

function DefaultConfirmationModal({
  onCancel,
  onConfirm,
}: DefaultConfirmationModalProps) {
  return (
    <div className={styles["modal"]}>
      <h3>Êtes-vous sûr de vouloir effectuer cette action ?</h3>
      <div className={styles["button-container"]}>
        <Button className={styles["confirm-button"]} onClick={onConfirm}>
          Confirmer
        </Button>
        <Button onClick={onCancel}>Annuler</Button>
      </div>
    </div>
  );
}

interface DeleteConfirmationModalProps {
  onCancel: () => void;
  onConfirm: () => void;
}

function DeleteConfirmationModal({
  onCancel,
  onConfirm,
}: DeleteConfirmationModalProps) {
  return (
    <div className={styles["modal"]}>
      <h3>Voulez-vous supprimer ce document ?</h3>
      <div className={styles["button-container"]}>
        <Button className={styles["delete-button"]} onClick={onConfirm}>
          Supprimer
        </Button>
        <Button onClick={onCancel}>Annuler</Button>
      </div>
    </div>
  );
}

interface SendQuoteConfirmationModalProps {
  onCancel: () => void;
  onConfirm: () => void;
}

function SendQuoteConfirmationModal({
  onCancel,
  onConfirm,
}: SendQuoteConfirmationModalProps) {
  return (
    <div className={styles["modal"]}>
      <h3>Voulez-vous envoyer le devis ?</h3>
      <div className={styles["button-container"]}>
        <Button className={styles["send-button"]} onClick={onConfirm}>
          Envoyer
        </Button>
        <Button onClick={onCancel}>Annuler</Button>
      </div>
    </div>
  );
}
