import { Delete } from "@mui/icons-material";
import { IOperation } from "../../../../../types/accounting.types";
import style from "./operationItem.module.scss";
import Price from "../../../../Price/Price";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";
import ConfirmModalComp from "components/confirmationModal/ConfirmModalComp";
import { useState } from "react";

interface OperationItemProps {
  operation: IOperation;
  index: number;
  isFormOpen: boolean;
  handleEditOperation: (operation: IOperation, index: number) => void;
  onDelete: (index: number) => void;
}
//

export default function OperationItem({
  operation,
  index,
  isFormOpen,
  handleEditOperation,
  onDelete,
}: OperationItemProps) {
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  return (
    <>
      <li
        className={`${style["operation-item"]} ${
          isFormOpen ? style["larger"] : ""
        }`}
        onClick={() => handleEditOperation(operation, index)}
      >
        <div className={style["description-container"]}>
          <span className={style["description"]}>
            - {operation.description}
          </span>
          <span className={style["dots"]}></span>
          <Price className={style["price"]} price={operation.price} />
        </div>
        {!isFormOpen && (
          <div className={style["delete"]} onClick={handleOpenModal}>
            <Delete />
          </div>
        )}
      </li>
      <ModalContainer
        width="fit"
        height="fit"
        isOpen={openConfirmModal}
        onCancel={handleCloseModal}
      >
        <ConfirmModalComp
          confirmButtonClassName={style["delete-button"]}
          title={"Voulez-vous supprimer cette opération?"}
          confirmText="Supprimer"
          onConfirm={() => onDelete(index)}
          onCancel={handleCloseModal}
        />
      </ModalContainer>
    </>
  );

  function handleOpenModal(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();
    setOpenConfirmModal(true);
  }

  function handleCloseModal() {
    setOpenConfirmModal(false);
  }
}
