import OverviewButton from "components/OverviewButton/OverviewButton";
import SelectState from "pages/shipping/create/SelectState/SelectState";
import styles from "./createLogisticHeader.module.scss";
import { LOGISTIC_STATE } from "utils/logistic.init";
import { ILogistic, LogisticForm } from "types/logistic.types";

interface CreateLogisticHeaderProps {
  defaultLogistic?: ILogistic;
  formValues: LogisticForm;
  onFormValuesChange: (formValues: LogisticForm) => void;
}

export default function CreateLogisticHeader({
  defaultLogistic,
  formValues,
  onFormValuesChange,
}: CreateLogisticHeaderProps) {
  return (
    <div className={styles["title"]}>
      <span className={styles["title-container"]}>
        {defaultLogistic
          ? "Mettre à jour document logistique"
          : "Nouveau document logistique"}
        <OverviewButton
          trackId={formValues.trackId}
          className={styles["icon"]}
        />
      </span>
      <SelectState
        label="Status du document logistique"
        state={formValues.status}
        stateOption={LOGISTIC_STATE}
        setState={handleOnStateChange}
      />
    </div>
  );

  function handleOnStateChange(state: string) {
    onFormValuesChange({ ...formValues, status: state });
  }
}
