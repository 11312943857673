import { useState } from "react";
import styles from "./incomingInternal.module.scss";
import { Replay } from "@mui/icons-material";
import ShippingList from "./ShippingList/ShippingList";

export default function IncomingInternal() {
  const [date, setDate] = useState<Date>(new Date());

  return (
    <>
      <div className={styles["incoming-internal"]}>
        <div className={styles["input-container"]}>
          <label>Choisir une date</label>
          <input
            type="date"
            onChange={handleDateChange}
            value={date.toISOString().substring(0, 10)}
            className={styles["date-input"]}
          />
        </div>
        <div
          className={styles["reset-button"]}
          onClick={() => setDate(new Date())}
        >
          <Replay />
        </div>
      </div>
      <ShippingList date={date} />
    </>
  );

  function handleDateChange({ target }: { target: HTMLInputElement }) {
    setDate(new Date(target.value));
  }
}
