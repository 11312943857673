import { useQuery } from "@tanstack/react-query";
import DataError from "components/errors/DataError/DataError";
import PictureLoadingSpinner from "components/loadingSpinner/pictureloadingSpinner";
import { useParams } from "react-router-dom";
import { getProAccount } from "../../../requests/proAccount";
import { IProAccount } from "../../../types/proAccount.types";
import Create from "../create/create";

export default function Edit() {
  const { id } = useParams();
  const { data, isFetching } = useQuery({
    queryKey: ["pro-account", id],
    queryFn: () => getProAccount(id),
    refetchOnWindowFocus: false,
    enabled: Boolean(id),
  });

  if (isFetching) {
    return <PictureLoadingSpinner />;
  }

  if (!data) {
    return <DataError error="cross-sell" />;
  }

  const proAccountToEdit: IProAccount = {
    id: data.proAccount.id,
    username: data.proAccount.username,
    password: data.proAccount.password,
    shops: data.proAccount.shops,
    email: data.proAccount.email,
  };

  return <Create data={proAccountToEdit} />;
}
