import { useQuery } from "@tanstack/react-query";
import DataError from "components/errors/DataError/DataError";
import PictureLoadingSpinner from "components/loadingSpinner/pictureloadingSpinner";
import { useParams } from "react-router-dom";
import { fetchShippingMethod } from "../../../requests/shippingMethod";
import Create from "../create/create";

export default function Edit() {
  const { shippingMethodId } = useParams();
  const { data, isFetching } = useQuery({
    queryKey: ["shipping-method", shippingMethodId],
    queryFn: () => fetchShippingMethod(shippingMethodId),
  });

  if (isFetching) {
    return <PictureLoadingSpinner />;
  }

  if (!data) {
    return <DataError error="shipping-method" />;
  }

  const shippingMethod = data;

  return <Create shippingMethod={shippingMethod} />;
}
