import style from "./proOutgoingShipping.module.scss";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { getCartsList } from "../../../../requests/professionalCarts";
import { useParams, useSearchParams } from "react-router-dom";
import { IProCart, IProduct } from "../../../../types/proCart.types";
import { useEffect, useMemo, useRef, useState } from "react";
import ProShippingModal from "../../../../components/modals/proShippingModal/proShippingModal";
import { getShop } from "../../../../requests/shops";
import { IShopOperation } from "../../../../types/shop.types";
import { queryObject } from "../../../../types/query.types";
import { Edit } from "@mui/icons-material";
import SearchByWorkshopId from "../../../../components/searchByWorkshopId/searchByWorkshopId";
import LoadingSpinner from "../../../../components/loadingSpinner/loadingSpinner";
import {
  ILogistic,
  IProduct as ILogisticProduct,
} from "../../../../types/logistic.types";
import { toast } from "react-toastify";
import { streamFile } from "../../../../requests/file";
import {
  cartProCheckout,
  fetchLogisticFromTrackerProId,
} from "../../../../requests/trackerPro";
import { TOAST_ERROR_OPTIONS } from "../../../../utils/toast.options";
import { getShippingPro } from "../../../../requests/shippingPro";
import ValidateButton from "../../../../components/validateButton/validateButton";
import CartProCardProduct from "../cartProCartProduct/cartProCartProduct";
import OutgoingShippingProModal from "../../../../components/modals/outgoingShippingProModal/outgoingShippingProModal";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";

interface IProductShipping extends IProduct {
  canceled: boolean;
}

interface IProCartShipping extends IProCart {
  productsList: IProductShipping[];
}

export const INITIAL_QUERY: Partial<queryObject> = {
  workshopId: "",
};

export default function ProOutgoingShipping() {
  const { shopId } = useParams();
  const [searchParams] = useSearchParams();
  const SHOP_NAME = searchParams.get("shopName");
  const SHIPPING_ID = searchParams.get("shipping-id") as string | undefined;
  const [query, setQuery] = useState<Partial<queryObject>>(INITIAL_QUERY);
  const { data, isFetching, refetch } = useQuery({
    queryKey: ["cart-list", query, shopId],
    queryFn: () => getCartsList(formatedQuery()),
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  });
  const { data: dataShop, isFetching: isFetchingShop } = useQuery({
    queryKey: ["shop", shopId],
    queryFn: () => getShop(shopId),
  });

  const {
    data: dataShipping,
    isFetching: isFetchingShipping,
    refetch: refetchShippingPro,
  } = useQuery({
    queryKey: ["shipping-pro", SHIPPING_ID],
    queryFn: () => getShippingPro(SHIPPING_ID),
  });
  const [cartList, setCartList] = useState<IProCartShipping[]>(
    formatCartList(data?.cartsList || [])
  );
  const [openModal, setOpenModal] = useState<boolean>(false);
  const READY_FOR_DELIVERY =
    dataShipping?.trackId?.length ===
    (dataShipping?.trackId?.length ?? 0) + (data?.cartsList.length ?? 0);

  useEffect(() => {
    if (data) {
      setCartList(formatCartList(data.cartsList));
    }
  }, [data]);

  if (isFetching) {
    return <LoadingSpinner color="gold" />;
  }

  return (
    <>
      <div className={style["pro-incoming-shipping"]}>
        <div className={style["header"]}>
          <div className={style["header-left"]}>
            <div className={style["title"]}>{SHOP_NAME}</div>
            <div>{"Liste des paniers à contrôler"}</div>
          </div>
          <div>
            <SearchByWorkshopId
              workshopId={query.workshopId || ""}
              setWorkshopId={handleSearchByWorkshopId}
            />
          </div>
        </div>
        <div>
          <div className={style["footer"]}>
            <div className={style["footer-right"]}>
              <div>
                {`Paniers contrôlés ${dataShipping?.trackId?.length} / ${
                  (dataShipping?.trackId?.length ?? 0) +
                  (data?.cartsList.length ?? 0)
                }`}
              </div>
              {!isFetchingShipping && (
                <ValidateButton
                  className={style["green-button"]}
                  handleOnClick={handleOpenModal}
                  title={
                    READY_FOR_DELIVERY
                      ? "Prêt à livrer !"
                      : "Valider la livraison"
                  }
                />
              )}
            </div>
          </div>
        </div>
        <div className={style["cart-list"]}>
          {isFetching ? (
            <div className={style["loading-container"]}>
              <LoadingSpinner color="gold" />
            </div>
          ) : (
            <>
              {cartList.length > 0 ? (
                cartList.map((cart: IProCartShipping) => {
                  return (
                    <CartProCard
                      cart={cart}
                      setCart={(cart) => setCart(cart, cart.id)}
                      key={cart.id}
                      refresh={refresh}
                      resetQuery={resetQuery}
                      shippingProId={
                        SHIPPING_ID ||
                        dataShop?.nextOutgoingShipping?.documentId
                      }
                      shopOperationsList={dataShop?.pricing?.operationsList}
                      workshopPrefixId={dataShop?.workshopPrefixId}
                    />
                  );
                })
              ) : (
                <div className={style["loading-container"]}>
                  aucun panier disponible
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <ModalContainer isOpen={openModal} onCancel={handleCloseModal}>
        <OutgoingShippingProModal
          shippingPro={dataShipping!}
          shopName={dataShop?.name}
        />
      </ModalContainer>
    </>
  );

  function handleOpenModal() {
    if (!dataShipping) {
      return;
    }
    if (!READY_FOR_DELIVERY) {
      if (!window.confirm("Veuillez contrôler tous les paniers.")) {
        return;
      }
    }
    setOpenModal(true);
  }

  function handleCloseModal() {
    setOpenModal(false);
  }

  function formatCartList(cartList: IProCart[]): IProCartShipping[] {
    return cartList.map((cart: IProCart) => {
      return {
        ...cart,
        productsList: cart.productsList.map((product) => {
          return { ...product, canceled: false };
        }),
      };
    });
  }

  function refresh() {
    refetch();
    refetchShippingPro();
  }

  // function handleOpenModal() {
  //   if (!dataShipping) {
  //     return;
  //   }
  //   if (!READY_FOR_DELIVERY) {
  //     if (!window.confirm("Veuillez contrôler tous les paniers.")) {
  //       return;
  //     }
  //   }
  //   setModalChildren(
  //     <OutgoingShippingProModal
  //       shippingPro={dataShipping}
  //       shopName={dataShop?.name}
  //     />
  //   );
  //   setModalIsOpen(true);
  // }

  function setCart(newCart: IProCartShipping, id?: string) {
    if (!id) {
      return;
    }
    setCartList(
      cartList.map((cart: IProCartShipping) => {
        return cart.id === id ? newCart : cart;
      })
    );
  }

  function formatedQuery(): string {
    let formatedQuery: string = `?shopId=${shopId}&state=CART_IN_REPAIR`;

    if (query.workshopId) {
      formatedQuery += `&productsList.workshopId=${query.workshopId}`;
    }
    formatedQuery += `&sort_field=productsList.workshopId&sort_direction=asc`;
    return formatedQuery;
  }

  function handleSearchByWorkshopId(value: string) {
    setQuery((prev) => ({ ...prev, workshopId: value }));
  }
  function resetQuery() {
    setQuery(INITIAL_QUERY);
  }
}

interface CartProCardProps {
  cart: IProCartShipping;
  setCart: (cart: IProCartShipping) => void;
  refresh: () => void;
  resetQuery: () => void;
  shippingProId?: string;
  shopOperationsList?: IShopOperation[];
  workshopPrefixId?: string;
}

function CartProCard({
  cart,
  setCart,
  refresh,
  resetQuery,
  shippingProId,
  shopOperationsList,
  workshopPrefixId,
}: CartProCardProps) {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [fileList, setFileList] = useState<File[][]>([
    ...Array(cart.productsList.length).fill([]),
  ]);
  const [productCheckList, setProductCheckList] = useState<boolean[]>([
    ...Array(cart.productsList.length).fill(false),
  ]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const READY_TO_CHECKIN = useMemo(
    () => productCheckList.every((item: boolean) => item === true),
    [productCheckList]
  );

  useEffect(() => {
    setProductCheckList([...Array(cart.productsList.length).fill(false)]);
  }, [cart.productsList]);

  return (
    <>
      <div className={style["cart-pro-product-card"]} ref={ref}>
        <div className={`${style["cart-pro-product-card-container"]}`}>
          <div className={style["cart-pro-product-card-left"]}>
            <div>
              {READY_TO_CHECKIN ? (
                <div className={style["button-container"]}>
                  {READY_TO_CHECKIN && (
                    <button
                      className={style["validate-button"]}
                      onClick={handleCartProCheckout}
                    >
                      Panier prêt
                    </button>
                  )}
                </div>
              ) : (
                <>
                  <div className={style["product-card-displayname"]}>
                    {cart.contact.displayname || "-"}
                  </div>
                  {cart.customRef && (
                    <div className={style["product-card-custom-ref"]}>
                      {cart.customRef}
                    </div>
                  )}
                  <div className={style["product-card-information"]}>
                    <span>Nombres d'articles</span>
                    <span>{cart.productsList.length}</span>
                  </div>
                  {cart.comments && (
                    <div className={style["product-card-comment-container"]}>
                      <span>Commentaire</span>
                      <span className={style["product-card-comment"]}>
                        {cart.comments}
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className={style["edit-cart"]} onClick={handleOpenModal}>
              modifier panier
              <div className={style["edit-icon"]}>
                <Edit />
              </div>
            </div>
          </div>
          <div className={style["cart-pro-product-card-right"]}>
            <div className={style["product-list"]}>
              {cart.productsList.map(
                (product: IProductShipping, index: number) => {
                  return (
                    <CartProCardProduct
                      cart={cart}
                      fileList={fileList[index]}
                      setFileList={(fileList) =>
                        setProductFileList(fileList, index)
                      }
                      index={index}
                      isChecked={productCheckList[index]}
                      key={index}
                      setIsChecked={() => setIsChecked(index)}
                      product={product}
                      displayPrintModal={false}
                    />
                  );
                }
              )}
            </div>
          </div>
        </div>
        {isLoading && (
          <div className={style["loading-spinner-container"]}>
            <div
              className={style["loading-spinner"]}
              style={{
                height: (ref.current?.clientHeight || 0) - 20,
                width: ref.current?.clientWidth,
              }}
            >
              <LoadingSpinner color="gold" />
            </div>
          </div>
        )}
      </div>
      <ModalContainer isOpen={openModal} onCancel={handleCloseModal}>
        <ProShippingModal cart={cart} setCart={handleValidateCart} />
      </ModalContainer>
    </>
  );

  function handleCloseModal() {
    setOpenModal(false);
  }

  function handleOpenModal() {
    setOpenModal(true);
  }

  function handleValidateCart(cart: IProCartShipping) {
    setCart(cart);
    handleCloseModal();
  }

  async function formatProCartCheckout(): Promise<
    | {
        logistic: ILogistic;
        toUpdateCart: IProCart;
        toAddCart?: IProCart;
      }
    | undefined
  > {
    if (!cart.trackId) {
      return;
    }

    const toUpdateCart = {
      ...cart,
      productsList: cart.productsList
        .filter((product: IProductShipping) => !product.canceled)
        .map((product) => {
          return { ...product, state: "WORKSHOP_OUTGOING" };
        }),
    };
    const toAddCartProductsList = cart.productsList.filter(
      (product: IProductShipping) => product.canceled
    );
    let toAddCart: IProCart | undefined;
    if (toAddCartProductsList.length !== 0) {
      toAddCart = {
        ...cart,
        productsList: toAddCartProductsList,
      };
      delete toAddCart.id;
      delete toAddCart.trackId;
    }

    const logistic = await fetchLogisticFromTrackerProId(cart.trackId);

    const newLogistic: Partial<ILogistic> = {
      ...logistic,
      status: "LOGISTIC_DONE",
      productsList: logistic?.productsList.map((product) => {
        return { ...product, status: "PRODUCT_CONTROLLED" };
      }),
    };

    return { logistic: newLogistic, toUpdateCart, toAddCart } as {
      logistic: ILogistic;
      toUpdateCart: IProCart;
      toAddCart?: IProCart;
    };
  }

  async function handleCartProCheckout() {
    if (!cart.trackId) {
      toast.error("TrackId manquant.", TOAST_ERROR_OPTIONS);
      return;
    }
    if (!shippingProId) {
      toast.error("ShippingProId manquant.", TOAST_ERROR_OPTIONS);
      return;
    }
    setIsLoading(true);
    const { logistic, toUpdateCart, toAddCart } =
      (await formatProCartCheckout()) as {
        logistic: ILogistic;
        toUpdateCart: IProCart;
        toAddCart?: IProCart;
      };
    try {
      for (const [index, files] of fileList.entries()) {
        let fileNameList: string[] = [];
        for (const fileObj of files) {
          const response = await streamFile(
            `_${cart.trackId}_pro-outgoing-shipping.`,
            fileObj
          );
          fileNameList = [...fileNameList, response.message];
        }
        logistic.productsList = logistic.productsList.map(
          (product: ILogisticProduct, prodIndex: number) => {
            if (prodIndex === index) {
              return { ...product, outgoingPicturesList: fileNameList };
            }
            return product;
          }
        );
        fileNameList = fileNameList.slice(0, 0);
      }
      const response = await cartProCheckout({
        trackId: cart.trackId,
        logistic,
        shippingProId,
        toUpdateCart,
        toAddCart,
      });
    } catch (error) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
      console.error(error);
    } finally {
      setIsLoading(false);
      refresh();
    }
  }

  function setProductFileList(newFileList: File[], index: number) {
    setFileList(
      fileList.map((file: File[], i) => {
        return index === i ? newFileList : file;
      })
    );
  }

  function setIsChecked(index: number) {
    setProductCheckList(
      productCheckList.map((checked: boolean, i: number) => {
        return i === index ? !checked : checked;
      })
    );
  }

  // function handleOpenModal() {
  //   setModalChildren(<ProShippingModal cart={cart} setCart={setCart} />);
  //   setModalIsOpen(true);
  // }
}
