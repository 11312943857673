import { keepPreviousData, useQuery } from "@tanstack/react-query";
import DataError from "components/errors/DataError/DataError";
import LoadingSpinner from "components/loadingSpinner/loadingSpinner";
import { fetchShippingList } from "requests/shipping";
import { IShipping } from "types/shipping.types";
import IncomingShippingCard from "./IncomingShippingCard/IncomingShippingCard";
import styles from "./shippingList.module.scss";

interface ShippingListProps {
  date: Date;
}

export default function ShippingList({ date }: ShippingListProps) {
  const { data, isFetching } = useQuery({
    queryKey: ["shippings-list", date],
    queryFn: () => fetchShippingList(formatedQuery()),
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  });

  if (isFetching) {
    return (
      <div className={styles["loading-spinner-container"]}>
        <LoadingSpinner color="gold" />
      </div>
    );
  }

  if (!data) {
    return <DataError error="Shipping" />;
  }

  const { shippingsList } = data;

  return (
    <div className={styles["shipping-selection-list"]}>
      {shippingsList.map((shipping: IShipping, index: number) => (
        <IncomingShippingCard key={index} shipping={shipping} />
      ))}
    </div>
  );

  function formatedQuery() {
    return `?period.start=${date.toISOString()}&period.start=${date.toISOString()}&shippingMethod.shippingService=INTERNAL&direction=INCOMING&state=PENDING`;
  }
}
