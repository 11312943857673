import { ICrossSellProduct, IOperation } from "types/accounting.types";
import { DropOffForm, DropOffProductForm } from "types/logistic.types";
import AddOperationAndCrossSellSectionHeader from "./AddOperationAndCrossSellSectionHeader/AddOperationAndCrossSellSectionHeader";
import OperationSection from "./OperationSection/OperationSection";
import CrossSellSection from "./CrossSellSection/CrossSellSection";
import styles from "./addOperationAndCrossSellSection.module.scss";
import { DEFAULT_CROSSSELL } from "utils/accounting.init";
import { OPERATION_DEFAULT } from "utils/operation.init";

interface AddOperationAndCrossSellSectionProps {
  dropOff: DropOffForm;
  onDropOffChange: (value: DropOffForm) => void;
  product: DropOffProductForm | null;
}

export default function AddOperationAndCrossSellSection({
  dropOff,
  onDropOffChange,
  product,
}: AddOperationAndCrossSellSectionProps) {
  return (
    <div className={styles["add-operation-and-cross-sell-section"]}>
      <AddOperationAndCrossSellSectionHeader
        dropOff={dropOff}
        product={product}
        onAddOperation={handleAddOperation}
        onAddCrossSell={handleAddCrossSell}
      />
      <OperationSection
        product={product}
        onUpdateOperation={handleUpdateOperation}
        onDeleteOperation={handleDeleteOperation}
      />
      <CrossSellSection
        onChange={handleUpdateCrossSell}
        onDelete={handleDeleteCrossSell}
        crossSellsList={dropOff.crossSells}
      />
    </div>
  );

  function handleAddOperation(operation: IOperation) {
    if (!product || !product.operationGroup || !product.productGroup) {
      return;
    }
    if (operation.slug) {
      const found = product.operations.findIndex(
        (op) => op.slug == operation.slug
      );
      if (found !== -1) {
        return handleDeleteOperation(found);
      } else {
        return onDropOffChange({
          ...dropOff,
          productList: dropOff.productList.map((p) => {
            if (p.id === product.id) {
              return {
                ...p,
                operations: [...(p.operations || []), operation],
              };
            }
            return p;
          }),
        });
      }
    }
    handleAddOperationEmptyOperation();
  }

  function handleUpdateOperation(operation: IOperation, targetIndex: number) {
    if (!product || !product.operationGroup || !product.productGroup) {
      return;
    }
    onDropOffChange({
      ...dropOff,
      productList: dropOff.productList.map((p) => {
        if (p.id === product.id) {
          return {
            ...p,
            operations: (p.operations || []).map((op, sourceIndex) =>
              sourceIndex === targetIndex ? operation : op
            ),
          };
        }
        return p;
      }),
    });
  }

  function handleDeleteOperation(targetIndex: number) {
    if (!product || !product.operationGroup || !product.productGroup) {
      return;
    }
    onDropOffChange({
      ...dropOff,
      productList: dropOff.productList.map((p) => {
        if (p.id === product.id) {
          return {
            ...p,
            operations: (p.operations || []).filter(
              (_, sourceIndex) => sourceIndex !== targetIndex
            ),
          };
        }
        return p;
      }),
    });
  }

  function handleAddCrossSell(crossSell?: ICrossSellProduct) {
    if (!crossSell) return;

    const found = dropOff.crossSells.findIndex(
      (cs) => cs.slug == crossSell.slug
    );
    if (found !== -1) {
      return handleDeleteCrossSell(found);
    } else {
      onDropOffChange({
        ...dropOff,
        crossSells: [
          ...(dropOff.crossSells || []),
          { ...(crossSell || DEFAULT_CROSSSELL), cartQuantity: 1 },
        ],
      });
    }
  }

  function handleUpdateCrossSell(
    crossSell: ICrossSellProduct,
    targetIndex: number
  ) {
    onDropOffChange({
      ...dropOff,
      crossSells: (dropOff.crossSells || []).map((cs, sourceIndex) =>
        sourceIndex === targetIndex ? crossSell : cs
      ),
    });
  }

  function handleDeleteCrossSell(index: number) {
    onDropOffChange({
      ...dropOff,
      crossSells: (dropOff.crossSells || []).filter((_, i) => i !== index),
    });
  }

  function handleAddOperationEmptyOperation() {
    if (!product || !product.operationGroup || !product.productGroup) {
      return;
    }
    const newOperation = {
      ...OPERATION_DEFAULT,
      operationGroup: product.operationGroup,
      productGroup: product.productGroup,
      operationType:
        product.productGroup === "SHOES" ? "SHOES_AUTRE" : "LEATHER_AUTRE",
    };
    onDropOffChange({
      ...dropOff,
      productList: dropOff.productList.map((p) => {
        if (p.id === product.id) {
          return {
            ...p,
            operations: [...(p.operations || []), newOperation],
          };
        }
        return p;
      }),
    });
  }
}
