import { IOperation } from "../../../types/accounting.types";
import REFASHION_LOGO from "assets/bonus-reparation-300x182.png";
import { REFASHION_OPERATIONS_PRICE } from "../../../utils/operation.init";
import styles from "./operationQuoteSummary.module.scss";
import clsx from "clsx";
import Price from "../../Price/Price";

interface OperationQuoteSummaryProps {
  operation: IOperation;
  onRefashionChange?: (target: EventTarget & HTMLSelectElement) => void;
}

export default function OperationQuoteSummary({
  operation,
  onRefashionChange,
}: OperationQuoteSummaryProps) {
  return (
    <li
      className={clsx(styles["operation-list-item"], {
        [styles["operation-canceled"]]: operation.canceled,
      })}
    >
      <div className={styles["operation-line"]}>
        <span className={styles["operation-description"]}>
          {operation.description}
        </span>
        <hr className={styles["operation-dots"]} />
        <Price className={styles["operation-price"]} price={operation.price} />
      </div>
      {onRefashionChange && (
        <div className={styles["operation-discount-line"]}>
          <img className={styles["discount-logo"]} src={REFASHION_LOGO}></img>
          <select
            className={styles["operation-discount-select"]}
            value={operation.refashionOperation}
            onChange={({ target }) => {
              onRefashionChange(target);
            }}
          >
            <option className={styles["operation-discount-option"]}>N/A</option>
            {REFASHION_OPERATIONS_PRICE.map((op, index) => (
              <option
                key={index}
                className={styles["operation-discount-option"]}
                value={op.refashionOperation}
              >
                {op.refashionProductCategory} - {op.refashionDiscount / 100}
              </option>
            ))}
          </select>
        </div>
      )}
    </li>
  );
}
