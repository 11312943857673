import AddressForm from "components/forms/addressForm/addressForm";
import SectionTitle from "components/SectionTitle/SectionTitle";
import styles from "./addShippingAddress.module.scss";
import { DropOffForm } from "types/logistic.types";
import { IShippingPoint } from "types/shipping.types";

interface AddShippingAddressProps {
  dropOff: DropOffForm;
  onAddressChange: (
    address: IShippingPoint,
    isShippingAddress?: boolean
  ) => void;
}

export default function AddShippingAddress({
  dropOff,
  onAddressChange,
}: AddShippingAddressProps) {
  return (
    <div className={styles["validate-drop-off-shipping"]}>
      <h3 className={styles["add-shipping-address-title"]}>
        Vous avez choisi une méthode de livraison autre que le salon.
      </h3>
      <p className={styles["add-shipping-address-message"]}>
        Veuillez saisir une adresse de livraison.
      </p>
      <SectionTitle title="Adresse de livraison" />
      <AddressForm
        contact={dropOff.contact!}
        defaultAddress={dropOff.shippingAddress || undefined}
        isShippingAddress
        onChange={onAddressChange}
        direction="OUTGOING"
      />
    </div>
  );
}
