import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../../components/Button/Button";
import CheckboxItem from "../../../components/checkboxItem/CheckboxItem";
import ConcurrentTasksTemplate from "../../../components/concurrentTasksTemplate/ConcurrentTasksTemplate";
import ContactForm from "../../../components/forms/ContactForm/ContactForm";
import ImagesForm from "../../../components/forms/imagesForm/imagesForm";
import ProductSection from "../../../components/forms/productForm/ProductSection";
import SectionFormTemplate from "../../../components/forms/sectionFormTemplate/SectionFormTemplate";
import OverviewButton from "../../../components/OverviewButton/OverviewButton";
import {
  addCustomer,
  fetchCustomersList,
  linkTracker,
} from "../../../requests/customer";
import { postEstimate, updateEstimate } from "../../../requests/estimate";
import { deleteFile, streamFile } from "../../../requests/file";
import { sendEstimateSentEmail } from "../../../requests/notification";
import { IValidationError } from "../../../types";
import { IEstimate } from "../../../types/booking.type";
import { IContact, IProduct } from "../../../types/logistic.types";
import { ESTIMATE_STATE } from "../../../utils/booking.init";
import { INITIAL_CONTACT } from "../../../utils/shipping.init";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../utils/toast.options";
import SelectState from "../../shipping/create/SelectState/SelectState";
import style from "./create.module.scss";
import SendEstimationModalComp from "../../../components/sendEstimationModal/SendEstimationModalComp";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";
import EstimateActionSection from "./EstimateActionSection/EstimateActionSection";

const INITIAL_ESTIMATE: IEstimate = {
  useragent: "",
  contact: INITIAL_CONTACT,
  imagesList: [],
  comments: "",
  state: "ESTIMATE_PENDING",
  productsList: [],
  createdAt: "",
};

export default function Create({ data }: { data?: IEstimate }) {
  const [fileList, setFileList] = useState<File[]>([]);
  const [formValues, setFormValues] = useState<IEstimate>(
    data || INITIAL_ESTIMATE
  );
  const [validationError, setValidationError] = useState<IValidationError[]>(
    []
  );
  const [scrollTo, setScrollTo] = useState<boolean>(false);
  const [sendEmail, setSendEmail] = useState<boolean>(true);
  const [addOrUpdateQuote, setAddOrUpdateQuote] = useState<boolean>(true);
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  return (
    <>
      <div className={style["estimate"]}>
        <div ref={ref} className={style["title"]}>
          <span className={style["title-container"]}>
            {data ? "Modifier estimation" : "Nouvelle estimation"}
            <OverviewButton trackId={data?.trackId} />
          </span>
          <div className={style["top-right"]}>
            <SelectState
              label="Status de l'estimation"
              state={formValues.state}
              setState={(state) =>
                setFormValues((prev) => ({ ...prev, state }))
              }
              stateOption={ESTIMATE_STATE}
            />
            <EstimateActionSection
              estimate={formValues}
              onEstimateChange={handleEstimateChange}
              fileList={fileList}
            />
          </div>
        </div>
        <div className={style["form"]}>
          <SectionFormTemplate title="Contact">
            <ContactForm
              contact={formValues.contact}
              setContact={handleContactChange}
              className={style["contact-form"]}
            />
          </SectionFormTemplate>
          <SectionFormTemplate title="Commentaire">
            {data?.comments ? (
              <p>"{data?.comments}"</p>
            ) : (
              <textarea
                className={style["comment"]}
                value={formValues.comments}
                onChange={({ target }) =>
                  setFormValues((prev) => ({ ...prev, comments: target.value }))
                }
              />
            )}
          </SectionFormTemplate>
          <SectionFormTemplate title="Images">
            <ImagesForm
              title="estimation"
              imageList={formValues.imagesList}
              fileList={fileList}
              setFileList={handleAddPicture}
              deleteImage={handleDeleteImage}
            />
          </SectionFormTemplate>
          <hr className={style["separator"]} />
          <ProductSection
            productsList={formValues.productsList}
            setProductsList={handleProductChange}
            hasWorkshopId={false}
          />
          <div>
            {validationError.find(
              (value: IValidationError) => value.field === "productsList"
            ) && (
              <div className={style["error-message"]}>
                * veuillez ajouter au moins un article.
              </div>
            )}
          </div>
          <ConcurrentTasksTemplate>
            <CheckboxItem
              checked={sendEmail}
              onChecked={setSendEmail}
              title="Envoyer l'estimation"
            />
            <CheckboxItem
              checked={addOrUpdateQuote}
              onChecked={setAddOrUpdateQuote}
              title={`${data ? "Mettre à jour" : "Créer"} le devis`}
            />
          </ConcurrentTasksTemplate>
          <div className={style["submit-button-container"]}>
            <Button type={"submit"} onClick={handleOpenConfirmModal}>
              {data ? "Mettre à jour" : "Créer"}
            </Button>
          </div>
        </div>
      </div>
      <ModalContainer
        isOpen={openConfirmModal}
        onCancel={handleCloseConfirmModal}
        width="fit"
        height="fit"
      >
        <SendEstimationModalComp
          confirmButtonClassName={style["validate-button"]}
          title={`Voulez-vous ${
            data ? "mettre à jour" : "créer"
          } cette estimation ?`}
          element={
            <>
              {(sendEmail || addOrUpdateQuote) && (
                <ul style={{ fontSize: "14px" }}>
                  Ceci entrainera les actions suivantes:
                  {sendEmail && <li>Envoi de l'estimation</li>}
                  {addOrUpdateQuote && (
                    <li>{`${data ? "Mise à jour" : "Création"}`} du devis</li>
                  )}
                </ul>
              )}
            </>
          }
          onConfirm={handleSubmit}
          onCancel={handleCloseConfirmModal}
        />
      </ModalContainer>
    </>
  );

  function handleOpenConfirmModal() {
    setOpenConfirmModal(true);
  }

  function handleCloseConfirmModal() {
    setOpenConfirmModal(false);
  }

  async function handleSubmit(mailBody?: string) {
    let response;
    let customerId: string | undefined = undefined;
    try {
      customerId = await handleAddCustomer(formValues.contact.email);
    } catch (error) {
      console.warn(error);
    }

    try {
      const imagesList = await submitFiles("_estimate.");
      response = data
        ? await updateEstimate(
            {
              ...formValues,
              imagesList,
              state: sendEmail ? "ESTIMATE_SENT" : formValues.state,
            },
            addOrUpdateQuote,
            sendEmail,
            customerId,
            mailBody
          )
        : await postEstimate(
            {
              ...formValues,
              imagesList,
              state: sendEmail ? "ESTIMATE_SENT" : formValues.state,
            },
            addOrUpdateQuote,
            sendEmail,
            customerId,
            mailBody
          );
      if ((response as IValidationError[]).length > 0) {
        setValidationError(response as IValidationError[]);
        setScrollTo(!scrollTo);
        return;
      }
      toast.success(
        `Estimation ${data ? "mise à jour" : "ajoutée"}`,
        TOAST_SUCCESS_OPTIONS
      );
      addOrUpdateQuote &&
        toast.success(
          `Devis ${data ? "mis à jour" : "ajouté"}`,
          TOAST_SUCCESS_OPTIONS
        );
      if (sendEmail) {
        setFormValues((prev) => ({ ...prev, state: "ESTIMATE_SENT" }));
        toast.success(`Email envoyé`, TOAST_SUCCESS_OPTIONS);
      }
    } catch (error) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    }
    try {
      if (customerId) {
        await handleLinkTracker(response, customerId);
      }
    } catch (error) {
      console.warn(error);
    }
  }

  async function handleAddCustomer(customerEmail?: string) {
    if (!customerEmail) {
      toast.warning("Email manquant", TOAST_ERROR_OPTIONS);
      return;
    }
    let id = formValues.contact.id;

    if (!id) {
      try {
        let customersList = await fetchCustomersList(`?email=${customerEmail}`);
        if (customersList.totalCount > 0 && customersList.customers[0]) {
          id = customersList.customers[0].id;
        }
      } catch (error) {
        toast.warning("Ajout du customer", TOAST_ERROR_OPTIONS);
      }
    }
    try {
      if (id) {
        return id;
      }
      if (customerEmail && !id) {
        let response = await addCustomer(formValues.contact);
        let customerId = response.id;
        toast.success(
          `Nouveau client [${formValues.contact.email}] ajouté ou mis à jour.`,
          TOAST_SUCCESS_OPTIONS
        );
        return customerId;
      }
    } catch (error) {
      toast.warning("Ajout du customer", TOAST_ERROR_OPTIONS);
    }
  }

  async function handleLinkTracker(response: any, customerId?: string) {
    try {
      if (response && (response as { estimate: IEstimate }).estimate) {
        let estimate = (response as { estimate: IEstimate }).estimate;
        if (estimate.trackId && customerId) {
          await linkTracker(customerId, estimate.trackId);
          toast.success("Tracker lié avec succès.", TOAST_SUCCESS_OPTIONS);
        }
      }
      !data && navigate("../");
    } catch (error) {
      toast.warning("Liaison du tracker", TOAST_ERROR_OPTIONS);
    }
  }

  async function submitFiles(slug: string) {
    try {
      let imagesList: string[] = formValues.imagesList || [];
      for (let file of fileList) {
        const response = await streamFile(slug, file);
        imagesList.push(response.message);
      }
      return imagesList;
    } catch (error) {
      toast.error(
        "Un problème est survenu lors de l'envoi de l'image.",
        TOAST_ERROR_OPTIONS
      );
      return [];
    }
  }

  function handleEstimateChange(estimate: IEstimate) {
    setFormValues(estimate);
  }

  async function handleDeleteImage(imageURI: string, index: number) {
    try {
      const newFormValues = { ...formValues };
      newFormValues.imagesList.splice(index, 1);
      setFormValues(newFormValues);
      await deleteFile(imageURI);
    } catch (error) {
      toast.error(
        "Erreur lors de la suppression de l'image.",
        TOAST_ERROR_OPTIONS
      );
    }
    try {
      await updateEstimate(formValues);
    } catch (error) {
      toast.error(
        "Erreur lors de la mise à jour de l'estimation.",
        TOAST_ERROR_OPTIONS
      );
    }
  }

  function handleAddPicture(_fileList: File[], isWebCam: boolean) {
    const newFileList = [...fileList];
    if (isWebCam) {
      _fileList.forEach((file: File) => {
        newFileList.push(file);
      });
      setFileList(newFileList);
    } else {
      setFileList([..._fileList]);
    }
  }

  function handleContactChange(newContact: IContact) {
    setFormValues((prev) => ({ ...prev, contact: newContact }));
  }

  function handleProductChange(newProduct: IProduct[]) {
    setFormValues((prev) => ({ ...prev, productsList: newProduct }));
  }
}
