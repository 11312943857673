import { Outlet, RouteObject } from "react-router-dom";
import ErrorPage from "../errorPage/error-page";

import Shops from "./index";
import Edit from "./edit/Edit";

export const route: RouteObject = {
  path: "/shops",
  element: <Outlet />,
  errorElement: <ErrorPage />,

  children: [
    {
      index: true,
      element: <Shops />,
    },
    {
      path: "edit/:id",
      element: <Edit />,
    },
  ],
};
