import clsx from "clsx";
import style from "./gpCalendarRowsAxis.module.scss";

export default function GPCalendarRowsAxis({
  rows,
  displayAxis = true,
  display,
}: {
  rows: { name: string; value: any }[];
  displayAxis?: boolean;
  display?: "daily" | "weekly";
}) {
  return (
    <div
      className={clsx(style["gp-calendar-rows-axis-container"], {
        [style["hide"]]: displayAxis === false,
      })}
    >
      {display === "weekly" && (
        <div className={style["gp-calendar-rows-axis-day-column-header"]} />
      )}
      <div className={style["gp-calendar-rows-axis-content"]}>
        {rows.map((row) => (
          <div key={row.value} className={style["gp-calendar-rows-axis-item"]}>
            {row.name}
          </div>
        ))}
      </div>
    </div>
  );
}
