import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from "../pages/errorPage/error-page";
import { route as professionalCartsRoute } from "../pages/professionalCart/route";
import { route as cartsRoute } from "../pages/cart/route";
import { route as loginRoute } from "../pages/login/route";
import { route as usersRoute } from "../pages/users/route";
import { route as proAccountRoute } from "../pages/proAccount/route";
import { route as crossSellingRoute } from "../pages/crossSelling/route";
import { route as operationRoute } from "../pages/operations/route";
import { route as shippingMethodRoute } from "../pages/shippingMethods/route";
import { route as estimateRoute } from "../pages/estimates/route";
import { route as logisticRoute } from "../pages/logistics/route";
import { route as quoteRoute } from "../pages/quotes/route";
import { route as shippingRoute } from "../pages/shipping/route";
import { route as shippingProRoute } from "../pages/shippingPro/route";
import { route as shopRoute } from "../pages/shops/route";
import { route as overviewRoute } from "../pages/overview/route";
import { route as discountRoute } from "../pages/discount/route";
import { route as quickAccessRoute } from "../pages/quickAccess/route";
import { route as customersRoute } from "../pages/customers/route";
import { route as paymentsRoute } from "../pages/payments/route";
import { route as invoiceProRoute } from "../pages/invoicePro/route";
import { route as referralRoute } from "../pages/referral/route";
import { route as licensesRoute } from "../pages/licenses/route";
import { route as reportsRoute } from "../pages/reports/route";
import { route as qualityControlRoute } from "../pages/qualityControl/route";
import { route as dashboardRoute } from "../pages/dashboard/route";
import { route as chronotraceRoute } from "../pages/chronotrace/route";
import { route as devToolsRoute } from "../pages/dev_tools/route";
import Home from "../pages/home";
import { ProtectedComponent } from "../components/protectedComponent/protectedComponent";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import { Layout } from "layouts/Layout";

export default function CustomRouter() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <PrivateRoute>
          <Layout />
        </PrivateRoute>
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: (
            <ProtectedComponent roles={["ROLE_ADMIN"]}>
              <Home />
            </ProtectedComponent>
          ),
          errorElement: <ErrorPage />,
        },
        professionalCartsRoute,
        dashboardRoute,
        cartsRoute,
        operationRoute,
        shippingMethodRoute,
        estimateRoute,
        quoteRoute,
        logisticRoute,
        shippingRoute,
        usersRoute,
        overviewRoute,
        discountRoute,
        quickAccessRoute,
        customersRoute,
        proAccountRoute,
        shippingProRoute,
        crossSellingRoute,
        paymentsRoute,
        devToolsRoute,
        invoiceProRoute,
        licensesRoute,
        reportsRoute,
        chronotraceRoute,
        shopRoute,
        referralRoute,
        qualityControlRoute,
      ],
    },
    loginRoute,
    {
      path: "*",
      element: <Navigate to="/" />,
    },
  ]);

  return <RouterProvider router={router}></RouterProvider>;
}
