import { useState } from "react";

export default function ModalTest() {
  const [text, setText] = useState("test");

  function handleOpenModal() {}

  //   useEffect(() => {
  //     console.
  //   }, [text])

  return (
    <div>
      {text}
      <button onClick={handleOpenModal}>OPEN MODAL</button>
      {/* <Modal
        style={{
          height: "30vh",
          width: "30vw",
          backgroundColor: "green",
        }}
      >
        <SimpleModal name={text} setText={setText} />
      </Modal> */}
    </div>
  );
}

interface SimpleModalProps {
  name: string;
  setText: (text: string) => void;
}

function SimpleModal({ name, setText }: SimpleModalProps) {
  return (
    <div>
      <input type="text" value={name} />
      <button onClick={() => setText(new Date().getTime().toString())}>
        PLOP
      </button>
    </div>
  );
}
