import SectionTitle from "components/SectionTitle/SectionTitle";
import ShippingServiceLogo from "components/ShippingServiceLogo/ShippingServiceLogo";
import { DropOffForm } from "types/logistic.types";
import styles from "./dropOffShippingSummary.module.scss";
import { addBusinessDays } from "utils/utils";
import { useSearchParams } from "react-router-dom";
import Date from "components/Date/Date";
import Timeslot from "components/Timeslot/Timeslot";

interface DropOffShippingSummaryProps {
  dropOff: DropOffForm;
}

export default function DropOffShippingSummary({
  dropOff,
}: DropOffShippingSummaryProps) {
  if (dropOff.nonDropOff) {
    return <></>;
  }

  return (
    <div>
      <SectionTitle title="Livraison" />
      <div className={styles["shipping-summary"]}>
        <ShippingServiceLogo shippingService={dropOff.shippingService.name} />
        <p className={styles["time-slot"]}>
          <Date
            date={
              dropOff.repareDays ? addBusinessDays(dropOff.repareDays) : null
            }
          />
        </p>
        <Timeslot className={styles["time-slot"]} timeSlot={dropOff.timeSlot} />
        {dropOff.repareDays && (
          <p className={styles["repare-days"]}>
            ({dropOff.repareDays} jours ouvrés)
          </p>
        )}
        {dropOff.shippingService.name !== "DROP-OR-COLLECT" && (
          <div className={styles["shipping-address"]}>
            {dropOff.shippingAddress
              ? dropOff.shippingAddress?.address1! +
                " " +
                dropOff.shippingAddress?.address2! +
                " " +
                dropOff.shippingAddress?.zipCode! +
                ", " +
                dropOff.shippingAddress?.city
              : "indisponible"}
          </div>
        )}
      </div>
    </div>
  );
}
