import { FormEvent, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import Button from "../../../components/Button/Button";
import Select from "../../../components/Select/Select";
import InputContainer from "../../../components/forms/inputs/inputContainer/inputContainer";
import Input from "../../../components/inputs/Input/Input";
import { fetchShippingList } from "../../../requests/shipping";
import { IShipping } from "../../../types/shipping.types";
import { TOAST_ERROR_OPTIONS } from "../../../utils/toast.options";
import style from "./inputCode.module.scss";

interface InputCodeProps {
  onFetched: (data: IShipping[]) => void;
  shippingService?: string;
}

export default function InputCode({
  onFetched,
  shippingService = "CHRONOPOST-2SHOP",
}: InputCodeProps) {
  const [shippingFound, setShippingFound] = useState<IShipping[]>([]);
  const [queryKey, setQueryKey] = useState<string>("contact.displayname");
  const [inputValue, setInputValue] = useState<string>("");
  const externalIdRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (shippingFound) {
      onFetched(shippingFound);
    }
  }, [shippingFound]);

  return (
    <>
      <form onSubmit={handleSubmit} className={style["input-code-container"]}>
        <div>
          <InputContainer>
            <div className={style["input-container"]}>
              <Input
                ref={externalIdRef}
                onChange={({ target }) => setInputValue(target.value)}
                type="text"
                name={"code"}
                list={"quick-access-externalId-input"}
                autoComplete="off"
                placeholder={`Entrez le ${
                  queryKey === "contact.displayname"
                    ? "nom complet"
                    : "numéro de suivi"
                }...`}
              />
              <Select
                optionsList={[
                  "contact.displayname",
                  "externalShipping.externalId",
                ]}
                value={queryKey}
                setValue={setQueryKey}
              />
            </div>
          </InputContainer>
        </div>
        <div>
          <Button type="submit">Valider</Button>
        </div>
      </form>
    </>
  );

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    try {
      const queryValue =
        queryKey === "contact.displayname"
          ? inputValue
          : extractSubstring(inputValue);
      const query = `?limit=10&${queryKey}=${queryValue}&shippingMethod.shippingService=${shippingService}&state=TRANSITING`;
      const response = await fetchShippingList(query);
      if (response) {
        setShippingFound(response.shippingsList);
        setInputValue("");
      }
    } catch (error) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    }
  }

  function extractSubstring(inputString: string) {
    const match = inputString.match(/XT(\d+)(\w+)/);
    return match ? `XT${match[1]}${match[2]}`.substring(0, 11) + "TS" : null;
  }
}
