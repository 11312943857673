import Price from "components/Price/Price";
import styles from "./quoteCrossSell.module.scss";
import { ICrossSellProduct } from "types/accounting.types";

interface QuoteCrossSellProps {
  crossSell: ICrossSellProduct;
}

export default function QuoteCrossSell({ crossSell }: QuoteCrossSellProps) {
  return (
    <li>
      <div className={styles["cross-sell-description"]}>
        <p className={styles["cross-sell"]}>{`${crossSell.description} ${
          crossSell.cartQuantity && crossSell.cartQuantity > 1
            ? `(${crossSell.cartQuantity})`
            : ""
        }`}</p>
        <div className={styles["dotted"]}></div>
        <Price
          className={styles["cross-sell-price"]}
          price={(crossSell.cartQuantity || 1) * (crossSell.price || 0)}
        />
      </div>
    </li>
  );
}
