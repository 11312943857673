import {
  DropOffForm,
  DropOffProductForm,
  IProduct,
} from "types/logistic.types";
import styles from "./productFormSection.module.scss";
import { OPERATION_GROUP, PRODUCT_GROUP } from "utils/logistic.init";
import WorkshopIdSection from "./WorkshopIdSection/WorkshopIdSection";
import ProductDescriptionSection from "./ProductDescriptionSection/ProductDescriptionSection";
import ImageSection from "./ImageSection/ImageSection";
import OperationGroupSelect from "pages/quickAccess/inStoreDropOff/ProductFormSection/OperationGroupSelect/OperationGroupSelect";
import ProductGroupSelect from "./ProductGroupSelect/ProductGroupSelect";
import clsx from "clsx";

interface ProductFormSectionProps {
  className?: string;
  dropOff: DropOffForm;
  product: DropOffProductForm | null;
  onProductChange: (product: DropOffProductForm) => void;
}

export default function ProductFormSection({
  className,
  dropOff,
  product,
  onProductChange,
}: ProductFormSectionProps) {
  if (!product || !product.selected) {
    return null;
  }

  return (
    <div className={clsx(styles["product-form-section"], className)}>
      <ProductGroupSelect
        onChange={handleProductGroupChange}
        productGroup={product.productGroup}
      />
      {PRODUCT_GROUP.includes(product.productGroup || "") && (
        <OperationGroupSelect
          onChange={handleOperationGroupChange}
          operationGroup={product.operationGroup}
          operationGroupList={OPERATION_GROUP.filter((op) =>
            op.includes(product.productGroup || "")
          )}
        />
      )}
      <WorkshopIdSection
        dropOff={dropOff}
        product={product}
        onWorkshopIdChange={handleWorkshopIdChange}
      />
      <ImageSection
        product={product}
        onProductChange={
          onProductChange as (product: DropOffProductForm | IProduct) => void
        }
      />
      <ProductDescriptionSection
        product={product}
        onProductChange={
          onProductChange as (product: DropOffProductForm | IProduct) => void
        }
      />
    </div>
  );

  function handleProductGroupChange(productGroup: string) {
    onProductChange({ ...product!, productGroup });
  }

  function handleOperationGroupChange(operationGroup: string) {
    onProductChange({ ...product!, operationGroup });
  }

  function handleWorkshopIdChange(workshopId: string | null) {
    onProductChange({ ...product!, workshopId });
  }
}
