import style from "./licenseKeysModal.module.scss";
import LinkIcon from "@mui/icons-material/Link";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toast } from "react-toastify";
import { TOAST_SUCCESS_OPTIONS } from "../../../utils/toast.options";

export default function LicenseKeysModal({
  redirections,
}: {
  redirections: { email: string; redirectionEndpoint: string }[];
}) {
  return (
    <div className={style["license-keys-modal"]}>
      <div>
        {redirections.map((redirection, index) => (
          <div
            key={`${redirection.email}-${index}`}
            className={style["license-keys-item"]}
          >
            <div>
              <div>{redirection.email}</div>
            </div>
            <div className={style["actions"]}>
              <a
                href={`https://professionel.galocheetpatin.fr${redirection.redirectionEndpoint}`}
                rel="noreferrer"
              >
                <LinkIcon />
              </a>

              <div
                className={style["copy-button"]}
                onClick={() =>
                  copyUrlToclipboard(
                    `https://professionel.galocheetpatin.fr${redirection.redirectionEndpoint}`
                  )
                }
              >
                <ContentCopyIcon />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function copyUrlToclipboard(redirectionUrl: string) {
  navigator.clipboard.writeText(redirectionUrl);
  toast.success("Lien copié dans le presse-papier", TOAST_SUCCESS_OPTIONS);
}
