import {
  AddCircleOutline,
  CheckCircleOutline,
  Done,
  DoneOutline,
  DoneOutlineTwoTone,
  FormatListBulletedOutlined,
} from "@mui/icons-material";
import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import Button from "components/Button/Button";
import GPCalendar from "components/gpCalendar";
import { GPCalendarHeaderButton } from "components/gpCalendar/gpCalendarHeader/GPCalendarHeader";
import GPCalendarItem from "components/gpCalendar/gpCalendarItem/GPCalendarItem";
import Input from "components/inputs/Input/Input";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";
import SeriesModal from "components/modals/seriesModal/SeriesModal";
import PagingComponent from "components/pagingComponent/pagingComponent";
import SearchByWorkshopIdV2 from "components/searchByWorkshopIdV2/searchByWorkshopIdV2";
import moment from "moment";
import ProductCard from "pages/quickAccess/workshop/productCard/ProductCard";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addProductToSeries,
  createSeries,
  getSeriesList,
  getUnassignedProducts,
} from "requests/series";
import { IProduct, Series } from "types/logistic.types";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "utils/toast.options";
import styles from "./seriesCalendar.module.scss";

const MIN_SERIES_LENGTH = 18;

function SeriesCalendarItem({ item, key, onClick, ...props }: any) {
  if (item?.data?.type === "EMPTY_ITEM") {
    return (
      <div
        {...props}
        onClick={() => onClick && onClick(item)}
        className={clsx(
          styles["gp-calendar-series-item"],
          styles["gp-calendar-series-item-empty"]
        )}
      />
    );
  }
  if (item?.data?.type === "ADD_PRODUCT") {
    return (
      <div
        {...props}
        onClick={() => onClick && onClick(item)}
        className={clsx(
          styles["gp-calendar-series-item"],
          styles["gp-calendar-series-item-add"]
        )}
      >
        <div
          className={
            styles["gp-calendar-series-item-add-series-length-container"]
          }
        >
          <span
            className={clsx(
              styles["gp-calendar-series-item-add-series-length"],
              (item?.data?.seriesLength || 0) < MIN_SERIES_LENGTH - 4
                ? styles["gp-calendar-series-item-add-series-length-success"]
                : styles["gp-calendar-series-item-add-series-length-error"]
            )}
          >
            {item?.data?.seriesLength || 0}
          </span>
          <span
            className={styles["gp-calendar-series-item-add-series-max-length"]}
          >
            /{MIN_SERIES_LENGTH}
          </span>
        </div>
      </div>
    );
  }

  return (
    <GPCalendarItem
      {...props}
      onClick={onClick}
      data={item}
      itemClassName={clsx(styles["gp-calendar-series-item"])}
      titleClassName={styles["gp-calendar-series-item-title"]}
      subtitleClassName={styles["gp-calendar-series-item-subtitle"]}
      itemStyle={(item: any) => {
        let style = {};
        if (item?.data?.color) {
          style = { ...style, backgroundColor: item.data.color };
        }
        return style;
      }}
    >
      <div
        className={clsx(
          styles["item-state"],
          styles[
            `item-state-${(
              item?.data?.product?.state || "1_TODO"
            ).toLowerCase()}`
          ]
        )}
      />
    </GPCalendarItem>
  );
}

function AddProductModal({
  seriesId,
  onProductAdded,
}: {
  seriesId: string;
  onProductAdded?: () => void;
}) {
  const [query, setQuery] = useState<any>({
    limit: 50,
    offset: 0,
    sort_field: "product.dueDate",
    sort_direction: "asc",
  });

  const { data, refetch } = useQuery({
    queryKey: [`unassigned-products`, query],
    queryFn: () => getUnassignedProducts(formatQuery(query)),
    placeholderData: keepPreviousData,
  });

  const addProductMutation = useMutation({
    mutationFn: addProductToSeries,
    onError: () => {
      toast.error("Une erreur est survenue.", TOAST_ERROR_OPTIONS);
    },
    onSuccess: (data) => {
      toast.success("Aricle ajouté à la série.", TOAST_SUCCESS_OPTIONS);
      onProductAdded && onProductAdded();
    },
    onSettled: () => {
      refetch();
    },
  });

  return (
    <div className={styles["series"]}>
      <div className={styles["series-content"]}>
        <SearchByWorkshopIdV2
          workshopId={query.workshopId || ""}
          setWorkshopId={(workshopId) => {
            setQuery((prev: any) => ({ ...prev, workshopId }));
          }}
        />
        <div>
          <input
            type="text"
            placeholder="Rechercher par opération..."
            onChange={({ target, ...event }) => {
              const value = target.value;
              setQuery((prev: any) => ({ ...prev, operation: value }));
            }}
          />
        </div>
        <PagingComponent
          query={query}
          setQuery={(q) => {
            setQuery(q);
          }}
          totalCount={data?.totalCount || 0}
        />
        <div className={styles["product-list"]}>
          {data &&
            data.productsList.map((p: IProduct, index: number) => {
              return (
                <div
                  key={p.id}
                >
                  <ProductCard
                    key={index}
                    product={p}
                    showOperationAcronym={true}
                    actionsComponent={(props: any) => (
                      <div
                        {...props}
                        className={clsx(
                          styles[
                            "add-product-modal-content-product-actions-container"
                          ],
                          props.className
                        )}
                      >
                        <Button
                          className={
                            styles["add-product-modal-content-add-button"]
                          }
                          onClick={() => {handleClicked(p)}}
                        >
                          <AddCircleOutline
                            className={
                              styles["add-product-modal-content-button-icon"]
                            }
                          />{" "}
                        </Button>
                      </div>
                    )}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );

  function handleClicked(product: IProduct) {
    addProductMutation.mutate({
      seriesId: seriesId,
      product,
    });
  }

  function formatQuery(query: any) {
    let formatedQuery: string = `?limit=${query.limit}&offset=${
      query.offset * query.limit
    }`;
    if (query.sort_field && query.sort_direction) {
      formatedQuery += `&sort_field=${query.sort_field}&sort_direction=${query.sort_direction}`;
    }
    if (query.workshopId) {
      formatedQuery += `&product.workshopId=${query.workshopId}`;
    }
    if (query.operation) {
      formatedQuery += `&product.operationsList.description=${query.operation}`;
    }
    return formatedQuery;
  }
}

function AddSeriesModal({
  onSeriesCreated,
  initialDate,
}: {
  initialDate: Date;
  onSeriesCreated: (series: Series) => void;
}) {
  const seriesNameRef = useRef<HTMLInputElement | null>(null);
  const seriesStartRef = useRef<HTMLInputElement | null>(null);
  const seriesEndRef = useRef<HTMLInputElement | null>(null);

  const createSeriesMutation = useMutation({
    mutationFn: createSeries,
    onError: (err) => {
      toast.error(err.message, TOAST_ERROR_OPTIONS);
    },
    onSuccess: ({ newSeries }) => {
      toast.success("Nouvelle série créée.", TOAST_SUCCESS_OPTIONS);
      if (seriesNameRef.current) {
        seriesNameRef.current.value = "";
      }
      if (seriesStartRef.current) {
        seriesStartRef.current.value = "";
      }
      if (seriesEndRef.current) {
        seriesEndRef.current.value = "";
      }
    },
    onSettled: ({ newSeries }) => {
      onSeriesCreated && onSeriesCreated(newSeries);
    },
  });

  return (
    <div className={styles["series-modal"]}>
      <div className={clsx(styles["item"], styles["series-form-container"])}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (
              !seriesNameRef.current?.value ||
              seriesNameRef.current.value.length < 3
            ) {
              toast.error(
                "Veuillez entrer un nom d'au moins 3 charactères.",
                TOAST_ERROR_OPTIONS
              );
              return;
            }
            handleCreateSeries(
              seriesNameRef.current.value,
              seriesStartRef.current?.value
                ? new Date(seriesStartRef.current.value)
                : new Date(),
              seriesEndRef.current?.value
                ? new Date(seriesEndRef.current.value)
                : new Date()
            );
          }}
        >
          <Input
            label="Du"
            type="date"
            ref={seriesStartRef}
            defaultValue={moment(initialDate).format("YYYY-MM-DD")}
          />
          <Input
            label="Au"
            type="date"
            ref={seriesEndRef}
            defaultValue={moment(initialDate).format("YYYY-MM-DD")}
          />
          <Input
            label="Nom"
            ref={seriesNameRef}
            type="text"
            placeholder="Nom de la nouvelle série..."
          />
          <Button type="submit">Nouvelle série</Button>
        </form>
      </div>
    </div>
  );

  function handleCreateSeries(
    seriesName: string,
    seriesStart: Date,
    seriesEnd: Date
  ) {
    createSeriesMutation.mutate({
      series: {
        name: seriesName,
        startDate: seriesStart,
        endDate: seriesEnd,
        productsList: [],
      } as any,
    });
  }
}

let rows = Array.from({ length: MIN_SERIES_LENGTH }, (_, i) => ({
  name: (i + 1).toString(),
  value: i + 1,
}));
rows = [{ name: "Nouveau", value: -1 }, ...rows];

export default function SeriesCalendar() {
  const [query, setQuery] = useState<any>({
    limit: 0,
    offset: 0,
    sort_field: "productsList.state",
    sort_order: "desc",
  });

  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState([new Date()]);

  const [seriesModalData, setSeriesModalData] = useState<{
    seriesId: string;
    productId: string;
  } | null>(null);
  const [addSeriesModalData, setAddSeriesModalData] = useState<{
    date: Date;
  } | null>(null);
  const [addProductModalData, setAddProductModalData] = useState<{
    seriesId: string;
  } | null>(null);

  const { data, refetch } = useQuery({
    queryKey: [`series`, query, dateRange],
    queryFn: () => getSeriesList(formatedQuery()),
    placeholderData: keepPreviousData,
    select: (d) => {
      let colorArr = ["#faaf90", "#b3c7f7", "#8babf1", "#b9e192", "#f194b8"];
      let longestSeriesLength = MIN_SERIES_LENGTH;
      let dataset = d.seriesList
        .map((series, seriesIndex) => {
          if (longestSeriesLength < series.productsList.length) {
            longestSeriesLength = series.productsList.length;
          }

          let seriesProducts = Array.from(
            { length: longestSeriesLength },
            (_, i) =>
              ({
                title: "",
                subtitle: "",
                start: new Date(series.startDate),
                end: new Date(series.endDate),
                data: {
                  type: "EMPTY_ITEM",
                  product: undefined,
                  color: colorArr[seriesIndex % colorArr.length],
                  seriesId: series.id,
                },
                rowStartValue: i + 1,
                rowEndValue: i + 1,
              } as any)
          );

          for (const [productIndex, product] of series.productsList
            .sort((a, b) => ((a as any).state === "DONE" ? 1 : -1))
            .entries()) {
            seriesProducts[productIndex] = {
              title: product.workshopId || "Inconnu",
              subtitle: series.name,
              start: new Date(series.startDate),
              end: new Date(series.endDate),
              data: {
                product,
                type: "PRODUCT_ITEM",
                color: colorArr[seriesIndex % colorArr.length],
                seriesId: series.id,
              },
              rowStartValue: productIndex + 1,
              rowEndValue: productIndex + 1,
            };
          }
          seriesProducts.push({
            title: "",
            subtitle: "",
            start: new Date(series.startDate),
            end: new Date(series.endDate),
            data: {
              type: "ADD_PRODUCT",
              product: undefined,
              color: colorArr[seriesIndex],
              seriesId: series.id,
              seriesLength: series.productsList.length,
            },
            rowStartValue: -1,
            rowEndValue: -1,
          } as any);
          return seriesProducts;
        })
        .flat();

      // dataset = [...addItems, ...dataset];
      return dataset;
    },
  });

  interface CalendarItemData {
    title: string;
    subtitle: string;
    start: Date;
    end: Date;
    data: any;
    rowStartValue: any;
    rowEndValue: any;
  }

  // const rows = [
  //   // {
  //   //   name: "Chronopost",
  //   //   value: 0,
  //   //   matchingFn: (date: Date, shippingService?: string) =>
  //   //     shippingService && shippingService.includes("CHRONO"),
  //   // },
  //   {
  //     name: "08:00",
  //     value: 1,
  //     matchingFn: (date: Date, shippingService?: string) =>
  //       (!shippingService || !shippingService.includes("CHRONO")) &&
  //       moment(moment(date).format("HH:mm"), "HH:mm").isAfter(
  //         moment("07:59", "HH:mm")
  //       ) &&
  //       moment(moment(date).format("HH:mm"), "HH:mm").isBefore(
  //         moment("10:00", "HH:mm")
  //       ),
  //   },
  //   {
  //     name: "10:00",
  //     value: 2,
  //     matchingFn: (date: Date, shippingService?: string) =>
  //       (!shippingService || !shippingService.includes("CHRONO")) &&
  //       moment(moment(date).format("HH:mm"), "HH:mm").isAfter(
  //         moment("09:59", "HH:mm")
  //       ) &&
  //       moment(moment(date).format("HH:mm"), "HH:mm").isBefore(
  //         moment("12:00", "HH:mm")
  //       ),
  //   },
  //   {
  //     name: "12:00",
  //     value: 3,
  //     matchingFn: (date: Date, shippingService?: string) =>
  //       (!shippingService || !shippingService.includes("CHRONO")) &&
  //       moment(moment(date).format("HH:mm"), "HH:mm").isAfter(
  //         moment("11:59", "HH:mm")
  //       ) &&
  //       moment(moment(date).format("HH:mm"), "HH:mm").isBefore(
  //         moment("14:00", "HH:mm")
  //       ),
  //   },
  //   {
  //     name: "14:00",
  //     value: 4,
  //     matchingFn: (date: Date, shippingService?: string) =>
  //       (!shippingService || !shippingService.includes("CHRONO")) &&
  //       moment(moment(date).format("HH:mm"), "HH:mm").isAfter(
  //         moment("13:59", "HH:mm")
  //       ) &&
  //       moment(moment(date).format("HH:mm"), "HH:mm").isBefore(
  //         moment("16:00", "HH:mm")
  //       ),
  //   },
  //   {
  //     name: "16:00",
  //     value: 5,
  //     matchingFn: (date: Date, shippingService?: string) =>
  //       (!shippingService || !shippingService.includes("CHRONO")) &&
  //       moment(moment(date).format("HH:mm"), "HH:mm").isAfter(
  //         moment("15:59", "HH:mm")
  //       ) &&
  //       moment(moment(date).format("HH:mm"), "HH:mm").isBefore(
  //         moment("18:00", "HH:mm")
  //       ),
  //   },
  //   {
  //     name: "18:00",
  //     value: 6,
  //     matchingFn: (date: Date, shippingService?: string) =>
  //       (!shippingService || !shippingService.includes("CHRONO")) &&
  //       moment(moment(date).format("HH:mm"), "HH:mm").isAfter(
  //         moment("17:59", "HH:mm")
  //       ) &&
  //       moment(moment(date).format("HH:mm"), "HH:mm").isBefore(
  //         moment("20:00", "HH:mm")
  //       ),
  //   },
  //   // { name: "09:00", value: 9 },
  //   // { name: "10:00", value: 10 },
  //   // { name: "11:00", value: 11 },
  //   // { name: "12:00", value: 12 },
  //   // { name: "13:00", value: 13 },
  //   // { name: "14:00", value: 14 },
  //   // { name: "15:00", value: 15 },
  //   // { name: "16:00", value: 16 },
  //   // { name: "17:00", value: 17 },
  //   // { name: "18:00", value: 18 },
  //   // { name: "19:00", value: 19 },
  //   // { name: "20:00", value: 20 },
  // ];

  // const { data, isLoading } = useQuery({
  //   queryKey: ["shippingCalendarData", dateRange],
  //   queryFn: () =>
  //     fetchShippingList(
  //       `?period.start=${(
  //         dateRange.at(0) || new Date()
  //       ).toISOString()}&period.start=${(
  //         dateRange.at(-1) || new Date()
  //       ).toISOString()}`
  //     ),
  //   refetchOnWindowFocus: true,
  //   placeholderData: keepPreviousData,
  //   select: (data): CalendarItemData[] => {
  //     let plop = (data?.shippingsList || []).map(
  //       (shipping): CalendarItemData => {
  //         let startDate = new Date(shipping.period?.start || new Date());
  //         let endDate = new Date(shipping.period?.end || new Date());
  //         let d = {
  //           title: shipping.contact?.displayname || "Inconnu",
  //           subtitle:
  //             moment(startDate).format("HH:mm") +
  //             " - " +
  //             moment(endDate).format("HH:mm"),
  //           start: startDate,
  //           end: endDate,
  //           data: {
  //             direction: shipping.direction || "UNKNOWN",
  //             shippingService: shipping.shippingMethod?.shippingService || "UNKNOWN"
  //           },
  //           rowStartValue:
  //             rows.find((r) =>
  //               r.matchingFn(
  //                 startDate,
  //                 shipping.shippingMethod?.shippingService
  //               )
  //             )?.value || 0,
  //           rowEndValue:
  //             rows.find((r) =>
  //               r.matchingFn(endDate, shipping.shippingMethod?.shippingService)
  //             )?.value || 0,
  //         };
  //         return d;
  //       }
  //     );

  //     return plop.filter(p => p.rowEndValue !== 0);
  //   },
  // });

  return (
    <div className={styles["series"]}>
      {/* <SeriesNav /> */}
      <div className={styles["series-content"]}>
        <GPCalendar
          initialDate={dateRange.at(0) || new Date()}
          initialDisplay="weekly"
          data={data || []}
          onDaysChange={handleDaysChange}
          onItemClick={handleItemClick}
          displayAxis={false}
          calendarItemComponent={({ key, ...props }: any) => (
            <SeriesCalendarItem {...props} />
          )}
          additionalButtons={[
            () => (
              <GPCalendarHeaderButton
                onClick={() => {
                  setAddSeriesModalData({ date: new Date() });
                }}
                icon={(props) => <AddCircleOutline {...props} />}
              />
            ),
            () => (
              <GPCalendarHeaderButton
                onClick={() => {
                  navigate && navigate("/quick-access/workshop/cobbler/series");
                }}
                icon={(props) => <FormatListBulletedOutlined {...props} />}
              />
            ),
          ]}
          // calendarItemClassName={style["gp-calendar-series-item"]}
          // calendarItemTitleClassName={style["gp-calendar-series-item-title"]}
          // calendarItemSubtitleClassName={
          //   style["gp-calendar-series-item-subtitle"]
          // }
          // calendarItemStyle={
          //   (data: any) =>
          //     data?.data?.color ? { backgroundColor: data.data.color } : {}

          //   // {
          //   //   let backgroundColor = "#0066cc";
          //   //   if (data.data.direction === "INCOMING") {
          //   //     backgroundColor = "#4cb263";
          //   //     if (data.data.shippingService.includes("DROP")) {
          //   //       backgroundColor = "#43694c";
          //   //     }
          //   //   }
          //   //   if (data.data.direction === "OUTGOING") {
          //   //     backgroundColor = "#0066cc";
          //   //     if (data.data.shippingService.includes("DROP")) {
          //   //       backgroundColor = "#3b4f63";
          //   //     }
          //   //   }

          //   //   return { backgroundColor: backgroundColor, color: "white"}

          //   // }

          // }
          rows={rows}
        />
      </div>

      <ModalContainer isOpen={!!seriesModalData} onCancel={handleModalCancel}>
        {seriesModalData && (
          <SeriesModal
            seriesId={seriesModalData.seriesId}
            initialProductId={seriesModalData.productId}
            onProductRemoved={() => refetch()}
            onSeriesUpdated={() => refetch()}
            onSeriesDeleted={() => {
              refetch();
              setSeriesModalData(null);
            }}
          />
        )}
      </ModalContainer>
      <ModalContainer
        isOpen={!!addSeriesModalData}
        onCancel={handleModalCancel}
      >
        {addSeriesModalData && (
          <AddSeriesModal
            initialDate={addSeriesModalData.date}
            onSeriesCreated={(series) => {
              setAddSeriesModalData(null);
              setAddProductModalData({ seriesId: series.id });
              refetch();
            }}
          />
        )}
      </ModalContainer>
      <ModalContainer
        isOpen={!!addProductModalData}
        onCancel={handleModalCancel}
      >
        {addProductModalData && (
          <AddProductModal
            seriesId={addProductModalData.seriesId}
            onProductAdded={() => refetch()}
          />
        )}
      </ModalContainer>
    </div>
  );

  function handleItemClick(item: any) {
    switch (item.data?.type) {
      case "PRODUCT_ITEM":
        setSeriesModalData({
          seriesId: item.data.seriesId,
          productId: item.data.product.id,
        });
        break;
      case "EMPTY_ITEM":
        break;
      case "ADD_PRODUCT":
        setAddProductModalData({ seriesId: item.data.seriesId });
        // setAddSeriesModalData({ date: item.start });
        break;
      default:
        break;
    }
  }

  function handleModalCancel() {
    setSeriesModalData(null);
    setAddSeriesModalData(null);
    setAddProductModalData(null);
  }

  function handleDaysChange(days: Date[]) {
    setDateRange(days);
  }

  function formatedQuery() {
    let formatedQuery: string = `?limit=${query.limit}&offset=${
      query.offset * query.limit
    }`;
    if (query.sort_field && query.sort_order) {
      formatedQuery += `&sort_field=${query.sort_field}&sort_direction=${query.sort_order}`;
    }
    if (query.workshopId) {
      formatedQuery += `&productsList.workshopId=${query.workshopId}`;
    }
    if (dateRange) {
      let start = dateRange.at(0);
      let end = dateRange.at(-1);
      if (start && end) {
        formatedQuery += `&startDate=${moment(start).format(
          "YYYY-MM-DD"
        )},${moment(end).format("YYYY-MM-DD")}`;
      }
    }
    return formatedQuery;
  }
}
