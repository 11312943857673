import { Outlet, RouteObject } from "react-router-dom";
import ErrorPage from "../errorPage/error-page";
import Create from "./create/create";
import Edit from "./edit/edit";
import Users from "./index";

export const route: RouteObject = {
  path: "/users",
  element: <Outlet />,
  errorElement: <ErrorPage />,

  children: [
    {
      index: true,
      element: <Users />,
    },
    {
      path: "create",
      element: <Create />,
    },
    {
      path: "edit/:id",
      element: <Edit />,
    },
  ],
};
