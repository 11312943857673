import { IMoreToolsOptions } from "../MoreTools";
import OptionItem from "./OptionItem/OptionItem";
import styles from "./optionList.module.scss";

interface OptionsListProps {
  options: IMoreToolsOptions[];
  onOptionClick: (option: IMoreToolsOptions) => void;
}

export default function OptionsList({
  options,
  onOptionClick,
}: OptionsListProps) {
  return (
    <div className={styles["option-list"]}>
      {options.map((option, index: number) => {
        return (
          <OptionItem
            key={index}
            option={option}
            onClickOnOption={() => onOptionClick(option)}
          />
        );
      })}
    </div>
  );
}
