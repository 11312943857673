import { Check } from "@mui/icons-material";
import { useMutation } from "@tanstack/react-query";
import Input from "components/inputs/Input/Input";
import PictureLoadingSpinner from "components/loadingSpinner/pictureloadingSpinner";
import SectionTitle from "components/SectionTitle/SectionTitle";
import { useDebounce } from "hooks/useDebounce";
import { useEffect, useState } from "react";
import { checkIfWorkshopIdExists } from "requests/logistic";
import { DropOffForm, DropOffProductForm } from "types/logistic.types";
import { isNumericString } from "utils/utils";
import styles from "./workshopIdSection.module.scss";

interface WorkshopIdSectionProps {
  dropOff: DropOffForm;
  product: DropOffProductForm | null;
  onWorkshopIdChange: (workshopId: string | null) => void;
}

export default function WorkshopIdSection({
  dropOff,
  product,
  onWorkshopIdChange,
}: WorkshopIdSectionProps) {
  const [workshopId, setWorkshopId] = useState<string>(
    product?.workshopId || ""
  );
  const [workshopIdIsValid, setWorkshopIdIsValid] = useState<boolean>(false);
  const mutation = useMutation({
    mutationFn: checkIfWorkshopIdExists,
    onSuccess: (data) => handleWorkshopIdExists(data),
    onError: () => setWorkshopIdIsValid(false),
  });
  useDebounce(() => !!workshopId && mutation.mutate(workshopId), [workshopId]);

  useEffect(() => {
    setWorkshopId(product?.workshopId || "");
  }, [product?.id]);

  if (!product || !product.operationGroup || !product.productGroup) {
    return null;
  }

  return (
    <>
      <SectionTitle
        title={"Ticket atelier"}
        className={styles["section-title"]}
      />
      <div className={styles["workshop-id-section"]}>
        <Input
          onChange={handleOnChange}
          onFocus={AddPrefixTicket}
          value={workshopId}
          placeholder={"1-1-XXX"}
          inputMode="numeric"
        />
        <div className={styles["workshop-exists"]}>
          {mutation.isPending ? (
            <PictureLoadingSpinner className={styles["loading-spinner"]} />
          ) : (
            <>
              {workshopIdIsValid ? (
                <Check className={styles["valid"]} />
              ) : (
                <p className={styles["error-message"]}>
                  {workshopId && workshopId.length === 7
                    ? "ce numéro de ticket est incorrect ou déjà utilisé."
                    : "1-1-XXX"}
                </p>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );

  function handleOnChange({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    setWorkshopIdIsValid(false);
    setWorkshopId(target.value);
  }

  function AddPrefixTicket({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    if (!target.value.startsWith("1-1-") && !target.value) {
      setWorkshopId("1-1-" + target.value);
    }
  }

  function handleWorkshopIdExists(exists: boolean) {
    if (workshopId.length === 7 && isNumericString(workshopId.substring(4))) {
      setWorkshopIdIsValid(!exists);
      if (!exists) {
        onWorkshopIdChange(workshopId);
      } else {
        if (product?.workshopId) {
          return;
        }
        onWorkshopIdChange(null);
      }
    } else {
      onWorkshopIdChange(null);
    }
  }
}
