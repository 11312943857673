import { useEffect, useState } from "react";
import OperationDateCard from "../OperationDateCard/OperationDateCard";
import OperationTypeCard from "../OperationTypeCard/OperationTypeCard";

export function OperationGroups({
  workshopId,
  showPro,
}: {
  workshopId?: string;
  showPro?: boolean;
}) {
  return (
    <>
      <OperationTypeCard
        operationType="SHOES_PATAFER"
        title="Patin, Talon, Fer"
        workshopId={workshopId}
        showPro={showPro}
      />
      <OperationTypeCard
        operationType="SHOES_RESSEMELAGE"
        title="Ressemellage"
        workshopId={workshopId}
        showPro={showPro}
      />
      <OperationTypeCard
        operationType="SHOES_ENTRETIEN"
        title="Soins"
        workshopId={workshopId}
        showPro={showPro}
      />
      <OperationTypeCard
        operationType="SHOES_MISE_EN_FORME"
        title="Mise en forme"
        workshopId={workshopId}
        showPro={showPro}
      />
      <OperationTypeCard
        operationType="SHOES_TEINTURE"
        title="Teinture"
        workshopId={workshopId}
        showPro={showPro}
      />
      <OperationTypeCard
        operationType="SHOES_COLLAGE_COUTURE"
        title="Collage / Couture"
        workshopId={workshopId}
        showPro={showPro}
      />
      <OperationTypeCard
        operationType="SHOES_AUTRE"
        title="Autres"
        workshopId={workshopId}
        showPro={showPro}
      />
    </>
  );
}

export function DateGroups({
  workshopId,
  showPro,
}: {
  workshopId?: string;
  showPro?: boolean;
}) {
  const [date, setDate] = useState(new Date());

  const [dates, setDates] = useState<Date[]>([]);

  function getDay(date: Date, additionalDays: number): string {
    let newDate = new Date();

    newDate.setDate(date.getDate() + additionalDays);
    return newDate.toISOString().substring(0, 10);
  }

  useEffect(() => {
      let d : Date[]= []
      for (let i = 0; i < 15; i++) {
      let newDate = new Date();
      newDate.setDate(date.getDate() + i);
      if (newDate.getDay() !== 0 && newDate.getDay() !== 6) {
        d.push(newDate)
      }
    }
    setDates(d);
  }, []);

  return (
    <>
      <OperationDateCard
        from={getDay(date, -1)}
        to={getDay(date, -1)}
        title="J-1"
        workshopId={workshopId}
        showPro={showPro}
      />
      {dates.map((date, index) => {
        return (
          <OperationDateCard
            from={date.toISOString().substring(0, 10)}
            to={date.toISOString().substring(0, 10)}
            title={`J+${index}`}
            workshopId={workshopId}
            showPro={showPro}
          />
        );
      })}

      <OperationDateCard
        from={getDay(date, -365)}
        to={getDay(date, -2)}
        title="Retards"
        workshopId={workshopId}
        showPro={showPro}
      />
    </>
  );
}
