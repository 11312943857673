import { Delete } from "@mui/icons-material";
import MoreTools, {
  IMoreToolsOptions,
} from "../../../components/MoreTools/MoreTools";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";
import { IInvoicePro } from "../../../types/invoicePro.types";
import styles from "./item.module.scss";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import InvoiceProModal from "../../../components/modals/invoiceProModal/invoiceProModal";
import CenterCell from "../../../components/CenterCell/CenterCell";
import State from "../../../components/State/State";
import clsx from "clsx";
import Price from "../../../components/Price/Price";
import { useState } from "react";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";

export default function Item({
  invoice,
  onDelete,
  handleGenerate,
  handleFinalize,
  handleToPayed,
  index,
}: {
  invoice: IInvoicePro;
  onDelete: (invoice?: IInvoicePro) => void;
  handleGenerate: (id?: string) => void;
  handleFinalize: (id?: string) => void;
  handleToPayed: (invoice?: IInvoicePro) => void;
  index: number;
}) {
  const [openModal, setOpenModal] = useState<boolean>(false);
  let MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [];

  let invoiceProPennylane = "QUOTE_TO_GENERATE";
  if (invoice.publicUrl && invoice.isDraft === true) {
    invoiceProPennylane = "QUOTE_DRAFT";
  }
  if (invoice.publicUrl && invoice.isDraft === false) {
    invoiceProPennylane = "QUOTE_VALIDATED";
  }
  MORE_TOOLS_OPTIONS = getToolList(
    MORE_TOOLS_OPTIONS,
    invoice,
    invoiceProPennylane
  );

  return (
    <>
      <tr className={styles["item"]}>
        <td className={clsx(styles["item-cell"], styles["status-box"])}>
          <CenterCell>
            <State state={invoiceProPennylane} />
          </CenterCell>
        </td>
        <td className={clsx(styles["item-cell"], styles["item-coord"])}>
          <div className={styles["coordinates"]}>
            {invoice.contact?.organization}
            <br />
            {invoice.contact?.email}
          </div>
        </td>
        <td
          className={`${styles["item-cell"]} ${styles["item-date"]}`}
          title={`${invoice.date && new Date(invoice.date).toLocaleString()}`}
        >
          {invoice.date
            ? new Date(invoice.date).toLocaleString("fr-FR").substring(0, 10)
            : "indisponible"}
        </td>
        <td className={styles["item-cell"]}>
          <span>{invoice.quotesIds.length}</span>
        </td>
        <td className={styles["item-cell"]}>
          <Price price={parseInt(((invoice.total || 0) / 1.2).toFixed(2))} />
        </td>
        <td className={styles["item-cell"]}>
          <Price price={invoice.total} />
        </td>
        <td
          className={clsx(styles["item-cell"], styles["status-box"])}
          onClick={handleOpenModal}
        >
          <CenterCell>
            <State state={invoice.state} />
          </CenterCell>
        </td>
        <td className={`${styles["item-cell"]} ${styles["tools"]}`}>
          <ProtectedComponent roles={["ROLE_ADMIN"]}>
            <CenterCell>
              <MoreTools options={MORE_TOOLS_OPTIONS} />
            </CenterCell>
          </ProtectedComponent>
        </td>
      </tr>
      <ModalContainer isOpen={openModal} onCancel={handleCloseModal}>
        <InvoiceProModal invoiceId={invoice.id!} shopId={invoice.shopId!} />
      </ModalContainer>
    </>
  );

  function handleOpenModal() {
    if (!invoice.id || !invoice.shopId) {
      return;
    }
    setOpenModal(true);
  }

  function handleCloseModal() {
    setOpenModal(false);
  }

  function handleBill(url?: string) {
    if (url) {
      window.open(url, "_blank");
    }
  }

  function getToolList(
    TOOLS_LIST: IMoreToolsOptions[],
    invoice: IInvoicePro,
    invoiceProPennylane: string
  ) {
    if (invoiceProPennylane == "QUOTE_TO_GENERATE") {
      TOOLS_LIST.push({
        onClick: () => handleGenerate(invoice.id),
        icon: <AddCircleOutlineIcon />,
        name: "Générer la facture",
      });
    }
    if (invoiceProPennylane == "QUOTE_DRAFT") {
      TOOLS_LIST.push({
        onClick: () => handleFinalize(invoice.id),
        icon: <ArrowCircleUpIcon />,
        name: "Finaliser la facture",
      });
      TOOLS_LIST.push({
        onClick: () => handleBill(invoice.fileUrl),
        icon: <ArrowCircleDownIcon />,
        name: "Télécharger la facture",
      });
    }
    if (invoiceProPennylane == "QUOTE_VALIDATED") {
      TOOLS_LIST.push({
        onClick: () => handleBill(invoice.fileUrl),
        icon: <ArrowCircleDownIcon />,
        name: "Télécharger la facture",
      });
    }
    if (
      invoice.state === "TO_BE_PAYED" &&
      invoiceProPennylane == "QUOTE_VALIDATED"
    ) {
      TOOLS_LIST.push({
        onClick: () => handleToPayed(invoice),
        icon: <CheckCircleOutlineIcon />,
        name: "Marquer comme payée",
      });
    }
    TOOLS_LIST.push({
      onClick: () => onDelete(invoice),
      icon: <Delete />,
      name: "Supprimer",
      askConfirmation: true,
    });
    return TOOLS_LIST;
  }
}
