import clsx from "clsx";
import styles from "./imageCarrousel.module.scss";
import Image from "components/image/Image";

interface ImageCarrouselProps {
  isBlob?: boolean;
  pictureIndex: number;
  pictureList: string[];
  onPictureSelect: (index: number) => void;
  hide: boolean;
}

export default function ImageCarrousel({
  isBlob,
  pictureIndex,
  pictureList,
  hide,
  onPictureSelect,
}: ImageCarrouselProps) {
  return (
    <div
      className={clsx(
        styles["image-carrousel-container"],
        hide && styles["hide"]
      )}
    >
      <div className={styles["image-carrousel"]}>
        {pictureList.map((uri, index) => {
          return (
            <Image
              key={index}
              className={clsx(
                styles["image-item"],
                pictureIndex === index && styles["selected"]
              )}
              isBlob={isBlob}
              onClick={() => onPictureSelect(index)}
              src={uri}
              showTools={false}
            />
          );
        })}
      </div>
    </div>
  );
}
