import style from "./photoControl.module.scss";
import ImagesForm from "../../../components/forms/imagesForm/imagesForm";
import { Check } from "@mui/icons-material";
import { IProduct } from "../../../types/logistic.types";
import Button from "../../../components/Button/Button";

interface PhotoControlProps {
  validateControl: () => void;
  product: IProduct;
  fileList: File[];
  setFileList: (fileList: File[]) => void;
}

export default function PhotoControl({
  product,
  validateControl,
  fileList,
  setFileList,
}: PhotoControlProps) {
  return (
    <div className={style["expandable-container"]}>
      Veuillez prendre au moins une photo
      <div className={style["uploaded-images"]}>
        <ImagesForm
          imageList={product.outgoingPicturesList ?? []}
          fileList={fileList}
          setFileList={setFileList}
          className={style["image-form-quality"]}
        />
      </div>
      <div className={style["button-container"]}>
        <Button
          disabled={
            product.outgoingPicturesList?.length === 0 && fileList.length === 0
          }
          onClick={validateControl}
          className={
            product.outgoingPicturesList?.length || fileList.length
              ? style["validate"]
              : style["disabled"]
          }
        >
          <Check />
          Valider
        </Button>
      </div>
    </div>
  );
}
