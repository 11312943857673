import React, { ReactElement } from "react";
import style from "./gpCalendarDayColumn.module.scss";
import clsx from "clsx";
import moment from "moment";
import GPCalendarItem from "../gpCalendarItem/GPCalendarItem";

export default function GPCalendarDayColumn({
  day,
  rows,
  display,
  dataset,
  calendarItemComponent,
  onHeaderClicked,
  calendarItemClassName,
  calendarItemStyle,
  calendarItemTitleClassName,
  calendarItemSubtitleClassName,
  onItemClick,
}: {
  day: Date;
  rows: { name: string; value: any }[];
  display: "daily" | "weekly";
  dataset: any[];
  calendarItemComponent?: (props: any) => ReactElement<any>;
  onHeaderClicked?: () => void;
  calendarItemClassName?: string;
  calendarItemStyle?: (data: any) => React.CSSProperties;
  calendarItemTitleClassName?: string;
  calendarItemSubtitleClassName?: string;
  onItemClick?: (data: any) => void;
}) {
  const DAYS = ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."];

  return (
    <div
      className={clsx(style["gp-calendar-day-column-container"], {
        [style["gp-calendar-day-row-weekly"]]: display === "weekly",
      })}
    >
      {display === "weekly" && (
        <div
          onClick={onHeaderClicked}
          className={clsx(style["gp-calendar-day-column-header"], {
            [style["gp-calendar-day-column-header-today"]]: moment().isSame(
              day,
              "day"
            ),
          })}
        >
          <div className={clsx(style["gp-calendar-day-column-header-day"])}>
            {DAYS[day.getDay()]}
          </div>
          <div className={style["gp-calendar-day-column-header-date"]}>
            {day.getDate()}
          </div>
        </div>
      )}
      <div className={clsx(style["gp-calendar-day-column-content"])}>
        {rows.map((row, index) => (
          <div
            key={`${row.name}-${row.value}-${index}-${day
              .getDate()
              .toString()}-${row.value.toString()}}`}
            id={`${row.name}-${row.value}-${index}-${day
              .getDate()
              .toString()}-${row.value.toString()}}`}
            className={clsx(style["gp-calendar-day-row"], style["skeleton"])}
          >
            {dataset
              .filter(
                (data) =>
                  data.start.getDate() <= day.getDate() &&
                  data.end.getDate() >= day.getDate() &&
                  data.rowStartValue === row.value
              )
              .map((d, i) =>
                calendarItemComponent ? (
                  <React.Fragment
                    key={`${row.name}-${row.value}-${index}-${day
                      .getDate()
                      .toString()}-${row.value.toString()}}-${
                      d.title
                    }-${d.start.getTime()}-${i}`}
                  >
                    {calendarItemComponent({
                      item: d,
                      onClick: onItemClick,
                    })}
                  </React.Fragment>
                ) : (
                  <GPCalendarItem
                    key={`${row.name}-${row.value}-${index}-${day
                      .getDate()
                      .toString()}-${row.value.toString()}}-${
                      d.title
                    }-${d.start.getTime()}-${i}`}
                    data={d}
                    itemClassName={calendarItemClassName}
                    itemStyle={calendarItemStyle}
                    titleClassName={calendarItemTitleClassName}
                    subtitleClassName={calendarItemSubtitleClassName}
                    onClick={onItemClick}
                  />
                )
              )}
          </div>
        ))}
      </div>
    </div>
  );
}
