import { useState } from "react";
import { IProduct } from "../../types/logistic.types";
import Button from "../Button/Button";
import styles from "./printLabel.module.scss";
import PrintProductLabelModal from "../modals/printProductLabelModal/PrintProductLabelModal";
import { PrintOutlined } from "@mui/icons-material";
import { ModalContainer } from "../modals/ModalContainer/ModalContainer";
import clsx from "clsx";

interface PrintLabelButtonProps {
  className?: string;
  product: IProduct;
}

export default function PrintLabelButton({
  className,
  product,
}: PrintLabelButtonProps) {
  const [printLabelModal, setPrintLabelModal] = useState<boolean>(false);
  return (
    <>
      <Button
        className={clsx(styles["print-label-button"], className)}
        onClick={handleOpenPrintLabelModal}
      >
        <PrintOutlined className={styles["icon"]} />
      </Button>
      <ModalContainer
        isOpen={printLabelModal}
        onCancel={handleClosePrintLabelModal}
      >
        <PrintProductLabelModal
          product={product}
          comments={
            product?.dueDate
              ? "à faire avant le: " +
                new Date(product.dueDate).toLocaleDateString("fr-FR")
              : ""
          }
        />
      </ModalContainer>
    </>
  );

  function handleClosePrintLabelModal() {
    setPrintLabelModal(false);
  }

  function handleOpenPrintLabelModal() {
    setPrintLabelModal(!printLabelModal);
  }
}
