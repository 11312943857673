import { Outlet, RouteObject } from "react-router-dom";
import ErrorPage from "../errorPage/error-page";
import Estimates from ".";
import Create from "./create/create";
import Edit from "./edit/edit";

export const route: RouteObject = {
  path: "/estimate",
  element: <Outlet />,

  errorElement: <ErrorPage />,
  children: [
    {
      index: true,
      element: <Estimates />,
    },
    {
      path: "create",
      element: <Create />,
    },
    {
      path: "edit/:id",
      element: <Edit />,
    },
  ],
};
