import Icon from "components/Icon/Icon";
import estimateIcon from "assets/icons/check.png";

interface EstimateIconProps {
  className?: string;
}

export default function EstimateIcon({ className }: EstimateIconProps) {
  return <Icon logo={estimateIcon} className={className} />;
}
