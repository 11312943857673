import { useEffect, useRef } from "react";
import {
  LABEL_HEIGHT,
  LABEL_PIXEL_PER_MM,
  LABEL_PREVIEW_SCALE,
  LABEL_WIDTH,
} from "../../../utils/constants";

const canvasWidth = LABEL_PIXEL_PER_MM * LABEL_WIDTH * LABEL_PREVIEW_SCALE;
const canvasHeight = LABEL_PIXEL_PER_MM * LABEL_HEIGHT * LABEL_PREVIEW_SCALE;

export function useDetailedLabelPreview(
  strings: string[],
  leftFooter: string,
  rightFooter: string
) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    // Canvas settings
    const padding = 15 * LABEL_PREVIEW_SCALE; // Padding around the content
    const gap = 10 * LABEL_PREVIEW_SCALE; // Gap between strings
    const footerHeight = 50 * LABEL_PREVIEW_SCALE; // Footer height reserved at the bottom
    const lineHeight = 35 * LABEL_PREVIEW_SCALE; // Line height for text
    const fontSize = 30 * LABEL_PREVIEW_SCALE; // Font size
    const contentWidth = canvasWidth - 2 * padding; // Content width minus padding
    const contentHeight = canvasHeight - 2 * padding - footerHeight; // Content height minus padding and footer

    // Set canvas dimensions
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    // Draw background
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Canvas text settings
    ctx.font = `${fontSize}px Arial`;
    ctx.fillStyle = "black";

    let x = padding; // Starting x-position
    let y = padding + lineHeight; // Starting y-position

    // Function to write text with wrapping
    const writeText = (text: string) => {
      const words = text.split(" ");
      let currentLine = "";

      words.forEach((word) => {
        const testLine = currentLine ? `${currentLine} ${word}` : word;
        const testLineWidth = ctx.measureText(testLine).width;

        // If line exceeds content width, write current line and move to the next
        if (x + testLineWidth > padding + contentWidth) {
          ctx.fillText(currentLine, x, y);
          currentLine = word;
          y += lineHeight;

          // Stop writing if we exceed the content height (leave room for the footer)
          if (y + lineHeight > padding + contentHeight) {
            return; // Stop writing when content height is reached
          }
        } else {
          currentLine = testLine;
        }
      });

      // Write the remaining text
      if (currentLine) {
        ctx.fillText(currentLine, x, y);
        y += lineHeight;
      }
    };

    // Loop through strings and write each with wrapping
    strings.forEach((str) => {
      writeText(`- ${str}`); // Add hyphen in front of each string
      y += gap; // Add gap between strings

      // Stop writing if we exceed the content height (leave room for the footer)
      if (y + lineHeight > padding + contentHeight) {
        return; // Stop writing when content height is reached
      }
    });

    // Footer: Draw footerLeft on the bottom-left and footerRight on the bottom-right
    ctx.font = `${20 * LABEL_PREVIEW_SCALE}px Arial`;
    const footerY = canvasHeight - padding;
    ctx.fillText(leftFooter, padding, footerY); // Footer left-aligned
    const footerRightWidth = ctx.measureText(rightFooter).width;
    ctx.fillText(
      rightFooter,
      canvasWidth - padding - footerRightWidth,
      footerY
    ); // Footer right-aligned
  }, [strings, leftFooter, rightFooter, canvasHeight, canvasWidth]);

  return canvasRef;
}

interface CanvasSize {
  width: number; // Canvas width in pixels
  height: number; // Canvas height in pixels
}

export function splitStringsToFitCanvas(
  strings: string[], // Array of strings to fit into the canvas
  canvasSize: CanvasSize, // Dimensions of the canvas (width, height)
  fontSize: number = 30, // Default font size (optional)
  lineHeight: number = 35, // Default line height (optional)
  padding: number = 10, // Padding inside the canvas (optional)
  gapBetweenStrings: number = 10 // Gap between individual strings (optional)
): string[][] {
  const ctx = document.createElement("canvas").getContext("2d");
  if (!ctx) throw new Error("Could not get canvas context");

  // Set the font style for measurement
  ctx.font = `${fontSize}px Arial`;

  const contentWidth = canvasSize.width - 2 * padding; // Content width minus padding
  const contentHeight = canvasSize.height - 2 * padding; // Content height minus padding

  let subArray: string[] = []; // To collect strings for each split
  const result: string[][] = []; // To store the result of string arrays that fit

  let currentHeight = 0; // Track the current height used in the canvas

  // Function to measure if a single line of text fits in the current canvas width
  const measureTextLine = (text: string): number => ctx.measureText(text).width;

  // Function to write a string with wrapping and return the number of lines it takes
  const getTextHeight = (text: string): number => {
    const words = text.split(" ");
    let currentLine = "";
    let numberOfLines = 1; // At least one line for the string

    words.forEach((word) => {
      const testLine = currentLine ? `${currentLine} ${word}` : word;
      const testLineWidth = measureTextLine(testLine);

      // If line exceeds content width, count as a new line
      if (testLineWidth > contentWidth) {
        currentLine = word; // Reset to new line with the current word
        numberOfLines++; // Increment the line count
      } else {
        currentLine = testLine;
      }
    });

    return numberOfLines * lineHeight; // Total height for the wrapped text
  };

  // Iterate through the input strings and split them based on the canvas height constraint
  for (let i = 0; i < strings.length; i++) {
    const str = `- ${strings[i]}`; // Add hyphen to each string as requested

    // Calculate the total height that would be taken by this string (with wrapping)
    const textHeight = getTextHeight(str);

    // If this string can fit within the remaining height
    if (currentHeight + textHeight <= contentHeight) {
      subArray.push(strings[i]); // Add to the current sub-array
      currentHeight += textHeight + gapBetweenStrings; // Update current height
    } else {
      // If the current string does not fit, finalize the current subArray
      result.push([...subArray]); // Push the current subArray to the result
      subArray = [strings[i]]; // Start a new subArray with the current string
      currentHeight = textHeight + gapBetweenStrings; // Reset current height to this string's height
    }
  }

  // Push the remaining subArray to the result if there are strings left
  if (subArray.length > 0) {
    result.push(subArray);
  }

  return result; // Return the array of string arrays that fit within the canvas
}
