import { Outlet, RouteObject } from "react-router-dom";
import ErrorPage from "../errorPage/error-page";
import Operations from ".";
import Create from "./create/create";
import Edit from "./edit/edit";

export const route: RouteObject = {
  path: "/operation-b2c",
  element: <Outlet />,
  errorElement: <ErrorPage />,

  children: [
    {
      path: "/operation-b2c",
      element: <Operations />,
    },
    {
      path: "create",
      element: <Create />,
    },
    {
      path: "edit/:operationId",
      element: <Edit />,
    },
  ],
};
