import ConcurrentTasksTemplate from "components/concurrentTasksTemplate/ConcurrentTasksTemplate";
import styles from "./createLogisticFooter.module.scss";
import CheckboxItem from "components/checkboxItem/CheckboxItem";
import { useState } from "react";
import Button from "components/Button/Button";
import { ILogistic, LogisticForm } from "types/logistic.types";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { addLogisticMutation, updateLogisticMutation } from "requests/logistic";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "utils/toast.options";
import { streamFile } from "requests/file";
import LogisticConfirmModal from "./LogisticConfirmModal/LogisticConfirmModal";

interface CreateLogisticFooterProps {
  defaultLogistic?: ILogistic;
  formValues: LogisticForm;
  onFormValuesChange: (formValues: LogisticForm) => void;
}

export default function CreateLogisticFooter({
  defaultLogistic,
  formValues,
  onFormValuesChange,
}: CreateLogisticFooterProps) {
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [updateQuote, setUpdateQuote] = useState<boolean>(true);

  const addLogistic = useMutation({
    mutationFn: addLogisticMutation,
    onSuccess: () => {
      toast.success(
        `Le document logistique à été créer.`,
        TOAST_SUCCESS_OPTIONS
      );
    },
    onError: () => {
      toast.error(`Un problème est survenu`, TOAST_ERROR_OPTIONS);
    },
    onSettled: handleOnSettled,
  });

  const updateLogistic = useMutation({
    mutationFn: updateLogisticMutation,
    onSuccess: () => {
      toast.success(
        `Le document logistique à été mis à jour.`,
        TOAST_SUCCESS_OPTIONS
      );
    },
    onError: () => {
      toast.error(`Un problème est survenu`, TOAST_ERROR_OPTIONS);
    },
    onSettled: handleOnSettled,
  });

  return (
    <>
      <ConcurrentTasksTemplate>
        <CheckboxItem
          checked={updateQuote}
          onChecked={setUpdateQuote}
          title="Mettre à jour le devis associé"
        />
      </ConcurrentTasksTemplate>
      <div className={styles["footer"]}>
        <Button onClick={handleOpenConfirmModal} isLoading={isLoading}>
          {defaultLogistic ? "Sauvegarder" : "Créer Nouveau"}
        </Button>
      </div>
      <ModalContainer
        width="fit"
        height="fit"
        isOpen={openConfirmModal}
        onCancel={handleCloseConfirmModal}
      >
        <LogisticConfirmModal
          body={confirmationBody()}
          isLoading={isLoading}
          onValidate={handleSubmit}
          onCancel={handleCloseConfirmModal}
        />
      </ModalContainer>
    </>
  );

  function confirmationBody() {
    return updateQuote ? (
      <ul className={styles["modal-body"]}>
        Ceci entrainera les actions suivantes:
        <li>Mise à jour du devis</li>
      </ul>
    ) : (
      <></>
    );
  }

  async function handleOpenConfirmModal() {
    if (!defaultLogistic) {
      return await handleSubmit();
    }
    setOpenConfirmModal(true);
  }

  function handleCloseConfirmModal() {
    setOpenConfirmModal(false);
  }

  function handleOnSettled() {
    setIsLoading(false);
    handleCloseConfirmModal();
  }

  async function handleSubmit() {
    setIsLoading(true);
    await submitFiles(
      `-${defaultLogistic?.trackId || "no-track-id"}-logistic.`
    );
    defaultLogistic
      ? updateLogistic.mutate({ logistic: formValues, updateQuote })
      : addLogistic.mutate({ logistic: formValues, updateQuote });
  }

  async function submitFiles(slug: string) {
    const fileList = formValues.productsList.map((p) => p.fileList);
    if (!fileList) {
      return;
    }
    for (const [index, logisticFile] of fileList.entries()) {
      if (!logisticFile) {
        continue;
      }
      if (logisticFile.incomingPicturesList) {
        for (let file of logisticFile.incomingPicturesList) {
          const response = await streamFile(slug, file);
          const newFormValues = { ...formValues };
          newFormValues.productsList[index] &&
            newFormValues.productsList[index].incomingPicturesList?.push(
              response.message
            );
          onFormValuesChange(newFormValues);
        }
      }
      if (logisticFile.outgoingPicturesList) {
        for (let file of logisticFile.outgoingPicturesList) {
          const response = await streamFile(slug, file);
          const newFormValues = { ...formValues };
          newFormValues.productsList[index] &&
            newFormValues.productsList[index].outgoingPicturesList?.push(
              response.message
            );
          onFormValuesChange(newFormValues);
        }
      }
    }
  }
}
