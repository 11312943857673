import { useEffect } from "react";

export function useDebounce(callback: () => void, deps?: React.DependencyList) {
  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(callback, 200);
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, deps);
}
