import { useEffect, useState } from "react";
import style from "./gpCalendarItem.module.scss";
import clsx from "clsx";

export default function GPCalendarItem({
  data,
  itemClassName,
  itemStyle,
  titleClassName,
  subtitleClassName,
  onClick,
  children,
}: {
  data: any;
  itemClassName?: string;
  itemStyle?: (data: any) => React.CSSProperties;
  titleClassName?: string;
  subtitleClassName?: string;
  onClick?: (data: any) => void;
  children?: React.ReactNode;
}) {
  const [height, setHeight] = useState(100);

  useEffect(() => {
    if (data.rowStartValue && data.rowEndValue) {
      setHeight((data.rowEndValue - data.rowStartValue) * 100 || 100);
    }
  }, [data]);

  return (
    <div
      className={clsx(style["gp-calendar-item"], itemClassName)}
      style={{
        height: `${height}%`,
        ...(itemStyle ? itemStyle(data) : {}),
      }}
      onClick={() => onClick && onClick(data)}
    >
      <div className={clsx(style["gp-calendar-item-title"], titleClassName)}>
        {data.title}
      </div>
      <div
        className={clsx(style["gp-calendar-item-subtitle"], subtitleClassName)}
      >
        {data.subtitle}
      </div>
      {children}
    </div>
  );
}
