import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import {
  getShopOperationGroups,
  getShopProductGroups,
} from "../../../../requests/shops";
import { IProduct as ILogisticProduct } from "../../../../types/logistic.types";
import { IProduct } from "../../../../types/proCart.types";
import Select from "../../../Select/Select";
import Input from "../../../inputs/Input/Input";
import OperationProSection from "../operationProSection/OperationProSection";
import style from "./workshopProInfoForm.module.scss";

interface WorkshopProInfoFormProps {
  shopId: string;
  shopRef: string;
  handleValidateProduct: () => void;
  productForm: IProduct;
  setProductForm: (product: IProduct | null) => void;
}

export default function WorkshopProInfoForm({
  shopId,
  shopRef,
  handleValidateProduct,
  productForm,
  setProductForm,
}: WorkshopProInfoFormProps) {
  const { data: productGroupData } = useQuery({
    queryKey: ["shop-product-groups", shopId],
    queryFn: () => getShopProductGroups(shopId),
  });
  const { data: operationGroupData } = useQuery({
    queryKey: ["shop-operation-groups", shopId],
    queryFn: () => getShopOperationGroups(shopId, productForm.productGroup),
  });

  useEffect(() => {
    let p = { ...productForm };
    if (
      productGroupData &&
      productGroupData.productGroups &&
      productGroupData.productGroups.length > 0
    ) {
      p = { ...p, productGroup: productGroupData.productGroups[0] };
    }

    if (
      operationGroupData &&
      operationGroupData.operationGroups &&
      operationGroupData.operationGroups.length > 0
    ) {
      p = { ...p, operationGroup: operationGroupData.operationGroups[0] };
    }

    setProductForm(p);
  }, [productGroupData, operationGroupData]);

  return (
    <div className={style["workshop-pro-info-form"]}>
      {/* <div className={style["left"]}>Informations atelier</div> */}
      <div className={style["right"]}>
        <div className={style["input-line"]}>
          <Input
            className={style["input-container"]}
            label="Numéro de ticket *"
            name="workshopId"
            value={productForm.workshopId || `${shopRef}-`}
            onChange={({ target }) =>
              setProductForm({ ...productForm, workshopId: target.value })
            }
          />
          <Input
            label="Marque"
            name="brand"
            value={productForm.brand}
            onChange={({ target }) =>
              setProductForm({ ...productForm, brand: target.value })
            }
          />
        </div>
        <div className={style["input-line"]}>
          <div className={style["select-container"]}>
            <label>Groupe d'articles *</label>
            <Select
              optionsList={productGroupData?.productGroups || []}
              value={productForm.productGroup}
              setValue={(value) =>
                setProductForm({ ...productForm, productGroup: value })
              }
              doTranslate
              placeholder={"Sélectionnez un groupe d'article..."}
            />
          </div>
          <div className={style["select-container"]}>
            <label>Groupe d'opérations *</label>
            <Select
              optionsList={operationGroupData?.operationGroups || []}
              value={productForm.operationGroup}
              setValue={(value) =>
                setProductForm({ ...productForm, operationGroup: value })
              }
              doTranslate
              placeholder={"Sélectionnez un groupe d'opérations..."}
            />
          </div>
        </div>
        <OperationProSection
          shopId={shopId}
          className={style["operation-section-container"]}
          productForm={productForm as ILogisticProduct}
          setProductForm={setProductForm as (value: ILogisticProduct) => void}
        />
        <div className={style["workshop-pro-form-footer"]}>
          <div
            className={style["validate-button"]}
            onClick={handleValidateProduct}
          >
            Valider
          </div>
          <div className={style["delete-button"]} onClick={handleCancel}>
            Annuler
          </div>
        </div>
      </div>
    </div>
  );

  function handleCancel() {
    setProductForm(null);
  }
}
