import { useFrontLabel } from "./useFrontLabel";
import styles from "./frontLabel.module.scss";
import { usePrinter } from "../../../context/printer.context";
import PrintButton from "../../../components/PrintButton/PrintButton";
import clsx from "clsx";
import { useFrontLabelPreview } from "./useFrontLabelPreview";

interface FrontLabelProps {
  operations: string[][];
  workshopId: string;
  // dueDate: Date | null;
  className?: string;
  comments?: string;
  isPreview?: boolean;
}

export default function FrontLabel({
  operations,
  workshopId,
  // dueDate,
  className,
  comments = "",
  isPreview = false,
}: FrontLabelProps) {
  const frontCanvas = useFrontLabel(operations, workshopId, comments || "");
  const previewCanvas = useFrontLabelPreview(
    operations,
    workshopId,
    comments || ""
  );

  const { onPrint, printProgress, printState, connectionState } = usePrinter();
  if (operations.length == 0) {
    return null;
  }
  return (
    <div className={clsx(styles["front-label"], className)}>
      <div className={styles["front-label-container"]}>
        <canvas
          ref={frontCanvas}
          style={{ border: "1px solid black" }}
          className={clsx(isPreview && styles["is-preview"])}
        />
        <canvas
          className={clsx(!isPreview && styles["is-preview"])}
          ref={previewCanvas}
          style={{ border: "1px solid black" }}
        />
      </div>
      {connectionState === "connected" && (
        <PrintButton
          handleOnPrint={handleOnPrint}
          printProgress={printProgress}
          printState={printState}
        />
      )}
    </div>
  );

  async function handleOnPrint() {
    onPrint({ canvas: frontCanvas.current });
  }
}
