import { keepPreviousData, useQuery } from "@tanstack/react-query";
import style from "./ShippingProHistoryModal.module.scss";
import { getShippingProList } from "../../../requests/shippingPro";
import LoadingSpinner from "../../loadingSpinner/loadingSpinner";
import { IShippingPro } from "../../../types/shipping.types";
import Item from "./listItem/Item";
import PagingComponent from "../../pagingComponent/pagingComponent";
import { useState } from "react";
import { queryObject } from "../../../types/query.types";

interface ShippingProHistoryModalProps {
  name: string;
  shopId: string;
}

const INITIAL_QUERY: queryObject = {
  limit: 7,
  offset: 0,
  sort: "desc",
};

export default function ShippingProHistoryModal({
  name,
  shopId,
}: ShippingProHistoryModalProps) {
  const [query, setQuery] = useState<queryObject>(INITIAL_QUERY);
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["shipping-list", query],
    queryFn: () => getShippingProList(formatedQuery()),
    placeholderData: keepPreviousData,
  });

  return (
    <div className={style["shipping-pro-history-modal"]}>
      <div className={style["shipping-pro-history-modal-container"]}>
        <div className={style["title"]}>
          {`Historique des livraisons/collectes (${name})`}
        </div>
        <PagingComponent
          query={query}
          setQuery={setQuery}
          totalCount={data?.totalCount}
          limitOptionsList={["8", "15", "20"]}
        />
        <div></div>
        <div className={style["shipping-list"]}>
          {isLoading ? (
            <div className={style["loading-container"]}>
              <LoadingSpinner color="gold" />
            </div>
          ) : (
            <>
              {data && data.shippingProList.length > 0 ? (
                data.shippingProList.map(
                  (shipping: IShippingPro, index: number) => (
                    <Item
                      key={index}
                      shipping={shipping}
                      refetch={refetch}
                      zIndex={data.shippingProList.length - index}
                    />
                  )
                )
              ) : (
                <div className={style["no-option-found"]}>
                  aucune livraison disponible
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );

  function formatedQuery() {
    let formatedQuery = `?limit=${query.limit}&offset=${
      query.limit * query.offset
    }&shopId=${shopId}&sort_field=period.start&sort_direction=desc`;

    return formatedQuery;
  }
}
