import {
  Description,
  Edit,
  ImageSearchOutlined,
  Launch,
  Person,
} from "@mui/icons-material";
import { ILogistic } from "../../../../types/logistic.types";
import MoreTools, { IMoreToolsOptions } from "../../../MoreTools/MoreTools";
import styles from "./logisticModalHeader.module.scss";
import State from "../../../State/State";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import clsx from "clsx";
import { ModalContainer } from "../../ModalContainer/ModalContainer";
import { useState } from "react";
import EstimateModal from "../../estimateModal/estimateModal";

interface LogisticModalHeaderProps {
  data?: ILogistic;
  requiredActions: boolean;
  selectedTab: "read" | "action";
  onTabChange: (value: "read" | "action") => void;
  onToolSelect?: () => void;
}

export default function LogisticModalHeader({
  data,
  requiredActions,
  selectedTab,
  onTabChange,
  onToolSelect,
}: LogisticModalHeaderProps) {
  const [estimateModal, setEstimateModal] = useState<boolean>(false);
  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      icon: <Person />,
      name: `${data?.contact?.displayname || ""}`,
    },
    {
      icon: <Launch />,
      name: "Vue d'ensemble",
      onClick: handleOnToolClick,
      to: `/overview/${data?.trackId}`,
    },
    {
      icon: <Edit />,
      name: "Modifier",
      onClick: handleOnToolClick,
      to: `/logistic/edit/${data?.id}`,
    },
    {
      icon: <ImageSearchOutlined />,
      name: "Estimation",
      onClick: handleOpenModal,
    },
  ];

  return (
    <>
      <div className={styles["header"]}>
        <div className={styles["tabs-section"]}>
          <div
            className={clsx(
              styles["tab-item"],
              selectedTab === "read" && styles["tab-selected"]
            )}
            onClick={() => onTabChange("read")}
          >
            <Description className={styles["icon"]} />
            <p className={styles["tabs-text"]}>{"Fiche produit"}</p>
          </div>
          {requiredActions && (
            <div
              className={clsx(
                styles["tab-item"],
                selectedTab === "action" && styles["tab-selected"]
              )}
              onClick={() => onTabChange("action")}
            >
              <WarningAmberIcon className={styles["icon"]} />
              <p className={styles["tabs-text"]}>{"Actions requises"}</p>
            </div>
          )}
        </div>
        <div className={styles["header-right"]}>
          <State state={data?.status} />
          <MoreTools
            options={MORE_TOOLS_OPTIONS}
            className={styles["more-tools"]}
          />
        </div>
      </div>
      <ModalContainer isOpen={estimateModal} onCancel={handleOpenModal}>
        <EstimateModal trackId={data?.trackId} />
      </ModalContainer>
    </>
  );

  function handleOnToolClick() {
    onToolSelect && onToolSelect();
  }

  function handleOpenModal() {
    setEstimateModal(!estimateModal);
  }
}
