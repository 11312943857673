import { DropOffForm, DropOffProductForm } from "types/logistic.types";
import styles from "./priceSummarySection.module.scss";
import { useMemo } from "react";
import Price from "components/Price/Price";

interface PriceSummarySectionProps {
  dropOffForm: DropOffForm;
  selectedProduct: DropOffProductForm | null;
}

export default function PriceSummarySection({
  dropOffForm,
  selectedProduct,
}: PriceSummarySectionProps) {
  const totalPrice = useMemo(() => {
    let totalOperations = 0;
    let totalCrossSell = dropOffForm.crossSells.reduce(
      (prev, cur) => prev + (cur.price || 0) * (cur.cartQuantity || 1),
      0
    );
    let totalShipping = dropOffForm.shippingService?.price || 0;
    for (let product of dropOffForm.productList) {
      totalOperations += (product.operations || []).reduce(
        (prev, cur) => prev + (cur.price || 0),
        0
      );
    }

    return totalOperations + totalShipping + totalCrossSell;
  }, [
    dropOffForm.productList,
    dropOffForm.shippingService,
    dropOffForm.crossSells,
  ]);

  const selectedProductTotalPrice = useMemo(
    () =>
      (selectedProduct?.operations || []).reduce(
        (prev, cur) => prev + (cur.price || 0),
        0
      ),
    [selectedProduct]
  );

  return (
    <div className={styles["total-price-container"]}>
      <div className={styles["total-price"]}>
        <p>Total</p>
        <Price price={totalPrice} />
      </div>
      <div className={styles["selected-product-price"]}>
        <p>Total de l'article sélectionné</p>
        <Price price={selectedProductTotalPrice} />
      </div>
    </div>
  );
}
