import { Cancel, Check } from "@mui/icons-material";
import style from "./operationControl.module.scss";
import { IProduct } from "../../../types/logistic.types";
import { useMemo, useState } from "react";
import Button from "../../../components/Button/Button";
import Checklist from "../../../components/Checklist/Checklist";
import { CheckListItem } from "../../../types";

interface OperationControlProps {
  product: IProduct;
  state: boolean;
  validateControl: () => void;
  rejectControl: () => void;
}

export default function OperationControl({
  product,
  state,
  validateControl,
  rejectControl,
}: OperationControlProps) {
  const [operationCheckList, setOperationCheckList] = useState<CheckListItem[]>(
    initOperationChecklist()
  );
  const DISABLED = useMemo(
    () => operationCheckList.some((op) => !op.checked),
    [operationCheckList]
  );

  return (
    <div className={style["operation-control"]}>
      <Checklist
        checklist={operationCheckList}
        setChecklist={setOperationCheckList}
      />
      <div className={style["button-container"]}>
        <Button className={style["cancel"]} onClick={rejectControl}>
          <Cancel />
          Retour Atelier
        </Button>
        <Button
          disabled={DISABLED}
          onClick={validateControl}
          className={DISABLED ? style["disabled"] : style["validate"]}
        >
          <Check />
          Valider
        </Button>
      </div>
    </div>
  );

  function initOperationChecklist() {
    return (product.operationsList || [])
      .filter((op) => !op.canceled)
      .map((op) => {
        return { description: op.description, checked: state };
      });
  }
}
