import { ProductForm } from "../../../types/logistic.types";
import style from "./addProductModal.module.scss";
import SelectState from "../../../pages/shipping/create/SelectState/SelectState";
import { PRODUCT_STATE } from "../../../utils/logistic.init";
import ImagesForm from "../../forms/imagesForm/imagesForm";
import Button from "../../Button/Button";
import WorkshopInfoForm from "./workshopInfoForm/WorkshopInfoForm";
import ProductInfoForm from "./productInfoForm/ProductInfoForm";
import MiscellaneousInfoForm from "./miscellaneousInfoForm/MiscellaneousInfoForm";
import CommentForm from "./commentForm/CommentForm";
import OperationSection from "./operationSection/OperationSection";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_ERROR_OPTIONS_LEFT,
  TOAST_SUCCESS_OPTIONS,
} from "../../../utils/toast.options";
import { toast } from "react-toastify";
import { deleteFileFromLogistic } from "../../../requests/logistic";

interface AddProductModalProps {
  product: ProductForm;
  setProduct: (product: ProductForm) => void;
  onValidateForm: () => void;
}

export default function AddProductModal({
  product,
  setProduct,
  onValidateForm,
}: AddProductModalProps) {
  const productForm = product;
  const setProductForm = setProduct;
  // const [productForm, setProductForm] = useState<ProductForm>(product);

  return (
    <div className={style["add-product-modal"]}>
      <div>
        <div className={style["add-product-modal-header"]}>
          <h1>Formulaire article</h1>
          <SelectState
            label="Status du produit"
            state={productForm.status || "PENDING_SHELVED"}
            stateOption={PRODUCT_STATE}
            setState={(state) => {
              setProductForm({ ...productForm, status: state });
            }}
          />
        </div>
        <hr className={style["separator"]} />
        <WorkshopInfoForm
          productForm={productForm}
          setProductForm={setProductForm}
        />
        <hr className={style["separator"]} />
        <ProductInfoForm
          productForm={productForm}
          setProductForm={setProductForm}
        />
        <hr className={style["separator"]} />
        <MiscellaneousInfoForm
          productForm={productForm}
          setProductForm={setProductForm}
        />
        <hr className={style["separator"]} />
        <CommentForm
          productForm={productForm}
          setProductForm={setProductForm}
        />
        <hr className={style["separator"]} />
        <OperationSection
          productForm={productForm}
          setProductForm={setProductForm}
        />
        <hr className={style["separator"]} />
        <label>Images avant</label>
        <ImagesForm
          title="incoming-picture-list"
          imageList={productForm.incomingPicturesList || []}
          fileList={productForm.fileList?.incomingPicturesList || []}
          setFileList={(fl) =>
            setProductForm({
              ...productForm,
              fileList: {
                ...(productForm.fileList || {
                  incomingPicturesList: [],
                  outgoingPicturesList: [],
                }),
                incomingPicturesList: fl,
              },
            })
          }
          deleteImage={(imageURI) =>
            handleDeleteImage(imageURI, "incomingPicturesList")
          }
        />
        <hr className={style["separator"]} />
        <label>Images après</label>
        <ImagesForm
          title="outgoing-picture-list"
          imageList={productForm.outgoingPicturesList || []}
          fileList={productForm.fileList?.outgoingPicturesList || []}
          setFileList={(fl) => {
            setProductForm({
              ...productForm,
              fileList: {
                ...(productForm.fileList || {
                  incomingPicturesList: [],
                  outgoingPicturesList: [],
                }),
                outgoingPicturesList: fl,
              },
            });
          }}
          deleteImage={(imageURI) =>
            handleDeleteImage(imageURI, "outgoingPicturesList")
          }
        />
      </div>
      <div className={style["footer"]}>
        <Button onClick={handleValidate}>Enregsitrer</Button>
      </div>
    </div>
  );

  async function handleDeleteImage(
    imageURI: string,
    pictureList: "incomingPicturesList" | "outgoingPicturesList"
  ) {
    try {
      setProductForm({
        ...productForm,
        [pictureList]: (productForm[pictureList] || []).filter(
          (uri) => uri !== imageURI
        ),
      });
      await deleteFileFromLogistic(imageURI);
      toast.success("Fichier supprimer avec succes.", TOAST_SUCCESS_OPTIONS);
    } catch (error) {
      toast.error("Un problème est survenue.", TOAST_ERROR_OPTIONS);
    }
  }

  function handleValidate() {
    if (!handleValidateForm()) {
      return;
    }
    // setProductForm(productForm);
    onValidateForm();
  }

  function handleValidateForm() {
    let errorString: string[] = [];
    if (!productForm.workshopId) {
      errorString.push("Numéro de ticket");
    }
    if (!productForm.operationGroup) {
      errorString.push("Groupe d'opérations");
    }
    if (!productForm.productGroup) {
      errorString.push("Groupe d'articles");
    }
    if (!productForm.operationsList.length) {
      errorString.push("List des opérations");
    }
    if (errorString.length === 0) {
      return true;
    }
    toast.error(
      `Veuillez remplir les champs requis: ${errorString.join(" - ")}`,
      TOAST_ERROR_OPTIONS_LEFT
    );
    return false;
  }
}
