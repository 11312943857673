import { DropOffForm } from "types/logistic.types";
import styles from "../shippingSection.module.scss";
import { useEffect, useState } from "react";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { TimeSlot } from "types/shipping.types";
import { addBusinessDays } from "utils/utils";
import RepareTimeSection from "../RepareTimeSection/RepareTimeSection";

interface NonDropOffShippingSectionProps {
  dropOff: DropOffForm;
  onDropOffChange: (dropOff: DropOffForm) => void;
}

export default function NonDropOffShippingSection({
  dropOff,
  onDropOffChange,
}: NonDropOffShippingSectionProps) {
  const [expand, setExpand] = useState<boolean>(false);
  const repareDays = dropOff.repareDays || defaultRepareDays();
  useEffect(() => {
    if (dropOff.repareDays == null) {
      handleRepareTimeChange(defaultRepareDays());
    }
  }, [dropOff.productList]);

  if (!expand) {
    return (
      <div
        className={styles["in-store-drop-off-bottom-collapsed"]}
        onClick={handleToogleSection}
      >
        <div className={styles["in-store-drop-off-bottom-collapsed-left"]}>
          <p className={styles["repare-date"]}>{repareDays} jours ouvrés</p>
          <p className={styles["repare-date"]}>
            {addBusinessDays(repareDays).toLocaleDateString("fr-FR", {
              day: "2-digit",
              weekday: "short",
              month: "short",
            })}
          </p>
        </div>
        <div className={styles["expand-button"]} onClick={handleToogleSection}>
          <AddCircleOutline />
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles["in-store-drop-off-bottom"]}>
        <RepareTimeSection
          dropOff={dropOff}
          onRepareTimeChange={handleRepareTimeChange}
          onTimeSlotChange={handleTimeSlotChange}
        />
        <div
          className={styles["collapse-button"]}
          onClick={handleToogleSection}
        >
          <RemoveCircleOutline />
        </div>
      </div>
    );
  }

  function handleToogleSection() {
    setExpand(!expand);
  }

  function handleRepareTimeChange(repareDays: number) {
    onDropOffChange({ ...dropOff, repareDays });
  }

  function handleTimeSlotChange(timeSlot: TimeSlot) {
    onDropOffChange({ ...dropOff, timeSlot });
  }

  function defaultRepareDays() {
    if (dropOff.productList.some((p) => p.productGroup === "LEATHER")) {
      return 21;
    }
    return 7;
  }
}
