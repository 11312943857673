import SectionTitle from "components/SectionTitle/SectionTitle";
import styles from "./dropOffContactSummary.module.scss";
import { DropOffForm } from "types/logistic.types";

interface DropOffContactSummaryProps {
  dropOff: DropOffForm;
}

export default function DropOffContactSummary({
  dropOff,
}: DropOffContactSummaryProps) {
  return (
    <>
      <SectionTitle title="Informations du client" />
      <p className={styles["contact-information"]}>
        {`${dropOff?.contact?.displayname} | ${dropOff?.contact?.phone} | ${dropOff?.contact?.email}`}
      </p>
    </>
  );
}
