import { useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import styles from "./qualityControlButton.module.scss";
import { SearchOutlined } from "@mui/icons-material";
import clsx from "clsx";

interface QualityControlButtonProps {
  className?: string;
  productId?: string;
}

export default function QualityControlButton({
  className,
  productId,
}: QualityControlButtonProps) {
  const navigate = useNavigate();

  if (!productId) {
    return null;
  }

  return (
    <Button
      onClick={() => navigate(`/quality-control/${productId}`)}
      className={clsx(styles["control-link-button"], className)}
    >
      <SearchOutlined />
    </Button>
  );
}
