import styles from "./item.module.scss";
import { Delete, Edit, Launch } from "@mui/icons-material";
import { ICart } from "../../../types/booking.type";
import CartModal from "../../../components/modals/cartModal/cartModal";
import MoreTools, {
  IMoreToolsOptions,
} from "../../../components/MoreTools/MoreTools";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";
import CenterCell from "../../../components/CenterCell/CenterCell";
import State from "../../../components/State/State";
import clsx from "clsx";
import { useState } from "react";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";

export default function Item({
  data,
  onDelete,
}: {
  data: ICart;
  onDelete: (id?: string) => void;
}) {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      icon: <Launch />,
      name: "Ouvrir",
      onClick: handleOpenModal,
    },
    {
      icon: <Edit />,
      name: "Modifier",
      to: `/carts/edit/${data.id}`,
    },
    {
      icon: <Delete />,
      name: "Supprimer",
      askConfirmation: true,
      onClick: () => onDelete(data.id),
    },
  ];

  if (!data.id) {
    return null;
  }

  return (
    <>
      <tr className={styles["item"]}>
        <td
          className={`${styles["item-cell"]} ${styles["item-date"]}`}
          title={`${
            data?.createdAt && new Date(data?.createdAt).toLocaleString()
          }`}
        >
          {data.createdAt
            ? new Date(data.createdAt).toLocaleDateString()
            : "indisponible"}
        </td>
        <td className={clsx(styles["item-cell"], styles["item-coord"])}>
          <div className={styles["coordinates"]}>
            <span>{data.contact?.displayname}</span>
            <span>{data.contact?.phone}</span>
          </div>
        </td>
        <td className={styles["item-cell"]}>{data.productsList.length}</td>
        <td className={clsx(styles["item-cell"], styles["status-box"])}>
          <CenterCell>
            <State state={data.state} />
          </CenterCell>
        </td>
        <td className={`${styles["item-cell"]} ${styles["tools"]}`}>
          <ProtectedComponent roles={["ROLE_ADMIN"]}>
            <CenterCell>
              <MoreTools options={MORE_TOOLS_OPTIONS} />
            </CenterCell>
          </ProtectedComponent>
        </td>
      </tr>
      <ModalContainer isOpen={openModal} onCancel={handleCloseModal}>
        <CartModal id={data.id} onEdit={handleCloseModal} />
      </ModalContainer>
    </>
  );

  function handleOpenModal() {
    setOpenModal(true);
  }

  function handleCloseModal() {
    setOpenModal(false);
  }
}
