import { useQuery } from "@tanstack/react-query";
import DataError from "components/errors/DataError/DataError";
import PictureLoadingSpinner from "components/loadingSpinner/pictureloadingSpinner";
import { fetchDiscountList } from "requests/discount";
import { IDiscount } from "types/accounting.types";
import styles from "./discountListModal.module.scss";

interface DiscountListModalProps {
  onDiscountSelect: (discount: IDiscount) => void;
}

export default function DiscountListModal({
  onDiscountSelect,
}: DiscountListModalProps) {
  const { data, isLoading } = useQuery({
    queryKey: ["discount-list"],
    queryFn: () => fetchDiscountList(),
    refetchOnWindowFocus: false,
  });

  if (isLoading) {
    <div className={styles["loading"]}>
      <PictureLoadingSpinner />
    </div>;
  }

  if (!data) {
    return (
      <div className={styles["loading"]}>
        <DataError error="Discount error" />
      </div>
    );
  }

  const { discounts } = data;

  return (
    <div className={styles["discount-modal"]}>
      <h3>Liste des codes promos</h3>
      <ul className={styles["discount-list"]}>
        {discounts.map((d, index) => (
          <DiscountItem
            key={index}
            discount={d}
            onClick={() => onDiscountSelect(d)}
          />
        ))}
      </ul>
    </div>
  );
}

interface DiscountItemProps {
  discount: IDiscount;
  onClick: () => void;
}

function DiscountItem({ discount, onClick }: DiscountItemProps) {
  return (
    <li className={styles["discount-item"]} onClick={onClick}>
      <p>{discount.code}</p>
      <p>
        {discount.isRate ? discount.value : discount.value / 100}
        {discount.isRate ? " %" : " €"}
      </p>
    </li>
  );
}
