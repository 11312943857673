import {
  ICrossSell,
  ICrossSellProduct,
  IQuote,
} from "../types/accounting.types";
import { INITIAL_CONTACT } from "./shipping.init";

export const QUOTE_STATES = [
  "QUOTE_DRAFT",
  "QUOTE_PENDING",
  "QUOTE_SENT",
  "QUOTE_VALIDATED",
  "QUOTE_CANCELED",
];

export const INVOICE_PRO_STATES = ["PENDING", "TO_BE_PAYED", "PAYED"];

export const INITIALE_QUOTE: IQuote = {
  contact: INITIAL_CONTACT,
  comments: "",
  currency: "EURO (€)",
  productsList: [],
  // discount?: IDiscount,
  // prepayed?: IPrepayed,

  shipping: {
    shippingService: "INTERNAL",
    price: 15,
    freeShippingMinimumAmount: 150,
  },

  operationsTotal: 0,
  salesTotal: 0,
  discountTotal: 0,
  total: 0,
  minimumDurationDays: 0,
  maximumDurationDays: 0,

  // sentAt: new Date(),
  // repliedAt: new Date(),

  state: "QUOTE_PENDING",
};

export const DEFAULT_CROSSSELL: ICrossSellProduct = {
  description: "",
  cartQuantity: 1,
};
