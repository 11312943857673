import { Delete, Edit, Launch } from "@mui/icons-material";
import { useMutation } from "@tanstack/react-query";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";
import { useState } from "react";
import { toast } from "react-toastify";
import ProShippingModal, {
  IProCartShipping,
} from "../../../components/modals/proShippingModal/proShippingModal";
import ReadCartModal from "../../../components/modals/readCartModal/ReadCartModal";
import MoreTools, {
  IMoreToolsOptions,
} from "../../../components/MoreTools/MoreTools";
import WorkshopId from "../../../components/workshopId/workshopId";
import { updateCart } from "../../../requests/professionalCarts";
import { ICart } from "../../../types/booking.type";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../utils/toast.options";
import translate from "../../../utils/translation";
import getStatusColor from "../../../utils/translation/statusColor";
import style from "./item.module.scss";

export default function Item({
  data,
  zIndex,
  onDelete,
  onUpdate,
}: {
  data: ICart;
  zIndex: number;
  onDelete: (id?: string) => void;
  onUpdate?: () => void;
}) {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [shippingModalIsOpen, setShippingModalIsOpen] =
    useState<boolean>(false);
  const { mutate } = useMutation({
    mutationFn: updateCart,
    onSuccess(data, variables, context) {
      onUpdate && onUpdate();
      toast.success("Panier mis à jour.", TOAST_SUCCESS_OPTIONS);
    },
    onError(error, variables, context) {
      toast.error(
        "Erreur lors de la mise à jour du panie.",
        TOAST_ERROR_OPTIONS
      );
    },
  });
  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      icon: <Launch />,
      name: "Ouvrir",
      onClick: handleOpenCartModal,
    },
    {
      icon: <Edit />,
      name: "Modifier",
      onClick: handleOpenShippingModal,
    },
    {
      icon: <Delete />,
      name: "Supprimer",
      askConfirmation: true,
      onClick: handleDelete,
    },
  ];

  return (
    <>
      <div className={style["item"]}>
        <div className={style["item-left"]} onClick={handleOpenCartModal}>
          <div className={`${style["item-element"]} ${style["coordinates"]}`}>
            <span>{data.contact?.displayname}</span>
            <span>{data.contact?.phone}</span>
          </div>
          <div className={style["item-element"]}>
            {data.createdAt && new Date(data.createdAt).toLocaleDateString()}
            {data.updatedAt && new Date(data.updatedAt).toLocaleDateString()}
          </div>
          <div className={style["item-element"]}>
            {data.productsList[0] && (
              <WorkshopId
                size={"xx-small"}
                workshopId={data.productsList[0].workshopId}
              />
            )}
            {data.productsList.length > 1 && (
              <div className={style["more-circle"]}>
                + {data.productsList.length - 1}
              </div>
            )}
          </div>
          <div className={style["item-element"]}>
            <div
              className={style["state-container"]}
              style={{ backgroundColor: getStatusColor(data.state) }}
            >
              {data.state && translate(data.state)}
            </div>
          </div>
        </div>
        <div className={`${style["item-element"]} ${style["tools"]}`}>
          <MoreTools options={MORE_TOOLS_OPTIONS} />
        </div>
      </div>
      <ModalContainer isOpen={modalIsOpen} onCancel={handleCloseCartModal}>
        <ReadCartModal cart={data} />
      </ModalContainer>
      <ModalContainer
        isOpen={shippingModalIsOpen}
        onCancel={handleCloseShippingModal}
      >
        <ProShippingModal cart={data as any} setCart={handleUpdateCart} />
      </ModalContainer>
    </>
  );

  function handleOpenCartModal() {
    setModalIsOpen(true);
  }

  function handleCloseCartModal() {
    setModalIsOpen(false);
  }

  function handleOpenShippingModal() {
    setShippingModalIsOpen(true);
  }

  function handleCloseShippingModal() {
    setShippingModalIsOpen(false);
  }

  function handleDelete() {
    onDelete(data.id);
  }

  function handleUpdateCart(cart: IProCartShipping) {
    mutate(cart);
    handleCloseShippingModal();
  }
}
