import { DropOffForm } from "types/logistic.types";

export async function postDropOff({
  dropOff,
  redirect = "overview",
}: {
  dropOff: DropOffForm;
  redirect?: string;
}): Promise<{ trackId: string; redirect: string }> {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/drop-off`, {
      headers: new Headers({ "Content-Type": "application/json" }),
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ dropOff }),
    });
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return { trackId: data.trackId, redirect };
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function updateDropOff({
  dropOff,
  redirect = "overview",
}: {
  dropOff: DropOffForm;
  redirect?: string;
}): Promise<{ trackId: string; redirect: string }> {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/drop-off`, {
      headers: new Headers({ "Content-Type": "application/json" }),
      method: "PUT",
      credentials: "include",
      body: JSON.stringify({ dropOff }),
    });
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return { trackId: data.trackId, redirect };
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function updateNonDropOffCheckIn({
  dropOff,
  redirect = "overview",
}: {
  dropOff: DropOffForm;
  redirect?: string;
}): Promise<{ trackId: string; redirect: string }> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/drop-off/non-drop-off`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "PUT",
        credentials: "include",
        body: JSON.stringify({ dropOff }),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return { trackId: data.trackId, redirect };
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function sendEstimateFromDropOff({
  dropOff,
}: {
  dropOff: DropOffForm;
}): Promise<any> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/drop-off/estimate`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ dropOff }),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function generateDropOff(
  trackId: string
): Promise<{ dropOff: DropOffForm }> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/drop-off/${trackId}`,
      {
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}
