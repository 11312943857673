import { keepPreviousData, useQuery } from "@tanstack/react-query";
import FilterByStatus from "../../components/filterByStatus/filterByStatus";
import FiltersContainer from "../../components/Filters/FiltersContainer";
import ListPageHeader from "../../components/ListPageHeader/ListPageHeader";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import PagingComponent from "../../components/pagingComponent/pagingComponent";
import SearchByKeyComponent from "../../components/searchByKeyComponent/searchByKeyComponent";
import useSaveQueryStorage from "../../hooks/useSaveQueryStorage";
import { fetchTrackersList } from "../../requests/tracker";
import { queryObject } from "../../types/query.types";
import { TRACKING_STATE } from "../../utils/tracking.init";
import styles from "./index.module.scss";
import ListHeader from "./ListHeader/ListHeader";
import Item from "./listItem/item";

const OPTIONS_LIST = [
  "logistic.workshopIds",
  "contact.displayname",
  "contact.organization",
  "contact.phone",
  "contact.email",
];

const INITIAL_OVERVIEW_QUERY: queryObject = {
  field: "logistic.workshopIds",
  value: "",
  direction: "DESC",
  state: "",
  limit: 10,
  offset: 0,
  sort: "sort_field=createdAt&sort_direction=desc",
};

export default function Overview() {
  const [query, setQuery] = useSaveQueryStorage(
    INITIAL_OVERVIEW_QUERY,
    "overview"
  );
  const { data, isLoading } = useQuery({
    queryKey: ["overviewList", query],
    queryFn: () => fetchTrackersList(formatedQuery()),
    placeholderData: keepPreviousData,
  });

  if (isLoading) {
    return <LoadingSpinner color="gold" />;
  }

  if (!data) {
    return <></>;
  }

  const { trackersList, totalCount } = data;

  if (!data || !trackersList) {
    return <></>;
  }

  return (
    <div className={styles["list-page"]}>
      <ListPageHeader title={"Vue d'ensemble"} />
      <FiltersContainer>
        <SearchByKeyComponent
          query={query}
          setQuery={setQuery}
          optionsList={OPTIONS_LIST}
        />
        <FilterByStatus
          query={query}
          setQuery={setQuery}
          optionsList={TRACKING_STATE}
          isTracking
        />
      </FiltersContainer>
      <div className={styles["list-container"]}>
        <div className={styles["paging-component-top"]}>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={totalCount}
          />
        </div>
        <div className={styles["list"]}>
          <table className={styles["table"]}>
            <ListHeader />
            <tbody>
              {trackersList.map((value: any, index: number) => {
                return <Item data={value} key={index} />;
              })}
            </tbody>
          </table>
        </div>
        <div className={styles["paging-component"]}>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={totalCount}
          />
        </div>
      </div>
    </div>
  );

  function formatedQuery() {
    let formatedQuery: string = "";

    if (query.limit) {
      formatedQuery = formatedQuery + `?limit=${query.limit}`;
    }

    formatedQuery = formatedQuery + `&offset=${query.offset * query.limit}`;

    if (query.field && query.value) {
      formatedQuery = formatedQuery + `&${query.field}=${query.value}`;
    }
    if (query.sort) {
      formatedQuery = formatedQuery + `&${query.sort}`;
    }

    if (query.state && query.state !== "Tous") {
      formatedQuery = formatedQuery + `&state=${query.state}`;
    }

    return formatedQuery;
  }
}
