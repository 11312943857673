import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import { ProtectedComponent } from "../../components/protectedComponent/protectedComponent";
import {
  deleteShippingMethod,
  fetchShippingMethodsList,
} from "../../requests/shippingMethod";
import { IShippingMethod } from "../../types/accounting.types";
import style from "./index.module.scss";
import Item from "./item/item";

let controller = new AbortController();

export default function ShippingMethods() {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["shipping-methods"],
    queryFn: () => fetchShippingMethodsList(),
  });

  if (isLoading) {
    return <LoadingSpinner color="gold" />;
  }

  return (
    <div className={style["item-list"]}>
      <div className={style["top"]}>
        <span className={style["title"]}>Méthodes de livraison</span>
        <ProtectedComponent roles={["ROLE_ADMIN"]}>
          <Link onClick={() => window.scrollTo(0, 0)} to={"./create"}>
            <Button>+ Ajouter nouveau</Button>
          </Link>
        </ProtectedComponent>
      </div>

      <div className={style["bottom"]}>
        <div className={style["item"]}>
          <div className={style["item-element"]}>Nom</div>
          <div className={style["item-element"]}>Service</div>
          <div className={style["item-element"]}>Prix</div>
          <div className={style["item-element"]}>Minimum gratuité</div>
          <div className={style["item-element"]}>Jours</div>
          <div className={style["item-element"]}>Créneaux</div>
          <ProtectedComponent roles={["ROLE_ADMIN"]}>
            <div className={style["item-element"]}>Actions</div>
          </ProtectedComponent>
        </div>
        {data &&
          data.shippingMethodsList &&
          data.shippingMethodsList.map(
            (value: IShippingMethod, key: number) => {
              return <Item key={key} data={value} onDelete={handleDelete} />;
            }
          )}
      </div>
    </div>
  );

  async function handleDelete(slug: string) {
    controller.abort();
    try {
      controller = new AbortController();
      await deleteShippingMethod({ slug: slug, signal: controller.signal });
      refetch();
    } catch (error) {
      console.error(error);
    }
  }
}
