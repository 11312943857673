import React, { useState } from "react";
import { IShop } from "../../../../types/shop.types";
import { INITIAL_SHOP } from "../../../../utils/shop.init";
import style from "./stepByStepModal.module.scss";
import StepByStepDispatch from "../StepByStepDispatch/StepByStepDispatch";
import Button from "../../../../components/Button/Button";
import { toast } from "react-toastify";
import { TOAST_ERROR_OPTIONS } from "../../../../utils/toast.options";

export default function StepByStepModal({
  shopInit,
  onValidate,
}: {
  shopInit?: IShop | null;
  onValidate: (shop: IShop) => void;
}) {
  const [step, setStep] = useState(1);
  const [shop, setShop] = useState<IShop>(shopInit || INITIAL_SHOP);
  return (
    <div className={style["step-by-step-modal"]}>
      <StepByStepDispatch step={step} shop={shop} onChange={setShop} />
      <div className={style["step-by-step-modal-footer"]}>
        {step > 1 && (
          <Button className={style["previous-button"]} onClick={previousStep}>
            Précédent
          </Button>
        )}
        {step < 5 && <Button onClick={nextStep}>Suivant</Button>}
        {step == 5 && <Button onClick={() => onValidate(shop)}>Valider</Button>}
      </div>
    </div>
  );

  function nextStep() {
    if (validateStep()) {
      return setStep(step + 1);
    }
    toast.error(
      "Veuillez remplir tous les champs nécessaires.",
      TOAST_ERROR_OPTIONS
    );
  }

  function previousStep() {
    setStep(Math.max(step - 1, 1));
  }

  function validateStep() {
    switch (step) {
      case 1:
        return (
          shop.licenseRank &&
          shop.name &&
          shop.workshopPrefixId &&
          shop.shippingMethod.shippingService
        );
      case 2:
        return (
          shop.contact.phone && shop.contact.email && shop.contact.organization
        );
      case 3:
        return shop.contact.address1 && shop.contact.zipCode;
      default:
        return true;
    }
  }
}
