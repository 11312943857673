import SectionTitle from "components/SectionTitle/SectionTitle";
import styles from "./productDescription.module.scss";
import { DropOffProductForm, IProduct } from "types/logistic.types";
import ColorPicker from "components/ColorPicker/ColorPicker";
import Input from "components/inputs/Input/Input";
import Button from "components/Button/Button";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";
import { useState } from "react";
import SelectFromListModal from "../../modals/SelectFromListModal/SelectFromListModal";
import brandsList from "requests/brands.json";
import categoriesList from "requests/categories.json";
import clsx from "clsx";

interface ProductDescriptionSectionProps {
  className?: string;
  isOptional?: boolean;
  product: DropOffProductForm | IProduct | null;
  onProductChange: (product: DropOffProductForm | IProduct) => void;
}

export default function ProductDescriptionSection({
  className,
  isOptional = true,
  product,
  onProductChange,
}: ProductDescriptionSectionProps) {
  const [openBrandModal, setOpenBrandModal] = useState<boolean>(false);
  const [openCategoryModal, setOpenCategoryModal] = useState<boolean>(false);

  if (!product || !product.workshopId) {
    return null;
  }

  return (
    <div className={clsx(styles["product-description-section"], className)}>
      <SectionTitle
        title={`Autres informations  ${isOptional ? "(optionnel)" : ""}`}
        className={styles["section-title"]}
      />
      <div className={styles["description-input"]}>
        <div className={styles["input"]}>
          <label>Couleur</label>
          <ColorPicker
            color={product.color || ""}
            onColorChange={handleColorChange}
          />
        </div>
        <div className={styles["input-container"]}>
          <Input
            className={styles["input"]}
            label="Marque"
            name="brand"
            value={product.brand || ""}
            onChange={handleInputChange}
            onFocus={handleOpenBrandModal}
          />
          <Button
            onClick={handleOpenBrandModal}
            className={styles["open-list"]}
          >
            Liste des marques
          </Button>
        </div>
        <div className={styles["input-container"]}>
          <Input
            className={styles["input"]}
            label="Catégorie"
            name="category"
            value={product.category || ""}
            onChange={handleInputChange}
            onFocus={handleOpenCategoryModal}
          />
          <Button
            onClick={handleOpenCategoryModal}
            className={styles["open-list"]}
          >
            Liste des catégories
          </Button>
        </div>
        <Input
          className={styles["input"]}
          label="Taille"
          name="size"
          inputMode={product.productGroup === "SHOES" ? "numeric" : "text"}
          value={product.size || ""}
          onChange={handleInputChange}
        />
        {isOptional && (
          <Input
            className={styles["input"]}
            label="Commentaires"
            name="comments"
            value={product.comments || ""}
            onChange={handleInputChange}
          />
        )}
      </div>
      <ModalContainer
        isOpen={openBrandModal}
        onCancel={handleCloseBrandModal}
        width="narrow"
      >
        <SelectFromListModal
          input={product.brand}
          onInputChange={handleBrandInputChange}
          onItemSelect={handleBrandSelect}
          searchList={brandsList}
        />
      </ModalContainer>
      <ModalContainer
        isOpen={openCategoryModal}
        onCancel={handleCloseCategoryModal}
        width="narrow"
      >
        <SelectFromListModal
          input={product?.category || null}
          onInputChange={handleCategoryInputChange}
          onItemSelect={handleCategorySelect}
          searchList={categoriesList}
        />
      </ModalContainer>
    </div>
  );

  function handleCloseBrandModal() {
    setOpenBrandModal(false);
  }

  function handleOpenBrandModal() {
    setOpenBrandModal(true);
  }

  function handleCloseCategoryModal() {
    setOpenCategoryModal(false);
  }

  function handleOpenCategoryModal() {
    setOpenCategoryModal(true);
  }

  function handleColorChange(color: string) {
    onProductChange({ ...product!, color });
  }

  function handleBrandSelect(brand: string) {
    onProductChange({ ...product!, brand });
    handleCloseBrandModal();
  }

  function handleBrandInputChange(brand: string) {
    onProductChange({ ...product!, brand });
  }

  function handleCategorySelect(category: string) {
    onProductChange({ ...product!, category });
    handleCloseCategoryModal();
  }

  function handleCategoryInputChange(category: string) {
    onProductChange({ ...product!, category });
  }

  function handleInputChange({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    const { name, value } = target;
    onProductChange({ ...product!, [name]: value });
  }
}
