import { useQuery } from "@tanstack/react-query";
import DataError from "components/errors/DataError/DataError";
import PictureLoadingSpinner from "components/loadingSpinner/pictureloadingSpinner";
import { useParams } from "react-router-dom";
import { generateDropOff } from "requests/dropOff";
import InStoreDropOff from "../../inStoreDropOff/InStoreDropOff";
import styles from "./IncomingShippingCheckIn.module.scss";

export default function IncomingShippingCheckIn() {
  const { trackId } = useParams();
  const { data, isLoading } = useQuery({
    queryKey: [`drop-off-${trackId}`],
    queryFn: () => generateDropOff(trackId!),
    refetchOnWindowFocus: false,
    enabled: Boolean(trackId),
  });

  if (isLoading) {
    return (
      <div className={styles["in-store-drop-off-edit"]}>
        <PictureLoadingSpinner />
      </div>
    );
  }

  if (!data) {
    return (
      <div className={styles["in-store-drop-off-edit"]}>
        <DataError error="drop-off" />
      </div>
    );
  }

  const { dropOff } = data;
  return <InStoreDropOff defaultDropOff={dropOff} />;
}
