import Image from "components/image/Image";
import styles from "./imageSlider.module.scss";
import { useMouseCoord } from "hooks/useMouseCoord";
import { useMemo } from "react";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import clsx from "clsx";

interface ImageSliderProps {
  isBlob?: boolean;
  pictureIndex: number;
  pictureList: string[];
  onPictureSelect: (index: number) => void;
  zoomIn: boolean;
  onPictureClick: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  onClickOutside: () => void;
}

export default function ImageSlider({
  isBlob,
  pictureIndex,
  pictureList,
  zoomIn,
  onPictureSelect,
  onPictureClick,
  onClickOutside,
}: ImageSliderProps) {
  const coords = useMouseCoord();
  const TRANSLATE_STYLE = useMemo(() => {
    return zoomIn
      ? {
          transform: `translate(${window.innerWidth / 2 - coords.x}px ,${
            window.innerHeight / 2 - coords.y
          }px)`,
        }
      : {};
  }, [zoomIn, coords]);

  return (
    <div className={styles["slider"]}>
      <div
        className={clsx(
          styles["chevron-container"],
          pictureIndex === 0 && styles["disabled"]
        )}
        onClick={previousPicture}
      >
        <ChevronLeft className={styles["chevron"]} />
      </div>
      <div
        className={styles["main-picture-container"]}
        onClick={onClickOutside}
      >
        <Image
          onClick={onPictureClick}
          className={clsx(styles["main-picture"], zoomIn && styles["zoom-in"])}
          style={TRANSLATE_STYLE}
          isBlob={isBlob}
          src={pictureList[pictureIndex] ? pictureList[pictureIndex] : ""}
          showTools={false}
        />
      </div>
      <div
        className={clsx(
          styles["chevron-container"],
          pictureIndex === pictureList.length - 1 && styles["disabled"]
        )}
        onClick={nextPicture}
      >
        <ChevronRight className={styles["chevron"]} />
      </div>
    </div>
  );

  function nextPicture() {
    if (pictureIndex + 1 < pictureList.length) {
      onPictureSelect(pictureIndex + 1);
    }
  }

  function previousPicture() {
    if (pictureIndex - 1 >= 0) {
      onPictureSelect(pictureIndex - 1);
    }
  }
}
