import clsx from "clsx";
import LoadingSpinner from "components/loadingSpinner/loadingSpinner";
import ProductCard from "pages/quickAccess/workshop/productCard/ProductCard";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { getProductList } from "requests/product";
import { TOAST_ERROR_OPTIONS } from "utils/toast.options";
import style from "./BarcodeReader.module.scss";

// @ts-ignore
const BarcodeDetector = (window as any).BarcodeDetector;

const BarcodeReader = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [barcode, setBarcode] = useState("");
  const [workshopIds, setWorkshopIds] = useState<string[]>([]);
  const [shouldDetect, setShouldDetect] = useState(false);
  const [videoStream, setVideoStream] = useState<MediaStream | null>(null);

  const { data, isLoading } = useQuery({
    queryKey: [`barcode-scanner`, workshopIds],
    queryFn: () =>
      getProductList("?".concat("workshopId=", workshopIds.join("|"))),

    select(data) {
      let products = [...(data?.productsList || [])];
      products.sort((a, b) => {
        let aDate = new Date("1999-01-01");
        let bDate = new Date("1999-01-01");
        if (a.historiesList && a.historiesList.length > 0) {
          let event = a.historiesList.at(-1);
          if (event && event.timeStamp) {
            aDate = new Date(event.timeStamp);
          }
        }

        if (b.historiesList && b.historiesList.length > 0) {
          let event = b.historiesList.at(-1);
          if (event && event.timeStamp) {
            bDate = new Date(event.timeStamp);
          }
        }

        return aDate.getTime() - bDate.getTime();
      });
      return { productsList: products };
    },
    enabled: workshopIds.length > 0,
  });

  useEffect(() => {
    return () => {
      if (videoStream) {
        stopCamera(videoStream);
      } else if (videoRef.current && videoRef.current.srcObject) {
        stopCamera(videoRef.current.srcObject as MediaStream);
      }
    };
  }, []);

  useEffect(() => {
    let videoRefValue: HTMLVideoElement | null = null;
    if (videoRef.current) {
      videoRefValue = videoRef.current;
    }

    return () => {
      if (videoRefValue && videoRefValue.srcObject) {
        (videoRefValue.srcObject as MediaStream)
          ?.getTracks()
          .forEach((track) => track.stop());
      }
    };
  }, [videoRef]);

  useEffect(() => {
    if (shouldDetect) {
      startCamera()
        .then((stream) => startBarcodeDetection(stream))
        .catch((err) => {
          toast.error("Error accessing camera: " + err.message, {
            ...TOAST_ERROR_OPTIONS,
            toastId: "barcode-camera-error",
          });
        });
    }
  }, [shouldDetect]);

  return (
    <div className={style["content"]}>
      <div className={style["video-container"]}>
        <video ref={videoRef} className={style["video"]} />
        <div
          className={clsx(style["placeholder"], {
            [style["hidden"]]: shouldDetect === true,
          })}
          onClick={() => setShouldDetect(true)}
        />
      </div>
      {isLoading && <LoadingSpinner color="gold" />}
      {data &&
        data.productsList.map((p) => (
          <ProductCard
            product={p}
            key={p.id}
            showOperationAcronym={true}
            showState={true}
            showDate={false}
          />
        ))}
    </div>
  );

  async function startCamera() {
    const constraints = {
      video: { facingMode: "environment" },
    };

    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      if (videoRef?.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
      setVideoStream(stream);
      return stream;
    } catch (err: any) {
      if (videoStream) {
        stopCamera(videoStream);
      }
      throw err;
    }
  }

  function stopCamera(stream: MediaStream) {
    stream?.getTracks().forEach((track) => track.stop());
    setVideoStream(null);
  }

  async function startBarcodeDetection(videoStream: MediaStream) {
    if (!("BarcodeDetector" in window)) {
      toast.error("BarcodeDetector API is not supported in this browser.", {
        ...TOAST_ERROR_OPTIONS,
        toastId: "barcode-not-supported",
      });
      return;
    }

    const barcodeDetector = new BarcodeDetector({
      formats: ["code_128"],
    });

    let workshopIds: string[] = [];

    const detectBarcode = () => {
      if (
        videoRef?.current &&
        videoRef.current.readyState === videoRef.current.HAVE_ENOUGH_DATA
      ) {
        barcodeDetector
          .detect(videoRef.current as HTMLVideoElement)
          .then((barcodes: any) => {
            if (barcodes && barcodes.length > 0) {
              setBarcode(barcodes[0].rawValue);
              workshopIds = formatWorkshopIds(barcodes[0].rawValue);
            }
          })
          .catch((err: any) => {
            toast.error("Barcode detection failed: " + err.message, {
              ...TOAST_ERROR_OPTIONS,
              toastId: "barcode-detection-failed",
            });
          });
      }
      if (workshopIds.length > 0) {
        stopCamera(videoStream);
        setWorkshopIds(workshopIds);
        setShouldDetect(false);
      } else {
        requestAnimationFrame(detectBarcode);
      }
    };
    requestAnimationFrame(detectBarcode);
  }

  function formatWorkshopIds(barcode: string) {
    let workshopIds = [];
    const productNumber = barcode.slice(-3);
    if (barcode.startsWith("2011")) {
      workshopIds.push("1-1-".concat(productNumber));
    } else {
      let prefix = barcode.slice(2, barcode.length - 3);
      if (prefix.length === 2) {
        workshopIds.push(`0${prefix[0]}-0${prefix[1]}-${productNumber}`);
      } else if (prefix.length === 3) {
        workshopIds.push(
          `0${prefix[0]}-${prefix[1]}${prefix[2]}-${productNumber}`
        );
        workshopIds.push(
          `${prefix[0]}${prefix[1]}-0${prefix[2]}-${productNumber}`
        );
      } else if (prefix.length === 4) {
        workshopIds.push(
          `${prefix[0]}${prefix[1]}-${prefix[2]}${prefix[3]}-${productNumber}`
        );
      }
    }
    if (workshopIds.length < 1) {
      toast.error("Impossible de formatter le code : " + barcode, {
        ...TOAST_ERROR_OPTIONS,
        toastId: "barcode-format-error",
      });
    }
    return workshopIds;
  }
};

export default BarcodeReader;
