import { IValidationError } from "../types";
import { ICrossSell } from "../types/accounting.types";

export async function addCrossSellItem(
  crossSellItem: ICrossSell
): Promise<ICrossSell | IValidationError[]> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/cross-sell`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "POST",
        credentials: "include",
        body: JSON.stringify(crossSellItem),
      }
    );

    const data = await response.json();

    if (response.status > 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function updateCrossSellItem(
  crossSellItem: ICrossSell
): Promise<ICrossSell> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/cross-sell`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "PUT",
        credentials: "include",
        body: JSON.stringify(crossSellItem),
      }
    );

    const data = await response.json();

    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getCrossSellItem(slug?: string): Promise<ICrossSell> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/cross-sell/${slug}`,
      {
        credentials: "include",
      }
    );

    const data = await response.json();

    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.crossSell;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getCrossSellsList(
  query?: string
): Promise<{ crossSells: ICrossSell[]; totalCount: number }> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/cross-sell${query ?? ""}`,
      {
        credentials: "include",
      }
    );

    const data = await response.json();

    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function deleteCrossSellItem(slug: string): Promise<ICrossSell> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/cross-sell/${slug}`,
      {
        method: "DELETE",
        credentials: "include",
      }
    );

    const data = await response.json();

    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function checkIfSlugExist(slug: string) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/cross-sell/check-if-slug-exist/${slug}`,
      {
        credentials: "include",
      }
    );

    const data = await response.json();

    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.exist;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function changeCrossSellQuantity(slug: string, quantity: number) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/cross-sell/change-quantity/${slug}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ quantity: quantity }),
      }
    );

    const data = await response.json();

    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.success;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}
