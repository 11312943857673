import { Delete } from "@mui/icons-material";
import LicenseLabel from "../../../../components/licenseLabel/licenseLabel";
import WorkshopId from "../../../../components/workshopId/workshopId";
import { IShop } from "../../../../types/shop.types";
import style from "./shopMiniature.module.scss";
import { useState } from "react";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";
import ConfirmModalComp from "components/confirmationModal/ConfirmModalComp";

interface ShopMiniatureProps {
  handleAddShop: (initialShop?: IShop) => void;
  handleDeleteShop: () => void;
  shop: IShop;
}

export default function ShopMiniature({
  handleAddShop,
  handleDeleteShop,
  shop,
}: ShopMiniatureProps) {
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <>
      <div
        className={style["shop-miniature"]}
        onClick={() => handleAddShop({ ...shop })}
      >
        <div className={style["shop-name"]}>{shop.name}</div>
        <LicenseLabel
          className={style["license-rank"]}
          rank={shop.licenseRank as string}
        />
        <WorkshopId size="xx-small" workshopId={shop.workshopPrefixId} />
        <div className={style["delete-button-container"]}>
          <div className={style["delete-button"]} onClick={handleOpenModal}>
            <Delete />
          </div>
        </div>
      </div>
      <ModalContainer
        width="fit"
        height="fit"
        isOpen={openModal}
        onCancel={handleCloseModal}
      >
        <ConfirmModalComp
          confirmButtonClassName={style["delete-button"]}
          title={"Etes-vous sur de vouloir supprimer cette boutique ?"}
          confirmText="Supprimer"
          onConfirm={handleDeleteShop}
          onCancel={handleCloseModal}
        />
      </ModalContainer>
    </>
  );

  function handleCloseModal() {
    setOpenModal(false);
  }

  function handleOpenModal(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();
    setOpenModal(true);
  }
}
