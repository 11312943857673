import { useState } from "react";
import style from "./autoCompleteDropDown.module.scss";
import useClickOutside from "../../../hooks/useClickOutSide";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { fetchCustomersList } from "../../../requests/customer";
import BeautifulDiv from "../../beautifulDiv/BeautifulDiv";
import { ICustomer } from "../../../types/customer.type";
import { isDesktop } from "react-device-detect";
import { useKeyDown } from "../../../hooks/useKeyDown";
import { useDebounceQuery } from "../../../hooks/useDebounceQuery";
import LoadingSpinner from "../../loadingSpinner/loadingSpinner";
import PictureLoadingSpinner from "../../loadingSpinner/pictureloadingSpinner";

interface AutoCompleteDropDownProps {
  setExpand: (value: boolean) => void;
  handleAutoComplete: (customer: ICustomer) => void;
  name?: string;
  value: string | number | readonly string[] | undefined;
}

export default function AutoCompleteDropDown({
  setExpand,
  handleAutoComplete,
  name,
  value,
}: AutoCompleteDropDownProps) {
  const dropdownRef = useClickOutside<HTMLDivElement>(closeDropDown);
  const [hoveredItem, setHoveredItem] = useState<number>(-1);
  const [limit, setLimit] = useState<number>(5);
  const { queryParams, isLoading } = useDebounceQuery([value, limit], 150);
  const { data } = useQuery({
    queryKey: ["customer-list", queryParams],
    queryFn: () => fetchCustomersList(formatedQuery()),
    placeholderData: keepPreviousData,
    staleTime: 150,
  });
  useKeyDown("ArrowDown", handleNavigateDown);
  useKeyDown("ArrowUp", handleNavigateUp);
  useKeyDown("Enter", handleSelectCustomer, [hoveredItem]);
  useKeyDown("Escape", closeDropDown);
  useKeyDown("Tab", closeDropDown);

  function closeDropDown() {
    setExpand(false);
    setLimit(5);
  }

  return (
    <div className={style["dropdown-container"]} ref={dropdownRef}>
      <div className={style["dropdown"]}>
        {isLoading && (
          <div className={style["circle-spinner-container"]}>
            <PictureLoadingSpinner
              color="gold"
              className={style["circle-spinner"]}
            />
          </div>
        )}
        {data?.customers && data?.customers.length > 0 ? (
          <>
            {data?.customers.map((customer: ICustomer, index: number) => {
              return (
                <div
                  className={`${style["autocomplete-item"]} ${
                    hoveredItem === index ? style["selected"] : ""
                  } ${isDesktop ? style["hover"] : style["active"]}`}
                  onClick={() => handleClickOnCustomer(index)}
                  onMouseEnter={() => setHoveredItem(index)}
                  key={customer.id}
                >
                  <div className={style["main-info"]}>{mainInfo(customer)}</div>
                  {secondaryInfo(customer)}
                </div>
              );
            })}
            {data.totalCount > limit && (
              <>
                {isLoading ? (
                  <div className={style["show-more-loading-container"]}>
                    <LoadingSpinner color="gold" className={style["small"]} />
                  </div>
                ) : (
                  <div className={style["show-more"]} onClick={showMore}>
                    afficher plus...
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <div className={style["no-result"]}>aucun résultat</div>
        )}
      </div>
      <BeautifulDiv />
    </div>
  );

  function handleClickOnCustomer(index: number) {
    setHoveredItem(index);
    if (index > -1 && data && data.customers[index]) {
      handleAutoComplete(data.customers[index]);
      closeDropDown();
    }
  }

  function handleSelectCustomer() {
    if (hoveredItem > -1 && data && data.customers[hoveredItem]) {
      handleAutoComplete(data.customers[hoveredItem]);
      closeDropDown();
    }
  }

  function handleNavigateDown(e?: KeyboardEvent) {
    e?.preventDefault();
    setHoveredItem((prev) =>
      prev + 1 < (data?.customers.length || 0) ? prev + 1 : 0
    );
  }

  function handleNavigateUp(e?: KeyboardEvent) {
    e?.preventDefault();
    setHoveredItem((prev) =>
      prev - 1 >= 0 ? prev - 1 : (data?.customers.length || 0) - 1
    );
  }

  function formatedQuery() {
    let query = `?limit=${limit}`;

    if (name && !!value) {
      if (name === "givenname" || name === "familyname" || name === "email") {
        query += `&search=${value}`;
      } else {
        query += `&${name}=${value
          .toString()
          .replace(/[.*+?^${}()|[\]\\]/g, "")}`;
      }
    }
    return query;
  }

  function showMore() {
    if (!data) {
      return;
    }
    setLimit((prev) =>
      prev + 5 <= data?.totalCount ? prev + 5 : data?.totalCount
    );
  }

  function mainInfo(customer: ICustomer) {
    switch (name) {
      case "email":
        return highlightedString(
          ((value || "") as string).toLocaleLowerCase(),
          customer.email
        );
      case "phone":
        return highlightedString((value || "") as string, customer.phone);
      default:
        return highlightedString(
          ((value || "") as string).toLocaleUpperCase(),
          (customer.givenname + " " + customer.familyname).toLocaleUpperCase()
        );
    }
  }

  function secondaryInfo(customer: ICustomer) {
    switch (name) {
      case "email":
        return (
          <>
            <div className={style["secondary-name-info"]}>
              {customer.givenname + " " + customer.familyname}
            </div>
            <div className={style["secondary-info"]}>{customer.phone}</div>
          </>
        );
      case "phone":
        return (
          <>
            <div className={style["secondary-name-info"]}>
              {customer.givenname + " " + customer.familyname}
            </div>
            <div className={style["secondary-info"]}>{customer.email}</div>
          </>
        );
      default:
        return (
          <>
            <div className={style["secondary-info"]}>{customer.email}</div>
            <div className={style["secondary-info"]}>{customer.phone}</div>
          </>
        );
    }
  }

  function highlightedString(bold: string, rawStr?: string) {
    if (!rawStr) {
      return "";
    }
    const rawBold =
      name === "phone" ? bold.replace(/[.*+?^${}()|[\]\\]/g, "") : bold;
    const splited = rawStr.split(new RegExp(rawBold, "i"));
    return (
      <div className={style["highlighted-string"]}>
        {splited.map((str, index: number) => (
          <div className={style["highlighted-string"]} key={index}>
            <div
              style={{ whiteSpace: "pre-wrap" }}
              className={style["not-highlighted"]}
            >
              {str}
            </div>
            {index < splited.length - 1 && (
              <div
                style={{ whiteSpace: "pre-wrap" }}
                className={style["highlighted"]}
              >
                {rawBold}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }
}
