import { IQuote } from "../../../types/accounting.types";
import styles from "./item.module.scss";
import { Delete, Edit, Launch, Send } from "@mui/icons-material";
import QuoteModal from "../../../components/modals/quoteModal/quoteModal";
import MoreTools, {
  IMoreToolsOptions,
} from "../../../components/MoreTools/MoreTools";
import CenterCell from "../../../components/CenterCell/CenterCell";
import State from "../../../components/State/State";
import clsx from "clsx";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";
import Price from "../../../components/Price/Price";
import { useState } from "react";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";

export default function Item({
  data,
  onDelete,
  handleSendQuote,
  zIndex,
}: {
  data: IQuote;
  onDelete: (id?: string) => void;
  handleSendQuote: (value: IQuote) => void;
  zIndex: number;
}) {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      icon: <Launch />,
      name: "Ouvrir",
      onClick: () => handleOpenQuoteModal(),
    },
    {
      icon: <Edit />,
      name: "Modifier",
      to: `/quotes/edit/${data.id}`,
    },
    {
      icon: <Delete />,
      name: "Supprimer",
      askConfirmation: true,
      onClick: () => onDelete(data.id),
    },
  ];

  if (data.state === "QUOTE_PENDING") {
    MORE_TOOLS_OPTIONS.unshift({
      icon: <Send />,
      name: "Envoyer devis",
      askConfirmation: true,
      onClick: () => handleSendQuote(data),
    });
  }

  return (
    <>
      <tr className={styles["item"]}>
        <td
          className={clsx(styles["item-cell"], styles["item-date"])}
          title={`${data?.sentAt && new Date(data?.sentAt).toLocaleString()}`}
        >
          {data.sentAt
            ? new Date(data.sentAt).toLocaleDateString()
            : "indisponible"}
        </td>
        <td
          className={clsx(styles["item-cell"], styles["item-date"])}
          title={`${
            data?.repliedAt && new Date(data?.repliedAt).toLocaleString()
          }`}
        >
          {data.repliedAt
            ? new Date(data.repliedAt).toLocaleDateString()
            : "indisponible"}
        </td>
        <td className={clsx(styles["item-cell"], styles["item-date"])}>
          {data.contact?.displayname}
        </td>
        <td className={clsx(styles["item-cell"], styles["item-date"])}>
          <CenterCell>{data.maximumDurationDays}</CenterCell>
        </td>
        <td className={clsx(styles["item-cell"])}>
          <Price price={data.total} />
        </td>
        <td className={`${styles["item-cell"]} ${styles["status-box"]}`}>
          <CenterCell>
            <State state={data.state} />
          </CenterCell>
        </td>
        <td className={`${styles["item-cell"]} ${styles["tools"]}`}>
          <ProtectedComponent roles={["ROLE_ADMIN"]}>
            <CenterCell>
              <MoreTools options={MORE_TOOLS_OPTIONS} />
            </CenterCell>
          </ProtectedComponent>
        </td>
      </tr>
      <ModalContainer isOpen={modalIsOpen} onCancel={handleCloseQuoteModal}>
        <QuoteModal id={data.id} onClose={handleCloseQuoteModal} />
      </ModalContainer>
    </>
  );

  function handleOpenQuoteModal() {
    setModalIsOpen(true);
  }

  function handleCloseQuoteModal() {
    setModalIsOpen(false);
  }
}
