import Button from "components/Button/Button";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";
import SectionTitle from "components/SectionTitle/SectionTitle";
import { DropOffForm } from "types/logistic.types";
import DiscountListModal from "./DiscountListModal/DiscountListModal";
import styles from "./dropOffDiscountSection.module.scss";
import { useState } from "react";
import { IDiscount } from "types/accounting.types";
import { Close } from "@mui/icons-material";

interface DropOffDiscountSectionProps {
  dropOff: DropOffForm;
  onDropOffChange: (dropOff: DropOffForm) => void;
}

export default function DropOffDiscountSection({
  dropOff,
  onDropOffChange,
}: DropOffDiscountSectionProps) {
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <>
      <SectionTitle title="Code promo" />
      <div className={styles["discount-section"]}>
        <p className={styles["discount"]} onClick={resetDiscount}>
          {dropOff.discount ? dropOff.discount.code : "aucun code promo"}
          {!!dropOff.discount && (
            <div className={styles["close-button"]}>
              <Close />
            </div>
          )}
        </p>
        <Button onClick={handleOpenModal}>Ajouter un code promo</Button>
      </div>
      <ModalContainer
        isOpen={openModal}
        width="narrow"
        onCancel={handleCloseModal}
      >
        <DiscountListModal onDiscountSelect={handleDiscountChange} />
      </ModalContainer>
    </>
  );

  function handleCloseModal() {
    setOpenModal(false);
  }

  function handleOpenModal() {
    setOpenModal(true);
  }

  function handleDiscountChange(discount: IDiscount) {
    onDropOffChange({ ...dropOff, discount });
    setOpenModal(false);
  }

  function resetDiscount() {
    onDropOffChange({ ...dropOff, discount: null });
  }
}
