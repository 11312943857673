import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchQuote } from "../../../../../requests/quote";
import OverviewSection from "../OverviewSection/OverviewSection";
import styles from "./quoteSection.module.scss";
import translate from "../../../../../utils/translation";
import { IInvoice } from "../../../../../types/tracker.types";
import { ModalContainer } from "../../../../../components/modals/ModalContainer/ModalContainer";
import QuoteModal from "../../../../../components/modals/quoteModal/quoteModal";
import OverviewSectionHeader from "../OverviewSectionHeader/OverviewSectionHeader";
import QuoteProduct from "./QuoteProduct/QuoteProduct";
import QuoteSumUp from "./QuoteSumUp/QuoteSumUp";
import PictureLoadingSpinner from "../../../../../components/loadingSpinner/pictureloadingSpinner";
import DataError from "../../../../../components/errors/DataError/DataError";
import QuoteAction from "./QuoteAction/QuoteAction";
import State from "../../../../../components/State/State";
import RequiredAction from "../../../../../components/RequiredAction/RequiredAction";
import QuoteCrossSell from "./QuoteCrossSell/QuoteCrossSell";

interface QuoteSectionProps {
  quoteId: string;
  invoice?: IInvoice;
  requiredActions?: boolean;
}

export default function QuoteSection({
  quoteId,
  invoice,
  requiredActions,
}: QuoteSectionProps) {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const {
    data: quote,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["quote-overview", quoteId],
    queryFn: () => fetchQuote(quoteId),
    refetchOnWindowFocus: false,
    enabled: Boolean(quoteId),
  });

  if (!quoteId) {
    return <></>;
  }

  if (isLoading) {
    return (
      <OverviewSection className={styles["loading-section"]}>
        <PictureLoadingSpinner />
      </OverviewSection>
    );
  }

  if (!quote) {
    <OverviewSection className={styles["loading-section"]}>
      <DataError error={"quote"} />
    </OverviewSection>;
  }

  return (
    <OverviewSection className={styles["quote-section"]}>
      <OverviewSectionHeader title={"Devis"} onClick={handleOpenModal}>
        <State state={quote?.state} className={styles["state"]} />
      </OverviewSectionHeader>
      {requiredQuoteActions() && (
        <RequiredAction className={styles["required-action"]} />
      )}
      <ul className={styles["product-list"]}>
        {quote?.productsList?.map((product, i) => (
          <QuoteProduct product={product} key={i} />
        ))}
      </ul>
      <ul className={styles["product-list"]}>
        {quote?.crossSellItems?.map((crossSell, i) => (
          <QuoteCrossSell crossSell={crossSell} key={i} />
        ))}
      </ul>
      <QuoteSumUp
        className={styles["sub-total"]}
        title={"Sous-total"}
        value={(quote?.operationsTotal || 0) + (quote?.salesTotal || 0)}
      />
      <QuoteSumUp
        className={styles["sub-total"]}
        title={`Livraison (${translate(quote?.shipping?.shippingService)})`}
        value={quote?.shipping?.price || 0}
      />
      {!!quote?.discountTotal && (
        <QuoteSumUp
          title={"Code promo"}
          value={-quote?.discountTotal}
          className={styles["sub-total"]}
        />
      )}
      <QuoteSumUp
        title={"Total"}
        value={quote?.total || 0}
        className={styles["total"]}
      />
      <QuoteAction trackId={quote?.trackId} />
      <ModalContainer isOpen={modalIsOpen} onCancel={handleCloseModal}>
        <QuoteModal
          id={quoteId}
          invoice={invoice}
          requiredActions={requiredActions}
          onClose={handleCloseModal}
        />
      </ModalContainer>
    </OverviewSection>
  );

  function requiredQuoteActions() {
    return requiredActions && quote?.state === "QUOTE_VALIDATED";
  }

  function handleCloseModal() {
    setModalIsOpen(false);
    refetch();
  }

  function handleOpenModal() {
    setModalIsOpen(true);
  }
}
