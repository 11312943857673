import { ICrossSellProduct } from "types/accounting.types";
import CrossSellEntry from "./CrossSellEntry/CrossSellEntry";
import styles from "./crossSellSection.module.scss";
import SectionTitle from "components/SectionTitle/SectionTitle";

interface CrossSellSectionProps {
  crossSellsList: ICrossSellProduct[];
  onChange: (crossSell: ICrossSellProduct, index: number) => void;
  onDelete: (index: number) => void;
}

export default function CrossSellSection({
  crossSellsList,
  onChange,
  onDelete,
}: CrossSellSectionProps) {
  if (crossSellsList.length <= 0) {
    return null;
  }

  return (
    <>
      <SectionTitle
        title={"Autres articles"}
        className={styles["section-title"]}
      />
      <div className={styles["cross-sell-list"]}>
        {crossSellsList.map((cs, index) => (
          <CrossSellEntry
            key={index}
            crossSell={cs}
            onChange={(crossSell) => onChange(crossSell, index)}
            onDelete={() => onDelete(index)}
          />
        ))}
      </div>
    </>
  );
}
