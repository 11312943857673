import {
  DeleteForeverOutlined,
  Done,
  DoneAll,
  RemoveCircleOutline,
  Undo,
} from "@mui/icons-material";
import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import Button from "components/Button/Button";
import moment from "moment";
import ProductCard from "pages/quickAccess/workshop/productCard/ProductCard";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  deleteSeries,
  getSeries,
  removeProductFromSeries,
  updateAllProductsState,
  updateProductState,
  updateSeries,
} from "requests/series";
import { IProduct, Series } from "types/logistic.types";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "utils/toast.options";
import ConfirmationModalDispatch from "../ConfirmationModalDispatch/ConfirmationModalDispatch";
import { ModalContainer } from "../ModalContainer/ModalContainer";
import styles from "./seriesModal.module.scss";

function SeriesModalHeader({
  series,
  onSeriesDeleted,
  onSeriesDone,
  onSeriesUpdated,
}: {
  series: Series;
  onSeriesDeleted?: (seriesId: string) => void;
  onSeriesDone?: (seriesId: string) => void;
  onSeriesUpdated?: (seriesId: string, series: Series) => void;
}) {
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false);

  function handleStartDateChange(date: string) {
    let startDate = moment(series.startDate);
    let endDate = moment(series.endDate);
    let initialDiff = startDate.diff(endDate, "days");
    let newStartDate = moment(date + "T12:00:00");
    let newEndDate = moment(date + "T12:00:00").add(initialDiff, "days");
    onSeriesUpdated &&
      onSeriesUpdated(series.id, {
        ...series,
        startDate: newStartDate.toDate(),
        endDate: newEndDate.toDate(),
      });
  }

  function handleEndDateChange(date: string) {
    let newEndDate = moment(date + "T12:00:00");
    onSeriesUpdated &&
      onSeriesUpdated(series.id, {
        ...series,
        endDate: newEndDate.toDate(),
      });
  }

  return (
    <>
      <div className={styles["series-modal-header"]}>
        <div className={styles["series-modal-header-actions"]}>
          <Button
            className={styles["series-modal-header-done"]}
            onClick={() => onSeriesDone && onSeriesDone(series.id)}
          >
            <DoneAll />
            <span className={styles["series-modal-button-text"]}>
              Terminer Série
            </span>
          </Button>

          <Button
            className={styles["series-modal-header-delete"]}
            onClick={() => setIsDeleteConfirmModalOpen(true)}
          >
            <DeleteForeverOutlined />
            <span className={styles["series-modal-button-text"]}>
              Supprimer Série
            </span>
          </Button>
        </div>

        <div className={styles["series-modal-header-title"]}>{series.name}</div>
        <div className={styles["series-modal-header-product-count-container"]}>
          <b className={styles["series-modal-header-product-count"]}>
            {series.productsList.length}
          </b>{" "}
          / 16
        </div>
        <div className={styles["series-modal-header-date-range"]}>
          <input
            type="date"
            value={moment(series.startDate).format("YYYY-MM-DD")}
            onChange={({ target }) => handleStartDateChange(target.value)}
          />
          <input
            type="date"
            value={moment(series.endDate).format("YYYY-MM-DD")}
            min={moment(series.startDate).format("YYYY-MM-DD")}
            onChange={({ target }) => handleEndDateChange(target.value)}
          />
        </div>
      </div>
      <ModalContainer
        isOpen={isDeleteConfirmModalOpen}
        onCancel={() => setIsDeleteConfirmModalOpen(false)}
        width="fit"
        height="fit"
      >
        <ConfirmationModalDispatch
          option={{ name: "Mon cul" }}
          onCancel={() => setIsDeleteConfirmModalOpen(false)}
          onConfirm={() => {
            onSeriesDeleted && onSeriesDeleted(series.id);
            setIsDeleteConfirmModalOpen(false);
          }}
        />
      </ModalContainer>
    </>
  );
}

function ActionsComponent({
  onRemoveClick,
  onStateChangeClick,
  productState,
  state,
  ...props
}: {
  onRemoveClick: () => void;
  onStateChangeClick: (
    state: "1_TODO" | "2_IN_PROGRESS" | "3_DONE" | "4_CONTROLLED"
  ) => void;
  productState: string;
  state: "1_TODO" | "2_IN_PROGRESS" | "3_DONE" | "4_CONTROLLED";
} & React.ComponentProps<"div">) {
  return (
    <div
      {...props}
      className={clsx(
        styles["series-modal-content-product-actions-container"],
        props.className
      )}
    >
      <Button
        className={styles["series-modal-content-remove-product-button"]}
        onClick={onRemoveClick}
      >
        <RemoveCircleOutline
          className={styles["series-modal-content-button-icon"]}
        />{" "}
      </Button>
      <Button
        className={styles["series-modal-content-product-done-button"]}
        onClick={() =>
          onStateChangeClick(state === "3_DONE" ? "2_IN_PROGRESS" : "3_DONE")
        }
      >
        {state === "3_DONE" ? (
          <Undo className={styles["series-modal-content-button-icon"]} />
        ) : (
          <Done className={styles["series-modal-content-button-icon"]} />
        )}
      </Button>
    </div>
  );
}

export default function SeriesModal({
  seriesId,
  initialProductId,
  onProductRemoved,
  onSeriesDeleted,
  onSeriesUpdated,
}: {
  seriesId: string;
  initialProductId?: string;
  onProductRemoved?: () => void;
  onSeriesDeleted?: () => void;
  onSeriesUpdated?: () => void;
}) {
  const [selectedProductId, setSelectedProductId] = useState(initialProductId);

  const { data: series, refetch } = useQuery({
    queryKey: [`series-modal-series`, seriesId],
    queryFn: () => getSeries(seriesId),
    placeholderData: keepPreviousData,
  });

  const removeProductMutation = useMutation({
    mutationFn: removeProductFromSeries,
    onError: () => {
      toast.error("Une erreur est survenue.", TOAST_ERROR_OPTIONS);
    },
    onSuccess: (data) => {
      toast.success("Aricle retiré de la série.", TOAST_SUCCESS_OPTIONS);
      onProductRemoved && onProductRemoved();
    },
    onSettled: () => {
      refetch();
    },
  });

  const deleteSeriesMutation = useMutation({
    mutationFn: deleteSeries,
    onError: () => {
      toast.error("Une erreur est survenue.", TOAST_ERROR_OPTIONS);
    },
    onSuccess: (data) => {
      toast.success(data.message, TOAST_SUCCESS_OPTIONS);
      onSeriesDeleted && onSeriesDeleted();
    },
    onSettled: () => {
      refetch();
    },
  });

  const updateProductStateMutation = useMutation({
    mutationFn: updateProductState,
    onError: () => {
      toast.error("Une erreur est survenue.", TOAST_ERROR_OPTIONS);
    },
    onSuccess: (data) => {
      onSeriesUpdated && onSeriesUpdated();
    },
    onSettled: () => {
      refetch();
    },
  });

  const updateAllProductsStateMutation = useMutation({
    mutationFn: updateAllProductsState,
    onError: () => {
      toast.error("Une erreur est survenue.", TOAST_ERROR_OPTIONS);
    },
    onSuccess: (data) => {
      onSeriesUpdated && onSeriesUpdated();
    },
    onSettled: () => {
      refetch();
    },
  });

  const updateSeriesMutation = useMutation({
    mutationFn: updateSeries,
    onError: () => {
      toast.error("Une erreur est survenue.", TOAST_ERROR_OPTIONS);
    },
    onSuccess: (data) => {
      toast.success("Série mise à jour.", TOAST_SUCCESS_OPTIONS);
      onSeriesUpdated && onSeriesUpdated();
    },
    onSettled: () => {
      refetch();
    },
  });

  useEffect(() => {
    if (selectedProductId && series) {
      let elem = document.getElementById(`product-${selectedProductId}`);
      elem?.scrollIntoView();
    }
  }, [selectedProductId, series]);

  return (
    <div className={styles["series-modal-container"]}>
      <div className={styles["series-modal-content"]}>
        <div className={styles["series-modal-header-container"]}>
          {series && (
            <SeriesModalHeader
              onSeriesDeleted={handleSeriesDeleted}
              onSeriesUpdated={handleSeriesUpdated}
              onSeriesDone={handleSeriesDone}
              series={series}
            />
          )}
        </div>
        <hr />
        {series &&
          (series.productsList || []).map((p) => (
            <div className={styles["series-modal-product-card-container"]}>
              <ProductCard
                key={`product-${p.id}`}
                id={`product-${p.id}`}
                product={p}
                showOperationAcronym={true}
                showDate={false}
                disableOnClick={true}
                isActive={selectedProductId === p.id}
                actionsComponent={(props: any) => (
                  <ActionsComponent
                    state={(p as any).state}
                    onStateChangeClick={(
                      state:
                        | "1_TODO"
                        | "2_IN_PROGRESS"
                        | "3_DONE"
                        | "4_CONTROLLED"
                    ) => handleStateChange(series.id, p.id || "", state)}
                    onRemoveClick={() => handleRemoveClicked(p)}
                    {...props}
                  />
                )}
                className={clsx(
                  styles[
                    `series-modal-product-card-state-${(p as any).state.toLowerCase()}`
                  ]
                )}
              />

            </div>
          ))}
      </div>
    </div>
  );

  function handleSeriesDeleted(seriesId: string) {
    deleteSeriesMutation.mutate(seriesId);
  }

  function handleSeriesUpdated(seriesId: string, series: Series) {
    updateSeriesMutation.mutate({ id: seriesId, series });
  }

  function handleSeriesDone(seriesId: string) {
    updateAllProductsStateMutation.mutate({ seriesId, state: "3_DONE" });
  }

  function handleStateChange(
    seriesId: string,
    productId: string,
    state: "1_TODO" | "2_IN_PROGRESS" | "3_DONE" | "4_CONTROLLED"
  ) {
    updateProductStateMutation.mutate({ seriesId, productId, state });
  }

  function handleRemoveClicked(product: IProduct) {
    if (!product.id) {
      toast.error("Une erreur est survenue.", TOAST_ERROR_OPTIONS);
      return;
    }
    removeProductMutation.mutate({
      seriesId: seriesId,
      productId: product.id,
    });
  }
}
