import { Launch } from "@mui/icons-material";
import styles from "./overviewButton.module.scss";
import { Link } from "react-router-dom";
import clsx from "clsx";

interface OverviewButtonProps {
  trackId?: string;
  className?: string;
}

export default function OverviewButton({
  className,
  trackId,
}: OverviewButtonProps) {
  if (!trackId) {
    return <></>;
  }
  return (
    <Link
      className={clsx(styles["overview-button"], className)}
      to={`/overview/${trackId}`}
      title={"Vue d'ensemble"}
    >
      <Launch className={styles["icon"]} />
    </Link>
  );
}
