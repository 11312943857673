import { MoreVert } from "@mui/icons-material";
import { clsx } from "clsx";
import styles from "./moreButton.module.scss";

interface MoreButtonProps {
  className?: string;
  onClick: () => void;
  buttonRef: React.MutableRefObject<HTMLDivElement | null>;
}

export default function MoreButton({
  className,
  onClick,
  buttonRef,
}: MoreButtonProps) {
  return (
    <div
      className={clsx(styles["more-button"], className)}
      onClick={onClick}
      ref={buttonRef}
    >
      <MoreVert />
    </div>
  );
}
