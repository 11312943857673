
import Icon from "components/Icon/Icon";
import Widget from "../../Widget";
import styles from "./seriesWidget.module.scss";
import seriesIcon from "assets/icons/calendrier-atelier.png";

export default function SeriesWidget() {
  return (
    <Widget to={"cobbler/series/calendar"}>
      <div className={styles["icon-container"]}>
        <Icon logo={seriesIcon} className={styles["icon"]} />
      </div>
      <div>Planning Cordo</div>
    </Widget>
  );
}