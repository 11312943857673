import { IProduct, Series } from "types/logistic.types";


export async function updateAllProductsState({
  seriesId,
  state,
}: {
  seriesId: string;
  state: "1_TODO" | "2_IN_PROGRESS" | "3_DONE" | "4_CONTROLLED";
}): Promise<{ series: Series }> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/series/${seriesId}/update-all-products-state`,
      {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ state }),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}


export async function updateProductState({
  seriesId,
  productId,
  state,
}: {
  seriesId: string;
  productId: string;
  state: "1_TODO" | "2_IN_PROGRESS" | "3_DONE" | "4_CONTROLLED";
}): Promise<{ series: Series }> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/series/${seriesId}/update-product-state/${productId}`,
      {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ state }),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function updateSeries({
  id,
  series,
}: {
  id: string;
  series: Series;
}): Promise<{ series: Series }> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/series/${id}`,
      {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(series),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function deleteSeries(id?: string): Promise<{ message: string }> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/series/${id}`,
      {
        method: "DELETE",
        credentials: "include",
        // signal: request.signal,
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getSeries(id?: string): Promise<Series> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/series/${id}`,
      {
        credentials: "include",
        // signal: request.signal,
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.series;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getSeriesList(
  query?: string
): Promise<{ totalCount: number; seriesList: Series[] }> {
  try {
    let queryUrl = query ? query : "";
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/series` + queryUrl,
      {
        credentials: "include",
        // signal: request.signal,
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function createSeries({ series }: { series: Series }) {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/series`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(series),
    });
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getUnassignedProducts(
  query?: string
): Promise<{ totalCount: number; productsList: IProduct[] }> {
  try {
    let queryUrl = query ? query : "";
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/series/unassigned-products` + queryUrl,
      {
        credentials: "include",
        // signal: request.signal,
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function addProductToSeries({
  seriesId,
  product,
}: {
  seriesId: string;
  product: IProduct;
}) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/series/${seriesId}/add-product`,
      {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(product),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function removeProductFromSeries({
  seriesId,
  productId,
}: {
  seriesId: string;
  productId: string;
}) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/series/${seriesId}/remove-product/${productId}`,
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}
