import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import HideSideBarProvider from "./context/hideSideBar.context";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { isDesktop } from "react-device-detect";
import { TouchBackend } from "react-dnd-touch-backend";
import CustomRouter from "./routes";
import AuthProvider from "./context/auth.context";
import PrinterProvider from "./context/printer.context";
import "utils/moment.locale.fr";

export default function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <DndProvider backend={isDesktop ? HTML5Backend : TouchBackend}>
          <PrinterProvider>
            <HideSideBarProvider>
              <ToastContainer />
              <CustomRouter />
              <div id="modal-root"></div>
            </HideSideBarProvider>
          </PrinterProvider>
        </DndProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}
