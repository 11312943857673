import styles from "./actionSection.module.scss";
import { DropOffForm } from "types/logistic.types";
import AddContactSection from "./AddContactSection/AddContactSection";
import ValidateFormSection from "./ValidateFormSection/ValidateFormSection";

interface ActionSectionProps {
  dropOff: DropOffForm;
  onDropOffChange: (dropOff: DropOffForm) => void;
}

export default function ActionSection({
  dropOff,
  onDropOffChange,
}: ActionSectionProps) {
  return (
    <div className={styles["action-container"]}>
      <AddContactSection
        className={styles["action-container-left"]}
        dropOff={dropOff}
        onDropOffChange={onDropOffChange}
      />
      <ValidateFormSection
        className={styles["action-container-right"]}
        dropOff={dropOff}
        onDropOffChange={onDropOffChange}
      />
    </div>
  );
}
