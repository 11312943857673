import { LocalOfferOutlined } from "@mui/icons-material";
import style from "./workshopId.module.scss";

interface WorkshopIdProps {
  className?: string;
  size?: string;
  type?: string;
  workshopId: string | undefined;
}

export default function WorkshopId({
  className,
  size,
  type,
  workshopId,
}: WorkshopIdProps) {
  return (
    <div
      className={`${
        style["workshop-id"]
      } ${sizeStyle()} ${canBePro()} ${typeStyle()} ${className}`}
    >
      {workshopId}
      <LocalOfferOutlined className={style["icon"]} />
      {!workshopId?.startsWith("1-1-") && !!workshopId && (
        <div className={`${style["pro-logo"]} ${proSizeStyle()}`}>
          {type === "cross-sell" ? "C-S" : "PRO"}
        </div>
      )}
    </div>
  );

  function sizeStyle() {
    switch (size) {
      case "list-size":
        return style["list-size"];
      case "xxx-small":
        return style["xxx-small"];
      case "xx-small":
        return style["xx-small"];
      case "x-small":
        return style["x-small"];
      case "small":
        return style["small"];
      case "medium":
        return style["medium"];
      case "large":
        return style["large"];
      default:
        return "";
    }
  }

  function proSizeStyle() {
    switch (size) {
      case "xxx-small":
        return style["xxx-small-pro"];
      case "xx-small":
        return style["xx-small-pro"];
      case "x-small":
        return style["x-small-pro"];
      case "small":
        return style["small-pro"];
      case "medium":
        return style["medium-pro"];
      case "large":
        return style["large-pro"];
      default:
        return "";
    }
  }

  function typeStyle() {
    switch (type) {
      case "galoche":
        return style["galoche"];
      case "cross-sell":
        return style["cross-sell"];
      case "pro":
        return style["pro"];
      default:
        return "";
    }
  }

  function canBePro() {
    if (!workshopId?.startsWith("1-1-") && !!workshopId) {
      return style["pro-not-definitive"];
    }
  }
}
