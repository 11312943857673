import style from "./index.module.scss";
import PayedListWidget from "./DashboardWidget/PayedListWidget/PayedListWidget";
import UnfinishedListWidget from "./DashboardWidget/UnfinishedListWidget/UnfinishedListWidget";
import ProductListSection from "pages/quickAccess/inStoreDropOff/ProductListSection/ProductListSection";

export default function Home() {
  return (
    <div className={style["dashboard"]}>
      <div className={style["dashboard-grid"]}>
        <PayedListWidget />
        <UnfinishedListWidget />
      </div>
    </div>
  );
}
