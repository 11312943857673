import SectionTitle from "components/SectionTitle/SectionTitle";
import styles from "./repareTimeSection.module.scss";
import Input from "components/inputs/Input/Input";
import Button from "components/Button/Button";
import { CalendarMonth } from "@mui/icons-material";
import { DropOffForm } from "types/logistic.types";
import { useRef } from "react";
import CheckboxItem from "components/checkboxItem/CheckboxItem";
import { TimeSlot } from "types/shipping.types";
import { toast } from "react-toastify";
import DateComp from "components/Date/Date";

interface RepareTimeSectionProps {
  dropOff: DropOffForm;
  onRepareTimeChange: (repareDays: number) => void;
  onTimeSlotChange: (timeSlot: TimeSlot) => void;
}

export default function RepareTimeSection({
  dropOff,
  onRepareTimeChange,
  onTimeSlotChange,
}: RepareTimeSectionProps) {
  const ref = useRef<HTMLInputElement | null>(null);

  if (!dropOff.productList.length) {
    return null;
  }

  return (
    <div className={styles["repare-time-section"]}>
      <SectionTitle
        title={"Délais de réparation"}
        className={styles["section-title"]}
      />
      <div className={styles["repare-time"]}>
        <Input
          inputMode="numeric"
          placeholder={"Jours ouvrés"}
          value={dropOff.repareDays! || 0}
          onChange={handleRepareDaysChange}
        />
        <div className={styles["date-section"]}>
          <DateComp
            className={styles["date"]}
            date={addBusinessDays(dropOff.repareDays!)}
          />
          <input
            className={styles["date-input"]}
            ref={ref}
            type="date"
            onChange={({ target }) =>
              calculateBusinessDaysToDate(new Date(target.value))
            }
            value={addBusinessDays(dropOff.repareDays!)
              .toISOString()
              .substring(0, 10)}
          />
          <Button
            className={styles["date-button"]}
            onClick={handleOpenDatePicker}
          >
            <CalendarMonth />
          </Button>
        </div>
      </div>
      {!!dropOff.shippingService && !dropOff.nonDropOff && (
        <>
          <SectionTitle title={"Créneau"} className={styles["section-title"]} />
          <div className={styles["timeslot"]}>
            {dropOff.shippingService.businessHours.map((bh, index) => (
              <CheckboxItem
                key={index}
                checked={
                  dropOff.timeSlot?.start === bh.start &&
                  dropOff.timeSlot?.end === bh.end
                }
                onChecked={(checked) => handleSelectTimeSlot(checked, bh)}
                title={`${bh.start} - ${bh.end}`}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );

  function handleSelectTimeSlot(checked: boolean, timeSlot: TimeSlot) {
    if (checked) {
      onTimeSlotChange(timeSlot);
    }
  }

  function handleRepareDaysChange({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    if (!target.value) {
      return onRepareTimeChange(0);
    }
    const value = parseInt(target.value);
    if (isNaN(value)) {
      return;
    }
    onRepareTimeChange(value);
  }

  function handleOpenDatePicker() {
    ref?.current?.showPicker();
  }

  function defaultRepareDays() {
    if (dropOff.productList.some((p) => p.productGroup === "LEATHER")) {
      return 21;
    }
    return 7;
  }

  function addBusinessDays(days: number | null): Date {
    const today = new Date();
    if (!days) {
      return today;
    }
    let remainingDays = days;

    while (remainingDays > 0) {
      today.setDate(today.getDate() + 1); // Move to the next day
      const dayOfWeek = today.getDay(); // Get the day of the week (0 = Sunday, 6 = Saturday)

      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        // Skip weekends
        remainingDays--;
      }
    }

    return today;
  }

  function calculateBusinessDaysToDate(targetDate: Date) {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize to start of day
    targetDate.setHours(0, 0, 0, 0); // Normalize to start of day

    if (targetDate < today) {
      toast.error("La date doit être dans le futur.");
    }

    let businessDays = 0;
    const currentDate = new Date(today);

    while (currentDate < targetDate) {
      currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
      const dayOfWeek = currentDate.getDay(); // Get the day of the week (0 = Sunday, 6 = Saturday)

      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        // Skip weekends
        businessDays++;
      }
    }
    onRepareTimeChange(businessDays);
  }
}
