import { Outlet, RouteObject } from "react-router-dom";
import ErrorPage from "../errorPage/error-page";
import Create from "./create/create";
//
import Edit from "./edit/edit";
import Customers from ".";

// import { action as destroyAction } from "./destroy/destroy";

export const route: RouteObject = {
  path: "/customers",
  element: <Outlet />,

  errorElement: <ErrorPage />,
  children: [
    {
      index: true,
      element: <Customers />,
    },
    {
      path: "create",
      element: <Create />,
    },
    {
      path: "edit/:id",
      element: <Edit />,
    },
  ],
};
