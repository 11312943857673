import { useState } from "react";
import { Series } from "types/logistic.types";
import style from "./seriesCard.module.scss";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import PictureLoadingSpinner from "components/loadingSpinner/pictureloadingSpinner";
import ProductCard from "pages/quickAccess/workshop/productCard/ProductCard";
interface SeriesCardProps {
  series: Series;
}
export default function SeriesCard({ series }: SeriesCardProps) {
  const [showOperationList, setShowOperationList] = useState<boolean>(false);

  return (
    <>
      <div
        className={style["operation-type-card"]}
        onClick={() => setShowOperationList(!showOperationList)}
      >
        <div className={style["operation-type-card-left"]}>
          <div className={style["title"]}>
            <div className={style["chevron"]}>
              {showOperationList ? <ExpandMore /> : <ChevronRight />}
            </div>
            <span>{series.name || "Sans nom"}</span>
          </div>
          {
            series.startDate && series.endDate && (
              <span className={style["earliest-product-dueDate"]}>
            {`du ${new Date(series.startDate).toLocaleDateString("fr-FR", {
              day: "2-digit",
              month: "long",
              year: "numeric",
            })} au ${new Date(series.endDate).toLocaleDateString("fr-FR", {
              day: "2-digit",
              month: "long",
              year: "numeric",
            })}`}
          </span>
            )
          }
        </div>
        <div className={style["operation-type-card-right"]}>
          {series ? (
            series.productsList.length
          ) : (
            <PictureLoadingSpinner
              color="white"
              className={style["loading-spinner"]}
            />
          )}
        </div>
      </div>
      {showOperationList && (
        <>
          {/* <PagingComponent
                    query={query}
                    setQuery={setQuery}
                    totalCount={data?.totalCount}
                  /> */}
          <div className={style["product-list"]}>
            {series &&
              series.productsList.map((p, index: number) => {
                return (
                  <ProductCard
                    key={index}
                    product={p}
                    showOperationAcronym={true}
                  />
                );
              })}
          </div>
          <hr />
        </>
      )}
    </>
  );
}
