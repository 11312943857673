import Button from "components/Button/Button";
import styles from "./dropOffActionSection.module.scss";
import { DropOffForm } from "types/logistic.types";
import { GP_SHIPPING_ADDRESS } from "utils/shipping.init";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import {
  postDropOff,
  updateDropOff,
  updateNonDropOffCheckIn,
} from "requests/dropOff";
import { toast } from "react-toastify";
import { STD_ERROR_MESSAGE } from "utils/constants";
import { TOAST_ERROR_OPTIONS } from "utils/toast.options";
import { INITIAL_DROPOFF } from "utils/logistic.init";

interface DropOffActionSectionProps {
  dropOff: DropOffForm;
  onClose: () => void;
  onDropOffChange: (dropOff: DropOffForm) => void;
  onPrevious?: () => void | null;
}

export default function DropOffActionSection({
  dropOff,
  onClose,
  onDropOffChange,
  onPrevious,
}: DropOffActionSectionProps) {
  const navigate = useNavigate();
  const postMutation = useMutation({
    mutationFn: postDropOff,
    onSuccess: ({ trackId, redirect }) => {
      onDropOffChange(INITIAL_DROPOFF);
      if (redirect === "overview") {
        navigate(`/overview/${trackId}`);
      } else {
        navigate(`/quick-access/in-store-drop-off`);
      }
      onClose();
    },
    onError: () => {
      toast.error(STD_ERROR_MESSAGE, TOAST_ERROR_OPTIONS);
    },
  });
  const updateMutation = useMutation({
    mutationFn: updateDropOff,
    onSuccess: ({ trackId, redirect }) => {
      onDropOffChange(INITIAL_DROPOFF);
      if (redirect === "overview") {
        navigate(`/overview/${trackId}`);
      } else {
        navigate(`/in-store-drop-off`);
      }
      onClose();
    },
    onError: () => {
      toast.error(STD_ERROR_MESSAGE, TOAST_ERROR_OPTIONS);
    },
  });
  const updateNonDropOffCheckInMutation = useMutation({
    mutationFn: updateNonDropOffCheckIn,
    onSuccess: ({ trackId, redirect }) => {
      onDropOffChange(INITIAL_DROPOFF);
      if (redirect === "overview") {
        navigate(`/overview/${trackId}`);
      } else {
        navigate(`/in-store-drop-off`);
      }
      onClose();
    },
    onError: () => {
      toast.error(STD_ERROR_MESSAGE, TOAST_ERROR_OPTIONS);
    },
  });

  return (
    <div className={styles["button-container"]}>
      {onPrevious && <Button onClick={onPrevious}>Retour</Button>}
      <Button
        isLoading={
          dropOff.trackId ? updateMutation.isPending : postMutation.isPending
        }
        onClick={() => handlePostForm("in-store-drop-off")}
      >
        Valider et commencer une nouvelle commande
      </Button>
      <Button
        isLoading={
          dropOff.trackId ? updateMutation.isPending : postMutation.isPending
        }
        onClick={() => handlePostForm("overview")}
      >
        Valider
      </Button>
    </div>
  );

  function handlePostForm(redirect: string) {
    if (dropOff.shippingService.name === "DROP-OR-COLLECT") {
      dropOff.shippingAddress = GP_SHIPPING_ADDRESS;
    }
    dropOff.productList.map((p) => {
      delete p.id;
      return p;
    });
    dropOff.contact = {
      ...dropOff.contact,
      address1: dropOff.shippingAddress?.address1,
      address2: dropOff.shippingAddress?.address2,
      zipCode: dropOff.shippingAddress?.zipCode,
      city: dropOff.shippingAddress?.city,
      country: dropOff.shippingAddress?.country,
      countryCode: dropOff.shippingAddress?.countryCode,
    };
    if (dropOff.trackId) {
      if (dropOff.nonDropOff) {
        updateNonDropOffCheckInMutation.mutate({ dropOff, redirect });
      } else {
        updateMutation.mutate({ dropOff, redirect });
      }
    } else {
      postMutation.mutate({ dropOff, redirect });
    }
  }
}
