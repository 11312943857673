import climbingIcon from "assets/icons/climbing-shoes.png";
import Icon from "components/Icon/Icon";

interface ClimbingIconProps {
  className?: string;
}

export default function ClimbingIcon({ className }: ClimbingIconProps) {
  return <Icon logo={climbingIcon} className={className} />;
}
