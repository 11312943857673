import Icon from "components/Icon/Icon";
import proDeliveryIcon from "assets/icons/delivery-truck.png";

interface ProDeliveryIconProps {
  className?: string;
}

export default function ProDeliveryIcon({ className }: ProDeliveryIconProps) {
  return <Icon logo={proDeliveryIcon} className={className} />;
}
