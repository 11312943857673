import { DropOffForm } from "types/logistic.types";
import styles from "./addShippingAddressSection.module.scss";
import AddShippingAddress from "./AddShippingAddress/AddShippingAddress";
import { IShippingPoint } from "types/shipping.types";
import Button from "components/Button/Button";

interface AddShippingAddressSectionProps {
  dropOff: DropOffForm;
  onShippingAddressChange: (address: IShippingPoint) => void;
  validateAddress: () => void;
}

export default function AddShippingAddressSection({
  dropOff,
  onShippingAddressChange,
  validateAddress,
}: AddShippingAddressSectionProps) {
  return (
    <div className={styles["validate-drop-off-modal"]}>
      <AddShippingAddress
        dropOff={dropOff}
        onAddressChange={onShippingAddressChange}
      />
      <div className={styles["button-container"]}>
        <Button onClick={validateAddress}>Confirmer l'adresse</Button>
      </div>
    </div>
  );
}
