import { useEffect, useState } from "react";

export function useDebounceQuery(deps: React.DependencyList, time: number) {
  const [queryParams, setQueryParams] = useState(deps);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    setIsLoading(true);
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      setQueryParams(deps);
      setIsLoading(false);
    }, time);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, deps);

  return { queryParams, isLoading };
}
