import Button from "components/Button/Button";
import styles from "./logisticConfirmModal.module.scss";

interface LogisticConfirmModalProps {
  body: JSX.Element;
  isLoading: boolean;
  onValidate: () => void;
  onCancel: () => void;
}

export default function LogisticConfirmModal({
  body,
  isLoading,
  onValidate,
  onCancel,
}: LogisticConfirmModalProps) {
  return (
    <div className={styles["logistic-confirm-modal"]}>
      <h3 className={styles["title"]}>
        {"Voulez-vous mettre à jour cet document logistique ?"}
      </h3>
      {body}
      <div className={styles["button-section"]}>
        <Button isLoading={isLoading} onClick={onValidate}>
          Oui
        </Button>
        <Button onClick={onCancel}>Non</Button>
      </div>
    </div>
  );
}
