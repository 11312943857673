import Select from "../../../../../../components/Select/Select";
import { REFASHION_OPERATIONS_PRICE } from "../../../../../../utils/operation.init";
import SectionFormTemplate from "../../../../../../components/forms/sectionFormTemplate/SectionFormTemplate";
import { OperationForm } from "../../../../../../types/accounting.types";
import styles from "./refashionSection.module.scss";
import clsx from "clsx";
import { isDesktop } from "react-device-detect";

interface RefashionSectionProps {
  operationForm: OperationForm;
  onChange: (name: string, value: string) => void;
  onReset: () => void;
}

export default function RefashionSection({
  operationForm,
  onChange,
  onReset,
}: RefashionSectionProps) {
  return (
    <SectionFormTemplate title={"Refashion"}>
      <div className={styles["refashion-section"]}>
        <Select
          placeholder="Opération Refashion..."
          name="operationType"
          className={styles["refashion-select"]}
          optionsList={REFASHION_OPERATIONS_PRICE.map(
            (rf) =>
              rf.refashionOperation + " - " + rf.refashionDiscount / 100 + " €"
          )}
          value={
            operationForm.refashionOperation
              ? operationForm.refashionOperation +
                " - " +
                (operationForm.refashionDiscount || 0) / 100 +
                " €"
              : ""
          }
          setValue={(value) => onChange("refashionDiscount", value)}
        />
        {!!operationForm.refashionDiscount && (
          <p
            className={clsx(
              styles["reset"],
              isDesktop ? styles["hover"] : styles["active"]
            )}
            onClick={onReset}
          >
            Retirer bonus refashion
          </p>
        )}
      </div>
    </SectionFormTemplate>
  );
}
