import moment from "moment";
import style from "./gpCalendarHeader.module.scss";
import clsx from "clsx";
import {
  ArrowBackIos,
  ArrowForwardIos,
  CalendarViewDay,
  CalendarViewWeek,
  EventRepeatOutlined,
} from "@mui/icons-material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";
import React, { ReactElement } from "react";

export function GPCalendarHeaderButton({
  onClick,
  icon,
}: {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  icon: (props: any) => ReactElement<any>;
}) {
  return (
    <div className={style["gp-calendar-header-button"]} onClick={onClick}>
      {icon({ className: style["gp-calendar-header-button-icon"] })}
    </div>
  );
}

export default function GPCalendarHeader({
  days,
  display,
  onPrevious,
  onNext,
  onDisplay,
  onDateChange,
  additionalButtons,
}: {
  days: Date[];
  display: "daily" | "weekly";
  onPrevious?: () => void;
  onNext?: () => void;
  onDisplay?: () => void;
  onDateChange?: (date: Date) => void;
  additionalButtons?: ((props: any) => ReactElement<any>)[];
}) {
  function formatDatePickerText() {
    if (days.length === 1) {
      return moment(days[0]).format("dddd DD MMMM YYYY");
    } else if (days.length > 1) {
      return (
        moment(days.at(0)).format("DD") +
        " - " +
        moment(days.at(-1)).format("DD MMMM YYYY")
      );
    }
  }

  return (
    <div className={style["gp-calendar-header"]}>
      <div className={style["gp-calendar-date-navigation"]}>
        <div
          className={clsx(
            style["gp-calendar-date-arrow"],
            style["gp-calendar-date-previous"]
          )}
          onClick={() => onPrevious && onPrevious()}
        >
          <ArrowBackIos />
        </div>
        <div className={style["gp-calendar-date-picker"]}>
          {formatDatePickerText()}
          <input
            type="date"
            className={style["gp-calendar-date-picker-input"]}
            onChange={({ target }) => {
              onDateChange && onDateChange(new Date(target.value));
            }}
          />
        </div>
        <div
          className={clsx(
            style["gp-calendar-date-arrow"],
            style["gp-calendar-date-next"]
          )}
          onClick={() => onNext && onNext()}
        >
          <ArrowForwardIos />
        </div>
      </div>
      <div className={style["gp-calendar-header-buttons-container"]}>
        {additionalButtons &&
          additionalButtons.map((b, i) => (
            <React.Fragment key={`gp-calendar-additional-button-${i}`}>
              {b({})}{" "}
            </React.Fragment>
          ))}
        <div className={style["gp-calendar-header-buttons-separator"]} />
        <GPCalendarHeaderButton
          onClick={() => onDateChange && onDateChange(new Date())}
          icon={(props) => <EventRepeatOutlined {...props} />}
        />
        <GPCalendarHeaderButton
          onClick={onDisplay}
          icon={(props) =>
            display === "daily" ? (
              <CalendarViewWeek {...props} />
            ) : (
              <CalendarViewDay {...props} />
            )
          }
        />
      </div>
    </div>
  );
}
