import moment from "moment";
import { ReactElement, useEffect, useState } from "react";
import GPCalendarDayColumn from "./gpCalendarDayColumn/GPCalendarDayColumn";
import GPCalendarHeader from "./gpCalendarHeader/GPCalendarHeader";
import GPCalendarRowsAxis from "./gpCalendarRowsAxis/GPCalendarRowsAxis";
import style from "./index.module.scss";

export default function GPCalendar({
  initialDate = new Date(),
  initialDisplay = "daily",
  showWeekEnds = false,
  rows,
  data,
  calendarItemComponent,
  calendarItemClassName,
  calendarItemStyle,
  calendarItemTitleClassName,
  calendarItemSubtitleClassName,
  onItemClick,
  onDaysChange,
  displayAxis = true,
  additionalButtons
}: {
  initialDate?: Date;
  initialDisplay?: "daily" | "weekly";
  showWeekEnds?: boolean;
  rows: { name: string; value: any }[];
  data: any;
  calendarItemComponent?: (props: any) => ReactElement<any>;
  calendarItemClassName?: string;
  calendarItemStyle?: (data: any) => React.CSSProperties;
  calendarItemTitleClassName?: string;
  calendarItemSubtitleClassName?: string;
  onItemClick?: (data: any) => void;
  onDaysChange?: (days: Date[]) => void;
  displayAxis?: boolean;
  additionalButtons?: ((props: any) => ReactElement<any>)[]
}) {
  const [display, setDisplay] = useState<"daily" | "weekly">(initialDisplay);
  const [days, setDays] = useState<Date[]>(
    initialDisplay === "daily" ? [initialDate] : getWeekDays(initialDate)
  );

  useEffect(() => {
    onDaysChange && onDaysChange(days);
  }, [days]);

  return (
    <div className={style["gp-calendar-container"]}>
      <div className={style["gp-calendar"]}>
        <GPCalendarHeader
          days={days}
          display={display}
          onPrevious={() => handleAddDays(-1)}
          onNext={() => handleAddDays(1)}
          onDateChange={handleDateChange}
          onDisplay={handleDisplayChange}
          additionalButtons={additionalButtons}
        />
        <div className={style["gp-calendar-body"]}>
          <GPCalendarRowsAxis rows={rows} displayAxis={displayAxis} display={display}/>
          <div className={style["gp-calendar-body-days"]}>
            {days.map((d) => (
              <GPCalendarDayColumn
                key={d.getTime()}
                day={d}
                rows={rows}
                display={display}
                dataset={data}
                calendarItemComponent={calendarItemComponent}
                onHeaderClicked={() => handleDayColumnHeaderClicked(d)}
                calendarItemClassName={calendarItemClassName}
                calendarItemStyle={calendarItemStyle}
                calendarItemTitleClassName={calendarItemTitleClassName}
                calendarItemSubtitleClassName={calendarItemSubtitleClassName}
                onItemClick={onItemClick}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  function handleDayColumnHeaderClicked(day: Date) {
    if (display === "weekly") {
      setDisplay("daily");
    }
    setDays([day]);
  }

  function handleDisplayChange() {
    if (display === "daily") {
      setDays(getWeekDays(days[0] || initialDate));
    } else {
      setDays([days[0] || initialDate]);
    }
    setDisplay((prev) => (prev === "daily" ? "weekly" : "daily"));
  }

  function handleDateChange(newDate: Date) {
    let newDays: Date[] = [];
    if (display === "daily" && days && days.length > 0) {
      newDays = [newDate];
    } else if (display === "weekly" && days && days.length > 0) {
      newDays = getWeekDays(newDate);
    }
    if (newDays.length > 0) {
      setDays(newDays);
    }
  }

  function handleAddDays(diffAmount: number) {
    let newDays: Date[] = [];
    if (display === "daily" && days && days.length > 0) {
      newDays = [moment(days[0]).hours(12).add(diffAmount, "day").toDate()];
    } else if (display === "weekly" && days && days.length > 0) {
      let start = moment(days[0]).hours(12).startOf("week").add(diffAmount, "week");
      newDays = getWeekDays(start.toDate());
    }
    if (newDays.length > 0) {
      setDays(newDays);
    }
  }

  function getWeekDays(date: Date) {
    let newDays: Date[] = [];
    let start = moment(date).hours(12).startOf("week").hours(12);
    for (let index = 0; index < (showWeekEnds ? 7 : 5); index++) {
      newDays.push(moment(start).hours(12).toDate());
      start.add(1, "day");
    }
    return newDays;
  }
}
