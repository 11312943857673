import { Link, useNavigate } from "react-router-dom";
import { IShippingPro } from "../../../types/shipping.types";
import style from "./outgoingShippingProModal.module.scss";
import { LocationOn, Mail, Phone } from "@mui/icons-material";
import ValidateButton from "../../validateButton/validateButton";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../utils/toast.options";
import { finishShippingPro } from "../../../requests/shippingPro";
import ToastedLink from "../../ToastedLink/ToastedLink";

interface OutgoingShippingProModalProps {
  shippingPro: IShippingPro;
  shopName?: string;
}

export default function OutgoingShippingProModal({
  shippingPro,
  shopName,
}: OutgoingShippingProModalProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const IS_CHRONOPOST =
    shippingPro.shippingMethod.shippingService.includes("CHRONOPOST");

  return (
    <div className={style["outgoing-shipping-pro-modal"]}>
      <div className={style["outgoing-shipping-pro-modal-container"]}>
        <div>
          <div className={style["title"]}>
            {"Livraison du "}
            {new Date(shippingPro.shippingDate || "").toLocaleDateString()}
          </div>
          <div className={style["outgoing-shipping-pro-body"]}>
            <div className={style["shipping-info"]}>
              <span className={style["organization"]}>
                {shippingPro.recipient.organization}
              </span>
              <span className={style["shipping-info-item"]}>
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  className={style["address-icon"]}
                  to={`http://maps.google.com/?q=${shippingPro.recipient.address1} ${shippingPro.recipient.address2} ${shippingPro.recipient.zipCode} ${shippingPro.recipient.city}`}
                >
                  <LocationOn />
                </Link>
                {shippingPro.recipient.address1}
              </span>
              <span className={style["shipping-info-item"]}>
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  className={style["address-icon"]}
                  to={`mailto:${shippingPro.recipient.email}`}
                >
                  <Mail />
                </Link>
                {shippingPro.recipient.email}
              </span>
              <span className={style["shipping-info-item"]}>
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  className={style["address-icon"]}
                  to={`tel:${shippingPro.recipient.phone}`}
                >
                  <Phone />
                </Link>
                {shippingPro.recipient.phone}
              </span>
              <span>Nombre d'articles: {shippingPro.trackId?.length}</span>
            </div>
          </div>
        </div>
        <div className={style["button-container"]}>
          <ValidateButton
            title={"Valider"}
            handleOnClick={handleValidateShipping}
            isLoading={isLoading}
            loadingSpinnerStyle={style["validate-button"]}
          />
        </div>
      </div>
    </div>
  );

  async function handleValidateShipping() {
    if (!shippingPro.id || !shippingPro.shopId) {
      toast.error("Shipping ID is missing", TOAST_ERROR_OPTIONS);
      return;
    }
    setIsLoading(true);
    try {
      await finishShippingPro(
        shippingPro.id,
        shippingPro.direction,
        shippingPro.shopId,
        "TRANSITING"
      );
      navigate("/quick-access/pro-shipping?direction=OUTGOING");
      toast.success(
        <ToastedLink
          message={"Livraison finalisée avec succès."}
          linkMessage={`${IS_CHRONOPOST ? "Génerer bon CHRONOPOST" : ""}`}
          handleOnLinkClick={() => navigate(`/shops?name=${shopName}`)}
        />,
        TOAST_SUCCESS_OPTIONS
      );
    } catch (error) {
      toast.error(
        "Un problème est survenu lors de la fin de la collecte",
        TOAST_ERROR_OPTIONS
      );
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }
}
