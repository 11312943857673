import cobblerIcon from "assets/icons/derby-heels.png";
import Icon from "components/Icon/Icon";

interface CobblerIconProps {
  className?: string;
}

export default function CobblerIcon({ className }: CobblerIconProps) {
  return <Icon logo={cobblerIcon} className={className} />;
}
