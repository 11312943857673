import { Outlet, RouteObject } from "react-router-dom";
import ErrorPage from "../errorPage/error-page";

import Referrals from ".";
import Edit from "./edit/Edit";

export const route: RouteObject = {
  path: "/referral",
  element: <Outlet />,

  errorElement: <ErrorPage />,
  children: [
    {
      index: true,
      element: <Referrals />,
    },
    {
      path: "edit/:id",
      element: <Edit />,
    },
  ],
};
