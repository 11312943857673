import { Launch } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { translateTrackingState } from "utils/tracking.init";
import { getTracker } from "../../requests/tracker";
import getStatusColor from "../../utils/translation/statusColor";
import PictureLoadingSpinner from "../loadingSpinner/pictureloadingSpinner";
import styles from "./trackerLink.module.scss";

interface TrackerLinkProps {
  trackId: string;
}

export default function TrackerLink({ trackId }: TrackerLinkProps) {
  const { data: tracker, isLoading } = useQuery({
    queryKey: ["tracker", trackId],
    queryFn: () => getTracker(trackId),
    refetchOnWindowFocus: false,
  });

  if (isLoading) {
    return <PictureLoadingSpinner />;
  }

  if (!tracker) {
    return <>tracker is missing !</>;
  }

  return (
    <Link to={`/overview/${tracker.id}`} className={styles["tracker-link"]}>
      <p className={styles[""]}>
        {!!tracker.eventsList[0].date
          ? new Date(tracker.eventsList[0].date).toLocaleDateString("fr-FR")
          : "indisponible"}
      </p>
      <p className={styles["trackId"]}>{trackId}</p>
      <p
        className={styles["state"]}
        style={{ color: getStatusColor(tracker.state) }}
      >
        {translateTrackingState(tracker.state)}
      </p>
      <span className={styles["launch"]}>
        <Launch className={styles["icon"]} />
      </span>
    </Link>
  );
}
