import { Outlet, RouteObject } from "react-router-dom";
import ErrorPage from "../errorPage/error-page";

import Discount from ".";
import Create from "./create/create";

export const route: RouteObject = {
  path: "/discount",
  element: <Outlet />,
  errorElement: <ErrorPage />,

  children: [
    {
      index: true,
      element: <Discount />,
    },
    {
      path: "create",
      element: <Create />,
    },
  ],
};
