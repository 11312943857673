import { Link } from "react-router-dom";
import translate from "../../../../utils/translation";
import { IShipping } from "../../../../types/shipping.types";
import style from "./item.module.scss";
import logo from "../../../../assets/logo-chronopost.png";
import {
  Download,
  Edit,
  Launch,
  LocationOn,
  Phone,
  ReportProblemTwoTone,
  TimerSharp,
} from "@mui/icons-material";
import { getShippingLabel } from "../../../../requests/shipping";
import { PRINTMODE } from "../../../../utils/shipping.init";
import { useState } from "react";
import ShippingReadModal from "../../../../components/modals/shippingReadModal/shippingReadModal";
import useClickOutside from "../../../../hooks/useClickOutSide";
import MoreTools, {
  IMoreToolsOptions,
} from "../../../../components/MoreTools/MoreTools";
import { toast } from "react-toastify";
import { TOAST_ERROR_OPTIONS } from "../../../../utils/toast.options";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";

export default function Item({ data }: { data: IShipping }) {
  const address = data.direction === "INCOMING" ? data.sender : data.recipient;
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [togglePrintMode, setTogglePrintMode] = useState<boolean>(false);
  const ref = useClickOutside<HTMLDivElement>(() => setTogglePrintMode(false));

  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      icon: <Launch />,
      name: "Ouvrir",
      onClick: handleOpenShippingReadModal,
    },
    {
      icon: <Edit />,
      name: "Modifier",
      to: `/shipping-calendar/edit/${data.id}`,
    },
  ];

  if (
    data.shippingMethod &&
    (data.shippingMethod.shippingService === "CHRONOPOST-2SHOP" ||
      data.shippingMethod.shippingService === "CHRONOPOST-EUR") &&
    data.externalShipping?.reservationNumber !== ""
  ) {
    MORE_TOOLS_OPTIONS.push({
      icon: <Download />,
      name: "Télécharger",
      onClick: () => setTogglePrintMode(!togglePrintMode),
    });
  }

  // () => setTogglePrintMode(!togglePrintMode)

  return (
    <>
      <div className={style["container"]}>
        <div className={style["shipping-info"]}>
          <div className={style["timeslot-date"]}>
            <div className={style["timeslot"]}>{timeSlotText()}</div>
            <div>{dateText()}</div>
          </div>
          {data.direction && (
            <div
              className={
                data.direction === "OUTGOING"
                  ? style["direction-outgoing"]
                  : style["direction-incoming"]
              }
            >
              {translate(data.direction)}
            </div>
          )}
          {(data.shippingMethod?.shippingService === "CHRONOPOST-2SHOP" ||
            data.shippingMethod?.shippingService === "CHRONOPOST-EUR") && (
            <img
              src={logo}
              alt="logo-chrono"
              className={style["chronopost-logo"]}
            />
          )}
        </div>
        {data.contact && address && (
          <div className={style["shipping-contact-info"]}>
            <div className={style["displayname"]}>
              {data.contact.displayname}
            </div>
            <div className={style["shipping-address"]}>
              <div className={style["address"]}>
                <div>
                  {address.address1} {address.address2}
                </div>
                <div>{address.address2}</div>
                <div>
                  {address.zipCode} {address.city} {address.country}
                </div>
                <div className={style["address-comment"]}>
                  {address?.comments}
                </div>
              </div>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                className={style["address-icon"]}
                to={`http://maps.google.com/?q=${address.address1} ${address.address2} ${address.zipCode} ${address.city}`}
              >
                <LocationOn />
              </Link>
            </div>
            <div className={style["shipping-phone"]}>
              <div className={style["phone"]}>{address.phone}</div>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                className={style["address-icon"]}
                to={`tel:${data?.contact.phone}`}
              >
                <Phone />
              </Link>
            </div>
          </div>
        )}
        <div className={style["shipping-product"]}>
          {data.productsList && data.productsList.length}
        </div>
        <div className={style["shipping-state"]}>
          <div className={stateColor()}>
            {translate(data.state || "indisponible")}
          </div>
          <div className={style["shipping-miscellaneous"]}>
            {isLate() && data.takeOverDate && data.period && (
              <div
                className={style["miscellaneous-icons"]}
                title={`retard de ${getTimeDifference(
                  new Date(data.takeOverDate),
                  new Date(data.period.end)
                )}`}
              >
                <TimerSharp />
              </div>
            )}{" "}
            {data.error && hasError() && (
              <div
                title={`${data.error.clientMissing ? "absence client" : ""}${
                  data.error.clientMissing ? "refus client" : ""
                }${data.error.other}`}
                className={style["miscellaneous-icons"]}
              >
                <ReportProblemTwoTone />
              </div>
            )}
          </div>
        </div>

        <div
          className={`${style["shipping-action-container"]} ${
            togglePrintMode
              ? style["shipping-action-container-toggled-list"]
              : ""
          }`}
        >
          <div className={style["shipping-action"]}>
            <MoreTools options={MORE_TOOLS_OPTIONS} />
          </div>
          {data.externalShipping &&
            data.shippingMethod?.shippingService &&
            togglePrintMode && (
              <div className={style["printmode-list-container"]}>
                <div ref={ref} className={style["printmode-list"]}>
                  {PRINTMODE.map((value: string[], index: number) => {
                    return (
                      <div
                        className={style["printmode-list-item"]}
                        key={index}
                        onClick={() => {
                          handleGetShippingLabel(
                            data?.shippingMethod?.shippingService as string,
                            value[1],
                            data?.externalShipping?.reservationNumber
                          );
                        }}
                      >
                        {value[0]}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
        </div>
      </div>
      <ModalContainer
        isOpen={modalIsOpen}
        onCancel={handleCloseShippingReadModal}
      >
        <ShippingReadModal
          id={data.id}
          onClose={handleCloseShippingReadModal}
        />
      </ModalContainer>
    </>
  );

  function handleOpenShippingReadModal() {
    setModalIsOpen(true);
  }

  function handleCloseShippingReadModal() {
    setModalIsOpen(false);
  }

  function isLate(): boolean {
    if (!data.period) {
      return false;
    }
    let takeOver = data.takeOverDate ? new Date(data.takeOverDate) : new Date();
    return (
      new Date(takeOver).getTime() - new Date(data.period.end).getTime() > 0
    );
  }

  function hasError(): boolean {
    if (!data.error) {
      return false;
    }
    return (
      data.error.clientMissing === true ||
      data.error.clientRefusal === true ||
      data.error.other !== ""
    );
  }

  function timeSlotText(): string {
    if (!data.period) {
      return "xx-xx";
    }
    let timeSlot =
      new Date(data.period.start).toLocaleTimeString("fr-FR", {
        hour: "2-digit",
        minute: "2-digit",
      }) +
      " - " +
      new Date(data.period.end).toLocaleTimeString("fr-FR", {
        hour: "2-digit",
        minute: "2-digit",
      });

    if (timeSlot === "08:00 - 20:00") timeSlot = "Toute la journée";

    return timeSlot;
  }

  function dateText(): string {
    if (!data.period) {
      return "error";
    }
    return new Date(data.period.start).toLocaleDateString("fr-FR");
  }

  function stateColor(): string {
    switch (data?.state) {
      case "PENDING":
        return `${style["state"]} ${style["yellow"]}`;
      case "TRANSITING":
        return `${style["state"]} ${style["yellow"]}`;
      case "DELIVERED":
        return `${style["state"]} ${style["green"]}`;
      case "CANCELED":
        return `${style["state"]} ${style["red"]}`;
      default:
        return `${style["state"]} ${style["yellow"]}`;
    }
  }

  function getTimeDifference(date1: Date, date2: Date): string {
    const timeDifference = Math.abs(date2.getTime() - date1.getTime());
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
    const seconds = Math.floor((timeDifference / 1000) % 60);

    return `${hours}h ${minutes}min ${seconds}s`;
  }

  async function handleGetShippingLabel(
    shippingService: string,
    mode: string,
    reservationNumber?: string
  ) {
    if (!reservationNumber) {
      return toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    }
    try {
      const response: any = await getShippingLabel(
        shippingService,
        reservationNumber,
        mode
      );

      const uint8Array = new Uint8Array(response.data);
      const blob = new Blob([uint8Array], {
        type: mode === "ZPL" ? "x-application/zpl" : "application/pdf",
      });

      const downloadLink = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = downloadLink;
      link.download = `chronopost-${reservationNumber}.${
        mode === "ZPL" ? "zpl" : "pdf"
      }`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error: any) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    } finally {
      setTogglePrintMode(!togglePrintMode);
    }
  }
}
