import styles from "./product.module.scss";
import { IProduct } from "../../../../../../types/logistic.types";
import WorkshopId from "../../../../../../components/workshopId/workshopId";
import Image from "../../../../../../components/image/Image";
import OperationGroupLogo from "../../../../../../components/OperationGroupLogo/OperationGroupLogo";
import State from "../../../../../../components/State/State";
import PrintLabelButton from "../../../../../../components/PrintLabel/PrintLabel";
import QualityControlButton from "../../../../../../components/QualityControlButton/QualityControlButton";
import RequiredAction from "components/RequiredAction/RequiredAction";

interface ProductProps {
  product: IProduct;
}

export default function Product({ product }: ProductProps) {
  return (
    <li className={styles["product-item"]}>
      <div className={styles["product-item-left"]}>
        <Image
          className={styles["image-miniature"]}
          src={product.incomingPicturesList?.at(0)}
          showTools={false}
          pictureList={(product?.incomingPicturesList || [])?.concat(
            product?.outgoingPicturesList || []
          )}
        />
        <div>
          <div className={styles["product-description"]}>
            <OperationGroupLogo
              className={styles["operation-logo"]}
              operationGroup={product.operationGroup}
            />
            <p className={styles["brand"]}>{product.brand}</p>
          </div>
          <WorkshopId workshopId={product.workshopId} size="list-size" />
        </div>
      </div>
      <div className={styles["product-item-right"]}>
        <div className={styles["product-item-right-left"]}>
          {missingInformation() && (
            <RequiredAction className={styles["required-action"]} />
          )}
          <QualityControlButton
            className={styles["action-button"]}
            productId={product.id}
          />
          <PrintLabelButton
            className={styles["action-button"]}
            product={product}
          />
        </div>
        <State state={product.status} className={styles["state"]} />
      </div>
    </li>
  );

  function missingInformation() {
    if (
      !product.color ||
      !product.brand ||
      !product.category ||
      !product.incomingPicturesList?.length
    ) {
      return true;
    }
    return false;
  }
}
