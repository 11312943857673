import { DropOffForm, DropOffProductForm } from "types/logistic.types";
import styles from "./dropOffQuoteSummary.module.scss";
import SectionTitle from "components/SectionTitle/SectionTitle";
import DropOffProductsSummary from "../DropOffProductsSummary/DropOffProductsSummary";
import DropOffCrossSellSummary from "../DropOffCrossSellSummary/DropOffCrossSellSummary";
import QuoteSumUp from "pages/overview/read/_components/QuoteSection/QuoteSumUp/QuoteSumUp";
import translate from "utils/translation";
import REFASHION_LOGO from "assets/bonus-reparation-300x182.png";
import Price from "components/Price/Price";
import clsx from "clsx";

interface DropOffQuoteSummaryProps {
  dropOff: DropOffForm;
  onDropOffChange: (dropOff: DropOffForm) => void;
}

export default function DropOffQuoteSummary({
  dropOff,
  onDropOffChange,
}: DropOffQuoteSummaryProps) {
  return (
    <>
      <SectionTitle title="Devis" />
      <ul className={styles["product-list"]}>
        {dropOff?.productList?.map((product, i) => (
          <DropOffProductsSummary
            product={product}
            key={i}
            onProductChange={(product) => handleProductChange(product, i)}
          />
        ))}
      </ul>
      <ul className={styles["product-list"]}>
        {dropOff?.crossSells?.map((crossSell, i) => (
          <DropOffCrossSellSummary crossSell={crossSell} key={i} />
        ))}
      </ul>
      <QuoteSumUp
        className={styles["sub-total"]}
        title={"Sous-total"}
        value={totalOperations() + totalCrossSell()}
      />
      <QuoteSumUp
        className={styles["sub-total"]}
        title={`Livraison (${translate(dropOff.shippingService.name)})`}
        value={dropOff.shippingService.price || 0}
      />
      {!!totalDiscount() && (
        <QuoteSumUp
          className={styles["sub-total"]}
          title={`Réduction`}
          value={-totalDiscount()}
        />
      )}
      <QuoteSumUp
        title={"Total"}
        value={totalDropOff()}
        className={clsx(
          styles["total"],
          !!totalRefashion() && styles["no-margin-bottom"]
        )}
      />
      {!!totalRefashion() && (
        <div className={styles["total-refashion-section"]}>
          <div className={styles["total-refashion"]}>
            {"dont"}
            <Price
              className={styles["refashion-price"]}
              price={totalRefashion()}
            />
            {"de"}
            <img className={styles["discount-logo"]} src={REFASHION_LOGO}></img>
          </div>
        </div>
      )}
    </>
  );

  function totalOperations() {
    return (dropOff?.productList || []).reduce((acc, product) => {
      return (
        acc +
        product.operations.reduce(
          (acc, operation) => acc + (operation.price || 0),
          0
        )
      );
    }, 0);
  }

  function totalCrossSell() {
    return (dropOff?.crossSells || []).reduce(
      (acc, cs) => acc + (cs.price || 0) * (cs.cartQuantity || 1),
      0
    );
  }

  function totalRefashion() {
    let total = (dropOff?.productList || []).reduce((accProd, curProd) => {
      return (
        accProd +
        curProd.operations.reduce((accOp, curOp) => {
          return accOp + (curOp.refashionDiscount || 0);
        }, 0)
      );
    }, 0);
    return total;
  }

  function totalDiscount() {
    if (!dropOff.discount || !dropOff.discount.value) return 0;
    if (!dropOff.discount.isRate) {
      return dropOff.discount.value;
    }
    return totalOperations() / dropOff.discount.value;
  }

  function totalDropOff() {
    const total =
      totalOperations() +
      totalCrossSell() +
      (dropOff.shippingService.price || 0) -
      totalDiscount();
    return total >= 0 ? total : 0;
  }

  function handleProductChange(product: DropOffProductForm, index: number) {
    onDropOffChange({
      ...dropOff,
      productList: dropOff.productList.map((p, i) =>
        index === i ? product : p
      ),
    });
  }
}
