import styles from "./operationGroupSelect.module.scss";
import translate from "utils/translation";
import clsx from "clsx";
import SectionTitle from "components/SectionTitle/SectionTitle";
import OperationGroupLogo from "components/OperationGroupLogo/OperationGroupLogo";

interface OperationGroupSelectProps {
  onChange: (value: string) => void;
  operationGroup: string | null;
  operationGroupList: string[];
}

export default function OperationGroupSelect({
  onChange,
  operationGroup,
  operationGroupList,
}: OperationGroupSelectProps) {
  return (
    <>
      <SectionTitle title={"Groupe d'opérations"} />
      <div className={styles["operation-group-list"]}>
        {operationGroupList.map((op, index) => (
          <div
            className={clsx(styles["operation-group"], {
              [styles["is-selected"]]: operationGroup === op,
            })}
            key={index}
            onClick={() => handleOnOperationGroupSelect(op)}
          >
            <OperationGroupLogo
              className={styles["logo"]}
              operationGroup={op}
            />
            <p>{translate(op)}</p>
          </div>
        ))}
      </div>
    </>
  );

  function handleOnOperationGroupSelect(operationGroup: string) {
    onChange(operationGroup);
  }
}
