import { ILogistic } from "types/logistic.types";
import styles from "./productCheckIn.module.scss";
import { useState } from "react";
import ProductCheckInList from "./ProductCheckInList/ProductCheckInList";
import ProductCheckInFormSection from "./ProductCheckInFormSection/ProductCheckInFormSection";

interface ProductsCheckInProps {
  logistic: ILogistic;
  onLogisticChange: (logistic: ILogistic) => void;
}

export default function ProductsCheckIn({
  logistic,
  onLogisticChange,
}: ProductsCheckInProps) {
  const [selectedProductId, setSelectedProductId] = useState<string | null>(
    logistic.productsList[0].id || null
  );

  return (
    <div className={styles["products-check-in"]}>
      <ProductCheckInList
        onProductSelect={handleSelectProduct}
        productsList={logistic.productsList}
        selectedProductId={selectedProductId}
      />
      <ProductCheckInFormSection
        logistic={logistic}
        onLogisticChange={onLogisticChange}
        selectedProductId={selectedProductId}
      />
    </div>
  );

  function handleSelectProduct(id: string | null) {
    setSelectedProductId(id);
  }
}
