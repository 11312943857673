import BarcodeIcon from "components/icons/BarcodeIcon";
import Widget from "../Widget";
import styles from "./Barcode.module.scss";

export default function Barcode() {

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  if (!isMobile) {
    return null;
  }

  if (!("BarcodeDetector" in window)) {
    return null;
  }

  return (
    <Widget to={"/quick-access/barcode"}>
      <div className={styles["icon-container"]}>
        <BarcodeIcon className={styles["icon"]} />
      </div>
      <p>Scanner un ticket</p>
    </Widget>
  );
}
