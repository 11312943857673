import { useParams } from "react-router-dom";
import styles from "./read.module.scss";
import { getTracker } from "../../../requests/tracker";
import { useQuery } from "@tanstack/react-query";
import OverviewHeader from "./_components/OverviewHeader/OverviewHeader";
import LogisticSection from "./_components/LogisticSection/LogisticSection";
import TabNavigation from "./_components/TabNavigation/TabNavigation";
import { ReactNode, useState } from "react";
import { OverviewTabs } from "../../../types/overview.types";
import QuoteSection from "./_components/QuoteSection/QuoteSection";
import CommentSection from "./_components/CommentSection/CommentSection";
import EstimateSection from "./_components/EstimateSection/EstimateSection";
import ShippingSection from "./_components/ShippingSection/ShippingSection";
import OverviewHistory from "./_components/OverviewHistory/OverviewHistory";
import EstimateTab from "./_components/EstimateTab/EstimateTab";
import QuoteTab from "./_components/QuoteTab/QuoteTab";
import PictureLoadingSpinner from "../../../components/loadingSpinner/pictureloadingSpinner";

export default function Read() {
  const { id } = useParams();
  const { data: tracker, isFetching } = useQuery({
    queryKey: ["tracker-overview", id],
    queryFn: () => getTracker(id || ""),
    enabled: Boolean(id),
    refetchOnWindowFocus: false,
  });
  const [selectedTab, setSelectedTab] = useState<OverviewTabs>("summary");

  if (isFetching) {
    return (
      <div className={styles["loading-container"]}>
        <PictureLoadingSpinner />
      </div>
    );
  }

  if (!tracker) {
    return <></>;
  }

  return (
    <div className={styles["overview-container"]}>
      <OverviewHeader tracker={tracker} onChangeTab={handleSelectTab} />
      <TabNavigation
        selectedTab={selectedTab}
        onSelect={handleSelectTab}
        tracker={tracker}
      />
      <div className={styles["body"]}>{tabsDispatch()}</div>
    </div>
  );

  function handleSelectTab(tab: OverviewTabs) {
    setSelectedTab(tab);
  }

  function tabsDispatch() {
    switch (selectedTab) {
      case "summary":
        return (
          <>
            <Left>
              <LogisticSection
                logisticId={tracker?.logistic?.documentId!}
                dueDate={tracker?.outgoingShipping?.period?.start}
                requiredActions={tracker?.state === "PAYED"}
              />
              <ShippingSection tracker={tracker!} />
              <EstimateSection estimateId={tracker?.estimate?.documentId!} />
            </Left>
            <Right>
              <CommentSection tracker={tracker!} />
              <QuoteSection
                quoteId={tracker?.quote?.documentId!}
                requiredActions={!tracker?.invoice}
              />
            </Right>
          </>
        );
      case "logistic":
        return <LogisticSection logisticId={tracker?.logistic?.documentId!} />;
      case "quote":
        return <QuoteTab quoteId={tracker?.quote?.documentId!} />;
      case "shipping":
        return <ShippingSection tracker={tracker!} />;
      case "estimation":
        return <EstimateTab estimateId={tracker?.estimate?.documentId!} />;
      case "history":
        return <OverviewHistory eventsList={tracker?.eventsList} />;
      default:
        return <></>;
    }
  }
}

interface LeftProps {
  children: ReactNode;
}

function Left({ children }: LeftProps) {
  return (
    <div className={styles["left"]} style={{ flex: 5, marginRight: "0.5rem" }}>
      {children}
    </div>
  );
}

interface RightProps {
  children: ReactNode;
}

function Right({ children }: RightProps) {
  return <div className={styles["right"]}>{children}</div>;
}
