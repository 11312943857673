import styles from "./create.module.scss";
import {
  fetchOperationGroups,
  fetchOperationTypes,
  fetchProductGroups,
} from "../../../requests/operation";
import { OperationForm as OperationFormType } from "../../../types/accounting.types";
import OperationForm from "./_components/OperationForm/OperationForm";
import { useQuery } from "@tanstack/react-query";
import DataError from "components/errors/DataError/DataError";
import PictureLoadingSpinner from "components/loadingSpinner/pictureloadingSpinner";

interface OperationProps {
  operation?: OperationFormType;
}

export default function Create({ operation }: OperationProps) {
  const { data: productGroups, isLoading: productGroupsIsLoading } = useQuery({
    queryKey: ["product-groups"],
    queryFn: () => fetchProductGroups({}),
    refetchOnWindowFocus: false,
  });
  const { data: operationGroups, isLoading: operationGroupsIsLoading } =
    useQuery({
      queryKey: ["operation-groups"],
      queryFn: () => fetchOperationGroups({}),
      refetchOnWindowFocus: false,
    });
  const { data: operationTypes, isLoading: operationTypesIsLoading } = useQuery(
    {
      queryKey: ["operation-types"],
      queryFn: () => fetchOperationTypes({}),
      refetchOnWindowFocus: false,
    }
  );

  if (
    productGroupsIsLoading ||
    operationGroupsIsLoading ||
    operationTypesIsLoading
  ) {
    return (
      <div className={styles["loading-container"]}>
        <PictureLoadingSpinner />
      </div>
    );
  }

  if (!productGroups || !operationGroups || !operationTypes) {
    return (
      <DataError error="product-groups or operation-groups or operation-types missing." />
    );
  }

  return (
    <div className={styles["container"]}>
      <div className={styles["title"]}>Ajouter une opération</div>
      <OperationForm
        operation={operation}
        operationGroups={operationGroups}
        operationTypes={operationTypes}
        productGroups={productGroups}
      />
    </div>
  );
}
