import clsx from "clsx";
import styles from "../modalContainer.module.scss";
import Button from "components/Button/Button";
import { Close } from "@mui/icons-material";
interface ConfirmCloseModalProps {
  onConfirmClose: () => void;
  onCancel: () => void;
}

export default function ConfirmCloseModal({
  onConfirmClose,
  onCancel,
}: ConfirmCloseModalProps) {
  return (
    <div className={styles["modal-container"]}>
      <div
        className={clsx(styles["close-button-container"], styles["fit-width"])}
      >
        <div className={styles["close-button"]} onClick={onCancel}>
          <Close />
        </div>
      </div>
      <div
        className={clsx(
          styles["modal-container-children"],
          styles["fit-width"],
          styles["fit-height"]
        )}
      >
        <div className={styles["confirm-close-modal"]}>
          <h3>Êtes-vous sûr de vouloir quitter ?</h3>
          <div className={styles["confirm-close-modal-button-container"]}>
            <Button className={styles["quit-button"]} onClick={onConfirmClose}>
              Quitter
            </Button>
            <Button onClick={onCancel}>Annuler</Button>
          </div>
        </div>
      </div>
      <div className={styles["background"]} onClick={onCancel}></div>
    </div>
  );
}
