import { useParams } from "react-router-dom";
import Create from "../create/create";
import { fetchEstimate } from "../../../requests/estimate";
import { useQuery } from "@tanstack/react-query";
import style from "./edit.module.scss";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";
import DataError from "components/errors/DataError/DataError";

export default function Edit() {
  const { id } = useParams();
  const { data, isFetching } = useQuery({
    queryKey: ["estimate", id],
    queryFn: () => fetchEstimate({ params: { id: id } }),
    refetchOnWindowFocus: false,
  });

  if (isFetching) {
    return (
      <div className={style["loading-spinner-container"]}>
        <LoadingSpinner color="gold" />
      </div>
    );
  }

  if (!data) {
    return <DataError error="Data is missing" />;
  }
  return <Create data={data} />;
}
