import ConfirmModalComp from "components/confirmationModal/ConfirmModalComp";
import Image from "components/image/Image";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";
import React, { useState } from "react";
import styles from "./imageContainer.module.scss";

interface ImageContainerProps {
  uri: string;
  index: number;
  deleteImage?: () => void;
  pictureList: string[];
}

export default function ImageContainer({
  uri,
  index,
  deleteImage,
  pictureList,
}: ImageContainerProps) {
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  return (
    <>
      <Image
        className={styles["image-preview-miniature"]}
        src={uri}
        index={index}
        handleDelete={handleDeleteImage}
        pictureList={pictureList}
      />
      <ModalContainer
        isOpen={openConfirmModal}
        onCancel={handleCloseConfirmModal}
        width="fit"
        height="fit"
      >
        <ConfirmModalComp
          confirmButtonClassName={styles["delete-button"]}
          title={"Voulez-vous supprimer l'image definitivement"}
          confirmText="Supprimer"
          onConfirm={() => {
            deleteImage && deleteImage();
            handleCloseConfirmModal();
          }}
        />
      </ModalContainer>
    </>
  );

  function handleCloseConfirmModal() {
    setOpenConfirmModal(false);
  }

  function handleDeleteImage() {
    if (!deleteImage) {
      return undefined;
    }
    setOpenConfirmModal(true);
  }
}
