import { DropOffProductForm } from "types/logistic.types";
import styles from "./operationSection.module.scss";
import SectionTitle from "components/SectionTitle/SectionTitle";
import OperationsList from "./OperationsList/OperationsList";
import { IOperation } from "types/accounting.types";

interface OperationSectionProps {
  product: DropOffProductForm | null;
  onUpdateOperation: (operation: IOperation, targetIndex: number) => void;
  onDeleteOperation: (index: number) => void;
}

export default function OperationSection({
  product,
  onUpdateOperation,
  onDeleteOperation,
}: OperationSectionProps) {
  if (!product) {
    return (
      <>
        <SectionTitle
          title={"Opérations"}
          className={styles["section-title"]}
        />
        <p className={styles["message"]}>
          Veuillez ajouter/sélectionner un article.
        </p>
      </>
    );
  }

  if (!product.operationGroup || !product.productGroup) {
    return (
      <>
        <SectionTitle
          title={"Opérations"}
          className={styles["section-title"]}
        />
        <p className={styles["message"]}>
          Veuillez sélectionner un groupe d'articles et un groupe d'opérations
          avant de pouvoir ajouter une opération.
        </p>
      </>
    );
  }

  if (product.operations.length <= 0) {
    return (
      <>
        <SectionTitle
          title={"Opérations"}
          className={styles["section-title"]}
        />
        <p className={styles["message"]}>Aucune opération.</p>
      </>
    );
  }

  return (
    <>
      <SectionTitle title={"Opérations"} className={styles["section-title"]} />
      <OperationsList
        product={product}
        onUpdateOperation={onUpdateOperation}
        onDeleteOperation={onDeleteOperation}
      />
    </>
  );
}
