import { keepPreviousData, useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import FilterByStatus from "../../components/filterByStatus/filterByStatus";
import FiltersContainer from "../../components/Filters/FiltersContainer";
import ListPageHeader from "../../components/ListPageHeader/ListPageHeader";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import PagingComponent from "../../components/pagingComponent/pagingComponent";
import useSaveQueryStorage from "../../hooks/useSaveQueryStorage";
import { getReferralFilter } from "../../requests/referrals";
import { queryObject } from "../../types/query.types";
import { Referral } from "../../types/referral.types";
import { REFERRAL_STATE } from "../../utils/referral.init";
import styles from "./index.module.scss";
import Item from "./item/item";
import ListHeader from "./ListHeader/ListHeader";

export const INITIAL_QUERY: queryObject = {
  field: "",
  value: "",
  limit: 10,
  offset: 0,
};

export default function Referrals() {
  const [query, setQuery] = useSaveQueryStorage(INITIAL_QUERY, "referrals");
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["referrals", query],
    queryFn: () => getReferralFilter(searchParams()),
    placeholderData: keepPreviousData,
  });

  if (isLoading) {
    return <LoadingSpinner color="gold" />;
  }

  if (!data || !data.referrals) {
    return <>Error data is missing !</>;
  }

  return (
    <div className={styles["list-page"]}>
      <ListPageHeader title={"Parrainage"} />
      <FiltersContainer>
        <FilterByStatus
          query={query}
          setQuery={setQuery}
          optionsList={REFERRAL_STATE}
          field={"status"}
        />
      </FiltersContainer>
      <div className={styles["list-container"]}>
        <div className={clsx(styles["list"])}>
          <table className={styles["table"]}>
            <ListHeader />
            <tbody>
              {data.referrals.map((referral: Referral, index: number) => (
                <Item key={index} referral={referral} />
              ))}
            </tbody>
          </table>
        </div>
        <div className={styles["paging-component"]}>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={data?.totalCount}
          />
        </div>
      </div>
    </div>
  );

  function searchParams() {
    const { field, value, ...rest } = query;

    const searchParams = new URLSearchParams(
      Object.entries(rest)
        .filter(
          ([key, value]) =>
            value !== "" && value !== undefined && value !== null
        )
        .map(([key, val]) => [key, String(val)])
    );
    if (query.field && query.value) {
      searchParams.append(query.field, query.value);
    }
    if (!query.sort) {
      searchParams.append("sort_field", "createdAt");
      searchParams.append("sort_direction", "desc");
    }
    return searchParams.toString();
  }
}
