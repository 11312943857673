import { Outlet, RouteObject } from "react-router-dom";
import ErrorPage from "../errorPage/error-page";

import Licenses from ".";
import Create from "./create/create";
import Edit, { loader as editLoader } from "./edit/edit";

export const route: RouteObject = {
  path: "/licenses",
  element: <Outlet />,
  errorElement: <ErrorPage />,

  children: [
    {
      index: true,
      element: <Licenses />,
    },
    {
      path: "create",
      element: <Create />,
    },
    {
      path: "edit/:licenseId",
      loader: editLoader,
      element: <Edit />,
    },
  ],
};
