import ContactForm from "components/forms/ContactForm/ContactForm";
import SectionFormTemplate from "components/forms/sectionFormTemplate/SectionFormTemplate";
import styles from "./contactSection.module.scss";
import { IContact, LogisticForm } from "types/logistic.types";

interface ContactSectionProps {
  formValues: LogisticForm;
  onFormValuesChange: (formValues: LogisticForm) => void;
}

export default function ContactSection({
  formValues,
  onFormValuesChange,
}: ContactSectionProps) {
  return (
    <SectionFormTemplate title={"Contact"}>
      <ContactForm
        contact={formValues.contact}
        setContact={handleContactChange}
        className={styles["contact-form"]}
      />
    </SectionFormTemplate>
  );

  function handleContactChange(contact: IContact) {
    onFormValuesChange({
      ...formValues,
      contact,
      productsList: formValues.productsList.map((p) => ({ ...p, contact })),
    });
  }
}
