import { useQuery } from "@tanstack/react-query";
import { IShippingPro } from "../../../../types/shipping.types";
import { useState } from "react";
import style from "./proShippingList.module.scss";
import { Replay } from "@mui/icons-material";
import { getShippingProList } from "../../../../requests/shippingPro";
import LoadingSpinner from "../../../../components/loadingSpinner/loadingSpinner";
import AddShippingProModal from "../../../../components/modals/addShippingProModal/addShippingProModal";
import SelectAndSearchShop from "../../../../components/SelectAndSearchShop/SelectAndSearchShop";
import { IShop } from "../../../../types/shop.types";
import ShippingProCard from "../shippingProCard/ShippingProCard";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";

interface ProShippingListProps {
  direction: string;
}

export default function ProShippingList({ direction }: ProShippingListProps) {
  const [shop, setShop] = useState<IShop | null>(null);
  const [date, setDate] = useState<Date | null>(null);
  const { data, isFetching } = useQuery({
    queryKey: ["shippings-pro", date, shop],
    queryFn: () => getShippingProList(formatedQuery()),
    refetchOnWindowFocus: true,
  });
  
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <>
      <div className={style["incoming-pro-shipping"]}>
        <div className={style["query-section"]}>
          <div className={style["shop-select"]}>
            <SelectAndSearchShop shop={shop} setShop={setShop} />
          </div>
          <div className={style["date-picker"]}>
            <input
              className={style["date-picker-input"]}
              onChange={handleDateChange}
              type="date"
              value={date ? date.toISOString().substring(0, 10) : ""}
            />
          </div>
          <div className={style["reset-button"]} onClick={handleResetSearch}>
            {date && <Replay />}
          </div>
        </div>
        <div className={style["shipping-list"]}>
          {isFetching ? (
            <div className={style["loading-spinner-container"]}>
              <LoadingSpinner color="gold" />
            </div>
          ) : (
            <div className={style["shipping-pro-card-list"]}>
              {data && (
                <>
                  {data.shippingProList.length > 0 ? (
                    data.shippingProList.map(
                      (shippingPro: IShippingPro, index: number) => {
                        return (
                          <ShippingProCard
                            direction={direction}
                            shippingPro={shippingPro}
                            key={index}
                          />
                        );
                      }
                    )
                  ) : (
                    <div className={style["loading-spinner-container"]}>
                      <span>{`aucune ${
                        direction === "OUTGOING" ? "livraison" : "collecte"
                      } disponible`}</span>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          <div className={style["modal-link"]} onClick={handleOpenModal}>
            {`ajouter une nouvelle ${
              direction === "OUTGOING" ? "livraison" : "collecte"
            }`}
          </div>
        </div>
      </div>
      <ModalContainer isOpen={openModal} onCancel={handleCloseModal}>
        <AddShippingProModal
          direction={direction}
          onValidate={handleCloseModal}
        />
      </ModalContainer>
    </>
  );

  function handleOpenModal() {
    setOpenModal(true);
  }

  function handleCloseModal() {
    setOpenModal(false);
  }

  function handleResetSearch() {
    setDate(null);
    setShop(null);
  }

  function handleDateChange({ target }: { target: HTMLInputElement }) {
    setDate(new Date(target.value));
  }

  function formatedQuery(): string {
    let formatedQuery = `?state=PENDING&direction=${direction}`;

    if (date) {
      formatedQuery += `&shippingDate=${date.toISOString().substring(0, 10)}`;
    }

    if (shop) {
      formatedQuery += `&${
        direction === "INCOMING" ? "sender" : "recipient"
      }.organization=${shop.name}`;
    }

    return formatedQuery;
  }
}
