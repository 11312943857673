import { ProductForm } from "../../../../types/logistic.types";
import Input from "../../../inputs/Input/Input";
import style from "./workshopInfoForm.module.scss";
import {
  OPERATION_GROUP,
  PRODUCT_GROUP,
} from "../../../../utils/logistic.init";
import Select from "../../../Select/Select";
import { useEffect, useMemo } from "react";

interface WorkshopInfoFormProps {
  productForm: ProductForm;
  setProductForm: (product: ProductForm) => void;
}

export default function WorkshopInfoForm({
  productForm,
  setProductForm,
}: WorkshopInfoFormProps) {
  const FILTERED_OPERATION_GROUP = useMemo(
    () => OPERATION_GROUP.filter((op) => op.includes(productForm.productGroup)),
    [productForm.productGroup]
  );

  useEffect(() => {
    if (!productForm.productGroup) {
      return;
    }
    setProductForm({
      ...productForm,
      operationGroup: FILTERED_OPERATION_GROUP[0],
    });
  }, [productForm.productGroup]);

  return (
    <div className={style["workshop-info-form"]}>
      <div className={style["left"]}>Informations atelier</div>
      <div className={style["right"]}>
        <div className={style["input-line"]}>
          <Input
            className={style["input-container"]}
            label="Numéro de ticket *"
            name="workshopId"
            inputMode="numeric"
            value={productForm.workshopId}
            onChange={({ target }) =>
              setProductForm({ ...productForm, workshopId: target.value })
            }
          />
          <Input
            label="Date de livraison"
            type="date"
            name="dueDate"
            value={
              productForm.dueDate &&
              new Date(productForm.dueDate).toISOString().substring(0, 10)
            }
            onChange={({ target }) =>
              setProductForm({
                ...productForm,
                dueDate: new Date(target.value),
              })
            }
          />
        </div>
        <div className={style["input-line"]}>
          <div className={style["select-container"]}>
            <label>Groupe d'articles *</label>
            <Select
              optionsList={PRODUCT_GROUP}
              value={productForm.productGroup}
              setValue={(value) =>
                setProductForm({ ...productForm, productGroup: value })
              }
              doTranslate
              placeholder={"Sélectionnez un groupe d'article..."}
            />
          </div>
          <div className={style["select-container"]}>
            <label>Groupe d'opérations *</label>
            <Select
              optionsList={FILTERED_OPERATION_GROUP}
              value={productForm.operationGroup}
              setValue={(value) =>
                setProductForm({ ...productForm, operationGroup: value })
              }
              doTranslate
              placeholder={"Sélectionnez un groupe d'opérations..."}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
