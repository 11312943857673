import { DropOffForm, IContact } from "types/logistic.types";
import styles from "./dropOffContactModal.module.scss";
import ContactForm from "components/forms/ContactForm/ContactForm";
import { INITIAL_CONTACT } from "utils/shipping.init";
import CustomerTrackersList from "./CustomerTrackersList/CustomerTrackersList";
import SectionTitle from "components/SectionTitle/SectionTitle";
import { useState } from "react";

interface DropOffContactModalProps {
  dropOff: DropOffForm;
  onDropOffChange: (dropOff: DropOffForm) => void;
  onClose: () => void;
}

export default function DropOffContactModal({
  dropOff,
  onDropOffChange,
  onClose,
}: DropOffContactModalProps) {
  const [autoComplete, setAutoComplete] = useState<boolean>(true);
  return (
    <div className={styles["contact-modal"]}>
      <SectionTitle title="Informations contact" />
      <div className={styles["autocomplete-container"]}>
        <div
          className={styles["autocomplete-wrapper"]}
          onClick={handleSetAutoComplete}
        >
          <label className={styles["autocomplete-label"]}>
            {"Auto-complétion"}
          </label>
          <input
            type={"checkbox"}
            checked={autoComplete}
            onChange={handleSetAutoComplete}
            disabled={!!dropOff?.contact?.id}
          />
        </div>
      </div>
      <ContactForm
        autoComplete={autoComplete}
        className={styles["contact-form"]}
        contact={dropOff.contact || INITIAL_CONTACT}
        setContact={handleContactChange}
        detailedForm={false}
      />
      <CustomerTrackersList onClose={onClose} dropOff={dropOff} />
    </div>
  );

  function handleContactChange(contact: IContact) {
    onDropOffChange({ ...dropOff, contact });
  }

  function handleSetAutoComplete() {
    if (dropOff?.contact?.id) {
      return;
    }
    setAutoComplete(!autoComplete);
  }
}
