import { useState } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { logoutRequest } from "../../requests/user";
import style from "./topBar.module.scss";
import { useAuth } from "../../context/auth.context";
import ConfirmModalComp from "../../components/confirmationModal/ConfirmModalComp";
import useClickOutside from "../../hooks/useClickOutSide";
import NavigationBarHeader from "../NavigationBar/NavigationBarHeader/NavigationBarHeader";
import ResponsiveMenu from "../../components/ResponsiveMenu/ResponsiveMenu";
import { Close, ExitToApp, KeyboardArrowDown } from "@mui/icons-material";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";

const DEFAULT_IMG_URL =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6kHZ5dGQTvZsLm9_ZJkWx47dt6IY0pKR2K2qGOhhyCgNBJOiLEQXObJq-0l7dTGlC2Qk&usqp=CAU";

export default function TopBar() {
  const navigate = useNavigate();
  const { username, unregisterUser } = useAuth();
  const [expand, setExpand] = useState<Boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const ref = useClickOutside<HTMLDivElement>(handleClickOutside);
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const Username: string = username || "user";

  return (
    <>
      <div className={style["top-bar"]}>
        <div className={style["left"]}>
          <NavigationBarHeader />
        </div>
        <div className={style["right"]}>
          <div
            className={style["profile"]}
            onClick={() => setExpand(!expand)}
            ref={ref}
          >
            <div className={style["profile-top"]}>
              <div>{capitalizeFirstLetter(Username)}</div>
              <img
                className={style["profile-picture"]}
                src={DEFAULT_IMG_URL}
                alt=""
              />
              <KeyboardArrowDown className={style["back-icon"]} />
            </div>
            <div className={style["sub-menu"]}>
              {expand && (
                <div className={style["container"]}>
                  <div className={style["logout"]} onClick={handleOpenModal}>
                    <ExitToApp className={style["logout-icon"]} />
                    Se déconnecter
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={style["version"]}>Version 2.5.1</div>
          <div className={style["menu"]} onClick={handleOpenMenu}>
            {openMenu ? <Close /> : <MenuIcon />}
          </div>
          {openMenu && (
            <ResponsiveMenu isOpen={openMenu} onClose={handleOpenMenu} />
          )}
        </div>
      </div>
      <ModalContainer
        width="fit"
        height="fit"
        isOpen={openModal}
        onCancel={handleCloseModal}
      >
        <ConfirmModalComp
          confirmButtonClassName={style["delete-button"]}
          title={"Voulez-vous vous déconnecter?"}
          confirmText="Se déconnecter"
          onConfirm={handlelogout}
          onCancel={handleCloseModal}
        />
      </ModalContainer>
    </>
  );

  function handleOpenMenu() {
    setOpenMenu(!openMenu);
  }

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
  }

  function handleOpenModal() {
    setOpenModal(true);
  }

  function handleCloseModal() {
    setOpenModal(false);
  }

  function handlelogout() {
    logoutRequest();
    unregisterUser();
    navigate("/login");
  }

  function handleClickOutside() {
    setExpand(false);
  }
}
