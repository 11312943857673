import { useState } from "react";
import style from "./create.module.scss";
import {
  ILogistic,
  LogisticForm,
  ProductForm,
} from "../../../types/logistic.types";
import { INITIAL_LOGISTIC } from "../../../utils/logistic.init";
import AddProductSection from "./addProductSection/AddProductSection";
import AddCrossSellLogistic from "../../../components/forms/crossSellForm/addCrossSellLogistic";
import CreateLogisticFooter from "./CreateLogisticFooter/CreateLogisticFooter";
import CreateLogisticHeader from "./CreateLogisticHeader/CreateLogisticHeader";
import CommentSection from "./CommentSection/CommentSection";
import ContactSection from "./ContactSection/ContactSection";

export default function Create({
  defaultLogistic,
}: {
  defaultLogistic?: ILogistic;
}) {
  const [formValues, setFormValues] = useState<LogisticForm>(
    defaultLogistic
      ? { ...INITIAL_LOGISTIC, ...defaultLogistic }
      : { ...INITIAL_LOGISTIC }
  );

  return (
    <div className={style["logistic"]}>
      <CreateLogisticHeader
        defaultLogistic={defaultLogistic}
        formValues={formValues}
        onFormValuesChange={handleFormValuesChange}
      />
      <ContactSection
        formValues={formValues}
        onFormValuesChange={handleFormValuesChange}
      />
      <CommentSection
        comments={formValues.comments}
        onCommentsChange={handleOnCommentsChange}
      />
      <hr className={style["separator"]} />
      <AddProductSection
        productsList={formValues?.productsList || []}
        onProductsListChange={handleProductsListChange}
      />
      <AddCrossSellLogistic
        formValues={formValues}
        onFormValuesChange={handleFormValuesChange}
      />
      <CreateLogisticFooter
        defaultLogistic={defaultLogistic}
        formValues={formValues}
        onFormValuesChange={handleFormValuesChange}
      />
    </div>
  );

  function handleOnCommentsChange(comments: string) {
    setFormValues((prev) => ({ ...prev, comments }));
  }

  function handleFormValuesChange(targetLogistic: LogisticForm) {
    setFormValues(targetLogistic);
  }

  function handleProductsListChange(productsList: ProductForm[]) {
    setFormValues((prev) => ({ ...prev, productsList }));
  }
}
