import Icon from "components/Icon/Icon";
import barcodeIcon from "assets/icons/barcode.png";

interface EstimateIconProps {
  className?: string;
}

export default function BarcodeIcon({ className }: EstimateIconProps) {
  return <Icon logo={barcodeIcon} className={className} />;
}
