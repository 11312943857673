import ErrorPage from "pages/errorPage/error-page";
import QualityControl from "pages/qualityControl/QualityControl";
import { Outlet, RouteObject } from "react-router-dom";

export const route: RouteObject = {
  path: "/quality-control",
  element: <Outlet />,

  errorElement: <ErrorPage />,
  children: [
    {
      path: ":productId",
      element: <QualityControl />,
    },
  ],
};
