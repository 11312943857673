import { useHideSideBar } from "context/hideSideBar.context";
import NavigationBar from "layouts/NavigationBar/NavigationBar";
import TopBar from "layouts/TopBar/TopBar.component";
import styles from "./layout.module.scss";
import clsx from "clsx";
import { Outlet } from "react-router-dom";

export function Layout() {
  const { hideSide } = useHideSideBar();

  return (
    <div className={styles["layout"]}>
      <TopBar />
      <NavigationBar />
      <div
        className={clsx(styles["outlet"], hideSide && styles["outlet-small"])}
      >
        <Outlet />
      </div>
    </div>
  );
}
