import { IProduct, ProductForm } from "../../../../types/logistic.types";
import Input from "../../../inputs/Input/Input";
import style from "./productInfoForm.module.scss";
import { PRODUCT_CATEGORIES } from "../../../../utils/operation.init";

interface ProductInfoFormProps {
  productForm: ProductForm;
  setProductForm: (product: ProductForm) => void;
}

export default function ProductInfoForm({
  productForm,
  setProductForm,
}: ProductInfoFormProps) {
  return (
    <div className={style["product-info-form"]}>
      <div className={style["left"]}>Informations produit</div>
      <div className={style["right"]}>
        <div className={style["input-line"]}>
          <Input
            className={style["input-container"]}
            label="Marque *"
            name="brand"
            value={productForm.brand}
            onChange={({ target }) =>
              setProductForm({ ...productForm, brand: target.value })
            }
          />
          <Input
            className={style["input-container"]}
            label="Modèle"
            name="model"
            value={productForm.model}
            onChange={({ target }) =>
              setProductForm({ ...productForm, model: target.value })
            }
          />
        </div>
        <div className={style["input-line"]}>
          <Input
            className={style["input-container"]}
            dataList={PRODUCT_CATEGORIES.map((c) => c.description)}
            label="Catégorie"
            name="category"
            value={productForm.category}
            onChange={({ target }) =>
              setProductForm({ ...productForm, category: target.value })
            }
          />
          <Input
            className={style["input-container"]}
            label="Réference d'origine"
            name="makerReference"
            value={productForm.makerReference}
            onChange={({ target }) =>
              setProductForm({ ...productForm, makerReference: target.value })
            }
          />
        </div>
      </div>
    </div>
  );
}
