import { useEffect, useState } from "react";
import { ProductForm } from "../../../../types/logistic.types";
import { INITIALE_OPERATION } from "../../../../utils/logistic.init";
import style from "./operationSection.module.scss";
import { IOperation } from "../../../../types/accounting.types";
import OperationForm from "./operationForm/OperationForm";
import OperationItem from "./operationItem/OperationItem";

interface OperationFormProps {
  className?: string;
  productForm: ProductForm;
  setProductForm: (product: ProductForm) => void;
}

export default function OperationSection({
  className,
  productForm,
  setProductForm,
}: OperationFormProps) {
  const [operationForm, setOperationForm] = useState<IOperation | null>(null);
  const NO_OPERATION_IS_SELECTED = -1;
  const [selectedOperationIndex, setSelectedOperationIndex] = useState<number>(
    NO_OPERATION_IS_SELECTED
  );

  useEffect(() => {
    if (!operationForm) {
      return;
    }
    setOperationForm((prev) => ({
      ...(prev as IOperation),
      productGroup: productForm.productGroup,
      operationGroup: productForm.operationGroup,
    }));
  }, [productForm]);

  return (
    <div
      className={`${style["operation-section"]} ${className ? className : ""}`}
    >
      <div className={style["operation-section-top"]}>
        <div className={style["left"]}>
          Liste des opérations *
          <ul className={style["operation-list"]}>
            {productForm.operationsList.map((op, index) => {
              return (
                <OperationItem
                  operation={op}
                  index={index}
                  isFormOpen={!!operationForm}
                  key={`${op.slug}-${index}`}
                  handleEditOperation={handleEditOperation}
                  onDelete={handleDelete}
                />
              );
            })}
          </ul>
        </div>
        {operationForm && (
          <OperationForm
            operationForm={operationForm}
            setOperationForm={setOperationForm}
            handleValidateOperation={handleValidateOperation}
          />
        )}
      </div>
      <div className={style["add-new"]} onClick={handleAddNewOperation}>
        Ajouter une nouvelle opération
      </div>
    </div>
  );

  function handleValidateOperation() {
    selectedOperationIndex !== NO_OPERATION_IS_SELECTED
      ? editOperation()
      : appendOperation();
    setOperationForm(null);
  }

  function appendOperation() {
    if (!operationForm) {
      return;
    }
    setProductForm({
      ...productForm,
      operationsList: [...productForm.operationsList, operationForm],
    });
  }

  function editOperation() {
    if (!operationForm) {
      return;
    }
    setProductForm({
      ...productForm,
      operationsList: productForm.operationsList.map((op, i) => {
        return selectedOperationIndex === i ? operationForm : op;
      }),
    });
  }

  function handleAddNewOperation() {
    setOperationForm({
      ...INITIALE_OPERATION,
      operationGroup: productForm.operationGroup,
      productGroup: productForm.productGroup,
    });
    setSelectedOperationIndex(NO_OPERATION_IS_SELECTED);
  }

  function handleEditOperation(operation: IOperation, index: number) {
    setOperationForm(operation);
    setSelectedOperationIndex(index);
  }

  function handleDelete(index: number) {
    setProductForm({
      ...productForm,
      operationsList: productForm.operationsList.filter((_, i) => index !== i),
    });
    setOperationForm(null);
  }
}
