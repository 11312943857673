import ShopDropWidget from "components/widget/ShopDrop/ShopDropWidget";
import IncomingShippingWidget from "../../components/widget/IncomingShipping/IncomingShippingWidget";
import ProShippingWidget from "../../components/widget/ProShipping/ProShippingWidget";
import styles from "./index.module.scss";
import WorkshopWidget from "components/widget/Workshop/WorkshopWidget";
import SendEstimateWidget from "components/widget/SendEstimate/SendEstimateWidget";
import Barcode from "components/widget/Barcode/Barcode";

export default function QuickAccess() {
  return (
    <div className={styles["quick-access"]}>
      <div className={styles["quick-access-grid"]}>
        <ShopDropWidget />
        <IncomingShippingWidget />
        <WorkshopWidget />
        <ProShippingWidget />
        <SendEstimateWidget />
        <Barcode />
      </div>
    </div>
  );
}
