import { IProduct } from "../../../../../types/logistic.types";
import styles from "./modalDetailedProduct.module.scss";
import ProductSheet from "./ProductSheet/ProductSheet";
import ProductOperationsList from "./OperationsList/OperationsList";
import ProductDescription from "./ProductDescription/ProductDescription";
import ProductComment from "./ProductComment/ProductComment";
import ProductDueDate from "./ProductDueDate/ProductDueDate";

interface ModalDetailedProductProps {
  product?: IProduct;
}

export default function ModalDetailedProduct({
  product,
}: ModalDetailedProductProps) {
  if (!product) {
    return null;
  }
  return (
    <div className={styles["modal-detailed-product"]}>
      <ProductSheet product={product} />
      <section className={styles["modal-detailed-product-right"]}>
        <ProductDueDate dueDate={product.dueDate} />
        <ProductOperationsList operationsList={product.operationsList} />
        <ProductDescription product={product} />
        <ProductComment product={product} />
      </section>
    </div>
  );
}
