import style from "./item.module.scss";
import { ICrossSell } from "../../../types/accounting.types";
import { Delete, Edit, Launch } from "@mui/icons-material";
import translate from "../../../utils/translation";
import { useState } from "react";
import ColorComponent from "../../../components/colorComponent/colorComponent";
import ReadModal from "../readModal/readModal";
import { handleImageError } from "../../../utils/utils";
import MoreTools, {
  IMoreToolsOptions,
} from "../../../components/MoreTools/MoreTools";
import Price from "../../../components/Price/Price";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";

interface ItemProps {
  data: ICrossSell;
  handleDelete: (slug: string | undefined) => void;
  onCloseModal: () => void;
}

export default function Item({ data, handleDelete, onCloseModal }: ItemProps) {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      icon: <Launch />,
      name: "Ouvrir",
      onClick: handleOpenModal,
    },
    {
      icon: <Edit />,
      name: "Modifier",
      to: `/cross-selling/edit/${data.slug}`,
    },
    {
      icon: <Delete />,
      name: "Supprimer",
      askConfirmation: true,
      onClick: () => handleDelete(data.slug),
    },
  ];

  return (
    <>
      <div className={style["item"]}>
        <div className={style["item-element"]}>
          <img
            className={style["item-element-miniature"]}
            src={`${process.env.REACT_APP_API_URL}/file/${data.image}`}
            alt="preview"
            onError={handleImageError}
          />
          <span className={style["description"]}>{data.description}</span>
          {data.color && <ColorComponent color={data.color} />}
        </div>
        <div className={style["item-element"]}>
          {data.productGroup &&
            data.productGroup.sort().map((pg: string, index: number) => (
              <div key={index} className={style["product-group-item"]}>
                {translate(pg)}
              </div>
            ))}
        </div>
        <div className={style["item-element"]}>
          {translate(data.category ?? "-")}
        </div>
        <Price className={style["item-element"]} price={data.price} />
        <div className={style["item-element"]}>{data.quantity}</div>
        <div className={`${style["item-element"]} ${style["tools"]}`}>
          <MoreTools options={MORE_TOOLS_OPTIONS} />
        </div>
      </div>
      <ModalContainer isOpen={openModal} onCancel={handleCloseModal}>
        <ReadModal data={data} handleCloseModal={handleCloseModal} />
      </ModalContainer>
    </>
  );

  function handleOpenModal() {
    setOpenModal(true);
  }

  function handleCloseModal() {
    onCloseModal();
    setOpenModal(false);
  }
}
