import { Outlet, RouteObject } from "react-router-dom";
import ErrorPage from "../errorPage/error-page";
import Shippings from ".";
import Create from "./create/Create";
import Read from "./read/read";
import Edit from "./edit/edit";
import Generate from "./generate/generate";

export const route: RouteObject = {
  path: "/shipping-calendar",
  element: <Outlet />,
  errorElement: <ErrorPage />,

  children: [
    {
      index: true,
      element: <Shippings />,
    },
    {
      path: "create",
      element: <Create />,
    },
    {
      path: "read/",
      element: <Read />,
    },
    {
      path: "generate/:trackerId/:direction",
      element: <Generate />,
    },
    {
      path: "edit/:id",
      element: <Edit />,
    },
  ],
};
