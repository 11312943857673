import styles from "./item.module.scss";
import { useRef, useState } from "react";
import { IShop } from "../../../types/shop.types";
import MoreTools, {
  IMoreToolsOptions,
} from "../../../components/MoreTools/MoreTools";
import { updateShop } from "../../../requests/shops";
import { toast } from "react-toastify";
import { TOAST_ERROR_OPTIONS } from "../../../utils/toast.options";
import { isDesktop } from "react-device-detect";
import translate from "../../../utils/translation";
import { Edit, History } from "@mui/icons-material";
import WorkshopId from "../../../components/workshopId/workshopId";
import ShippingProHistoryModal from "../../../components/modals/shippingProHistoryModal/ShippingProHistoryModal";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";
import CenterCell from "../../../components/CenterCell/CenterCell";
import clsx from "clsx";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";

const weekdays = new Map<string, number>();
weekdays.set("sunday", 0);
weekdays.set("monday", 1);
weekdays.set("tuesday", 2);
weekdays.set("wednesday", 3);
weekdays.set("thursday", 4);
weekdays.set("friday", 5);
weekdays.set("saturday", 6);

interface ItemProps {
  refetch: () => void;
  shop: IShop;
}

export default function Item({ shop, refetch }: ItemProps) {
  const nextIncomingShippingRef = useRef<HTMLInputElement>(null);
  const nextOutgoingShippingRef = useRef<HTMLInputElement>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      icon: <Edit />,
      name: "Modifier",
      to: `/licenses?shop=${shop.name}`,
    },
    {
      icon: <History />,
      name: "Historique livraisons",
      onClick: handleOpenModal,
    },
  ];

  return (
    <>
      <tr className={styles["item"]}>
        <td className={clsx(styles["item-cell"])}>
          <div className={styles["shop-item"]}>
            <WorkshopId
              workshopId={shop.workshopPrefixId}
              size="xxx-small"
              className={styles["workshop-prefix-id"]}
            />
            <p>{shop.name}</p>
          </div>
        </td>
        <td className={clsx(styles["item-cell"], styles["shop-item"])}>
          <div className={styles["shop-item"]}>
            <div
              className={`${styles["next-shipping-date"]} ${
                isDesktop ? styles["hover"] : styles["active"]
              }`}
              onClick={() => {
                nextIncomingShippingRef?.current?.showPicker();
              }}
            >
              <label>Collecte</label>
              <div className={styles["next-shipping-date-info"]}>
                {shop.nextIncomingShipping && shop.nextIncomingShipping.date
                  ? new Date(shop.nextIncomingShipping.date)
                      .toLocaleString("fr-FR")
                      .substring(0, 10)
                  : "date à définir"}{" "}
                <Edit className={styles["edit-icon"]} />
              </div>
            </div>
            <input
              className={styles["date-input"]}
              onChange={handleChangeNextIncomingDate}
              type="date"
              ref={nextIncomingShippingRef}
            />
            <div
              className={`${styles["next-shipping-date"]} ${
                isDesktop ? styles["hover"] : styles["active"]
              }`}
              onClick={() => {
                nextOutgoingShippingRef?.current?.showPicker();
              }}
            >
              <label>Livraison</label>
              <div className={styles["next-shipping-date-info"]}>
                {shop.nextOutgoingShipping && shop.nextOutgoingShipping.date
                  ? new Date(shop.nextOutgoingShipping.date)
                      .toLocaleString("fr-FR")
                      .substring(0, 10)
                  : "date à définir"}
                <Edit className={styles["edit-icon"]} />
              </div>
            </div>
            <input
              className={styles["date-input"]}
              onChange={handleChangeNextOutgoingDate}
              type="date"
              ref={nextOutgoingShippingRef}
            />
            <div className={styles["shipping-service"]}>
              <label>Type de livraison</label>
              <div className={styles["shipping-service-info"]}>
                <span className={styles["shipping-service"]}>
                  {translate(shop.shippingMethod.name)}
                </span>
              </div>
            </div>
          </div>
        </td>
        <td className={`${styles["item-cell"]} ${styles["tools"]}`}>
          <ProtectedComponent roles={["ROLE_ADMIN"]}>
            <CenterCell>
              <MoreTools options={MORE_TOOLS_OPTIONS} />
            </CenterCell>
          </ProtectedComponent>
        </td>
      </tr>
      <ModalContainer isOpen={openModal} onCancel={handleCloseModal}>
        <ShippingProHistoryModal name={shop.name} shopId={shop?.id!} />
      </ModalContainer>
    </>
  );

  function handleOpenModal() {
    if (!shop.id) {
      return toast.error(
        "Un problème est survenu. [shopId is missing]",
        TOAST_ERROR_OPTIONS
      );
    }
    setOpenModal(true);
  }

  function handleCloseModal() {
    setOpenModal(false);
  }

  async function handleChangeNextOutgoingDate({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    if (!shop || !shop.id) {
      return;
    }
    if (
      !window.confirm("Voulez-vous changez la date de la prochaine livraison ?")
    ) {
      return;
    }
    try {
      const response = await updateShop(
        {
          ...shop,
          nextOutgoingShipping: {
            date: target.value.substring(0, 10),
            documentId: "",
          },
        },
        shop.id
      );
      refetch();
    } catch (error) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    }
  }

  async function handleChangeNextIncomingDate({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    if (!shop || !shop.id) {
      return;
    }
    if (
      !window.confirm("Voulez-vous changez la date de la prochaine collecte ?")
    ) {
      return;
    }
    try {
      const response = await updateShop(
        {
          ...shop,
          nextIncomingShipping: {
            date: target.value.substring(0, 10),
            documentId: "",
          },
        },
        shop.id
      );
      refetch();
    } catch (error) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    }
  }
}
