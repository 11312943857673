import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { ModalContainer } from "components/modals/ModalContainer/ModalContainer";
import SeriesModal from "components/modals/seriesModal/SeriesModal";
import PagingComponent from "components/pagingComponent/pagingComponent";
import SearchByWorkshopIdV2 from "components/searchByWorkshopIdV2/searchByWorkshopIdV2";
import ProductCard from "pages/quickAccess/workshop/productCard/ProductCard";
import { useState } from "react";
import { getUnassignedProducts } from "requests/series";
import { IProduct, Series } from "types/logistic.types";
import { SeriesNav } from "../Series";
import style from "./unassigned.module.scss";

export default function Unassigned() {
  const [query, setQuery] = useState<any>({
    limit: 10,
    offset: 0,
    sort_field: "product.dueDate",
    sort_direction: "asc",
  });

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null);
  
  const { data, refetch } = useQuery({
    queryKey: [`unassigned-products`, query],
    queryFn: () => getUnassignedProducts(formatQuery(query)),
    placeholderData: keepPreviousData,
  });

  return (
    <div className={style["series"]}>
      <SeriesNav />
      <div className={style["series-content"]}>
        <SearchByWorkshopIdV2
          workshopId={query.workshopId || ""}
          setWorkshopId={(workshopId) => {
            setQuery((prev: any) => ({ ...prev, workshopId }));
          }}
        />
        <div>
          <input
            type="text"
            placeholder="Rechercher par opération..."
            onChange={({ target, ...event }) => {
              const value = target.value;
              setQuery((prev: any) => ({ ...prev, operation: value }));
            }}
          />
        </div>
        <PagingComponent
          query={query}
          setQuery={(q) => {
            setQuery(q);
            // setFormatedQuery(formatQuery(q))
          }}
          totalCount={data?.totalCount || 0}
        />
        <div className={style["product-list"]}>
          {data &&
            data.productsList.map((p: IProduct, index: number) => {
              return (
                <div
                  key={p.id}
                  onClick={(e) => {
                    e.preventDefault();
                    handleClicked(p);
                  }}
                >
                  <ProductCard
                    key={index}
                    product={p}
                    showOperationAcronym={true}
                  />
                </div>
              );
            })}
        </div>
      </div>
      
    </div>
  );

  async function handleOpenModal() {
    if (modalIsOpen) {
      setModalIsOpen(false);
      refetch();
      return;
    }
    setModalIsOpen(true);
  }

  function handleClicked(product: IProduct) {
    setSelectedProduct(product);
    handleOpenModal();
  }

  function formatQuery(query: any) {
    let formatedQuery: string = `?limit=${query.limit}&offset=${
      query.offset * query.limit
    }`;
    if (query.sort_field && query.sort_direction) {
      formatedQuery += `&sort_field=${query.sort_field}&sort_direction=${query.sort_direction}`;
    }
    if (query.workshopId) {
      formatedQuery += `&product.workshopId=${query.workshopId}`;
    }
    if (query.operation) {
      formatedQuery += `&product.operationsList.description=${query.operation}`;
    }
    return formatedQuery;
  }
}
