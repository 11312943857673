import clsx from "clsx";
import styles from "./selectFromList.module.scss";

interface ListProps {
  input: string | null;
  onItemSelect: (item: string) => void;
  searchList: string[];
}

export default function SelectFromList({
  input,
  searchList,
  onItemSelect,
}: ListProps) {
  return (
    <ul className={styles["list"]}>
      {searchList
        .filter(handleFilterList)
        .sort((a, b) => a.localeCompare(b))
        .map((item, index) => {
          return (
            <ListItem
              key={index}
              item={item}
              onItemSelect={onItemSelect}
              isSelected={input === item}
            />
          );
        })}
    </ul>
  );

  function handleFilterList(item: string) {
    if (input) {
      return item
        .toLocaleLowerCase("fr-FR")
        .includes(input.toLocaleLowerCase("fr-FR"));
    }
    return true;
  }
}

interface ListItemProps {
  item: string;
  onItemSelect: (item: string) => void;
  isSelected: boolean;
}

function ListItem({ item, onItemSelect, isSelected }: ListItemProps) {
  return (
    <li
      className={clsx(styles["item"], isSelected && styles["selected"])}
      onClick={() => onItemSelect(item)}
    >
      {item}
    </li>
  );
}
