import styles from "./overviewHeader.module.scss";
import { ITracker } from "../../../../../types/tracker.types";
import OverviewContact from "./OverviewContact/OverviewContact";
import OverviewAction from "./OverviewAction/OverviewAction";
import OverviewReference from "./OverviewReference/OverviewReference";
import { OverviewTabs } from "../../../../../types/overview.types";

interface OverviewHeaderProps {
  tracker: ITracker;
  onChangeTab: (tab: OverviewTabs) => void;
}

export default function OverviewHeader({
  tracker,
  onChangeTab,
}: OverviewHeaderProps) {
  if (!tracker) {
    return <></>;
  }

  return (
    <div className={styles["overview-container-top"]}>
      <OverviewReference tracker={tracker} />
      <OverviewContact
        contactId={tracker?.contact?.documentId}
        trackId={tracker.id}
      />
      <OverviewAction tracker={tracker} onChangeTab={onChangeTab} />
    </div>
  );
}
