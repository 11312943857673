import Widget from "../Widget";
import styles from "./proShipping.module.scss";
import ProDeliveryIcon from "components/icons/ProDeliveryIcon";

export default function ProShippingWidget() {
  return (
    <Widget to={"pro-shipping"}>
      <div className={styles["icon-container"]}>
        <ProDeliveryIcon className={styles["icon"]} />
      </div>
      <div>Livraison/Collecte PRO</div>
    </Widget>
  );
}
