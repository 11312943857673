import Icon from "components/Icon/Icon";
import Widget from "../Widget";
import styles from "./workshop.module.scss";
import workshopIcon from "assets/icons/shoemaker.png";

export default function WorkshopWidget() {
  return (
    <Widget to={"workshop"}>
      <div className={styles["icon-container"]}>
        <Icon logo={workshopIcon} className={styles["icon"]} />
      </div>
      <div>Atelier</div>
    </Widget>
  );
}
