import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { getShop } from "../../../../../requests/shops";
import { IOperation } from "../../../../../types/accounting.types";
import { OPERTATION_TYPE } from "../../../../../utils/logistic.init";
import Select from "../../../../Select/Select";
import Input from "../../../../inputs/Input/Input";
import AutoCompleteOperationInput from "./autoCompleteOperationInput/AutoCompleteOperationInput";
import style from "./operationForm.module.scss";

const CLIMBING_OPTIONS = [
  "Edge 5mm",
  "Edge 4mm",
  "Grip 5mm",
  "Grip 4mm",
  "Grip-2",
];

interface OperationFormProps {
  shopId: string;
  operationForm: IOperation;
  setOperationForm: (operationForm: IOperation | null) => void;
  handleValidateOperation: () => void;
}

export default function OperationForm({
  shopId,
  operationForm,
  setOperationForm,
  handleValidateOperation,
}: OperationFormProps) {
  const [priceIsDisabled, setPriceIsDisabled] = useState<boolean>(false);
  const { data } = useQuery({
    queryKey: ["pro-operation"],
    queryFn: () => getShop(shopId),
  });

  const autoCompleteOperationList = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.pricing.operationsList.filter(
      (op) =>
        op.productGroup === operationForm.productGroup &&
        op.operationGroup === operationForm.operationGroup
    );
  }, [data, operationForm.productGroup, operationForm.operationGroup]);

  return (
    <div className={style["operation-form"]}>
      <div>
        <div className={style["input-line"]}>
          <AutoCompleteOperationInput
            autoCompleteOperationsList={
              autoCompleteOperationList as IOperation[]
            }
            operationForm={operationForm}
            setOperationForm={setOperationForm}
            setPriceIsDisabled={setPriceIsDisabled}
          />
          <Input
            className={style["input-container"]}
            disabled={priceIsDisabled}
            label="Prix *"
            name="price"
            type="number"
            value={operationForm.price || 0}
            onChange={({ target }) =>
              setOperationForm({
                ...operationForm,
                price: parseFloat(target.value) * 100,
              })
            }
          />
        </div>
        <div className={style["input-line"]}>
          <div className={style["select-container"]}>
            <label>Type d'opération *</label>
            <Select
              optionsList={OPERTATION_TYPE}
              value={operationForm.operationType || ""}
              setValue={(value) =>
                setOperationForm({
                  ...operationForm,
                  operationType: value,
                })
              }
              doTranslate
              placeholder={"Sélectionnez un groupe d'article..."}
            />
          </div>
          <div className={style["select-container"]}>
            {operationForm.operationGroup === "SHOES_CLIMBING" && (
              <>
                <label>Type de gomme *</label>
                <Select
                  className={style["rubber-select"]}
                  optionsList={CLIMBING_OPTIONS}
                  placeholder="Type de gomme..."
                  name="comments"
                  value={operationForm.comments || ""}
                  setValue={(value) =>
                    setOperationForm({ ...operationForm, comments: value })
                  }
                />
              </>
            )}
          </div>
        </div>
        <div className={style["operation-form-footer"]}>
          <div
            className={style["validate-button"]}
            onClick={handleValidateOperation}
          >
            Valider opération
          </div>
          <div className={style["delete-button"]} onClick={handleCancel}>
            Annuler
          </div>
        </div>
      </div>
    </div>
  );

  function handleCancel() {
    setOperationForm(null);
  }

  function formatedQuery() {
    return `operationGroup=${operationForm.operationGroup}&productGroup=${operationForm.productGroup}`;
  }
}
