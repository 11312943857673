import { ICrossSellProduct } from "types/accounting.types";
import styles from "./DropOffCrossSellSummary.module.scss";
import Price from "components/Price/Price";

interface DropOffCrossSellSummaryProps {
  crossSell: ICrossSellProduct;
}

export default function DropOffCrossSellSummary({
  crossSell,
}: DropOffCrossSellSummaryProps) {
  return (
    <li>
      <div className={styles["cross-sell-description"]}>
        <p className={styles["cross-sell"]}>{`${crossSell.description} ${
          crossSell.cartQuantity && crossSell.cartQuantity > 1
            ? `(${crossSell.cartQuantity})`
            : ""
        }`}</p>
        <div className={styles["dotted"]}></div>
        <Price
          className={styles["cross-sell-price"]}
          price={(crossSell.cartQuantity || 1) * (crossSell.price || 0)}
        />
      </div>
    </li>
  );
}
