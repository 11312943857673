import { useQuery } from "@tanstack/react-query";
import DataError from "components/errors/DataError/DataError";
import PictureLoadingSpinner from "components/loadingSpinner/pictureloadingSpinner";
import { useParams } from "react-router-dom";
import { getUser } from "../../../requests/user";
import Create from "../create/create";

export default function Edit() {
  const { id } = useParams();
  const { data, isFetching } = useQuery({
    queryKey: ["shipping-method", id],
    queryFn: () => getUser(id),
    refetchOnWindowFocus: false,
    enabled: Boolean(id),
  });

  if (isFetching) {
    return <PictureLoadingSpinner />;
  }

  if (!data) {
    return <DataError error="user" />;
  }

  return <Create data={data} />;
}
