import {
  IMaterials,
  ILogistic,
  IProduct,
  IContact,
  DropOffProductForm,
  DropOffForm,
  ProductForm,
  LogisticForm,
} from "../types/logistic.types";
import { IOperation } from "../types/accounting.types";
import { generateRandomString } from "./utils";

const INITIAL_CONTACT: IContact = {
  displayname: "",
  familyname: "",
  givenname: "",
  phone: "",
  email: "",
  organization: "",
};

export const INITIALE_MATERIAL: IMaterials[] = [
  {
    name: "",
    comments: "",
  },
];

export const INITIAL_DROPOFF_PRODUCT: DropOffProductForm = {
  id: generateRandomString(12),
  selected: true,
  operationGroup: null,
  productGroup: null,
  operations: [],
  color: null,
  brand: null,
  size: null,
  category: null,
  incomingPicturesList: [],
  comments: null,
};

export const INITIAL_DROPOFF: DropOffForm = {
  contact: null,
  productList: [INITIAL_DROPOFF_PRODUCT],
  crossSells: [],
  shippingService: {
    name: "DROP-OR-COLLECT",
    price: 0,
    businessHours: [
      { start: "09:30", end: "12:00" },
      { start: "14:00", end: "18:30" },
    ],
  },
  repareDays: null,
  shippingAddress: null,
  timeSlot: { start: "09:30", end: "12:00" },
  discount: null,
  nonDropOff: false,
};

export const INITIALE_OPERATIONS: IOperation[] = [
  {
    operationType: "SHOES_AUTRE",
    slug: "",
    description: "",
    comments: "",
  },
];

export const INITIALE_OPERATION: IOperation = {
  operationType: "SHOES_AUTRE",
  slug: "",
  description: "",
  comments: "",
  price: 0,
  operationGroup: "GENERIC_OPERATION",
  productGroup: "GENERIC_PRODUCT",
  publicDisplay: false,
};

export const INITIALE_PRODUCT: IProduct[] = [
  {
    workshopId: "",
    id: "",
    contact: INITIAL_CONTACT,
    comments: "",

    productGroup: "GENERIC_PRODUCT",
    operationGroup: "GENERIC_OPERATION",
    category: "",
    brand: "",
    model: "",

    makerReference: "",
    mainMaterialsList: [],
    secondaryMaterialsList: [],
    color: "",
    size: "",

    dueDate: new Date(),
    status: "PENDING_SHELVED",
    operationsList: [],
    incomingPicturesList: [],
    outgoingPicturesList: [],
    logisticId: "",
    displayName: "",
    historiesList: [],
  },
];

export const DEFAULT_PRODUCT: Readonly<IProduct> = {
  workshopId: "",
  id: "",
  contact: INITIAL_CONTACT,
  comments: "",

  productGroup: "",
  operationGroup: "",
  category: "",
  brand: "",
  model: "",

  makerReference: "",
  mainMaterialsList: [],
  secondaryMaterialsList: [],
  color: "",
  size: "",

  // dueDate: new Date(),
  status: "PENDING_SHELVED",
  operationsList: [],
  incomingPicturesList: [],
  outgoingPicturesList: [],
  logisticId: "",
  displayName: "",
  historiesList: [],
};

export const DEFAULT_PRODUCT_FORM: Readonly<ProductForm> = {
  workshopId: "",
  id: "",
  contact: INITIAL_CONTACT,
  comments: "",

  productGroup: "",
  operationGroup: "",
  category: "",
  brand: "",
  model: "",

  makerReference: "",
  mainMaterialsList: [],
  secondaryMaterialsList: [],
  color: "",
  size: "",

  status: "PENDING_SHELVED",
  operationsList: [],
  incomingPicturesList: [],
  outgoingPicturesList: [],
  fileList: {
    incomingPicturesList: [],
    outgoingPicturesList: [],
  },
};

export const INITIALE_STATE: ILogistic = {
  trackId: "",
  id: "",
  contact: INITIAL_CONTACT,
  comments: "",
  status: "LOGISTIC_INCOMING",
  productsList: [],
  mainDueDate: new Date(),
};

export const INITIAL_LOGISTIC: LogisticForm = {
  trackId: "",
  id: "",
  contact: INITIAL_CONTACT,
  comments: "",
  status: "LOGISTIC_INCOMING",
  productsList: [],
  mainDueDate: new Date(),
};

const INITIAL_DROP_ORDER_CONTACT: IContact = {
  displayname: "",
  familyname: "",
  givenname: "",
  phone: "",
  email: "",
  organization: "",
  address1: "",
  city: "",
  zipCode: "",
  country: "",
  countryCode: "",
};

export const INITIALE_DROP_ORDER_LOGISTIC: ILogistic = {
  trackId: "",
  id: "",
  contact: INITIAL_DROP_ORDER_CONTACT,
  comments: "",
  status: "LOGISTIC_INCOMING",
  productsList: [],
  mainDueDate: new Date(),
};

export const PRODUCT_GROUP: string[] = ["SHOES", "LEATHER"];

export const OPERATION_LEATHER_GROUP = ["LEATHER_SMALL", "LEATHER_LARGE"];

export const OPERATION_SHOES_GROUP = [
  "SHOES_MAN",
  "SHOES_WOMAN",
  "SHOES_KID",
  "SHOES_SNEAKERS",
  "SHOES_CLIMBING",
];

export const OPERATION_GROUP = [
  // "GENERIC_OPERATION",
  "SHOES_MAN",
  "SHOES_WOMAN",
  "SHOES_SNEAKERS",
  "SHOES_CLIMBING",
  "LEATHER_LARGE",
];

export const LOGISTIC_STATE = [
  "LOGISTIC_INCOMING",
  "LOGISTIC_QUOTE_ESTIMATION",
  "LOGISTIC_QUOTE_SENT",
  "LOGISTIC_WORKSHOP",
  "LOGISTIC_OUTGOING",
  "LOGISTIC_DONE",
];

export const OPERTATION_TYPE = [
  "SHOES_PATAFER",
  "SHOES_COLLAGE_COUTURE",
  "SHOES_RESSEMELAGE",
  "SHOES_ENTRETIEN",
  "SHOES_TEINTURE",
  "SHOES_MISE_EN_FORME",
  "SHOES_AUTRE",
  // "CLIMBING_AUTRE",
  "CLIMBING_RESSEMELAGE",
  "CLIMBING_ENROBAGE",
  "LEATHER_ENTRETIEN",
  "LEATHER_TEINTURE",
  "LEATHER_TRANCHE",
  "LEATHER_DOUBLURE",
  "LEATHER_JONC",
  "LEATHER_COUTURE",
  "LEATHER_ENCHAPE",
  "LEATHER_POIGNEE",
  "LEATHER_BANDOULIERE",
  "LEATHER_AUTRE",
  "LEATHER_FAG",
];

export const OPERTATION_SUBTYPE = [
  "SHOES_PATAFER_PATIN",
  "SHOES_PATAFER_TALON",
  "SHOES_PATAFER_FER",
  "SHOES_COLLAGE_COUTURE_COLLAGE",
  "SHOES_COLLAGE_COUTURE_COUTURE",
  "CLIMBING_RESSEMELAGE_RESSEMELAGE",
  "CLIMBING_ENROBAGE_ENROBAGE",
  "LEATHER_COUTURE_COUTURE",
  "LEATHER_TEINTURE_TEINTURE",
  "LEATHER_ENTRETIEN_ENTRETIEN",
];

export const PRODUCT_STATE = [
  "PENDING_SHELVED",
  "EXTERNAL_JOB",
  "WORKSHOP_REPAIR",
  "WORKSHOP_CARE",
  "WORKSHOP_OUTGOING",
  "WORKSHOP_COMPLETE",
  "PRODUCT_CONTROLLED",
];

export const PRODUCT_STATE_CHECK_IN = [
  "EXTERNAL_JOB",
  "WORKSHOP_REPAIR",
  "WORKSHOP_CARE",
  "PRODUCT_CONTROLLED",
];

export const logisticStateMap = new Map<string, number>([
  ["LOGISTIC_INCOMING", 0],
  ["LOGISTIC_QUOTE_ESTIMATION", 1],
  ["LOGISTIC_QUOTE_SENT", 2],
  ["LOGISTIC_WORKSHOP", 3],
  ["LOGISTIC_OUTGOING", 4],
  ["LOGISTIC_DONE", 5],
]);

export const LOGISTIC_INCOMING = 0;
export const LOGISTIC_QUOTE_ESTIMATION = 1;
export const LOGISTIC_QUOTE_SENT = 2;
export const LOGISTIC_WORKSHOP = 3;
export const LOGISTIC_OUTGOING = 4;
export const LOGISTIC_DONE = 5;
