import { useDetailedLabel } from "./useDetailedLabel";
import { usePrinter } from "../../../context/printer.context";
import PrintButton from "../../../components/PrintButton/PrintButton";
import CanvasPreview from "./labelPreview";
import styles from "./frontLabel.module.scss";
import clsx from "clsx";
import { useDetailedLabelPreview } from "./useDetailedLabelPreview";

interface RearLabelProps {
  strings: string[];
  workshopId: string;
  dueDate: Date | null;
  isPreview?: boolean;
}

export default function RearLabel({
  strings,
  workshopId,
  dueDate,
  isPreview = false,
}: RearLabelProps) {
  const canvas = useDetailedLabel(
    strings,
    workshopId,
    dueDate
      ? "à faire avant le: " + new Date(dueDate).toLocaleDateString("fr-FR")
      : "-"
  );

  const canvasPreview = useDetailedLabelPreview(
    strings,
    workshopId,
    dueDate
      ? "à faire avant le: " + new Date(dueDate).toLocaleDateString("fr-FR")
      : "-"
  );

  const { onPrint, printProgress, printState, connectionState } = usePrinter();

  return (
    <div className={styles["rear-label"]}>
      <div>
        <canvas
          ref={canvas}
          style={{ border: "1px solid black" }}
          className={clsx(isPreview && styles["is-preview"])}
        />
        <canvas
          ref={canvasPreview}
          style={{ border: "1px solid black" }}
          className={clsx(!isPreview && styles["is-preview"])}
        />
      </div>
      {connectionState === "connected" && (
        <PrintButton
          handleOnPrint={handleOnPrint}
          printProgress={printProgress}
          printState={printState}
        />
      )}
    </div>
  );

  function handleOnPrint() {
    onPrint({ canvas: canvas.current });
  }
}
